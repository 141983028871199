import IconButton from "@mui/material/IconButton";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { BergBalanceResultPath } from "../../../../../views/Demo/Assessments/BergBalanceResultPath";
import HistoricalResults from "./HistoricalResults";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [value, setValue] = React.useState(location.state?.startTab || 0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Current Observation" {...a11yProps(0)} />
            <Tab label="History" {...a11yProps(1)} />
          </Tabs>
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch({ type: "RESET-GAIT-PATIENT-INFO" });
              dispatch({ type: "RESET-BERG-BALANCE-RESULTS" });
              dispatch({ type: "RESET-LAST-BERG-BALANCE-RESULTS" });
              navigate("/all_patients");
            }}
            sx={{ right: 0, position: "absolute" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <BergBalanceResultPath />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <HistoricalResults />
      </CustomTabPanel>
    </Box>
  );
}
