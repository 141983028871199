import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CableIcon from "@mui/icons-material/Cable";
import { Card, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import FirebaseAuthContext from "../../../../contexts/auth/FirebaseAuthContext";
import {
  deleteRoadmap,
  getRoadmaps,
} from "../../../../utilities/Demo/ExerciseManagement/firestore";
import RoadmapConfigurator from "../Configurator/Roadmap/RoadmapConfigurator";
import DeleteButton from "../DeleteButton";

export default function Roadmaps({ searchValue }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [roadmaps, setRoadmaps] = React.useState([]);
  const [configuratorOpen, setConfiguratorOpen] = React.useState(false);
  const [roadmapData, setRoadmapData] = React.useState({});
  const { user } = React.useContext(FirebaseAuthContext);

  const removeRoadmap = (roadmapId) => {
    setRoadmaps(roadmaps.filter((roadmap) => roadmap.id !== roadmapId));
  };

  React.useEffect(() => {
    setLoading(true);
    getRoadmaps({ searchTerm: searchValue, userId: user.uid }).then(
      (schedules) => {
        setLoading(false);
        setRoadmaps(schedules || []);
      }
    );
  }, [searchValue]);

  const addRoadmapToList = (roadmap) => {
    setRoadmaps([...roadmaps, roadmap]);
  };

  const updateRoadmapInList = (roadmap) => {
    setRoadmaps(
      roadmaps.map((r) => {
        if (r.id === roadmap.id) {
          return roadmap;
        }
        return r;
      })
    );
  };

  if (configuratorOpen) {
    return (
      <RoadmapConfigurator
        onClose={() => setConfiguratorOpen(false)}
        addRoadmapToList={addRoadmapToList}
        updateRoadmapInList={updateRoadmapInList}
        roadmapData={roadmapData}
      />
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <h1>Roadmaps</h1>
      <Grid container spacing={2}>
        <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: 2,
              border: 1,
              borderRadius: 5,
              m: 2,
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setRoadmapData({});
                setConfiguratorOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
        {loading && <CircularProgress />}
        {roadmaps.map((roadmap) => {
          return (
            <Grid
              item
              key={roadmap.name}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => {
                setRoadmapData(roadmap);
                setConfiguratorOpen(true);
              }}
              sx={{ ":hover": { cursor: "pointer" } }}
            >
              <Card sx={{ padding: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 1,
                  }}
                >
                  <Grid item xs={8}>
                    <h1>{roadmap.name}</h1>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setRoadmapData({
                          ...roadmap,
                          id: null,
                          name: roadmap.name + " (copy)",
                        });
                        setConfiguratorOpen(true);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spcacing={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <DeleteButton
                    onDelete={() => {
                      deleteRoadmap({ roadmapId: roadmap.id }).then(() => {
                        removeRoadmap(roadmap.id);
                      });
                    }}
                    deletionTarget={"Roadmap " + roadmap.name}
                  />
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="delete patient"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/roadmap/" + roadmap.id);
                    }}
                  >
                    <CableIcon />
                  </IconButton>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
