import React from "react";
import "./trainers.css";
import pose from "../../assets/Pose_02.png";
import Tooltip from "@mui/material/Tooltip";
import tooltipCircle from "../../assets/tooltip_circle.png";

export const WorkoutType = ({ name, status }) => {
  const getBoxStyle = () => {
    switch (status) {
      case "completed":
        return {
          borderColor: "#30B38C",
          background: "rgba(48, 179, 140, 0.15)",
        };
      case "not-complete":
        return {
          borderColor: "#6D7270",
          background: "rgba(109, 114, 112, 0.15)",
        };
      default:
        return null;
    }
  };
  return (
    <div className="workout_cont" style={getBoxStyle()}>
      {" "}
      {status === "completed" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Tooltip
            title="Performed 10 correct counts"
            placement="right-start"
            arrow
          >
            <img
              src={tooltipCircle}
              style={{
                cursor: "pointer",
                width: "1.5em",
                objectFit: "contain",
              }}
            />{" "}
          </Tooltip>{" "}
        </div>
      )}{" "}
      <div className="img_cont">
        <img src={pose} />{" "}
      </div>{" "}
      <div className="name"> {name} </div>{" "}
    </div>
  );
};
