import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";

const flattenConditions = (exerciseData) => {
  exerciseData = { ...exerciseData };
  exerciseData.feedbackConditions = {};
  exerciseData.feedback.forEach((feedback, index) => {
    exerciseData.feedbackConditions[index] = feedback.conditions;
  });

  exerciseData.stateConditions = [];
  exerciseData.states.forEach((state, index) => {
    exerciseData.stateConditions[index] = state.conditions;
  });
  return mapArrays(exerciseData);
};

export const mapArrays = (exerciseData) => {
  exerciseData = { ...exerciseData };
  Object.keys(exerciseData).forEach((key) => {
    if (Array.isArray(exerciseData[key])) {
      exerciseData[key] = exerciseData[key].map((item) => {
        if (typeof item === "object") {
          return mapArrays(item);
        } else {
          return item;
        }
      });
    }
  });
  return exerciseData;
};

const revertToObject = (data) => {
  if (data === null) return data;
  // If data is an object but not an array
  if (typeof data === "object" && !Array.isArray(data)) {
    // Check if object keys are array indices
    const keys = Object.keys(data).sort();
    if (
      keys.length > 0 &&
      keys.every((key, index) => parseInt(key) === index)
    ) {
      return Object.values(data).map(revertToObject);
    }

    // If not, process each property of the object
    const newData = {};
    for (const key in data) {
      newData[key] = revertToObject(data[key]);
    }
    return newData;
  }

  // If data is an array, process each item
  if (Array.isArray(data)) {
    return data.map(revertToObject);
  }

  // If data is neither an array nor an object
  return data;
};

const nestConditions = (exerciseData) => {
  exerciseData = { ...exerciseData };
  exerciseData = revertToObject(exerciseData);
  if (Object.keys(exerciseData.feedbackConditions).length > 0) {
    exerciseData.feedbackConditions.forEach((condition, index) => {
      exerciseData.feedback[index].conditions = condition;
    });
    delete exerciseData.feedbackConditions;
  }

  exerciseData.stateConditions.forEach((condition, index) => {
    exerciseData.states[index].conditions = condition;
  });
  delete exerciseData.stateConditions;
  return exerciseData;
};

export const addExercise = async ({ exerciseData, user }) => {
  exerciseData = flattenConditions(exerciseData);
  const timeStamp = Timestamp.now();
  exerciseData["createdBy"] = user.uid;
  exerciseData["createdAt"] = timeStamp;
  exerciseData["updatedAt"] = timeStamp;
  exerciseData["updatedBy"] = user.uid;
  exerciseData["deletedAt"] = null;
  try {
    const resp = await addDoc(collection(db, "exercises"), {
      ...exerciseData,
    });
    console.log("Exercise added with ID: ", resp?.id);
    return { ...exerciseData, id: resp?.id };
  } catch (e) {
    console.error("Error adding exercise: ", e);
  }
};

export const updateExercise = async ({ exerciseData, user }) => {
  exerciseData = flattenConditions(exerciseData);
  exerciseData["updatedAt"] = Timestamp.now();
  exerciseData["updatedBy"] = user.uid;
  try {
    await updateDoc(doc(db, "exercises", exerciseData.id), {
      ...exerciseData,
    });
    console.log("Exercise updated with ID: ", exerciseData.id);
    return { ...exerciseData };
  } catch (e) {
    console.error("Error updating exercise: ", e, exerciseData);
  }
};

export const deleteExercise = async ({ exerciseId }) => {
  try {
    const exerciseDoc = doc(db, "exercises", exerciseId);
    await updateDoc(exerciseDoc, {
      deletedAt: Timestamp.now(),
    });
    console.log("Exercise deleted with ID: ", exerciseId);
  } catch (e) {
    console.error("Error deleting exercise: ", e);
  }
};

export const getExercise = async ({ exerciseId }) => {
  try {
    const exerciseDoc = doc(db, "exercises", exerciseId);
    const exerciseSnapshot = await getDoc(exerciseDoc);
    if (exerciseSnapshot.exists()) {
      return {
        ...exerciseSnapshot.data(),
        id: exerciseSnapshot.id,
      };
    }
  } catch (e) {
    console.error("Error getting exercise: ", e);
  }
};

export const getExercises = async ({
  searchTerm = "",
  orderByField = "name",
  searchTermField = "name",
  orderByDirection = "asc",
  searchTag = "",
  limit,
}) => {
  try {
    let exercisesQuery = collection(db, "exercises");

    // Add orderBy
    exercisesQuery = query(
      exercisesQuery,
      where("deletedAt", "==", null),
      orderBy(orderByField, orderByDirection)
    );

    // Conditionally add the where clauses based on searchTerm
    if (searchTerm) {
      exercisesQuery = query(
        exercisesQuery,
        where(searchTermField, ">=", searchTerm),
        where(searchTermField, "<=", searchTerm + "~")
      );
    }

    if (searchTag) {
      exercisesQuery = query(
        exercisesQuery,
        where("tags", "array-contains", searchTag)
      );
    }

    // Add the limit
    exercisesQuery = query(exercisesQuery, limit && limit(10));

    const snapshot = await getDocs(exercisesQuery);

    if (snapshot.empty) {
      console.log("No matching documents.");
      return [];
    }

    return snapshot.docs.map((doc) => {
      return nestConditions({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt.toDate().toString(),
        updatedAt: doc.data().updatedAt.toDate().toString(),
      });
    });
  } catch (e) {
    console.error("Error getting exercises: ", e);
  }
};

export const deleteSchedule = async ({ scheduleId }) => {
  try {
    const scheduleDoc = doc(db, "schedules", scheduleId);
    await updateDoc(scheduleDoc, {
      deletedAt: Timestamp.now(),
    });
    console.log("Schedules deleted with ID: ", scheduleId);
  } catch (e) {
    console.error("Error deleting schedule: ", e);
  }
};

export const addSchedule = async ({ scheduleData, user }) => {
  try {
    const timeStamp = Timestamp.now();
    scheduleData["createdBy"] = user.uid;
    scheduleData["createdAt"] = timeStamp;
    scheduleData["updatedAt"] = timeStamp;
    scheduleData["updatedBy"] = user.uid;
    scheduleData["deletedAt"] = null;
    const docRef = await addDoc(collection(db, "schedules"), {
      ...scheduleData,
    });
    console.log("Schedules added with ID: ", docRef.id);
    return {
      id: docRef.id,
      ...scheduleData,
    };
  } catch (e) {
    console.error("Error adding schedule: ", e, scheduleData);
  }
};

export const updateSchedule = async ({ scheduleData, user }) => {
  try {
    scheduleData["updatedAt"] = Timestamp.now();
    scheduleData["updatedBy"] = user.uid;
    await updateDoc(doc(db, "schedules", scheduleData.id), {
      ...scheduleData,
    });
    console.log("Schedules updated with ID: ", scheduleData.id);
    return { ...scheduleData };
  } catch (e) {
    console.error("Error updating schedule: ", e, scheduleData);
  }
};

export const getSchedule = async ({ scheduleId, collection = "schedules" }) => {
  try {
    const scheduleDoc = doc(db, collection, scheduleId);
    const docSnap = await getDoc(scheduleDoc);

    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        id: docSnap.id,
        // createdAt: docSnap.data().createdAt.toDate().toString(),
        // updatedAt: docSnap.data().updatedAt.toDate().toString(),
      };
    } else {
      console.log("No such document!");
    }
  } catch (e) {
    console.error("Error getting schedule: ", e);
  }
};

export const getSchedules = async ({ searchTerm = "" }) => {
  try {
    let schedulesQuery = collection(db, "schedules");
    schedulesQuery = query(
      schedulesQuery,
      where("deletedAt", "==", null),
      // where('createdBy', '==', userId),
      orderBy("name")
    );
    if (searchTerm) {
      schedulesQuery = query(
        schedulesQuery,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "~")
      );
    }
    const snapshot = await getDocs(schedulesQuery);

    if (snapshot.empty) {
      console.log("No matching documents.");
      return [];
    }

    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        // createdAt: doc.data().createdAt.toDate().toString(),
        // updatedAt: doc.data().updatedAt.toDate().toString(),
      };
    });
  } catch (e) {
    console.error("Error getting schedules: ", e);
  }
};

export const addAudioFeedback = async ({ audioFeedbackData, user }) => {
  try {
    const timeStamp = Timestamp.now();
    audioFeedbackData["createdBy"] = user.uid;
    audioFeedbackData["createdAt"] = timeStamp;
    audioFeedbackData["updatedAt"] = timeStamp;
    audioFeedbackData["updatedBy"] = user.uid;
    audioFeedbackData["deletedAt"] = null;
    const docRef = await addDoc(collection(db, "audios"), {
      ...audioFeedbackData,
    });
    console.log("Audio feedback added with ID: ", docRef.id);
    return { ...audioFeedbackData, id: docRef.id };
  } catch (e) {
    console.error("Error adding audio feedback: ", e, audioFeedbackData);
  }
};

export const updateAudioFeedback = async ({ audioFeedbackData, user }) => {
  console.log("audioFeedbackData", JSON.stringify(audioFeedbackData));
  try {
    audioFeedbackData["updatedAt"] = Timestamp.now();
    audioFeedbackData["updatedBy"] = user.uid;
    await updateDoc(doc(db, "audios", audioFeedbackData.id), {
      ...audioFeedbackData,
    });
    console.log("Audio feedback updated with ID: ", audioFeedbackData.id);
    return { ...audioFeedbackData };
  } catch (e) {
    console.error("Error updating audio feedback: ", e, audioFeedbackData);
  }
};

export const deleteAudioFeedback = async ({ audioFeedbackId }) => {
  try {
    const audioFeedbackDoc = doc(db, "audios", audioFeedbackId);
    await updateDoc(audioFeedbackDoc, {
      deletedAt: Timestamp.now(),
    });
    console.log("Audio feedback deleted with ID: ", audioFeedbackId);
  } catch (e) {
    console.error("Error deleting audio feedback: ", e);
  }
};

export const getAudioFeedback = async ({ audioFeedbackId }) => {
  try {
    const audioFeedbackDoc = doc(db, "audios", audioFeedbackId);
    const docSnap = await getDoc(audioFeedbackDoc);

    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        id: docSnap.id,
        // createdAt: docSnap.data().createdAt.toDate().toString(),
        // updatedAt: docSnap.data().updatedAt.toDate().toString(),
      };
    } else {
      console.log("No such document!");
    }
  } catch (e) {
    console.error("Error getting audio feedback: ", e);
  }
};

export const getAudioFeedbacks = async ({
  searchTerm,
  searchTag,
  limitTo = 10,
}) => {
  try {
    let audioFeedbacksQuery = collection(db, "audios");
    audioFeedbacksQuery = query(
      audioFeedbacksQuery,
      where("deletedAt", "==", null),
      orderBy("name")
    );
    if (searchTag) {
      audioFeedbacksQuery = query(
        audioFeedbacksQuery,
        where("tags", "array-contains", searchTag)
      );
    }
    if (searchTerm) {
      audioFeedbacksQuery = query(
        audioFeedbacksQuery,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "~")
      );
    }
    audioFeedbacksQuery = query(audioFeedbacksQuery, limit(limitTo));
    const snapshot = await getDocs(audioFeedbacksQuery);

    if (snapshot.empty) {
      console.log("No matching documents.");
      return [];
    }

    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        // createdAt: doc.data().createdAt.toDate().toString(),
        // updatedAt: doc.data().updatedAt.toDate().toString(),
      };
    });
  } catch (e) {
    console.error("Error getting audio feedbacks: ", e);
  }
};

export const getTransitions = async ({ searchTerm = "" }) => {
  try {
    let transitionsQuery = collection(db, "transitions");
    transitionsQuery = query(
      transitionsQuery,
      where("deletedAt", "==", null),
      // where('createdBy', '==', userId),
      orderBy("name")
    );
    if (searchTerm) {
      transitionsQuery = query(
        transitionsQuery,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "~")
      );
    }
    const snapshot = await getDocs(transitionsQuery);

    if (snapshot.empty) {
      console.log("No matching documents.");
      return [];
    }

    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        // createdAt: doc.data().createdAt.toDate().toString(),
        // updatedAt: doc.data().updatedAt.toDate().toString(),
      };
    });
  } catch (e) {
    console.error("Error getting transitions: ", e);
  }
};

export const deleteTransition = async ({ transitionId }) => {
  try {
    const transitionDoc = doc(db, "transitions", transitionId);
    await updateDoc(transitionDoc, {
      deletedAt: Timestamp.now(),
    });
    console.log("transition deleted with ID: ", transitionId);
  } catch (e) {
    console.error("Error deleting transition: ", e);
  }
};

export const addTransition = async ({ transitionData, user }) => {
  try {
    const timeStamp = Timestamp.now();
    transitionData["createdBy"] = user.uid;
    transitionData["createdAt"] = timeStamp;
    transitionData["updatedAt"] = timeStamp;
    transitionData["updatedBy"] = user.uid;
    transitionData["deletedAt"] = null;
    const docRef = await addDoc(collection(db, "transitions"), {
      ...transitionData,
    });
    console.log("transition added with ID: ", docRef.id);
    return {
      id: docRef.id,
      ...transitionData,
    };
  } catch (e) {
    console.error("Error adding transition: ", e, transitionData);
  }
};

export const updateTransition = async ({ transitionData, user }) => {
  try {
    transitionData["updatedAt"] = Timestamp.now();
    transitionData["updatedBy"] = user.uid;
    await updateDoc(doc(db, "transitions", transitionData.id), {
      ...transitionData,
    });
    console.log("transition updated with ID: ", transitionData.id);
    return { ...transitionData };
  } catch (e) {
    console.error("Error updating transition: ", e, transitionData);
  }
};

export const addTag = async ({ tagData, user, type }) => {
  try {
    const timeStamp = Timestamp.now();
    tagData["createdBy"] = user.uid;
    tagData["createdAt"] = timeStamp;
    tagData["updatedAt"] = timeStamp;
    tagData["updatedBy"] = user.uid;
    tagData["deletedAt"] = null;
    tagData["type"] = type;
    const docRef = await addDoc(collection(db, "tags"), {
      ...tagData,
    });
    console.log("tag added with ID: ", docRef.id);
    return { ...tagData, id: docRef.id };
  } catch (e) {
    console.error("Error adding tag: ", e, tagData);
  }
};

export const updateTag = async ({ tagData, user }) => {
  try {
    tagData["updatedAt"] = Timestamp.now();
    tagData["updatedBy"] = user.uid;
    await updateDoc(doc(db, "tags", tagData.id), {
      ...tagData,
    });
    console.log("tag updated with ID: ", tagData.id);
    return { ...tagData };
  } catch (e) {
    console.error("Error updating tag: ", e, tagData);
  }
};

export const deleteTag = async ({ tagId }) => {
  try {
    const tagDoc = doc(db, "tags", tagId);
    await updateDoc(tagDoc, {
      deletedAt: Timestamp.now(),
    });
    console.log("tag deleted with ID: ", tagId);
  } catch (e) {
    console.error("Error deleting tag: ", e);
  }
};

export const getTag = async ({ tagId }) => {
  try {
    const tagDoc = doc(db, "tags", tagId);
    const docSnap = await getDoc(tagDoc);

    if (docSnap.exists()) {
      if (docSnap.data().deletedAt !== null) return;
      return {
        ...docSnap.data(),
        id: docSnap.id,
        // createdAt: docSnap.data().createdAt.toDate().toString(),
        // updatedAt: docSnap.data().updatedAt.toDate().toString(),
      };
    } else {
      console.log("No such document!", tagId);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getTags = async ({ searchTerm = "", type }) => {
  try {
    let tagsQuery = collection(db, "tags");
    tagsQuery = query(
      tagsQuery,
      where("deletedAt", "==", null),
      where("type", "==", type),
      // where('createdBy', '==', userId),
      orderBy("name")
    );
    if (searchTerm) {
      tagsQuery = query(
        tagsQuery,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "~")
      );
    }
    const snapshot = await getDocs(tagsQuery);

    if (snapshot.empty) {
      console.log("No matching documents.");
      return [];
    }

    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        // createdAt: doc.data().createdAt.toDate().toString(),
        // updatedAt: doc.data().updatedAt.toDate().toString(),
      };
    });
  } catch (e) {
    console.error(
      "Error getting tags: ",
      e,
      "searchTerm",
      searchTerm,
      "type",
      type
    );
  }
};

export const addAngle = async ({ angleData, user }) => {
  try {
    const timeStamp = Timestamp.now();
    angleData["createdBy"] = user.uid;
    angleData["createdAt"] = timeStamp;
    angleData["updatedAt"] = timeStamp;
    angleData["updatedBy"] = user.uid;
    angleData["deletedAt"] = null;
    const docRef = await addDoc(collection(db, "angles"), {
      ...angleData,
    });
    console.log("angle added with ID: ", docRef.id);
    return { ...angleData, id: docRef.id };
  } catch (e) {
    console.error("Error adding angle: ", e, angleData);
  }
};

export const updateAngle = async ({ angleData, user }) => {
  try {
    angleData["updatedAt"] = Timestamp.now();
    angleData["updatedBy"] = user.uid;
    await updateDoc(doc(db, "angles", angleData.id), {
      ...angleData,
    });
    console.log("angle updated with ID: ", angleData.id);
    return { ...angleData };
  } catch (e) {
    console.error("Error updating angle: ", e, angleData);
  }
};

export const getAngles = async ({ searchTerm = "", type = null }) => {
  try {
    let anglesQuery = collection(db, "angles");
    anglesQuery = query(
      anglesQuery,
      where("deletedAt", "==", null)
      // where('createdBy', '==', userId),
    );
    if (searchTerm) {
      anglesQuery = query(
        anglesQuery,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "~")
      );
    }
    if (type) {
      anglesQuery = query(anglesQuery, where("type", "==", type));
    }
    anglesQuery = query(anglesQuery, orderBy("name"));
    const snapshot = await getDocs(anglesQuery);

    if (snapshot.empty) {
      console.log("No matching documents.");
      return [];
    }

    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        // createdAt: doc.data().createdAt.toDate().toString(),
        // updatedAt: doc.data().updatedAt.toDate().toString(),
      };
    });
  } catch (e) {
    console.error("Error getting angles: ", e);
  }
};

export const deleteAngle = async ({ angleId }) => {
  try {
    const angleDoc = doc(db, "angles", angleId);
    await updateDoc(angleDoc, {
      deletedAt: Timestamp.now(),
    });
    console.log("angle deleted with ID: ", angleId);
  } catch (e) {
    console.error("Error deleting angle: ", e);
  }
};

export const getAngle = async ({ angleId }) => {
  try {
    const angleDoc = doc(db, "angles", angleId);
    const docSnap = await getDoc(angleDoc);

    if (docSnap.exists()) {
      console.log("fetched angle", angleId, docSnap.data());
      return {
        ...docSnap.data(),
        id: docSnap.id,
        // createdAt: docSnap.data().createdAt.toDate().toString(),
        // updatedAt: docSnap.data().updatedAt.toDate().toString(),
      };
    } else {
      console.log("No such document!");
    }
  } catch (e) {
    console.error("Error getting angle: ", e);
  }
};

export const addRoadmap = async ({ roadmapData, user }) => {
  try {
    const timeStamp = Timestamp.now();
    roadmapData["createdBy"] = user.uid;
    roadmapData["createdAt"] = timeStamp;
    roadmapData["updatedAt"] = timeStamp;
    roadmapData["updatedBy"] = user.uid;
    roadmapData["deletedAt"] = null;
    const docRef = await addDoc(collection(db, "roadmaps"), {
      ...roadmapData,
    });
    console.log("roadmap added with ID: ", docRef.id);
    return { ...roadmapData, id: docRef.id };
  } catch (e) {
    console.error("Error adding roadmap: ", e, roadmapData);
  }
};

export const updateRoadmap = async ({ roadmapData, user }) => {
  try {
    roadmapData["updatedAt"] = Timestamp.now();
    roadmapData["updatedBy"] = user.uid;
    await updateDoc(doc(db, "roadmaps", roadmapData.id), {
      ...roadmapData,
    });
    console.log("roadmap updated with ID: ", roadmapData.id);
    return { ...roadmapData };
  } catch (e) {
    console.error("Error updating roadmap: ", e, roadmapData);
  }
};

export const getRoadmap = async ({ roadmapId }) => {
  try {
    const roadmapDoc = doc(db, "roadmaps", roadmapId);
    const docSnap = await getDoc(roadmapDoc);

    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        id: docSnap.id,
        // createdAt: docSnap.data().createdAt.toDate().toString(),
        // updatedAt: docSnap.data().updatedAt.toDate().toString(),
      };
    } else {
      console.log("No such document!");
    }
  } catch (e) {
    console.error("Error getting roadmap: ", e);
  }
};

export const getRoadmaps = async ({ searchTerm = "" }) => {
  try {
    let roadmapsQuery = collection(db, "roadmaps");
    roadmapsQuery = query(
      roadmapsQuery,
      where("deletedAt", "==", null),
      orderBy("name")
    );
    if (searchTerm) {
      roadmapsQuery = query(
        roadmapsQuery,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "~")
      );
    }
    const snapshot = await getDocs(roadmapsQuery);

    if (snapshot.empty) {
      console.log("No matching documents.");
      return [];
    }

    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        // createdAt: doc.data().createdAt.toDate().toString(),
        // updatedAt: doc.data().updatedAt.toDate().toString(),
      };
    });
  } catch (e) {
    console.error("Error getting roadmaps: ", e);
  }
};

export const deleteRoadmap = async ({ roadmapId }) => {
  try {
    const roadmapDoc = doc(db, "roadmaps", roadmapId);
    await updateDoc(roadmapDoc, {
      deletedAt: Timestamp.now(),
    });
    console.log("roadmap deleted with ID: ", roadmapId);
  } catch (e) {
    console.error("Error deleting roadmap: ", e);
  }
};

export const addVideo = async ({ videoData, user }) => {
  try {
    const timeStamp = Timestamp.now();
    videoData["createdBy"] = user.uid;
    videoData["createdAt"] = timeStamp;
    videoData["updatedAt"] = timeStamp;
    videoData["updatedBy"] = user.uid;
    videoData["deletedAt"] = null;
    const docRef = await addDoc(collection(db, "videos"), {
      ...videoData,
    });
    console.log("video added with ID: ", docRef.id);
    return { ...videoData, id: docRef.id };
  } catch (e) {
    console.error("Error adding video: ", e, videoData);
  }
};

export const updateVideo = async ({ videoData, user }) => {
  try {
    videoData["updatedAt"] = Timestamp.now();
    videoData["updatedBy"] = user.uid;
    await updateDoc(doc(db, "videos", videoData.id), {
      ...videoData,
    });
    console.log("video updated with ID: ", videoData.id);
    return { ...videoData };
  } catch (e) {
    console.error("Error updating video: ", e, videoData);
  }
};

export const getVideo = async ({ videoId }) => {
  try {
    const videoDoc = doc(db, "videos", videoId);
    const docSnap = await getDoc(videoDoc);

    if (docSnap.exists()) {
      return {
        ...docSnap.data(),
        id: docSnap.id,
        // createdAt: docSnap.data().createdAt.toDate().toString(),
        // updatedAt: docSnap.data().updatedAt.toDate().toString(),
      };
    } else {
      console.log("No such document!");
    }
  } catch (e) {
    console.error("Error getting video: ", e);
  }
};

export const getVideos = async ({ searchTerm = "" }) => {
  try {
    let videosQuery = collection(db, "videos");
    videosQuery = query(
      videosQuery,
      where("deletedAt", "==", null),
      orderBy("name")
    );
    if (searchTerm) {
      videosQuery = query(
        videosQuery,
        where("name", ">=", searchTerm),
        where("name", "<=", searchTerm + "~")
      );
    }
    const snapshot = await getDocs(videosQuery);

    if (snapshot.empty) {
      console.log("No matching documents.");
      return [];
    }

    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        // createdAt: doc.data().createdAt.toDate().toString(),
        // updatedAt: doc.data().updatedAt.toDate().toString(),
      };
    });
  } catch (e) {
    console.error("Error getting videos: ", e);
  }
};

export const deleteVideo = async ({ videoId }) => {
  try {
    const videoDoc = doc(db, "videos", videoId);
    await updateDoc(videoDoc, {
      deletedAt: Timestamp.now(),
    });
    console.log("video deleted with ID: ", videoId);
  } catch (e) {
    console.error("Error deleting video: ", e);
  }
};
