import React, { useEffect, useState } from "react";
import search from "../../assets/search.png";
import moment from "moment";
import "./chat.css";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const AllChats = ({ teachers, setTch, showChat, setShowChat }) => {
  const { t } = useTranslation();
  const [load] = useState(false);
  const [ar, setAr] = useState(teachers);
  const [filter, setFilter] = useState("");
  let det = teachers;

  useEffect(() => {
    if (filter !== "")
      det = teachers.filter((item) =>
        new RegExp("^" + filter, "i").test(item.name)
      );
    setAr(det);
  }, [filter]);
  useEffect(() => {
    setAr(teachers);
  }, [teachers]);
  return (
    <div
      className={
        showChat ? "all-chat-container remove" : "all-chat-container add-dm "
      }
    >
      {load ? (
        <CircularProgress size="small" color="success" />
      ) : (
        <div className="all-chats">
          <div className="search-message-input-container">
            <div className="searchcont">
              <img src={search} className="sclip">
                {" "}
              </img>{" "}
              <input
                placeholder={t("ChatScreen.search")}
                onChange={(event) => setFilter(event.target.value)}
              />{" "}
            </div>{" "}
          </div>{" "}
          {ar &&
            ar.map((item, index) => (
              <div
                key={"single-chat-container hoverable" + index}
                className="single-chat-container hoverable"
                onClick={() => {
                  setTch(item);
                  setShowChat(true);
                }}
              >
                <img src={item.profile_pic} alt="Person Image" />
                <div className="single-person-chat-info">
                  <div className="single-person-chat-info-top">
                    <h2> {item.name} </h2>{" "}
                    <div className="last-msg-info">
                      <span>
                        {" "}
                        {item.recent_chat &&
                        typeof item.recent_chat.date == "number"
                          ? moment(item.recent_chat.date).format("HH:mm")
                          : item.date}{" "}
                      </span>{" "}
                      <div className="single-chat-notification"> </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="single-chat-last-msg">
                    {" "}
                    {item.recent_chat && item.recent_chat.message}{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            ))}{" "}
        </div>
      )}{" "}
    </div>
  );
};

export default AllChats;
