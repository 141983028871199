import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React from "react";

export default function CloseBadge({ setConfig, sx }) {
  return (
    <IconButton
      aria-label="close"
      onClick={() => {
        setConfig(null);
      }}
      sx={{ ...sx }}
    >
      <CloseIcon />
    </IconButton>
  );
}
