import Grid from "@mui/material/Grid";
import React from "react";
import { useParams } from "react-router-dom";
import { LastTest } from "../../../components/Demo/Assessments/BergBalance/LastTest";
import { AssessmentPathMini } from "../../../components/Demo/Assessments/BergBalance/AssessmentPathMini";
import { AssessmentPath } from "../../../components/Demo/Assessments/BergBalance/AssessmentPath";
import { LastTestMini } from "../../../components/Demo/Assessments/BergBalance/LastTestMini";

import "../../../components/Demo/Assessments/assessments.css";

export const BergBalanceResultPath = () => {
  const { type } = useParams();
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        {type === "full" && <AssessmentPath />}
        {type === "mini" && <AssessmentPathMini />}
      </Grid>
      <Grid item xs={4}>
        {type === "full" && <LastTest />}
        {type === "mini" && <LastTestMini />}
      </Grid>
      {/* <ProgressComponent data={board} />
        <SessionComponent data={schedule} /> */}
    </Grid>
  );
};
