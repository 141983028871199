import React, { useRef } from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useCountsInTimeCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import {
  drawAngleText,
  drawArc,
  drawVerticalLine,
  findAngle,
} from "../../../utilities/Demo/physio";
import PoseDetection from "../PoseDetection";
import { useDispatch } from "react-redux";
import { safeIterScore } from "../../../hooks/Demo/sharedLogic";
import { loadPhysioAudio } from "../../../utilities/Demo/audioFeedback";

function PhysioDemo({ onComplete, showWebcam = false, showBackbones = false }) {
  const dispatch = useDispatch();
  let rightArmRaised = useRef(false);
  let rightStartedArmRaise = useRef(false);
  let leftArmRaised = useRef(false);
  let leftStartedArmRaise = useRef(false);
  const showFeedback = useRef(false);
  const timeThresh = 60;
  const {
    correctCount,
    updateFeedback,
    incorrectCount,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadPhysioAudio,
  });
  cptScore.current = () => {
    return (correctCount.current / timeThresh) * 2;
  };

  function observeCounts(angle, armRaised, startedArmRaise) {
    const thresh = 30;
    if (angle > 90) {
      armRaised.current = true;
    } else if (angle < thresh && armRaised.current) {
      showFeedback.current = false;
      armRaised.current = false;
      startedArmRaise.current = false;
      correctCount.current += 1;
      updateCounts(correctCount.current);
      setStartingTime(seconds);
      const score = cptScore.current();
      if (!raiseLevel(score)) {
        playAudio(audioFeedback.current?.achievement, true);
      } else {
        safeIterScore(dispatch, score);
      }
    } else if (angle < thresh && startedArmRaise.current) {
      incorrectCount.current += 1;
      showFeedback.current = true;
      playAudio(audioFeedback.current?.raiseArmOver);
      // updateIncorrectCounts(incorrectCount.current);
      startedArmRaise.current = false;
    } else if (angle > thresh) {
      startedArmRaise.current = true;
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    // Calculate the angle between the right shoulder and elbow
    let right_shoulder = [
      results.poseLandmarks[11].x,
      results.poseLandmarks[11].y,
    ];
    let right_elbow = [
      results.poseLandmarks[13].x,
      results.poseLandmarks[13].y,
    ];
    const right_angle = findAngle(right_shoulder, right_elbow);

    let left_shoulder = [
      results.poseLandmarks[12].x,
      results.poseLandmarks[12].y,
    ];
    let left_elbow = [results.poseLandmarks[14].x, results.poseLandmarks[14].y];
    let left_angle = findAngle(left_shoulder, left_elbow);

    // Draw the vertical lines from the shoulders
    const line_length = 150;
    const line_color = "white";
    const line_width = 3;

    // Draw the angle arc and text on top of the pose landmarks
    let left_arc_radius = 120;
    let left_arc_start = Math.PI / 2;
    if (left_angle > 90) {
      left_angle = 90 + (270 - left_angle);
    }
    let left_arc_end = left_arc_start + left_angle * (Math.PI / 180);

    drawArc(
      canvasCtx,
      left_shoulder,
      left_arc_radius,
      left_arc_start,
      left_arc_end,
      "white",
      3,
      false
    );
    drawAngleText(
      canvasCtx,
      left_angle,
      left_shoulder[0] - left_arc_radius + 40,
      left_shoulder[1] + 60
    );

    drawVerticalLine(
      canvasCtx,
      left_shoulder[0],
      left_shoulder[1],
      line_length,
      line_color,
      line_width
    );

    // Draw the angle arc and text on top of the pose landmarks
    let right_arc_radius = 120;
    let right_arc_start = Math.PI / 2;
    let right_arc_end = right_arc_start - right_angle * (Math.PI / 180);
    drawArc(
      canvasCtx,
      right_shoulder,
      right_arc_radius,
      right_arc_start,
      right_arc_end,
      "white",
      3
    );
    drawAngleText(
      canvasCtx,
      right_angle,
      right_shoulder[0] + right_arc_radius - 90,
      right_shoulder[1] + 60
    );

    drawVerticalLine(
      canvasCtx,
      right_shoulder[0],
      right_shoulder[1],
      line_length,
      line_color,
      line_width
    );

    observeCounts(right_angle, rightArmRaised, rightStartedArmRaise);
    observeCounts(left_angle, leftArmRaised, leftStartedArmRaise);

    if (showFeedback.current) {
      updateFeedback("Raise your arm over 90°");
    } else {
      updateFeedback("");
    }
  }

  return (
    <PoseDetection
      onResults={onResults}
      showWebcam={showWebcam}
      showBackbones={showBackbones}
    />
  );
}

export default React.memo(PhysioDemo);
