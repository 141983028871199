import { baseUrl } from "../../constants";
import axios from "axios";

export const getDashboardData = async (dispatch, email = "") => {
  let token = await localStorage.getItem("token");
  console.log("em::", email);
  return axios
    .get(`${baseUrl}/studentprofile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        email,
      },
    })
    .then(async (response) => {
      dispatch({
        type: "BOARD",
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
    });
};

export const getScheduleData = async (
  dispatch,
  date,
  setLoader,
  dstring = "SCHEDULE"
) => {
  setLoader(true);
  let token = await localStorage.getItem("token");
  console.log("REC::", date);
  return axios
    .get(`${baseUrl}/schedules`, {
      params: {
        date: date,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      console.log("Status is ::" + JSON.stringify(response));
      dispatch({
        type: dstring,
        payload: response.data,
      });
      setLoader(false);
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      dispatch({
        type: dstring,
        payload: {},
      });
      setLoader(false);
    });
};

export const getRankings = async (dispatch, setLoad) => {
  setLoad(true);
  let token = await localStorage.getItem("token");
  let response = await axios.get(`${baseUrl}/studentrankers`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("Status is ::" + JSON.stringify(response));
  dispatch({
    type: "RANKINGS",
    payload: response.data.data,
  });
  setLoad(false);
};

export const getClubData = async (dispatch, setLoad) => {
  setLoad(true);
  let token = await localStorage.getItem("token");
  let response = await axios.get(`${baseUrl}/studentclubdetails`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("Status is ::" + JSON.stringify(response));
  dispatch({
    type: "CLUBDATA",
    payload: response.data.data,
  });
  setLoad(false);
};

export const getAgeClubs = async (dispatch, setLoad) => {
  setLoad(true);
  let token = await localStorage.getItem("token");
  let response = await axios.get(`${baseUrl}/studentageclub`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("Status is ::" + JSON.stringify(response));
  dispatch({
    type: "AGECLUBDATA",
    payload: response.data.data,
  });
  setLoad(false);
};

export const getSweatPointsThresh = async (date) => {
  let token = await localStorage.getItem("token");
  let response = await axios.get(`${baseUrl}/studentsweatpoints?date=${date}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  // console.log('Status is ::' + JSON.stringify(response));
  return response.data;
};
