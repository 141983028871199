import { Button, CircularProgress } from "@mui/material";
import { Dialog, DialogContent, DialogTitle, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { sendMagicLinkToEmail } from "../../../../utilities/Demo/auth";
import { emitToast } from "../../../../utilities/Demo/ExerciseManagement/Configurators/toasts";
import { checkIfNumber } from "../../../../utilities/Demo/generic";
import InputBarComponent from "../../../InputBarComponent";

const MyDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    margin: 0,
    width: "80vw",
    maxWidth: "800px",
  },
});

export default function PatientInfoDialog({
  open,
  onClose,
  addPatient,
  patientData,
  updatePatient,
}) {
  const [patientName, setPatientName] = useState(patientData.name);
  const [patientHeight, setPatientHeight] = useState(patientData.height || 0);
  const [patientAge, setPatientAge] = useState(patientData.age || 0);
  const [patientWeight, setPatientWeight] = useState(patientData.weight || 0);
  const [patientFootSize, setPatientFootSize] = useState(
    patientData.footSize || 0
  );
  const [patientArch, setPatientArch] = useState(patientData.arch || 0);
  const [patientGender, setPatientGender] = useState(patientData.gender || "");
  const [patientEmail, setPatientEmail] = useState(patientData.email || "");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPatientName(patientData.name || "");
    setPatientHeight(patientData.height || 0);
    setPatientAge(patientData.age || 0);
    setPatientWeight(patientData.weight || 0);
    setPatientFootSize(patientData.footSize || 28);
    setPatientArch(patientData.arch || 27);
    setPatientGender(patientData.gender || "");
    setPatientEmail(patientData.email || "");
  }, [patientData]);

  const buttonEnabled = () => {
    return (
      patientName !== "" &&
      patientHeight !== 0 &&
      patientAge !== 0 &&
      patientGender !== "" &&
      // patientEmail !== '' &&
      patientWeight !== 0 &&
      patientFootSize !== 0 &&
      patientArch !== 0 &&
      !error
    );
  };

  const resetFields = () => {
    setPatientAge(0);
    setPatientHeight(0);
    setPatientName("");
    setPatientGender("");
    setPatientEmail("");
    setLoading(false);
  };

  return (
    <MyDialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Patient information</DialogTitle>
      <DialogContent sx={{ width: "100%", maxWidth: "800px" }}>
        <Grid container spacing={2} sx={{ marginTop: 5 }}>
          <Grid item xs={12}>
            <InputBarComponent
              label="Patient name"
              name="patient-name"
              type="text"
              value={patientName}
              onChange={(e) => {
                setPatientName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputBarComponent
              label="Patient height (cm)"
              name="patient-height"
              type="text"
              value={patientHeight}
              onChange={(e) => {
                if (checkIfNumber(e.target.value) || e.target.value === "") {
                  setPatientHeight(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputBarComponent
              label="Patient age"
              name="patient-age"
              type="text"
              value={patientAge}
              onChange={(e) => {
                if (checkIfNumber(e.target.value) || e.target.value === "") {
                  setPatientAge(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputBarComponent
              label="Patient weight (KG)"
              name="patient-weight"
              type="number"
              value={patientWeight}
              onChange={(e) => {
                if (checkIfNumber(e.target.value) || e.target.value === "") {
                  setPatientWeight(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputBarComponent
              label="Patient Foot Size [optional]"
              name="patient-foot-size"
              type="number"
              value={patientFootSize}
              onChange={(e) => {
                if (checkIfNumber(e.target.value) || e.target.value === "") {
                  setPatientFootSize(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputBarComponent
              label="Patient Arch [optional]"
              name="patient-arch"
              type="number"
              value={patientArch}
              onChange={(e) => {
                if (checkIfNumber(e.target.value) || e.target.value === "") {
                  setPatientArch(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={patientGender}
                  label="Gender"
                  onChange={(e) => {
                    setPatientGender(e.target.value);
                  }}
                  sx={{ borderRadius: 30 }}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"divers"}>Divers</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InputBarComponent
              label="Patient email [optional]"
              name="patient-email"
              type="email"
              error={error}
              value={patientEmail}
              onChange={(e) => {
                const err = !e.currentTarget.checkValidity();
                setError(err); // Use the browser's constraint validation
                setPatientEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="text"
              onClick={() => {
                resetFields();
                onClose();
              }}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!buttonEnabled()}
              onClick={() => {
                const patientNewData = {
                  id: patientData?.id,
                  name: patientName,
                  height: patientHeight,
                  age: patientAge,
                  gender: patientGender,
                  email: patientEmail,
                  weight: patientWeight,
                  footSize: patientFootSize,
                  arch: patientArch,
                };
                setLoading(true);
                if (!patientData?.id) {
                  addPatient(patientNewData).then((patientDoc) => {
                    if (patientDoc) {
                      emitToast({
                        render: "Added patient " + patientName,
                        type: "success",
                      });
                      if (patientEmail) {
                        sendMagicLinkToEmail(patientEmail).then(() => {
                          resetFields();
                          onClose();
                        });
                      } else {
                        onClose();
                      }
                    } else {
                      emitToast({
                        render: "Failed Adding patient " + patientName,
                        type: "error",
                      });
                      setLoading(false);
                    }
                  });
                } else {
                  setLoading(true);
                  updatePatient(patientNewData).then((patientDataUpdate) => {
                    if (patientDataUpdate) {
                      emitToast({
                        render: "Updated patient " + patientName,
                        type: "success",
                      });
                      resetFields();
                      onClose();
                    } else {
                      emitToast({
                        render: "Failed updating patient " + patientName,
                        type: "error",
                      });
                      setLoading(false);
                    }
                  });
                }
              }}
            >
              {loading ? <CircularProgress /> : "Save"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </MyDialog>
  );
}
