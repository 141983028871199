const initialState = {};

export const rewardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REWARDS": {
      console.log("RWS is::" + JSON.stringify(action));
      return {
        values: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
