import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useBalanceCoreFeedback } from "../../../../../hooks/Demo/feedbackTypes";
import { safeIterScore } from "../../../../../hooks/Demo/sharedLogic";
import {
  checkIfRightSide,
  checkIfSide,
} from "../../../../../utilities/Demo/angles";
import { loadStandWithFeetInLineAudio } from "../../../../../utilities/Demo/audioFeedback";
import {
  findAdjustedAngle,
  findDependentAngle,
} from "../../../../../utilities/Demo/physio";
import PoseDetection from "../../../PoseDetection";
import {
  backRomMetrics,
  computeMetrics,
  upperLegRomMetrics,
} from "../../../../../utilities/Demo/Physio/BergBalance/metrics";

export default function StandWithFeetInLine({
  onComplete,
  showWebcam = false,
}) {
  const timeThresh = 60;
  const dispatch = useDispatch();
  const isLeft = useRef(false);
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    raiseLevel,
    setSide,
    cptScore,
    updatePoseHeldDeps,
    playAudio,
    audioFeedback,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadStandWithFeetInLineAudio,
    maxScore: 1,
  });
  const metrics = useRef(backRomMetrics().concat(upperLegRomMetrics()));

  cptScore.current = () => {
    const secondsCount =
      secondsPoseHeldComplete.current + secondsPoseHeld.current;
    if (secondsCount >= 30) return 1;
    if (secondsCount >= 15) return 0.5;
    return 0;
  };

  function observation(backAngle, kneeAngle, results) {
    const state = holdsPose.current;
    if (
      results.poseLandmarks[24].visibility > 0.5 &&
      results.poseLandmarks[26].visibility > 0.5 &&
      results.poseLandmarks[28].visibility > 0.5 &&
      results.poseLandmarks[23].visibility > 0.5 &&
      results.poseLandmarks[25].visibility > 0.5 &&
      results.poseLandmarks[27].visibility > 0.5 &&
      results.poseLandmarks[29].visibility > 0.5 &&
      results.poseLandmarks[30].visibility > 0.5 &&
      results.poseLandmarks[31].visibility > 0.5 &&
      results.poseLandmarks[32].visibility > 0.5
    ) {
      if (
        backAngle > 70 &&
        backAngle < 110 &&
        kneeAngle > 160 &&
        kneeAngle < 190
      ) {
        updateFeedback("");
        const ankleLeft = results.poseLandmarks[29];
        const ankleRight = results.poseLandmarks[30];
        const toeLeft = results.poseLandmarks[31];
        const toeRight = results.poseLandmarks[32];
        if (
          Math.abs(1 - ankleLeft.y / ankleRight.y) < 0.05 &&
          Math.abs(1 - toeLeft.y / toeRight.y) < 0.05 &&
          (Math.abs(ankleLeft.x - toeRight.x) >=
            Math.abs(ankleRight.x - toeRight.x) * 1.7 ||
            Math.abs(ankleRight.x - toeLeft.x) >=
              Math.abs(ankleLeft.x - toeLeft.x) * 1.7)
        ) {
          holdsPose.current = true;
          secondsPoseHeld.current =
            seconds.current - secondPoseHeldStarted.current;
          updateFeedback("");
        } else {
          updateFeedback("Stand with your feet in one line!");
          // updateFeedback(
          //   `${Math.abs(1 - ankleLeft.y / ankleRight.y).toFixed(3)} ${Math.abs(
          //     1 - toeLeft.y / toeRight.y
          //   ).toFixed(3)} `
          // );
          holdsPose.current = false;
        }
      } else {
        updateFeedback("Stand straight!");
        holdsPose.current = false;
      }
    } else {
      updateFeedback("Person out of frame!");
      holdsPose.current = false;
    }
    updatePoseHeldDeps(state);
    if (secondsPoseHeldComplete.current + secondsPoseHeld.current >= 30) {
      const score = cptScore.current();
      raiseLevel(score);
      safeIterScore(dispatch, score);
      onComplete();
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback("Turn to the side!");
      setSide(false);
      return;
    } else {
      updateFeedback("");
      setSide(true);
    }
    isLeft.current = checkIfRightSide(results, "left");
    let kneeAngle;
    let backAngle;
    let hipKneeAngle;
    let displayConfigs;

    if (isLeft.current) {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      backAngle = findAdjustedAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      hipKneeAngle =
        360 -
        findAdjustedAngle(results.poseLandmarks[23], results.poseLandmarks[25]);
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[23],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[11],
          left: false,
        },
      };
    } else {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (kneeAngle > 180) {
        kneeAngle = 360 - kneeAngle;
      }
      backAngle =
        360 -
        findAdjustedAngle(
          results.poseLandmarks[12],
          results.poseLandmarks[24],
          0
        );
      hipKneeAngle = findAdjustedAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26]
      );
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[24],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[12],
          left: false,
        },
      };
    }
    computeMetrics({
      metrics: metrics.current,
      angles: {
        current: {
          backAngle,
          hipKneeAngle,
        },
      },
      dispatch,
      displayConfigs,
      canvasCtx,
    });
    observation(backAngle, kneeAngle, results);
    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
