import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import BalanceIcon from "@mui/icons-material/Balance";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import Button from "@mui/material/Button";
import { useContext } from "react";
import FirebaseAuthContext from "../../../../contexts/auth/FirebaseAuthContext";

const MyDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    margin: 0,
    width: "80vw",
    maxWidth: "800px",
  },
});

export function SelectRecordingDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigationGoals = [
    () => navigate("/gait_analysis"),
    () => {
      dispatch({
        type: "RESET-ITER",
      });
      navigate("/demos/sit_and_stand_assessment");
    },
    () => {
      dispatch({
        type: "RESET-ITER",
        payload: { maxScore: 1 },
      });
      dispatch({
        type: "SET-ITER-RAISE-LEVEL-SOUNDS",
        payload: false,
      });
      navigate("/berg_balance_result/mini");
    },
    () => {
      dispatch({
        type: "RESET-ITER",
        payload: { maxScore: 1 },
      });
      dispatch({
        type: "SET-ITER-RAISE-LEVEL-SOUNDS",
        payload: false,
      });
      navigate("/berg_balance_result/full");
    },
    () => {
      navigate("/schedule_results/A7Du5i05tX9Uh1ajduoh");
    },
    () => {
      navigate("/schedule_results/TsKuZ7mNfPxwLeYPlN1u");
    },
    () => {
      navigate("/schedule_results/7CQwfA1NzzKlGgGzLPuF");
    },
    () => {
      navigate("/schedule_results/uK3TzSerSqHVi1pea0Ma");
    },
  ];

  return (
    <SelectNavigationDialog
      open={open}
      setOpen={setOpen}
      navigationGoals={navigationGoals}
    />
  );
}

export function SelectResultsDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigationGoals = [
    () => {
      dispatch({
        type: "RESET-GAIT-RESULTS",
      });
      dispatch({
        type: "RESET-LAST-GAIT-RESULTS",
      });
      navigate("/gait_result", { state: { startTab: 1 } });
    },
    () => {
      dispatch({
        type: "RESET-ITER",
      });
      dispatch({
        type: "RESET-LAST-GAIT-RESULTS",
      });
      navigate("/sit_and_stand_result", { state: { startTab: 1 } });
    },
    () => {
      dispatch({
        type: "RESET-ITER",
      });
      dispatch({
        type: "RESET-LAST-GAIT-RESULTS",
      });
      navigate("/berg_balance_result/mini", { state: { startTab: 1 } });
    },
    () => {
      dispatch({
        type: "RESET-ITER",
      });
      dispatch({
        type: "RESET-LAST-GAIT-RESULTS",
      });
      navigate("/berg_balance_result/full", { state: { startTab: 1 } });
    },
    () => {
      navigate("/schedule_results/A7Du5i05tX9Uh1ajduoh");
    },
    () => {
      navigate("/schedule_results/TsKuZ7mNfPxwLeYPlN1u");
    },
    () => {
      navigate("/schedule_results/7CQwfA1NzzKlGgGzLPuF");
    },
    () => {
      navigate("/schedule_results/uK3TzSerSqHVi1pea0Ma");
    },
  ];

  return (
    <SelectNavigationDialog
      open={open}
      setOpen={setOpen}
      navigationGoals={navigationGoals}
    />
  );
}

export function SelectNavigationDialog({ open, setOpen, navigationGoals }) {
  const { user } = useContext(FirebaseAuthContext);
  const patientId = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  ).id;
  return (
    <MyDialog open={open} fullWidth maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <DialogTitle>Select Recording</DialogTitle>
        </Grid>
        <Grid item xs={1} sx={{ marginTop: 2 }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
          padding: 5,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton
          onClick={() => navigationGoals[0]()}
          sx={{ width: "100%" }}
        >
          <ListItemIcon sx={{ mr: "10%" }}>
            <TransferWithinAStationIcon />
          </ListItemIcon>
          <ListItemText primary="Gait analysis" />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigationGoals[1]()}
          sx={{ width: "100%" }}
        >
          <ListItemIcon sx={{ mr: "10%" }}>
            <AirlineSeatReclineNormalIcon />
          </ListItemIcon>
          <ListItemText primary="Sit and stand test" />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigationGoals[2]()}
          sx={{ width: "100%" }}
        >
          <ListItemIcon sx={{ mr: "10%" }}>
            <BalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Berg balance test mini" />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigationGoals[3]()}
          sx={{ width: "100%" }}
        >
          <ListItemIcon sx={{ mr: "10%" }}>
            <BalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Berg balance test full" />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigationGoals[4]()}
          sx={{ width: "100%" }}
        >
          <ListItemIcon sx={{ mr: "10%" }}>
            <AccessibilityIcon />
          </ListItemIcon>
          <ListItemText primary="Bio Mechanical Assessment" />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(
                window.location.host +
                  "/schedule_results/A7Du5i05tX9Uh1ajduoh" +
                  `?patientId=${patientId}&doctorId=${user.uid}`
              );
            }}
          >
            Copy Link
          </Button>
        </ListItemButton>
        <ListItemButton
          onClick={() => navigationGoals[5]()}
          sx={{ width: "100%" }}
        >
          <ListItemIcon sx={{ mr: "10%" }}>
            <FitnessCenterIcon />
          </ListItemIcon>
          <ListItemText primary="General Fitness Assessment" />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigationGoals[6]()}
          sx={{ width: "100%" }}
        >
          <ListItemIcon sx={{ mr: "10%" }}>
            <TrackChangesIcon />
          </ListItemIcon>
          <ListItemText primary="Functional Movement Screening" />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigationGoals[7]()}
          sx={{ width: "100%" }}
        >
          <ListItemIcon sx={{ mr: "10%" }}>
            <SportsMartialArtsIcon />
          </ListItemIcon>
          <ListItemText primary="Free Flow" />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(
                window.location.host +
                  "/schedule_results/uK3TzSerSqHVi1pea0Ma" +
                  `?patientId=${patientId}&doctorId=${user.uid}`
              );
            }}
          >
            Copy Link
          </Button>
        </ListItemButton>
      </List>
      <DialogContent sx={{ width: "100%", maxWidth: "400px" }}></DialogContent>
    </MyDialog>
  );
}
