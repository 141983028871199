/* eslint-disable react/no-unknown-property */
import Grid from "@mui/material/Grid";
import React, { useContext, useEffect, useRef } from "react";
import FirebaseAuthContext from "../../contexts/auth/FirebaseAuthContext";
import { addResult } from "../../utilities/Demo/Physio/GaitAnalysis/firestore";
import HeaderBox from "./Summary/HeaderBox";
import MetricBox from "./Summary/MetricBox";
import WorkOutScore from "./WorkOutScore.js";
import Duration from "../Highlights/Duration";

import "../../views/Demo/summary.css";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fitnessAssessmentComponents,
  exhibitionAssessmentComponents,
  sitAndStandAssessmentComponents,
  bergBalanceAssessmentComponents,
  bergBalanceAssessmentMiniComponents,
} from "../../utilities/Demo/demoComponents.js";
import { Metrics } from "./Metrics.js";
import _ from "lodash";
import FootMap from "./SensorResults/FootMap";
import { commonSensorDataProcessing } from "../../utilities/Demo/SensorDataResults/commonSensorDataProcessing";

const assessmentComponentMap = {
  fitness_assessment: fitnessAssessmentComponents,
  exhibition_assessment: exhibitionAssessmentComponents,
  sit_and_stand_assessment: sitAndStandAssessmentComponents,
  berg_balance_assessment: bergBalanceAssessmentComponents,
  berg_balance_assessment_mini: bergBalanceAssessmentMiniComponents,
};

const SummaryHighlights = ({ duration, squatCount, message, demoType }) => {
  const iteration = useSelector(({ iter }) => iter.values.iteration);
  const metrics = useSelector(({ iter }) => iter.values.metrics);
  let ind1 = [
    {
      color: "#CABDFF",
      squatCount: squatCount ? squatCount : 0,
    },
    {
      color: "#B1E5FC",
      squatCount: 85,
    },
  ];
  let ind2 = [
    {
      color: "#CABDFF",
      duration: duration ? parseFloat(parseFloat(duration) / 60).toFixed(2) : 0,
    },
    {
      color: "#B1E5FC",
      duration: parseFloat((27.5 * 60) / 60).toFixed(2),
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const iter = useSelector(({ iter }) => iter.values);
  console.log(iter, demoType, iteration);
  const balance = useSelector(({ balance }) => balance.values);
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );
  const stretch = useSelector(({ stretch }) => stretch.values);
  const stretchCount = useSelector(({ stretchCount }) => stretchCount.values);
  const countsInTime = useSelector(({ countsInTime }) => countsInTime.values);
  const sentObservationToDB = useRef(false);
  const { user } = useContext(FirebaseAuthContext) || {};
  let observation;
  const scoreMultiplicator = () => {
    if (demoType.startsWith("berg_balance_assessment")) return 3;
    else if (demoType.endsWith("assessment")) return 5;
    return iter.scoreMultiplication[iteration] || 1;
  };
  const exercise = Object.keys(assessmentComponentMap).includes(demoType)
    ? assessmentComponentMap[demoType] &&
      assessmentComponentMap[demoType][iteration]
    : demoType;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sensorDataProcessed = useRef(null);
  console.log(iter, "iter");

  useEffect(() => {
    if (!iter.sensorData[iteration]) return;
    sensorDataProcessed.current = commonSensorDataProcessing({
      sensorData: iter.sensorData[iteration],
    });
  }, [iter.sensorData]);

  const getObservation = () => {
    switch (exercise) {
      case "exerciseConfig":
        if (iter.exerciseTypes[iteration] === "count") {
          return {
            labels: ["Correct"],
            counts: [countsInTime.correct],
            time: countsInTime.time,
          };
        } else if (iter.exerciseTypes[iteration] === "stretchCount") {
          return {
            labels: ["Correct"],
            counts: [stretchCount.correct],
            time: stretchCount.time,
          };
        } else if (iter.exerciseTypes[iteration] === "stretch") {
          return {
            labels: ["Best Angle"],
            counts: [stretch.bestValue],
            time: stretch.time,
          };
        } else if (iter.exerciseTypes[iteration] === "balance") {
          return {
            labels: ["Interruptions", "Pose Held"],
            counts: [balance.interruptions, balance.secondsPoseHeld],
            time: balance.time,
          };
        }
        break;
      case "boatpose":
      case "flamingo":
      case "treepose":
        return {
          labels: ["Interruptions", "Pose Held"],
          counts: [balance.interruptions, balance.secondsPoseHeld],
          time: balance.time,
        };
      case "pushups":
      case "highknees":
        return {
          labels: ["Correct"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "vstretch":
        return {
          labels: ["Best Angle"],
          counts: [stretch.bestValue],
          time: stretch.time,
        };
      case "squats":
        return {
          labels: ["Correct"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "physio":
        return {
          labels: ["Correct"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "sitandstand":
        return {
          labels: ["Correct"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "sittostand":
        return {
          labels: ["Correct"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "standtosit":
        return {
          labels: ["Correct"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "standwithfeettogether":
        return {
          labels: ["Interruptions", "Pose Held"],
          counts: [balance.interruptions, balance.secondsPoseHeld],
          time: balance.time,
        };
      case "standononeleg":
        return {
          labels: ["Interruptions", "Pose Held"],
          counts: [balance.interruptions, balance.secondsPoseHeld],
          time: balance.time,
        };
      case "kickboxing":
        return {
          labels: ["Counts"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "situnsupported":
        return {
          labels: ["Interruptions", "Pose Held"],
          counts: [balance.interruptions, balance.secondsPoseHeld],
          time: balance.time,
        };
      case "reachforwardwitharmoutstretched":
        return {
          labels: ["Highest Distance (cm)"],
          counts: [stretch.bestValue],
          time: stretch.time,
        };
      case "pickupoffofthefloor":
        return {
          labels: ["Counts"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "turnandlookbehind":
        return {
          labels: ["Counts"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "standwithfeetinline":
        return {
          labels: ["Interruptions", "Pose Held"],
          counts: [balance.interruptions, balance.secondsPoseHeld],
          time: balance.time,
        };
      case "standunsupportedwitheyesclosed":
        return {
          labels: ["Interruptions", "Pose Held"],
          counts: [balance.interruptions, balance.secondsPoseHeld],
          time: balance.time,
        };
      case "standunsupported":
        return {
          labels: ["Interruptions", "Pose Held"],
          counts: [balance.interruptions, balance.secondsPoseHeld],
          time: balance.time,
        };
      case "transferbetweenchairs":
        return {
          labels: ["Counts"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "placealternatefootonstep":
        return {
          labels: ["Counts"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
      case "turnaroundincompletecircle":
        return {
          labels: ["Counts"],
          counts: [countsInTime.correct],
          time: countsInTime.time,
        };
    }
  };
  observation = getObservation();

  function nextButtonText() {
    if (demoType === "sitandstand") {
      return "PatientOverview";
    } else {
      if (assessmentComponentMap[demoType]) {
        return assessmentComponentMap[demoType][iteration + 1]
          ? "Jump to Next"
          : "PatientOverview";
      } else {
        return iter.exerciseIds[iter.iteration + 1]
          ? "Jump to Next"
          : "PatientOverview";
      }
    }
  }

  // on the basis of demoType load the output
  return (
    <div
      className="summary-highlights-container"
      style={{
        overflow: "auto",
      }}
    >
      <div className="top-bar">
        <div className="orange-box"> </div> <h2> Workout Highlights </h2>{" "}
      </div>{" "}
      <div className="today-highlights-content">
        <div className="top">
          <Metrics labels={observation.labels} counts={observation.counts} />{" "}
        </div>{" "}
        <div className="bottom">
          <Duration points={observation.time} indi={ind2} />{" "}
          {/*!demoType.startsWith('berg_balance_assessment') && (
                            <SweatPoints points={sp ? sp : 10} indi={ind1} />
                          )*/}{" "}
          <WorkOutScore
            points={iter.scores[iter.iteration] * scoreMultiplicator()}
            indi={ind1}
            title={"Score"}
          />{" "}
        </div>{" "}
      </div>{" "}
      <div
        className="box--container bottom-box"
        style={{
          width: "100%",
          mb: 30,
        }}
      >
        <div className="top-bar">
          <div className="left">
            <div className="orange-box"> </div> <h2> Feedback: </h2>{" "}
          </div>{" "}
        </div>{" "}
        <div className="my-rank-content">
          <div className="left">
            <h1> {message} </h1>{" "}
          </div>{" "}
          <Button
            // type="submit"
            variant="contained"
            className="demo-next"
            onClick={() => {
              dispatch({
                type: "RESET-INTRO",
              });
              if (
                (assessmentComponentMap[demoType] &&
                  assessmentComponentMap[demoType][iteration + 1] ===
                    undefined) ||
                (!assessmentComponentMap[demoType] &&
                  iter.exerciseIds[iter.iteration + 1] === undefined)
              ) {
                if (iter.overviewRedirect) {
                  navigate(
                    "/schedule_results/" +
                      iter.overviewRedirect +
                      `?${queryParams.toString()}`
                  );
                } else if (demoType === "sit_and_stand_assessment") {
                  navigate("/sit_and_stand_result");
                } else if (demoType.startsWith("berg_balance_assessment")) {
                  addResult({
                    userId: user.uid,
                    patientId: patientInfo.id,
                    exerciseType: demoType,
                    data: {
                      metrics: {
                        score: _.sum(
                          Object.keys(iter.scores).map(
                            (key) => iter.scores[key] * scoreMultiplicator()
                          )
                        ),
                      },
                    },
                  }).then(() => {
                    sentObservationToDB.current = true;
                  });
                  dispatch({
                    type: "RESET-BERG-BALANCE-RESULTS",
                    payload: {},
                  });
                  dispatch({
                    type: "RESET-LAST-BERG-BALANCE-RESULTS",
                    payload: {},
                  });
                  navigate(
                    "/berg_balance_result" +
                      (demoType.endsWith("mini") ? "/mini" : "/full")
                  );
                } else if (demoType === "exerciseConfig") {
                  navigate("/exercise_management");
                } else {
                  navigate(`/${demoType}`);
                }
              } else {
                dispatch({
                  type: "ADD-ITER",
                  payload: {},
                });
                if (demoType === "exerciseConfig" || user) {
                  navigate(`/exercise/${demoType}?${queryParams.toString()}`);
                } else {
                  navigate(`/demos/${demoType}?${queryParams.toString()}`);
                }
              }
            }}
          >
            {nextButtonText()}{" "}
          </Button>{" "}
        </div>{" "}
      </div>{" "}
      {metrics[iteration] && <HeaderBox> Metrics: </HeaderBox>}{" "}
      {metrics[iteration] && (
        <Grid container spacing={2}>
          {" "}
          {metrics[iteration].map((metric, index) => {
            return (
              <Grid item xs={6} key={index}>
                <MetricBox metric={metric} />{" "}
              </Grid>
            );
          })}{" "}
        </Grid>
      )}{" "}
      <div
        style={{
          position: "relative",
          marginTop: 50,
        }}
      >
        <FootMap
          generalResults={iter.sensorData}
          sensorData={{
            meanLeft: sensorDataProcessed.current?.left,
            meanRight: sensorDataProcessed.current?.right,
          }}
        />{" "}
      </div>{" "}
    </div>
  );
};

export default SummaryHighlights;
