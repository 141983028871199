import { combineReducers } from "redux";
import { loginReducer } from "./auth/loginReducer";
import { clubsReducer, ageClubsReducer } from "./auth/clubReducer";
import {
  boardReducer,
  scheduleReducer,
  tomscheduleReducer,
  userReducer,
  valReducer,
} from "./auth/boardReducer";
import { pointsReducer } from "./auth/pointsReducer";
import { ranksReducer } from "./auth/ranksReducer";
import {
  attendanceReducer,
  classProgressReducer,
  classStudsReducer,
  classTeacherReducer,
  homeReducer,
} from "./auth/teacherReducer";
import { rewardsReducer } from "./auth/rewardReducer";
import { chatsReducer, chatsstudsReducer } from "./auth/chatsReducer";
import {
  bergBalanceLastResultsReducer,
  bergBalanceResultsReducer,
} from "./bergBalance/bergBalanceResults";
import { exerciseConfigReducer } from "./exerciseManagment/exerciseConfigReducer";
import { balanceReducer } from "./feedback/balanceReducer";
import { livesReducer } from "./feedback/livesReducer";
import { stretchCountReducer } from "./feedback/stretchCountReducer";
import { sweatPointsReducer } from "./feedback/sweatPointsReducer";
import { webcamReducer } from "./feedback/webcamReducer";
import { gaitFrontReducer } from "./gaitAnalysis/gaitFrontReducer";
import { introReducer } from "./feedback/introReducer";
import { sideReducer } from "./feedback/sideReducer";
import { stretchReducer } from "./feedback/stretchReducer";
import { iterationsReducer } from "./feedback/iterationsReducer";
import { countsReducer } from "./feedback/countsReducer";
import { countsInTimeReducer } from "./feedback/countsInTimeReducer";
import { gaitPatientInfoReducer } from "./gaitAnalysis/gaitPatientInfo";
import { gaitReducer } from "./gaitAnalysis/gaitReducer";
import {
  gaitResultsReducer,
  lastGaitResultsReducer,
} from "./gaitAnalysis/gaitResultsReducer";
import { allClassesReducer, allSetsReducer } from "./superadmin/allclasses";

const rootReducer = combineReducers({
  auth: loginReducer,
  board: boardReducer,
  schedule: scheduleReducer,
  tschedule: tomscheduleReducer,
  club: clubsReducer,
  ageclub: ageClubsReducer,
  points: pointsReducer,
  ranks: ranksReducer,
  thome: homeReducer,
  cstuds: classStudsReducer,
  cprogress: classProgressReducer,
  user: userReducer,
  cteacher: classTeacherReducer,
  chats: chatsReducer,
  chatstuds: chatsstudsReducer,
  val: valReducer,
  attnd: attendanceReducer,
  rewards: rewardsReducer,
  counts: countsReducer,
  countsInTime: countsInTimeReducer,
  lives: livesReducer,
  balance: balanceReducer,
  stretch: stretchReducer,
  stretchCount: stretchCountReducer,
  iter: iterationsReducer,
  side: sideReducer,
  intro: introReducer,
  gait: gaitReducer,
  gaitFront: gaitFrontReducer,
  gaitPatientInfo: gaitPatientInfoReducer,
  sweatPoints: sweatPointsReducer,
  gaitResults: gaitResultsReducer,
  lastGaitResults: lastGaitResultsReducer,
  webcam: webcamReducer,
  classes: allClassesReducer,
  sets: allSetsReducer,
  bergBalanceResults: bergBalanceResultsReducer,
  bergBalanceLastResults: bergBalanceLastResultsReducer,
  exerciseConfig: exerciseConfigReducer,
});

export default rootReducer;
