import React from "react";
import ReactApexChart from "react-apexcharts";

export default function PieChart({
  series,
  labels = null,
  width = 500,
  title = "Pie Chart",
}) {
  console.log(series, labels, title);
  if (!series) return null;

  const options = {
    chart: {
      width: width,
      type: "donut",
    },
    labels, // Add the labels from the dataObj keys
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    title: {
      text: title,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      width={width}
    />
  );
}
