import { toast } from "react-toastify";

export function updateToast({ render, id, type }) {
  console.log(id);
  toast.update(id, {
    render: render,
    type: type,
    isLoading: false,
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function emitToast({ render, type }) {
  toast(render, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: type,
  });
}

export function updateToastCaseFunction({
  id,
  successRender = "Saved!",
  errorRender = "Error Saving!",
}) {
  return (resultDoc) => {
    if (resultDoc) {
      updateToast({
        render: successRender,
        id: id,
        type: "success",
      });
    } else {
      updateToast({
        render: errorRender,
        id: id,
        type: "error",
      });
    }
  };
}
