import React from "react";
import Button from "@mui/material/Button";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const DownloadButton = ({ filePath, buttonLabel = "Download File" }) => {
  const handleDownload = async () => {
    if (!filePath || filePath === "/") {
      console.error("Invalid file path");
      return;
    }

    try {
      const storage = getStorage();
      const storageRef = ref(storage, filePath);

      const url = await getDownloadURL(storageRef);
      const response = await fetch(url);
      const blob = await response.blob();
      const localUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = localUrl;
      a.download = filePath.split("/").pop(); // Set the filename for download
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(localUrl); // Clean up the URL object
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleDownload}>
      {" "}
      {buttonLabel}{" "}
    </Button>
  );
};

export default DownloadButton;
