import { collectForEachWindow } from "../results";
import { resampleAndMean } from "../../../../../service/Demo/Physio/GaitAnalysis/results";
import {
  ANKLE_ROM_NORMAL_DATA,
  HIP_ROM_NORMAL_DATA,
  KNEE_ROM_NORMAL_DATA,
} from "../../../../../constants/Demo/gaitAnalysis";

export class GraphDataCollector {
  constructor({ validityChecker }) {
    this.validityChecker = validityChecker;
    this.results = {};
    this.collectAllGraphWindows();
  }

  collectAllGraphWindows() {
    Object.keys(this.validityChecker.gaitResults.graphData).forEach((key) => {
      this.collectWindows(key);
    });
  }

  collectWindows(key) {
    if (key % 2 === 0) {
      this.collectWindowsLeft(key);
    } else {
      this.collectWindowsRight(key);
    }
  }

  collectWindowsLeft(key) {
    const data = this.validityChecker.gaitResults.graphData[key]?.data;
    const stanceWindowsAll = collectForEachWindow({
      windowArray: this.validityChecker.gaitResults.stanceTimeWindowsLeft,
      graphData: data,
    });
    const stanceWindows = this.validityChecker.stanceTimeLeftIndexes.map(
      (validIndex) => stanceWindowsAll[validIndex]
    );
    const swingWindowsAll = collectForEachWindow({
      windowArray: this.validityChecker.gaitResults.strideTimeWindowsLeft,
      graphData: data,
    });
    const swingWindows = this.validityChecker.strideTimeLeftIndexes.map(
      (validIndex) => swingWindowsAll[validIndex]
    );
    this.results[key] = {
      stanceWindows,
      swingWindows,
    };
  }

  collectWindowsRight(key) {
    const data = this.validityChecker.gaitResults.graphData[key]?.data;
    const stanceWindowsAll = collectForEachWindow({
      windowArray: this.validityChecker.gaitResults.stanceTimeWindowsRight,
      graphData: data,
    });
    const stanceWindows = this.validityChecker.stanceTimeRightIndexes.map(
      (validIndex) => stanceWindowsAll[validIndex]
    );
    const swingWindowsAll = collectForEachWindow({
      windowArray: this.validityChecker.gaitResults.strideTimeWindowsRight,
      graphData: data,
    });
    const swingWindows = this.validityChecker.strideTimeRightIndexes.map(
      (validIndex) => swingWindowsAll[validIndex]
    );
    this.results[key] = {
      stanceWindows,
      swingWindows,
    };
  }
}

export class GraphDataResampler {
  constructor({ graphDataCollector }) {
    this.graphDataCollector = graphDataCollector;
    this.resampledResults = {};
  }

  async resampleData(retryCount = 0, maxRetries = 3) {
    const promises = [];

    for (const key in this.graphDataCollector.results) {
      const data = this.graphDataCollector.results[key];
      this.resampledResults[key] = {};

      const stanceWindowPromise = resampleAndMean(data["stanceWindows"]).then(
        (resampledData) => {
          this.resampledResults[key]["stanceWindows"] =
            resampledData.data.series;
        }
      );
      const swingWindowPromise = resampleAndMean(data["swingWindows"]).then(
        (resampledData) => {
          this.resampledResults[key]["swingWindows"] =
            resampledData.data.series;
        }
      );

      promises.push(stanceWindowPromise, swingWindowPromise);
    }

    try {
      await Promise.all(promises);
    } catch (error) {
      if (retryCount < maxRetries) {
        console.log(
          `Retrying to resmaple... (${retryCount + 1}/${maxRetries})`
        );
        await new Promise((resolve) => setTimeout(resolve, 4000)); // 4-second delay
        await this.resampleData(retryCount + 1, maxRetries);
      } else {
        throw new Error(`Failed after ${maxRetries} retries.`);
      }
    }
  }
}

export class GraphConnector {
  constructor({ graphDataResampler }) {
    this.graphDataResampler = graphDataResampler;
    this.connectedResults = {};
    this.connectData();
  }

  connectData() {
    for (const key in this.graphDataResampler.resampledResults) {
      const data = this.graphDataResampler.resampledResults[key];
      this.connectedResults[key] = data["stanceWindows"].concat(
        data["swingWindows"]
      );
    }
  }
}

export class GraphDataSetter {
  constructor({ graphConnector }) {
    this.graphConnector = graphConnector;
    this.validityChecker =
      graphConnector.graphDataResampler.graphDataCollector.validityChecker;
    this.results = {};
    this.setGraphData();
  }

  flattenData(data) {
    return data.map((point) => point.y);
  }

  adjustData(data, adjustment) {
    return data.map((point) => point + adjustment);
  }

  normXValues(data, norm = 100) {
    return data.map((point, index) => {
      return {
        x: (index / (data.length - 1)) * norm,
        y: point,
      };
    });
  }

  mapNormalCanvasData(data, adjust = 1, norm = 100) {
    return data.map((point, index) => {
      return {
        x: (index / (data.length - 1)) * norm,
        y: [point * (1 - adjust), point * (1 + adjust)],
      };
    });
  }

  setGraphData() {
    this.results["mainChartData"] = [
      {
        name: "Norm",
        type: "rangeArea",
        data: this.mapNormalCanvasData(HIP_ROM_NORMAL_DATA, 0.15),
      },
      {
        name: "Left",
        data: this.normXValues(
          this.adjustData(this.graphConnector.connectedResults[0], 0)
        ),
      },
      {
        name: "Right",
        data: this.normXValues(
          this.adjustData(this.graphConnector.connectedResults[1], 0)
        ),
      },
    ];
    this.results["mainChartDataAll"] = [
      {
        name: "Left",
        data: this.adjustData(
          this.flattenData(this.validityChecker.gaitResults.graphData[0].data),
          0
        ),
      },
      {
        name: "Right",
        data: this.adjustData(
          this.flattenData(this.validityChecker.gaitResults.graphData[1].data),
          0
        ),
      },
    ];
    this.results["kneeChartData"] = [
      {
        name: "Norm",
        type: "rangeArea",
        data: this.mapNormalCanvasData(KNEE_ROM_NORMAL_DATA, 0.15),
      },
      {
        name: "Left",
        data: this.normXValues(
          this.adjustData(this.graphConnector.connectedResults[2], 0)
        ),
      },
      {
        name: "Right",
        data: this.normXValues(
          this.adjustData(this.graphConnector.connectedResults[3], 0)
        ),
      },
    ];
    this.results["kneeChartDataAll"] = [
      {
        name: "Left",
        data: this.adjustData(
          this.flattenData(this.validityChecker.gaitResults.graphData[2].data),
          0
        ),
      },
      {
        name: "Right",
        data: this.adjustData(
          this.flattenData(this.validityChecker.gaitResults.graphData[3].data),
          0
        ),
      },
    ];
    this.results["ankleChartData"] = [
      {
        name: "Norm",
        type: "rangeArea",
        data: this.mapNormalCanvasData(ANKLE_ROM_NORMAL_DATA, 0.15),
      },
      {
        name: "Left",
        data: this.normXValues(this.graphConnector.connectedResults[4]),
      },
      {
        name: "Right",
        data: this.normXValues(this.graphConnector.connectedResults[5]),
      },
    ];
    this.results["ankleChartDataAll"] = [
      {
        name: "Left",
        data: this.flattenData(
          this.validityChecker.gaitResults.graphData[4].data
        ),
      },
      {
        name: "Right",
        data: this.flattenData(
          this.validityChecker.gaitResults.graphData[5].data
        ),
      },
    ];
    this.results["hipChartData"] = [
      {
        name: "Left",
        data: this.normXValues(this.graphConnector.connectedResults[6]),
      },
      {
        name: "Right",
        data: this.normXValues(this.graphConnector.connectedResults[7]),
      },
    ];
    this.results["hipChartDataAll"] = [
      {
        name: "Left",
        data: this.flattenData(
          this.validityChecker.gaitResults.graphData[6].data
        ),
      },
      {
        name: "Right",
        data: this.flattenData(
          this.validityChecker.gaitResults.graphData[7].data
        ),
      },
    ];
    this.results["hipRotationChartData"] = [
      {
        name: "Left",
        data: this.normXValues(this.graphConnector.connectedResults[8]),
      },
      {
        name: "Right",
        data: this.normXValues(this.graphConnector.connectedResults[9]),
      },
    ];
    this.results["hipRotationChartDataAll"] = [
      {
        name: "Left",
        data: this.flattenData(
          this.validityChecker.gaitResults.graphData[8].data
        ),
      },
      {
        name: "Right",
        data: this.flattenData(
          this.validityChecker.gaitResults.graphData[9].data
        ),
      },
    ];
  }
}
