import { POSE_CONNECTIONS } from "@mediapipe/pose";
import React, { useEffect, useRef, useState } from "react";
import {
  drawFilteredConnectors,
  selectLandmarksFromObject,
} from "../../../../../utilities/Demo/poseDetection";

function Replay({ relativeLandmarks }) {
  // console.log(relativeLandmarks, 'relative landmarks');
  const [restructuredData, setRestructuredData] = useState([]);
  const canvasRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutMS = 50;

  useEffect(() => {
    console.log(relativeLandmarks, "relative landmarks changed");
    if (!relativeLandmarks[Object.keys(relativeLandmarks)[0]]) return;
    const newRestructuredData = [];
    relativeLandmarks[Object.keys(relativeLandmarks)[0]].forEach((_, index) => {
      const dataAtIndex = {};
      Object.keys(relativeLandmarks)?.forEach((key) => {
        dataAtIndex[key] = relativeLandmarks[key][index];
        try {
          dataAtIndex[key].x =
            dataAtIndex[key].x * (canvasRef.current.width || 1);
          dataAtIndex[key].y =
            dataAtIndex[key].y * (canvasRef.current.width || 1);
        } catch (e) {
          console.log(e);
        }
      });
      newRestructuredData.push(dataAtIndex);
    });
    setRestructuredData(newRestructuredData);
  }, [relativeLandmarks]);

  const drawFrame = (ctx, poseData) => {
    // Clear canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // console.log(poseData, 'pose data');
    const selectedLandmarks = selectLandmarksFromObject(poseData);
    // console.log(selectedLandmarks, 'selected landmarks');
    // Draw each point from BlazePose
    Object.keys(selectedLandmarks).forEach((point) => {
      if (!selectedLandmarks[point]) return;
      const { x, y, z } = selectedLandmarks[point];
      // console.log(x, y, z, 'x y z');
      const size = 5 - z / 2;
      const opacity = 1 - z / 10;
      ctx.beginPath();
      ctx.arc(x, y, size, 0, 2 * Math.PI);
      ctx.fillStyle = `rgba(0, 128, 255, ${opacity})`;
      ctx.fill();
    });
    drawFilteredConnectors(ctx, selectedLandmarks, POSE_CONNECTIONS);
    // drawLandmarks(ctx, selectedLandmarks);
    // drawConnectors(ctx, selectedLandmarks, POSE_CONNECTIONS);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let timeoutId;

    const render = () => {
      if (currentIndex < restructuredData.length) {
        const currentObservation = { ...restructuredData[currentIndex] };
        drawFrame(ctx, currentObservation);
        setCurrentIndex((prevIndex) => prevIndex + 1);
        timeoutId = setTimeout(render, timeoutMS); // 100ms delay between each frame
      } else {
        setCurrentIndex(0);
        timeoutId = setTimeout(render, timeoutMS); // 100ms delay before restarting
      }
    };

    // Initialize first frame
    timeoutId = setTimeout(render, timeoutMS); // 100ms delay to start

    return () => {
      clearTimeout(timeoutId);
    };
  }, [restructuredData, currentIndex]);

  return (
    <canvas
      ref={canvasRef}
      width={640 * 2}
      height={480 * 1.5 * 2}
      style={{ background: "black" }}
    />
  );
}

export default React.memo(Replay);
