import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

const MenuItem = ({ text, icon, onClick = () => {} }) => {
  return (
    <ListItem disablePadding onClick={onClick}>
      <ListItemButton>
        <ListItemIcon> {icon} </ListItemIcon> <ListItemText primary={text} />{" "}
      </ListItemButton>{" "}
    </ListItem>
  );
};

export default function DrawerMenu({ setSelected }) {
  return (
    <div>
      <List>
        {" "}
        {["Exercises", "Feedback Audios"].map((text, index) => (
          <MenuItem
            key={text}
            text={text}
            icon={index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            onClick={() => setSelected(text)}
          />
        ))}{" "}
      </List>{" "}
      <Divider />
      <List>
        {" "}
        {[
          "Schedule",
          "Transitions",
          "Roadmaps",
          "Tags",
          "Angles",
          "Videos",
        ].map((text, index) => (
          <MenuItem
            key={text}
            text={text}
            icon={index % 3 === 0 ? <InboxIcon /> : <MailIcon />}
            onClick={() => setSelected(text)}
          />
        ))}{" "}
      </List>{" "}
    </div>
  );
}
