import React from 'react';
import GraphComponent from './GraphComponent';
import {
    useTranslation
} from 'react-i18next';

// import useMediaQuery from '@mui/material/useMediaQuery';
import ArticleNavigation from './ArticleNavigation';

function ProgressComponent({
    data,
    classes
}) {
    const {
        t
    } = useTranslation();

    return ( <
        div className = "bottom-left article" >
        <
        ArticleNavigation top = {
            false
        }
        title = {
            t('ProgressComponent.progress')
        }
        classes = {
            classes
        }
        /> <
        GraphComponent progress = {
            data ? data.progress : null
        }
        /> <
        /div>
    );
}

export default ProgressComponent;