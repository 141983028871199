import React from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useBalanceCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import PoseDetection from "../PoseDetection";
import { checkIfSide } from "../../../utilities/Demo/angles";
import {
  leftHipArc,
  leftShoulderArc,
  rightHipArc,
  rightShoulderArc,
} from "../../../utilities/Demo/arcs";
import { loadTreePoseAudio } from "../../../utilities/Demo/audioFeedback";

function TreePoseDemo({ legSide, onComplete, timeThresh = 60 }) {
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    playAudio,
    audioFeedback,
    cptScore,
    updatePoseHeldDeps,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: () => loadTreePoseAudio(legSide),
  });
  cptScore.current = () => {
    return (
      (secondsPoseHeldComplete.current + secondsPoseHeld.current) /
      (timeThresh * 2)
    );
  };

  function giveFeedback(
    leftArmAngle,
    rightArmAngle,
    legAngle,
    standingLegAngle
  ) {
    if (leftArmAngle <= 110) {
      updateFeedback("Raise your left arm higher!");
      playAudio(audioFeedback.current?.raiseLeftArm);
      return;
    }
    if (rightArmAngle <= 110) {
      updateFeedback("Raise your right arm higher!");
      playAudio(audioFeedback.current?.raiseRightArm);
      return;
    }
    if (standingLegAngle > 30) {
      updateFeedback("Raise your other leg!");
      playAudio(audioFeedback.current?.raiseOtherLeg);
      return;
    }
    if (legAngle <= 30) {
      updateFeedback("Raise your leg higher!");
      playAudio(audioFeedback.current?.raiseLeg);
      return;
    }
    if (standingLegAngle >= 10) {
      updateFeedback("Keep your standing leg straight!");
      playAudio(audioFeedback.current?.keepStandingLegStraight);
    }
  }

  function observeBalance(
    leftArmAngle,
    rightArmAngle,
    legAngle,
    standingLegAngle
    // canvasCtx
  ) {
    const state = holdsPose.current;
    if (
      leftArmAngle > 110 &&
      rightArmAngle > 110 &&
      legAngle > 30 &&
      standingLegAngle < 10
    ) {
      updateFeedback("");
      holdsPose.current = true;
      secondsPoseHeld.current = seconds.current - secondPoseHeldStarted.current;
    } else {
      holdsPose.current = false;
      giveFeedback(leftArmAngle, rightArmAngle, legAngle, standingLegAngle);
    }
    updatePoseHeldDeps(state);
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    const leftArmAngle = leftShoulderArc(canvasCtx, results, 60);
    const rightArmAngle = rightShoulderArc(canvasCtx, results, 60);
    const leftLegAngle = leftHipArc(canvasCtx, results, 60);
    const rightLegAngle = rightHipArc(canvasCtx, results, 60);
    const side = checkIfSide(results, 4);

    if (!side) {
      observeBalance(
        leftArmAngle,
        rightArmAngle,
        legSide === "left" ? leftLegAngle : rightLegAngle,
        legSide === "left" ? rightLegAngle : leftLegAngle,
        canvasCtx
      );
    } else {
      updateFeedback("Face the camera!");
      playAudio(audioFeedback.current?.faceCamera);
    }

    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(TreePoseDemo);
