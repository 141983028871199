import React, {
    useEffect,
    useState
} from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
    getClassProgress
} from '../../redux/actions/getClassHome';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useTranslation
} from 'react-i18next';

const ArticleNavigation = ({
    top,
    title,
    classes,
    setLoad
}) => {
    const {
        t
    } = useTranslation();
    const val = useSelector(({
        val
    }) => val.values);
    const [, setV] = useState(null);
    console.log('Val is::', val);
    useEffect(() => {
        if (val) {
            console.log('VAL::', val);
            setV(val);
        }
    }, [val]);
    const dispatch = useDispatch();
    var unique = new Set();
    try {
        if (classes) {
            for (var i = 0; i < classes.length; i++) {
                unique.add(
                    JSON.stringify({
                        class_name: classes[i].class_name,
                        class_id: classes[i].class_id,
                    })
                );
            }
            if (!val) {
                console.log('I am dispatching');
                setV(JSON.parse(Array.from(unique)[0]).class_id);
                dispatch({
                    type: 'VAL',
                    payload: JSON.parse(Array.from(unique)[0]).class_id,
                });
            }
        }
    } catch (error) {
        console.log('error', error);
    }

    return ( <
        div className = "article-navigation"
        style = {
            {
                marginBottom: !top && '1rem'
            }
        } >
        <
        div className = "left" >
        <
        div className = "orange-rect" > < /div> <
        h1 > {
            title
        } < /h1> { /* <h1>{ (teacher ? 'Top three performers' : 'hola')  (top ? 'PatientOverview' : 'Hello') }</h1> */ } <
        /div> <
        div className = "right" > {
            title !== t('TopThreeComponent.topthreeperformerTitle') && val && ( <
                Select className = "dashboard-top-select"
                defaultValue = {
                    unique && val
                }
                onChange = {
                    async (e) => {
                        console.log('I dispatched here in onchange');
                        dispatch({
                            type: 'VAL',
                            payload: e.target.value
                        });
                        await getClassProgress(
                            dispatch,
                            setLoad ? setLoad : (a) => a,
                            e.target.value
                        );
                    }
                } >
                <
                MenuItem value = "" >
                <
                em > None < /em> <
                /MenuItem> {
                    unique ? (
                        Array.from(unique).map((item, index) => ( <
                            MenuItem key = {
                                index + '-menu-item'
                            }
                            value = {
                                JSON.parse(item).class_id
                            }
                            autoFocus = {
                                index !== 0
                            } >
                            {
                                `Class ${JSON.parse(item).class_name}`
                            } < /MenuItem>
                        ))
                    ) : ( <
                        MenuItem value = {
                            10
                        } > Class 1 < /MenuItem>
                    )
                } <
                /Select>
            )
        } <
        /div> <
        /div>
    );
};

export default ArticleNavigation;