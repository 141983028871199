import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import ApexChart from "react-apexcharts";
import { downloadFile } from "../../../utilities/Demo/ExerciseManagement/Generators/storage";

function CsvChart({ anglesFileId }) {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
      },
      xaxis: {
        categories: [],
      },
    },
  });

  useEffect(() => {
    const fetchCsv = async (fileId) => {
      try {
        const csvFile = await downloadFile({
          filePath: "files/" + fileId,
        });
        const csvText = await csvFile.text();

        parseCsv(csvText);
      } catch (error) {
        console.error("Error fetching CSV:", error);
      }
    };

    if (anglesFileId) {
      fetchCsv(anglesFileId);
    }
  }, [anglesFileId]);

  const transformToColumnarData = (data) => {
    const columnarData = {};
    data.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (!columnarData[key]) {
          columnarData[key] = [];
        }
        columnarData[key].push(Math.round(Number(row[key])));
      });
    });
    return columnarData;
  };

  const parseCsv = (data) => {
    Papa.parse(data, {
      skipEmptyLines: true,
      beforeFirstChunk: function (chunk) {
        const rows = chunk.split(/\r\n|\r|\n/); // Split by row
        const headers = rows[2].split(",").map((item, index) => {
          return rows[2].split(",")[index] + " " + rows[3].split(",")[index];
        }); // Use the fourth row as header
        rows.splice(0, 4); // Remove the first four rows
        return headers + "\n" + rows.join("\n");
      },
      complete: (result) => {
        console.log("Parsed:", result.data);
        const columnarData = transformToColumnarData(result.data);
        console.log("Columnar data:", columnarData);
        // Transform data to ApexCharts format here
        setChartData({
          series: Object.keys(columnarData).map((dataKey) => {
            return {
              name: dataKey,
              data: columnarData[dataKey],
            };
          }),
          options: {
            chart: {
              type: "line",
              stacked: false,
              height: 700,
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              size: 0,
            },
            title: {
              text: "Angles",
              align: "left",
            },
            // fill: {
            //   type: 'gradient',
            //   gradient: {
            //     shadeIntensity: 1,
            //     inverseColors: false,
            //     opacityFrom: 0.5,
            //     opacityTo: 0,
            //     stops: [0, 90, 100],
            //   },
            // },
          },
        });
      },
      header: true,
    });
  };

  return (
    <div>
      <ApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={500}
      />{" "}
    </div>
  );
}

export default CsvChart;
