import React, { useState } from "react";
import Button from "@mui/material/Button";
import { handleTask, uploadFile } from "../../../service/Demo/Sports2D/tasks";
import FirebaseAuthContext from "../../../contexts/auth/FirebaseAuthContext";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material"; // Import the Button component

function FileUpload({ projectId, setReload }) {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { user } = React.useContext(FirebaseAuthContext);
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    // Example: POST request using fetch API
    try {
      const authToken = await user.getIdToken();
      setLoading(true);
      const response = await uploadFile({
        selectedFile,
        projectId,
        authToken,
      });

      if (response.status === 200) {
        alert("File uploaded successfully");
        handleTask({
          user,
          fileId: response.data.id,
          patientInfo,
          setLoading: (newLoadingBool) => {
            setLoading(newLoadingBool);
            setReload();
          },
        });
      } else {
        alert("Upload failed");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during upload:", error);
      alert("Upload failed");
    }
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        style={{
          display: "none",
        }}
        id="contained-button-file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Choose File{" "}
        </Button>{" "}
      </label>{" "}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleUpload}
        style={{
          marginLeft: 10,
        }}
      >
        {loading ? <CircularProgress /> : "Upload"}{" "}
      </Button>{" "}
    </div>
  );
}

export default FileUpload;
