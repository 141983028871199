import { baseUrl } from "../../constants";
import axios from "axios";
import moment from "moment";
import { getDashboardData, getRankings, getScheduleData } from "./getDashboard";
import { getClassHome, getClassProgress } from "./getClassHome";

export const loginWithJWT = async (
  email,
  password,
  dispatch,
  setLoad,
  setMsg,
  setType,
  setChange
) => {
  axios
    .post(`${baseUrl}/signin`, {
      email,
      password,
    })
    .then(async (response) => {
      console.log("Status is ::" + JSON.stringify(response));
      dispatch({
        type: "LOGIN",
        payload: response.data,
      });
      await localStorage.setItem(
        "user_data",
        JSON.stringify(response.data.user)
      );
      await localStorage.setItem("user_name", response.data.user.name);
      await localStorage.setItem("token", response.data.token);
      await localStorage.setItem(
        "full_user",
        JSON.stringify(response.data.user)
      );
      if (response.data.user.temp_pwd) {
        setMsg("Please change your pwd as this is first time");
        setChange(true);
      } else {
        dispatch({
          type: "USER",
          payload: response.data.user,
        });
        await getDashboardData(dispatch);
        if (response.data.user.role === "teacher") {
          await getClassProgress(dispatch, (a) => a);
          await getClassHome(dispatch);
          await getScheduleData(
            dispatch,
            moment().format("YYYY-MM-DD"),
            (some) => some
          );
          setMsg("Login Successfull");
          setType("success");
          setLoad(false);
        } else {
          await getScheduleData(
            dispatch,
            moment().format("YYYY-MM-DD"),
            (some) => some
          );
          await getDashboardData(dispatch);
          await getRankings(dispatch, (a) => a);
          setMsg("Login Successfull");
          setType("success");
          setLoad(false);
        }
      }
    })
    .catch((res2) => {
      console.log("eror::" + JSON.stringify(res2));
      setLoad(false);
      setType("error");
      setMsg(
        res2.response
          ? res2.response.data
            ? res2.response.data.message
            : res2.message
          : res2.message
      );
    });
};

export const changePwd = async (
  email,
  password,
  setLoad,
  setMsg,
  setMs,
  setType
) => {
  axios
    .put(`${baseUrl}/updatepwd`, {
      email,
      password,
    })
    .then(async (response) => {
      console.log("Status is ::" + JSON.stringify(response));
      setLoad(false);
      setMs("Success");
      setMsg("Login Successfull");
    })
    .catch((res2) => {
      console.log("eror::" + JSON.stringify(res2));
      setLoad(false);
      setType("error");
      setMsg(
        res2.response
          ? res2.response.data
            ? res2.response.data.message
            : res2.message
          : res2.message
      );
    });
};
