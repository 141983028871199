import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React from "react";

function DeleteDialog({ handleDelete, onClose, deletionTarget, open }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete {deletionTarget}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to delete this ${deletionTarget}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function DeleteButton({ onDelete, deletionTarget }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [deleting, setIsDeleting] = React.useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    setDialogOpen(false);
    await onDelete();
    setIsDeleting(false);
  };

  return (
    <div>
      <DeleteDialog
        handleDelete={handleDelete}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        deletionTarget={deletionTarget}
      />
      <IconButton
        disabled={deleting}
        size="large"
        edge="start"
        color="inherit"
        onClick={(e) => {
          e.stopPropagation();
          setDialogOpen(true);
        }}
      >
        {deleting ? <CircularProgress /> : <DeleteForeverIcon />}
      </IconButton>
    </div>
  );
}
