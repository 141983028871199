export function drawAngleText(ctx, angle, x, y) {
    ctx.save(); // Save current state

    ctx.translate(x, y); // Move to the position where you want to draw the text
    ctx.scale(-1, 1); // Apply a horizontal flip
    ctx.fillStyle = 'white';
    ctx.font = '16px sans-serif';
    ctx.fillText(`${angle}°`, -30, 0); // Draw the text at the new origin (0, 0)

    ctx.restore(); // Restore the original state
}

export function drawArc(
    ctx,
    center,
    radius,
    startAngle,
    endAngle,
    color,
    lineWidth,
    counterClockwise = true
) {
    ctx.beginPath();
    ctx.arc(center[0], center[1], radius, startAngle, endAngle, counterClockwise);
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
}

export function drawVerticalLine(ctx, x, y, length, color, lineWidth) {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, y + length);
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;

    ctx.setLineDash([5, 5]);
    ctx.stroke();
    // Reset the line dash pattern to the default (solid line)
    ctx.setLineDash([]);
}

export function findAngle(p1, p2, respect = 90) {
    if (!p1 || !p2) return 0;
    if (p1.x) {
        p1 = [p1.x, p1.y];
        p2 = [p2.x, p2.y];
    }
    let deltaY = p2[1] - p1[1];
    let deltaX = p2[0] - p1[0];

    let angle = Math.atan2(deltaY, deltaX);

    // Subtract pi/2 (90 degrees) to get the angle with respect to the vertical axis
    let degree = (180 / Math.PI) * angle - respect;
    degree = Math.abs(Math.round(degree));

    // Return the angle in the range of 0 to 180 degrees
    return degree;
}

export function findAdjustedAngle(p1, p2, respect = 90) {
    if (!p1 || !p2) return 0;
    if (p1.x) {
        p1 = [p1.x, p1.y];
        p2 = [p2.x, p2.y];
    }
    let deltaY = p2[1] - p1[1];
    let deltaX = p2[0] - p1[0];

    let angle = Math.atan2(deltaY, deltaX);

    // Subtract pi/2 (90 degrees) to get the angle with respect to the vertical axis
    let degree = (180 / Math.PI) * angle - respect;
    degree = Math.round(degree) * -1;
    if (degree < 0) {
        degree = 360 + degree;
    }

    // Return the angle in the range of 0 to 180 degrees
    return degree;
}

export function findCounterAngle(p1, p2, respect = 90) {
    if (p1.x) {
        p1 = [p1.x, p1.y];
        p2 = [p2.x, p2.y];
    }
    let deltaY = p1[1] - p2[1]; // Note the swap here
    let deltaX = p2[0] - p1[0];

    let angle = Math.atan2(deltaY, deltaX); // The atan2 function takes (y,x) to compute the angle

    // Subtract pi/2 (90 degrees) to get the angle with respect to the vertical axis
    // As we are moving counterclockwise, add respect instead of subtract
    let degree = (180 / Math.PI) * angle + respect;

    // Since we're going counterclockwise, subtract the degree from 360 if it's greater than 180
    if (degree > 180) {
        degree = 360 - degree;
    }

    // Return the angle in the range of 0 to 180 degrees
    return Math.abs(Math.round(degree));
}

export function horizontalAngle(P1, P2) {
    const dx = P2.x - P1.x;
    const dy = P2.y - P1.y;
    const angleRad = Math.atan2(dy, dx);
    return (angleRad * 180) / Math.PI;
}

export function findDependentAngle(a, b, c, ccw = false, respect = 0) {
    if (!a || !b || !c) return NaN;

    const ab = {
        x: a.x - b.x,
        y: a.y - b.y
    };
    const cb = {
        x: c.x - b.x,
        y: c.y - b.y
    };

    const dotProduct = ab.x * cb.x + ab.y * cb.y;
    const crossProduct = ab.x * cb.y - ab.y * cb.x;

    const abLength = Math.sqrt(ab.x * ab.x + ab.y * ab.y);
    const cbLength = Math.sqrt(cb.x * cb.x + cb.y * cb.y);

    const cosTheta = dotProduct / (abLength * cbLength);
    let theta = Math.acos(cosTheta);

    // if the cross product is negative, the angle is greater than 180 degrees
    if (crossProduct < 0) {
        theta = 2 * Math.PI - theta;
    }

    let angle = Math.abs(Math.round((theta * 180) / Math.PI));

    // Apply the respect/offset
    angle = (angle + respect) % 360;

    if (angle < 0) {
        angle = 360 + angle;
    }

    if (ccw) {
        return 360 - angle;
    } else {
        return angle;
    }
}

export function displayCounts(canvasCtx, correctCount, incorrectCount) {
    // Draw the green background for the correct count
    canvasCtx.fillStyle = '#41B857';
    canvasCtx.fillRect(55, 5, 165, 30);

    // Draw the red background for the incorrect count
    canvasCtx.fillStyle = '#F37637';
    canvasCtx.fillRect(55, 45, 165, 30);

    // Draw the text with white font
    canvasCtx.fillStyle = 'white';
    canvasCtx.font = '20px sans-serif';
    canvasCtx.fillText(`Correct: ${correctCount}`, 80, 30);
    canvasCtx.fillText(`Incorrect: ${incorrectCount}`, 80, 70);
}

export function displayFeedback(canvasCtx) {
    // Draw the red background for the incorrect count
    canvasCtx.fillStyle = '#F9C444';
    canvasCtx.fillRect(200, 415, 262, 30);

    // Draw the text with white font
    canvasCtx.fillStyle = 'black';
    canvasCtx.font = '20px sans-serif';
    canvasCtx.fillText('Raise your arm over 90°', 210, 440);
}

export function drawExtendedLine(
    canvasCtx,
    coord1,
    coord2,
    extension,
    displayAngle = true,
    color = 'rgb(243,55,77)',
    thickness = 3
) {
    // Calculate the direction of the line segment
    const dx = coord2.x - coord1.x;
    const dy = coord2.y - coord1.y;

    // Calculate the length of the line segment
    const length = Math.sqrt(dx * dx + dy * dy);

    // Normalize the direction vector
    const unitDx = dx / length;
    const unitDy = dy / length;

    // Calculate the new start and end points by extending the original line segment
    const extendedStart = {
        x: coord1.x - unitDx * extension,
        y: coord1.y - unitDy * extension,
    };
    const extendedEnd = {
        x: coord2.x + unitDx * extension,
        y: coord2.y + unitDy * extension,
    };

    // Set the color and thickness (line width)
    canvasCtx.strokeStyle = color;
    canvasCtx.lineWidth = thickness;

    // Draw the extended line
    canvasCtx.beginPath();
    canvasCtx.moveTo(extendedStart.x, extendedStart.y);
    canvasCtx.lineTo(extendedEnd.x, extendedEnd.y);
    canvasCtx.stroke();

    const angle = findCounterAngle(extendedStart, extendedEnd);
    let displayInfo;
    if (displayAngle) {
        drawAngleText(canvasCtx, angle, extendedEnd.x - 50, extendedEnd.y);
    } else {
        displayInfo = {
            coord: {
                x: extendedEnd.x - 50,
                y: extendedEnd.y
            },
            isExtendedLine: true,
        };
    }

    // Optional: Reset the color and line width to default values
    canvasCtx.strokeStyle = '#000000';
    canvasCtx.lineWidth = 1;

    return [Math.round(angle), displayInfo];
}