export const getAverageScoreByAge = ({
    age,
    gender
}) => {
    if (gender === 'male') {
        if (age >= 18 <= 29) return 27;
        if (age >= 30 <= 39) return 27;
        if (age >= 40 <= 49) return 29;
        if (age >= 50 <= 59) return 25;
        if (age >= 60 <= 69) return 25;
        if (age >= 70 <= 79) return 19;
        if (age >= 80) return 17;
    } else if (gender === 'female') {
        if (age >= 18 <= 29) return 27;
        if (age >= 30 <= 39) return 27;
        if (age >= 40 <= 49) return 29;
        if (age >= 50 <= 59) return 25;
        if (age >= 60 <= 69) return 25;
        if (age >= 70 <= 79) return 19;
        if (age >= 80) return 17;
    } else {
        if (age >= 18 <= 29) return 27;
        if (age >= 30 <= 39) return 27;
        if (age >= 40 <= 49) return 29;
        if (age >= 50 <= 59) return 25;
        if (age >= 60 <= 69) return 25;
        if (age >= 70 <= 79) return 19;
        if (age >= 80) return 17;
    }
};