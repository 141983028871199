import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useWebcamSettings = () => {
  const dispatch = useDispatch();

  const updateWebcamSettings = (useOffset) => {
    dispatch({
      type: "UPDATE-WEBCAM-SETTINGS",
      payload: {
        useOffset: useOffset,
      },
    });
  };

  useEffect(() => {
    return () =>
      dispatch({
        type: "RESET-WEBCAM-SETTINGS",
      });
  }, []);

  return {
    updateWebcamSettings,
  };
};
