import * as React from "react";
import InputBarComponent from "./InputBarComponent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Snackbar } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import { changePwd } from "../redux/actions/loginwithJwt";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

export default function ChangePwdComponent({ open, setMsg }) {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const [load, setLoad] = useState(false);
  const [ms, setMs] = useState("");
  const [type, setType] = useState("");

  const onSubmit = async (data) => {
    setLoad(true);
    console.log("On submit is ::" + JSON.stringify(data));
    let { email } = await JSON.parse(localStorage.getItem("user_data"));
    console.log("EMAIL::", email);
    changePwd(email, data.pwd, setLoad, setMsg, setMs, setType);
  };

  return (
    <Box>
      <Snackbar
        open={ms !== ""}
        autoHideDuration={60000}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        onClose={() => {
          setMs("");
        }}
      >
        <Alert severity={type}> {ms} </Alert>{" "}
      </Snackbar>{" "}
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 10,
            height: "33%",
            width: "30%",
            padding: 2,
          },
        }}
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          backgroundColor: "rgb(242, 247, 247,0.5)",
        })}
        open={open}
        disableEscapeKeyDown={true}
        onClose={() => true}
        scroll="body"
      >
        <DialogTitle
          sx={{
            fontSize: 18,
          }}
        >
          {" "}
          {t("welcomeView.changePassword")}{" "}
        </DialogTitle>{" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputBarComponent
                  name="old_pwd"
                  value={value}
                  onChange={onChange}
                  label={t("welcomeView.oldPassword")}
                  type="password"
                />
              )}
            />{" "}
            <Controller
              name="pwd"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputBarComponent
                  name="new_pwd"
                  value={value}
                  onChange={onChange}
                  label={t("welcomeView.newPassword")}
                  type="password"
                />
              )}
            />{" "}
          </DialogContent>{" "}
          <DialogActions
            disableSpacing={true}
            sx={{
              justifyContent: "center",
            }}
          >
            {load ? (
              <CircularProgress color="inherit" />
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: 20,
                  backgroundColor: "#FF592C",
                }}
                onClick={() => console.log("Load main page")}
              >
                {t("welcomeView.change")}{" "}
              </Button>
            )}{" "}
          </DialogActions>{" "}
        </form>{" "}
      </Dialog>{" "}
    </Box>
  );
}
