import * as React from 'react';
import {
    useState
} from 'react';
import UserSignUpDetails from './UserSignUpDetails';
import AgeGroup from './AgeGroup';
import ConfirmAllDetails from './ConfirmAllDetails';

// eslint-disable-next-line react/prop-types
export default function SignUpComponent({
    open,
    setOpen
}) {
    const [state, setState] = useState({
        step: 1
    });
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [birthdate, setBirthdate] = useState('');

    const nextStep = () => {
        const {
            step
        } = state;
        setState({
            step: step + 1,
        });
    };

    // Go back to prev step
    const prevStep = () => {
        const {
            step
        } = state;
        setState({
            step: step - 1,
        });
    };

    const {
        step
    } = state;

    switch (step) {
        case 1:
            return ( <
                UserSignUpDetails nextStep = {
                    nextStep
                }
                open = {
                    open
                }
                setOpen = {
                    setOpen
                }
                setName = {
                    setName
                }
                setEmail = {
                    setEmail
                }
                setPassword = {
                    setPassword
                }
                />
            );
        case 2:
            return ( <
                AgeGroup nextStep = {
                    nextStep
                }
                prevStep = {
                    prevStep
                }
                open = {
                    open
                }
                setOpen = {
                    setOpen
                }
                setBirthdate = {
                    setBirthdate
                }
                />
            );
        case 3:
            return ( <
                ConfirmAllDetails nextStep = {
                    nextStep
                }
                prevStep = {
                    prevStep
                }
                open = {
                    open
                }
                setOpen = {
                    setOpen
                }
                name = {
                    name
                }
                email = {
                    email
                }
                password = {
                    password
                }
                birthdate = {
                    birthdate
                }
                />
            );
    }
}