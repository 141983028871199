import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  deleteAssignment,
  getAssignments,
} from "../../../../../utilities/Demo/Physio/Patient/Overview/assignments";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import Button from "@mui/material/Button";
import ProtocolConfig from "./ProtocolConfig";
import { Alert, CircularProgress, List, Snackbar } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import DeleteButton from "../../../ExerciseManagement/DeleteButton";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useStartSchedule } from "../../../../../hooks/Demo/exerciseManagement/startEntity";
import Typography from "@mui/material/Typography";
import { ContentCopyOutlined } from "@mui/icons-material";
import { useState } from "react";

export default function Assignments({ patientId }) {
  const navigate = useNavigate();
  const { user } = useContext(FirebaseAuthContext);
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const startSchedule = useStartSchedule();
  const [loadingAssignments, setLoadingAssignments] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  const onClose = () => {
    navigate("/all_patients");
  };
  useEffect(() => {
    const doctorId = user.claims.doctorId || user.uid;
    patientId = user.claims.patientId || patientId;
    setLoadingAssignments(true);
    getAssignments({ doctorId, patientId }).then((assignments) => {
      setAssignments(assignments);
      setLoadingAssignments(false);
    });
  }, [patientId]);

  const addAssignment = (assignment) => {
    setAssignments([...assignments, assignment]);
  };

  const updateAssignment = (assignment) => {
    setAssignments(
      assignments.map((a) => {
        if (a.id === assignment.id) {
          return assignment;
        }
        return a;
      })
    );
  };

  if (selectedAssignment) {
    return (
      <ProtocolConfig
        patientId={patientId}
        config={selectedAssignment}
        onClose={() => setSelectedAssignment(null)}
        addProtocolToList={addAssignment}
        updateProtocolInList={updateAssignment}
      />
    );
  }

  const handleDeleteProtocol = async (assignmentId) => {
    await deleteAssignment({ userId: user?.uid, assignmentId: assignmentId });
    setAssignments(
      assignments.filter((assignment) => assignmentId !== assignment?.id)
    );
  };

  console.log("assignments", assignments);

  const handleCopyToClipboard = (assignmentId) => {
    navigator.clipboard
      .writeText(
        window.location.host +
          `/schedule_results/${assignmentId}?type=assignment`
      )
      ?.then(() => setIsCopied(true));
  };

  return (
    <Box style={{ margin: 5, padding: 5 }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <h1>Assignments</h1>
      <List>
        {loadingAssignments ? (
          <CircularProgress />
        ) : assignments.length === 0 ? (
          <Typography>No assignments</Typography>
        ) : null}
        {assignments.map((assignment) => (
          <ListItemButton key={assignment.id} style={{ padding: 10 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <h2
                  onClick={() => {
                    if (user.claims.patientId) {
                      startSchedule({
                        scheduleDoc: assignment,
                        queryParams: "type=assignment",
                      });
                    } else {
                      setSelectedAssignment(assignment);
                    }
                  }}
                >
                  {assignment.name}
                </h2>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="delete patient"
                  onClick={(e) => {
                    e.stopPropagation();
                    startSchedule({
                      scheduleDoc: assignment,
                      queryParams: "type=assignment",
                    });
                  }}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="copy"
                  onClick={() => handleCopyToClipboard(assignment?.id)}
                >
                  <ContentCopyOutlined />
                </IconButton>
              </Grid>
              {!user.claims.patientId && (
                <Grid item xs={2}>
                  <DeleteButton
                    onDelete={() => handleDeleteProtocol(assignment?.id)}
                    deletionTarget={"Protocol"}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography>{`${assignment.days} days, ${assignment.sessions} sessions`}</Typography>
              </Grid>
            </Grid>
          </ListItemButton>
        ))}
      </List>
      {!user.claims.patientId && (
        <Button
          onClick={() => {
            setSelectedAssignment({});
          }}
        >
          Add Protocol
        </Button>
      )}
      <Snackbar
        open={isCopied}
        autoHideDuration={2000}
        onClose={() => setIsCopied(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setIsCopied(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Copied!
        </Alert>
      </Snackbar>
    </Box>
  );
}
