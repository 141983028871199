export const detectBrowser = () => {
    const userAgent = navigator.userAgent;

    if (/MSIE|Trident/.test(userAgent)) {
        return 'Internet Explorer';
    } else if (/Edge/.test(userAgent)) {
        return 'Microsoft Edge';
    } else if (/Chrome/.test(userAgent)) {
        return 'Google Chrome';
    } else if (/Firefox/.test(userAgent)) {
        return 'Mozilla Firefox';
    } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
        return 'Safari';
    } else {
        return 'Unknown browser';
    }
};

export const supportedBrowsers = ['Google Chrome', 'Mozilla Firefox'];

export const isBrowserSupported = () => {
    const browser = detectBrowser();
    return supportedBrowsers.includes(browser);
};