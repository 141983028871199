import React, {
    useEffect
} from 'react';
import {
    useState
} from 'react';
import Pose1 from '../../assets/Pose_04.png';
import Planet from '../../assets/Pose_04.png';
import Ring from '../../assets/SA_Monster.png';

const AdminBgComponent = ({
    pageName = 'schedule'
}) => {
    const [astroPos, setAstroPos] = useState(25);
    const [dashboardAstro, setDashboardAstro] = useState(10);

    useEffect(() => {
        if (document.body.clientWidth < 2600) {
            setAstroPos(-5);
        } else if (document.body.clientWidth < 1600) {
            setAstroPos(0);
        }
        window.addEventListener('resize', () => {
            if (document.body.clientWidth < 2600) {
                setAstroPos(-5);
            } else if (document.body.clientWidth < 1600) {
                setAstroPos(0);
            }
        });
    });

    useEffect(() => {
        if (document.body.clientWidth < 1750) {
            setDashboardAstro(15);
        } else if (document.body.clientWidth > 1750) {
            setDashboardAstro(10);
        }
        window.addEventListener('resize', () => {
            if (document.body.clientWidth < 1750) {
                setDashboardAstro(15);
            } else if (document.body.clientWidth > 1750) {
                setDashboardAstro(10);
            }
        });
    });

    return ( <
        div style = {
            {
                position: 'fixed',
                width: '100%',
                height: '100%',
                zIndex: -100,
                backgroundColor: 'white',
                overflow: 'hidden',
                overflowX: 'hidden',
            }
        } >
        <
        img src = {
            pageName == 'schedule' ? Ring : Pose1
        }
        alt = "Exar"
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                top: pageName == 'schedule' ? '40%' : '20%',
                left: pageName == 'schedule' ? '1%' : '-20%',
                height: pageName == 'schedule' ? '60%' : '90%',
                width: pageName == 'schedule' ? '20%' : '55%',
            }
        }
        /> <
        img src = {
            pageName == 'schedule' ? Pose1 : Planet
        }
        alt = "Exar"
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                top: '-5%',
                right: pageName == 'schedule' ? `${astroPos}%` : `${dashboardAstro}%`,
                height: pageName == 'schedule' ? '50%' : '45%',
                width: pageName == 'schedule' ? '25%' : '40%',
            }
        }
        /> <
        /div>
    );
};

export default AdminBgComponent;