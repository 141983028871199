import React, { useRef } from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useCountsInTimeCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import {
  findAngle,
  findCounterAngle,
  findDependentAngle,
  horizontalAngle,
} from "../../../utilities/Demo/physio";
import PoseDetection from "../PoseDetection";
import {
  checkIfRightSide,
  checkIfSide,
  drawAngle,
} from "../../../utilities/Demo/angles";
import { safeIterScore } from "../../../hooks/Demo/sharedLogic";
import { loadSquatsAudio } from "../../../utilities/Demo/audioFeedback";
import { useDispatch } from "react-redux";

function SquatsDemo({
  onComplete,
  showWebcam = false,
  showBackbones = false,
  timeThresh = 60,
}) {
  const dispatch = useDispatch();
  const collecting = useRef(false);
  const peaked = useRef(false);
  const prePeaked = useRef(false);
  const {
    correctCount,
    updateFeedback,
    incorrectCount,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    setSide,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadSquatsAudio,
  });
  cptScore.current = () => {
    return (correctCount.current / timeThresh) * 2;
  };

  function giveFeedback(backAngle, kneeAnkleAngle, angle) {
    if (backAngle > 140) {
      playAudio(audioFeedback.current?.keepBackStraight);
      updateFeedback("Keep your back straight!");
    } else if (kneeAnkleAngle > 75) {
      playAudio(audioFeedback.current?.kneesFallingOverToes);
      updateFeedback("Knees falling over toes!");
    } else if (angle < 40) {
      playAudio(audioFeedback.current?.tooDeep);
      updateFeedback("Too deep!");
    }
  }

  function observation(backAngle, kneeAnkleAngle, angle) {
    // incorrect posture
    if (backAngle > 140 || kneeAnkleAngle > 75 || angle < 40) {
      if (collecting.current) {
        incorrectCount.current += 1;
        collecting.current = false;
      }
      giveFeedback(backAngle, kneeAnkleAngle, angle);
      // correct posture
    } else {
      if (angle < 65) {
        if (collecting.current) {
          peaked.current = true;
        }
        updateFeedback("");
      } else {
        if (collecting.current) {
          if (peaked.current && angle > 150) {
            collecting.current = false;
            correctCount.current += 1;
            updateCounts(correctCount.current);
            const score = cptScore.current();
            if (!raiseLevel(score)) {
              playAudio(audioFeedback.current?.achievement, true);
            } else {
              safeIterScore(dispatch, score);
            }
            updateFeedback("");
            peaked.current = false;
            setStartingTime(seconds);
          }
          if (!peaked.current) {
            if (angle < 80) {
              prePeaked.current = true;
            } else if (angle > 80 && prePeaked.current) {
              updateFeedback("Lower your hips!");
              playAudio(audioFeedback.current?.lowerHips);
            }
          }
        } else {
          if (angle < 150 && !peaked.current) {
            collecting.current = true;
            updateFeedback("");
          } else if (angle > 150) {
            peaked.current = false;
            prePeaked.current = false;
            updateFeedback("");
          }
        }
      }
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback("Turn to the side!");
      setSide(false);
      return;
    } else {
      updateFeedback("");
      setSide(true);
    }
    const isLeft = checkIfRightSide(results, "left");
    let angle;
    let kneeAnkleAngle;
    let backAngle;

    if (isLeft) {
      angle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      kneeAnkleAngle = findAngle(
        results.poseLandmarks[25],
        results.poseLandmarks[27],
        90
      );
      backAngle = findCounterAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      drawAngle(canvasCtx, angle, results.poseLandmarks[25]);
    } else {
      angle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (angle > 180) {
        angle = 360 - angle;
      }
      kneeAnkleAngle = findCounterAngle(
        results.poseLandmarks[26],
        results.poseLandmarks[28],
        90
      );
      backAngle = horizontalAngle(
        results.poseLandmarks[12],
        results.poseLandmarks[24]
      );
      drawAngle(canvasCtx, angle, results.poseLandmarks[26]);
    }
    observation(backAngle, kneeAnkleAngle, angle);
  }

  return (
    <PoseDetection
      onResults={onResults}
      showWebcam={showWebcam}
      showBackbones={showBackbones}
    />
  );
}

export default React.memo(SquatsDemo);
