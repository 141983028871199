import React, { useRef } from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useCountsInTimeCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import {
  drawExtendedLine,
  findAngle,
  findCounterAngle,
  findDependentAngle,
} from "../../../utilities/Demo/physio";
import PoseDetection from "../PoseDetection";
import { checkIfSide } from "../../../utilities/Demo/angles";
import { safeIterScore } from "../../../hooks/Demo/sharedLogic";
import { loadSquatsAudio } from "../../../utilities/Demo/audioFeedback";
import { useDispatch } from "react-redux";
import _ from "lodash";

function SquatsFront({
  onComplete,
  showWebcam = false,
  showBackbones = false,
}) {
  const dispatch = useDispatch();
  const upperLegLengthStanding = useRef(0);
  const torsoLengthStanding = useRef(0);
  const collecting = useRef(false);
  const peaked = useRef(false);
  const prePeaked = useRef(false);
  const timeThresh = 60;
  const {
    correctCount,
    updateFeedback,
    incorrectCount,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadSquatsAudio,
  });
  cptScore.current = () => {
    return (correctCount.current / timeThresh) * 2;
  };

  function giveFeedback(torsoLengthSquatting, upperLegLengthSquatting) {
    if (torsoLengthSquatting < torsoLengthStanding.current * 0.5) {
      playAudio(audioFeedback.current?.keepBackStraight);
      updateFeedback("Keep your back straight!");
    } else if (upperLegLengthSquatting < upperLegLengthStanding.current * 0.5) {
      playAudio(audioFeedback.current?.tooDeep);
      updateFeedback("Too deep!");
    }
  }

  function observation(
    hipKneeAngleLeft,
    hipKneeAngleRight,
    kneeAngleLeft,
    kneeAngleRight,
    results
  ) {
    const midHip = _.mean([
      results.poseLandmarks[24].y,
      results.poseLandmarks[26].y,
    ]);
    const midShoulder = _.mean([
      results.poseLandmarks[11].y,
      results.poseLandmarks[12].y,
    ]);
    const midKnee = _.mean([
      results.poseLandmarks[25].y,
      results.poseLandmarks[26].y,
    ]);
    const upperLegLengthSquatting = Math.abs(midHip - midKnee);
    const torsoLengthSquatting = Math.abs(midHip - midShoulder);
    if (
      hipKneeAngleLeft < 5 &&
      hipKneeAngleRight < 5 &&
      kneeAngleLeft < 195 &&
      kneeAngleRight < 195 &&
      kneeAngleLeft > 165 &&
      kneeAngleRight > 165 &&
      results.poseLandmarks[24].visibility > 0.5 &&
      results.poseLandmarks[26].visibility > 0.5 &&
      results.poseLandmarks[28].visibility > 0.5 &&
      results.poseLandmarks[23].visibility > 0.5 &&
      results.poseLandmarks[25].visibility > 0.5 &&
      results.poseLandmarks[27].visibility > 0.5
    ) {
      torsoLengthStanding.current = Math.abs(torsoLengthSquatting);
      upperLegLengthStanding.current = Math.abs(upperLegLengthSquatting);
    }
    // incorrect posture
    if (
      torsoLengthSquatting < torsoLengthStanding.current * 0.7 ||
      upperLegLengthSquatting < upperLegLengthStanding.current * 0.5
    ) {
      if (collecting.current) {
        incorrectCount.current += 1;
        collecting.current = false;
      }
      giveFeedback(torsoLengthSquatting, upperLegLengthSquatting);
      // correct posture
    } else {
      updateFeedback("");
      if (collecting.current) {
        if (upperLegLengthSquatting < upperLegLengthStanding.current * 0.8) {
          peaked.current = true;
          updateFeedback("");
        }
        if (
          peaked.current &&
          upperLegLengthSquatting < upperLegLengthStanding.current * 0.7
        ) {
          collecting.current = false;
          correctCount.current += 1;
          updateCounts(correctCount.current);
          const score = cptScore.current();
          if (!raiseLevel(score)) {
            playAudio(audioFeedback.current?.achievement, true);
          } else {
            safeIterScore(dispatch, score);
          }
          updateFeedback("");
          peaked.current = false;
          setStartingTime(seconds);
        }
        if (!peaked.current) {
          if (upperLegLengthSquatting < upperLegLengthStanding.current * 0.9) {
            prePeaked.current = true;
          } else if (
            upperLegLengthSquatting > upperLegLengthStanding.current * 0.9 &&
            prePeaked.current
          ) {
            updateFeedback("Lower your hips!");
            playAudio(audioFeedback.current?.lowerHips);
          }
        }
      } else {
        if (
          upperLegLengthSquatting > upperLegLengthStanding.current * 0.95 &&
          !peaked.current
        ) {
          collecting.current = true;
          updateFeedback("");
        } else if (
          upperLegLengthSquatting >
          upperLegLengthStanding.current * 0.95
        ) {
          peaked.current = false;
          prePeaked.current = false;
          updateFeedback("");
        }
      }
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (checkIfSide(results)) {
      // playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback("Face the camera!");
      return;
    } else {
      updateFeedback("");
    }

    const hipKneeAngleLeft = findAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25]
    );
    const hipKneeAngleRight = findCounterAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26]
    );
    const kneeAngleLeft = findDependentAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      results.poseLandmarks[27]
    );
    const kneeAngleRight = findDependentAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      results.poseLandmarks[28]
    );
    drawExtendedLine(
      canvasCtx,
      results.poseLandmarks[11],
      results.poseLandmarks[12],
      100
    );
    drawExtendedLine(
      canvasCtx,
      results.poseLandmarks[23],
      results.poseLandmarks[24],
      100
    );
    drawExtendedLine(
      canvasCtx,
      results.poseLandmarks[13],
      results.poseLandmarks[14],
      100
    );
    // drawAngle(canvasCtx, hipKneeAngleLeft, results.poseLandmarks[23]);
    // drawAngle(canvasCtx, hipKneeAngleRight, results.poseLandmarks[24]);
    // drawAngle(canvasCtx, kneeAngleLeft, results.poseLandmarks[25]);
    // drawAngle(canvasCtx, kneeAngleRight, results.poseLandmarks[26]);

    observation(
      hipKneeAngleLeft,
      hipKneeAngleRight,
      kneeAngleLeft,
      kneeAngleRight,
      results
    );
  }

  return (
    <PoseDetection
      onResults={onResults}
      showWebcam={showWebcam}
      showBackbones={showBackbones}
    />
  );
}

export default React.memo(SquatsFront);
