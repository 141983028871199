import React from "react";
import rank1 from "../../assets/rank_bg1.png";
import rank2 from "../../assets/rank_bg2.png";

const RankingBg = () => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "150%",
        top: "0",
        zIndex: -100,
        backgroundColor: "#F0ECEB",
        overflow: "hidden",
        overflowX: "hidden",
      }}
    >
      <img
        src={rank1}
        alt="Exar"
        style={{
          objectFit: "contain",
          position: "fixed",
          bottom: "-5%",
          left: "-22%",
          height: "75%",
          width: "70%",
        }}
      />{" "}
      <img
        src={rank2}
        alt="Exar"
        style={{
          objectFit: "contain",
          position: "fixed",
          top: "10%",
          right: "-8%",
          height: "60%",
          width: "50%",
        }}
      />{" "}
    </div>
  );
};

export default RankingBg;
