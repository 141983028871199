import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  GAIT_TIME_INTERVAL,
  OPTIMAL_GAIT_CYCLE_PCT,
} from "../../constants/Demo/gaitAnalysis";
import { getCenterOfMassTimeSeries } from "../../utilities/Demo/gaitAnalysis";
import {
  collectForEachStep,
  collectForEachWindow,
  computeCenterOfMass,
  cptGaitCycleOptimumDeviation,
  cptROMSymmetryIndex,
  cptSymmetryIndex,
  divideSeries,
  filterValidSteps,
  flattenSensorDataByKeys,
  getMeanSensorDataByKeys,
  getStrikeDistribution,
  getTimeWindowList,
  removeOutliers,
  resolveMeanAndResampledArrays,
  standardDeviation,
  sumSeries,
  useGraphSideSlices,
} from "../../utilities/Demo/Physio/GaitAnalysis/results";
import { useUpdatingGaitResultsActions } from "./actions";
import {
  CadenceComputer,
  GaitCycleMeanOptimumDeviationComputer,
  GaitCycleVariabilityComputer,
  MeanComputer,
  MeanStepTimeComputer,
  ROMSymmetryIndexComputer,
  SymmetryIndexComputer,
  ValidityChecker,
  WalkingSpeedComputer,
} from "../../utilities/Demo/Physio/GaitAnalysis/SensorResults/ResultsComputer";
import {
  MeanPerKeyComputer,
  MeanSumPerStepPerKeyComputer,
  SensorDataCollector,
  SumPerStepPerKeyComputer,
  SumZonesFromAllSensorDataComputer,
  SumZonesObjectComputer,
  TimeWindowCollector,
} from "../../utilities/Demo/Physio/GaitAnalysis/SensorResults/SensorDataPreparator";
import { zonesLeft } from "../../components/Demo/SensorResults/FootMap";
import {
  GraphConnector,
  GraphDataCollector,
  GraphDataResampler,
  GraphDataSetter,
} from "../../utilities/Demo/Physio/GaitAnalysis/SensorResults/GraphPreparator";

function getCenterOfMassTimeSeriesAxis(timeSeries) {
  return {
    x: timeSeries.map((value) => value.x),
    y: timeSeries.map((value) => value.y),
    z: timeSeries.map((value) => value.z),
  };
}

export function useCenterOfMassTimeSeries(front) {
  
  const gait = front
    ? useSelector(({ gaitFront }) => gaitFront.values)
    : useSelector(({ gait }) => gait.values);

  const filterCenterOfMassRight = collectForEachStep(
    gait.stepTimeLengthRight,
    gait.centerOfMassData,
    gait.stepValidityRight
  ).flat();
  const filterCenterOfMassLeft = collectForEachStep(
    gait.stepTimeLengthLeft,
    gait.centerOfMassData,
    gait.stepValidityLeft
  ).flat();
  const centerOfMassComplete = _.uniqBy(
    filterCenterOfMassLeft.concat(filterCenterOfMassRight),
    _.isEqual
  );

  const poseLandmarks = getCenterOfMassTimeSeries(centerOfMassComplete);
  Object.keys(poseLandmarks).forEach((joint) => {
    joint = parseInt(joint);
    poseLandmarks[joint] = getCenterOfMassTimeSeriesAxis(poseLandmarks[joint]);
  });

  return poseLandmarks;
}

export function useValidJointCollections(front = false) {
  const gait = front
    ? useSelector(({ gaitFront }) => gaitFront.values)
    : useSelector(({ gait }) => gait.values);

  const leftLegCollection = collectForEachWindow({
    windowArray: gait.stanceTimeWindowsLeft,
    graphData: gait.graphData[0]?.data,
    validityArray: gait.stepValidityLeft,
  });
  const rightLegCollection = collectForEachStep(
    gait.stepTimeLengthRight,
    gait.graphData[1]?.data,
    gait.stepValidityRight
  );
  const leftKneeCollection = collectForEachStep(
    gait.stepTimeLengthLeft,
    gait.graphData[2]?.data,
    gait.stepValidityLeft
  );
  const rightKneeCollection = collectForEachStep(
    gait.stepTimeLengthRight,
    gait.graphData[3]?.data,
    gait.stepValidityRight
  );
  const leftAnkleCollection = collectForEachStep(
    gait.stepTimeLengthLeft,
    gait.graphData[4]?.data,
    gait.stepValidityLeft
  );
  const rightAnkleCollection = collectForEachStep(
    gait.stepTimeLengthRight,
    gait.graphData[5]?.data,
    gait.stepValidityRight
  );
  const leftHipCollection = collectForEachStep(
    gait.stepTimeLengthLeft,
    gait.graphData[6]?.data,
    gait.stepValidityLeft
  );
  const rightHipCollection = collectForEachStep(
    gait.stepTimeLengthRight,
    gait.graphData[7]?.data,
    gait.stepValidityRight
  );
  const leftHipRotationCollection = collectForEachStep(
    gait.stepTimeLengthLeft,
    gait.graphData[8]?.data,
    gait.stepValidityLeft
  );
  const rightHipRotationCollection = collectForEachStep(
    gait.stepTimeLengthRight,
    gait.graphData[8]?.data,
    gait.stepValidityRight
  );
  const leftHipBalanceCollection = collectForEachStep(
    gait.stepTimeLengthLeft,
    gait.graphData[9]?.data,
    gait.stepValidityLeft
  );
  const rightHipBalanceCollection = collectForEachStep(
    gait.stepTimeLengthRight,
    gait.graphData[9]?.data,
    gait.stepValidityRight
  );

  return {
    leftLegCollection,
    rightLegCollection,
    leftKneeCollection,
    rightKneeCollection,
    leftAnkleCollection,
    rightAnkleCollection,
    leftHipCollection,
    rightHipCollection,
    leftHipRotationCollection,
    rightHipRotationCollection,
    leftHipBalanceCollection,
    rightHipBalanceCollection,
  };
}

export function useSensorDataRefactored() {
  const gait = useSelector(({ gait }) => gait.values);
  console.log(_.cloneDeep(gait), "gait");
  if (
    gait.sensorData?.left.length === 0 ||
    gait.sensorData?.right.length === 0
  ) {
    return {};
  }
  // const timeWindowCollector = new TimeWindowCollector({
  //   gaitResults: _.cloneDeep(gait),
  // });
  console.log(TimeWindowCollector, "time window collector");
  const sumZonesFromAllSensorDataComputer =
    new SumZonesFromAllSensorDataComputer({
      gaitResults: _.cloneDeep(gait),
    });
  const sumZonesObjectComputer = new SumZonesObjectComputer({
    sumZonesFromAllSensorDataComputer,
  });

  const validityChecker = new ValidityChecker({
    gaitResults: _.cloneDeep(gait),
  });
  const sensorDataCollector = new SensorDataCollector({
    validityChecker,
  });
  console.log(sensorDataCollector, "sensor data collector");
  const meanPerKeyComputer = new MeanPerKeyComputer({
    sensorDataCollector,
  });
  const sumPerStepPerKeyComputer = new SumPerStepPerKeyComputer({
    sensorDataCollector,
  });
  const meanSumPerStepPerKeyComputer = new MeanSumPerStepPerKeyComputer({
    sumPerStepPerKeyComputer,
  });
  console.log(
    meanSumPerStepPerKeyComputer,
    "mean sum per step per key computer"
  );
  // const meanSumPerStepPerSideComputer = new MeanSumPerStepPerSideComputer({
  //   meanSumPerStepPerKeyComputer,
  // });
  // const sumPerKeyComputer = new SumPerKeyComputer({
  //   sensorDataCollector,
  // });
  // const sumPerSideComputer = new SumPerSideComputer({
  //   sumPerKeyComputer,
  // });
  // const meanMaxPerStepComputer = new MeanMaxPerStepComputer({
  //   sensorDataCollector,
  // });

  // const leftStrikeDistribution = getStrikeDistribution(
  //   sensorDataCollector.leftSensorDataCollection
  // );
  // const rightStrikeDistribution = getStrikeDistribution(
  //   sensorDataCollector.rightSensorDataCollection
  // );
  const leftStrikeDistribution = getStrikeDistribution([
    gait.sensorData?.left.map((obs) => obs.value),
  ]);
  const rightStrikeDistribution = getStrikeDistribution([
    gait.sensorData?.right.map((obs) => obs.value),
  ]);

  // const meanLeft = { ...meanMaxPerStepComputer.leftMeanMaxPerStep };
  // const meanRight = { ...meanMaxPerStepComputer.rightMeanMaxPerStep };

  // const meanLeft = { ...meanSumPerStepPerKeyComputer.leftMeanSumPerStepPerKey };
  // const meanRight = {
  //   ...meanSumPerStepPerKeyComputer.rightMeanSumPerStepPerKey,
  // };
  const meanLeft =
    sumZonesFromAllSensorDataComputer.leftSumZonesFromAllSensorData;
  const meanRight =
    sumZonesFromAllSensorDataComputer.rightSumZonesFromAllSensorData;

  meanLeft["overpronate"] = meanPerKeyComputer.leftMeanPerKey["overpronate"];
  meanRight["overpronate"] = meanPerKeyComputer.rightMeanPerKey["overpronate"];
  // meanLeft['balance_sum'] = meanSumPerStepPerSideComputer.leftMeanSumPerStep;
  // meanRight['balance_sum'] = meanSumPerStepPerSideComputer.rightMeanSumPerStep;
  meanLeft["balance_sum"] = sumZonesObjectComputer.leftSumZones;
  meanRight["balance_sum"] = sumZonesObjectComputer.rightSumZones;

  return {
    meanLeft,
    meanRight,
    leftSensorDataCollection: sensorDataCollector.leftSensorDataCollection,
    rightSensorDataCollection: sensorDataCollector.rightSensorDataCollection,
    leftStrikeDistribution,
    rightStrikeDistribution,
  };
}

export function useSensorData() {
  const gait = useSelector(({ gait }) => gait.values);
  if (
    gait.sensorData?.left.length === 0 ||
    gait.sensorData?.right.length === 0
  ) {
    console.log("gait", gait);
    return {};
  }
  const rightValidIndex = removeOutliers({
    dataArray: filterValidSteps(
      getTimeWindowList(gait.stanceTimeWindowsRight),
      gait.stepValidityRight
    ),
    getIndex: true,
  });
  const leftValidIndex = removeOutliers({
    dataArray: filterValidSteps(
      getTimeWindowList(gait.stanceTimeWindowsLeft),
      gait.stepValidityLeft
    ),
    getIndex: true,
  });
  let leftSensorDataCollection = collectForEachWindow({
    windowArray: gait.stanceTimeWindowsLeft,
    graphData: gait.sensorData?.left,
    xAxis: "time",
    yAxis: "value",
  });
  leftSensorDataCollection = leftValidIndex.map(
    (index) => leftSensorDataCollection[index]
  );
  let rightSensorDataCollection = collectForEachWindow({
    windowArray: gait.stanceTimeWindowsRight,
    graphData: gait.sensorData?.right,
    xAxis: "time",
    yAxis: "value",
  });
  rightSensorDataCollection = rightValidIndex.map(
    (index) => rightSensorDataCollection[index]
  );
  if (
    leftSensorDataCollection.length === 0 ||
    rightSensorDataCollection.length === 0
  ) {
    leftSensorDataCollection = [gait.sensorData.left];
    rightSensorDataCollection = [gait.sensorData.right];
  }
  const leftStrikeDistribution = getStrikeDistribution(
    leftSensorDataCollection
  );
  const rightStrikeDistribution = getStrikeDistribution(
    rightSensorDataCollection
  );
  const maxPerStepPerKeyRight = rightSensorDataCollection.map((step) => {
    const maxPerKey = {};
    const stepFlattened = flattenSensorDataByKeys([step]);
    Object.keys(stepFlattened).forEach((key) => {
      maxPerKey[key] = _.max(stepFlattened[key]);
    });
    return maxPerKey;
  });
  const maxPerStepPerKeyLeft = leftSensorDataCollection.map((step) => {
    const maxPerKey = {};
    const stepFlattened = flattenSensorDataByKeys([step]);
    Object.keys(stepFlattened).forEach((key) => {
      maxPerKey[key] = _.max(stepFlattened[key]);
    });
    return maxPerKey;
  });
  const meanLeft = {};
  const meanRight = {};
  Object.keys(maxPerStepPerKeyLeft[0]).forEach((key) => {
    meanLeft[key] = _.mean(maxPerStepPerKeyLeft.map((step) => step[key]));
  });
  Object.keys(maxPerStepPerKeyRight[0]).forEach((key) => {
    meanRight[key] = _.mean(maxPerStepPerKeyRight.map((step) => step[key]));
  });
  const flatLeft = flattenSensorDataByKeys(leftSensorDataCollection);
  const flatRight = flattenSensorDataByKeys(rightSensorDataCollection);

  const meanAllStanceLeft = getMeanSensorDataByKeys(flatLeft);
  const meanAllStanceRight = getMeanSensorDataByKeys(flatRight);

  meanLeft["cobHoriz"] = meanAllStanceLeft["cobHoriz"];
  meanRight["cobHoriz"] = meanAllStanceRight["cobHoriz"];
  meanLeft["cobVert"] = meanAllStanceLeft["cobVert"];
  meanRight["cobVert"] = meanAllStanceRight["cobVert"];
  meanLeft["overpronate"] = meanAllStanceLeft["overpronate"];
  meanRight["overpronate"] = meanAllStanceRight["overpronate"];
  meanLeft["balance_sum"] = _.sum(
    Object.keys(zonesLeft).map((key) => meanAllStanceLeft[key])
  );
  meanRight["balance_sum"] = _.sum(
    Object.keys(zonesLeft).map((key) => meanAllStanceRight[key])
  );

  return {
    meanLeft,
    meanRight,
    leftSensorDataCollection,
    rightSensorDataCollection,
    leftStrikeDistribution,
    rightStrikeDistribution,
  };
}

export function useRelativeLandmarks(lastIndex) {
  const gait = useSelector(({ gait }) => gait.values);
  const lastGaitResults = useSelector(({ lastGaitResults }) => lastGaitResults);
  const [result, setResult] = useState({});
  useEffect(() => {
    let relativeLandmarks = {};
    let observationsPerJoint = {};
    if (gait.relativeLandmarks.length > 0 && !lastIndex) {
      relativeLandmarks = _.cloneDeep(gait.relativeLandmarks);
      Object.keys(relativeLandmarks)
        .slice(0, 33)
        .forEach((joint) => {
          observationsPerJoint[joint] = [];
        });
      relativeLandmarks.forEach((observation) => {
        Object.keys(observation).forEach((joint) => {
          observationsPerJoint[joint].push(observation[joint]);
        });
      });
    } else if (lastGaitResults.docs.length > 0) {
      const targetIndex = lastIndex || 0;
      relativeLandmarks = _.cloneDeep(
        lastGaitResults.docs[targetIndex].relativeLandmarks
      );
      observationsPerJoint = relativeLandmarks;
    }

    if (!relativeLandmarks) return;
    setResult(observationsPerJoint);
  }, [gait.relativeLandmarks, lastGaitResults.docs, lastIndex]);

  return result;
}

export function useGraphDataRefactored() {
  const [results, setResults] = useState({});
  const gait = useSelector(({ gait }) => gait.values);
  const validityChecker = new ValidityChecker({
    gaitResults: _.cloneDeep(gait),
  });
  const graphDataCollector = new GraphDataCollector({ validityChecker });
  const graphDataResampler = new GraphDataResampler({ graphDataCollector });
  useEffect(() => {
    graphDataResampler.resampleData().then(() => {
      const graphConnector = new GraphConnector({ graphDataResampler });
      const graphDataSetter = new GraphDataSetter({ graphConnector });
      setResults({ ...graphDataSetter.results, allSet: true });
    });
  }, []);

  return results;
}

export function useGraphData(useRefactored = true) {
  if (useRefactored) return useGraphDataRefactored();
  const tried = useRef(false);
  const collectionData = useValidJointCollections();
  const collectionDataFront = useValidJointCollections(true);
  const graphSideSlices = useGraphSideSlices(collectionData);
  const graphSideSlicesFront = useGraphSideSlices(collectionDataFront);

  const [mainChartData, setMainChartData] = useState([]);
  const [ankleChartData, setAnkleChartData] = useState([]);
  const [kneeChartData, setKneeChartData] = useState([]);
  const [hipChartData, setHipChartData] = useState([]);
  const [hipRotationChartData, setHipRotationChartData] = useState([]);
  const [ankleChartDataFront, setAnkleChartDataFront] = useState([]);
  const [kneeChartDataFront, setKneeChartDataFront] = useState([]);
  const [hipChartDataFront, setHipChartDataFront] = useState([]);
  const [hipRotationChartDataFront, setHipRotationChartDataFront] = useState(
    []
  );
  const [hipBalanceChartDataFront, setHipBalanceChartDataFront] = useState([]);
  const [meanAndResampledArrays, setMeanAndResampledArrays] = useState({});
  const [meanAndResampledArraysFront, setMeanAndResampledArraysFront] =
    useState({});
  const [allSet, setAllSet] = useState(false);

  useEffect(() => {
    // Side Data
    async function setChartData() {
      const resolvedData = await resolveMeanAndResampledArrays(graphSideSlices);
      setMeanAndResampledArrays(resolvedData);
      setMainChartData([
        {
          name: "Left Leg Angle",
          data: resolvedData.leftLegMean,
        },
        {
          name: "Right Leg Angle",
          data: resolvedData.rightLegMean,
        },
      ]);
      setAnkleChartData([
        {
          name: "Left Ankle Angle",
          data: resolvedData.leftAnkleMean,
        },
        {
          name: "Right Ankle Angle",
          data: resolvedData.rightAnkleMean,
        },
      ]);
      setKneeChartData([
        {
          name: "Left Knee Angle",
          data: resolvedData.leftKneeMean,
        },
        {
          name: "Right Knee Angle",
          data: resolvedData.rightKneeMean,
        },
      ]);
      setHipChartData([
        {
          name: "Left Hip Angle",
          data: resolvedData.leftHipMean,
        },
        {
          name: "Right Hip Angle",
          data: resolvedData.rightHipMean,
        },
      ]);
      setHipRotationChartData([
        {
          name: "Left Hip Rotation",
          data: resolvedData.leftHipRotationMean,
        },
        {
          name: "Right Hip Rotation",
          data: resolvedData.rightHipRotationMean,
        },
      ]);

      // Front Data
      const resolvedDataFront = await resolveMeanAndResampledArrays(
        graphSideSlicesFront
      );
      setMeanAndResampledArraysFront(resolvedDataFront);
      setAnkleChartDataFront([
        {
          name: "Left Ankle Angle",
          data: resolvedDataFront?.leftAnkleMean,
        },
        {
          name: "Right Ankle Angle",
          data: resolvedDataFront?.rightAnkleMean,
        },
      ]);
      setKneeChartDataFront([
        {
          name: "Left Knee Angle",
          data: resolvedDataFront?.leftKneeMean,
        },
        {
          name: "Right Knee Angle",
          data: resolvedDataFront?.rightKneeMean,
        },
      ]);
      setHipChartDataFront([
        {
          name: "Left Hip Angle",
          data: resolvedDataFront?.leftHipMean,
        },
        {
          name: "Right Hip Angle",
          data: resolvedDataFront?.rightHipMean,
        },
      ]);
      setHipRotationChartDataFront([
        {
          name: "Left Hip Rotation",
          data: resolvedDataFront?.leftHipRotationMean,
        },
        {
          name: "Right Hip Rotation",
          data: resolvedDataFront?.rightHipRotationMean,
        },
      ]);
      setHipBalanceChartDataFront([
        {
          name: "Left Hip Balance",
          data: resolvedDataFront?.leftHipBalanceMean,
        },
        {
          name: "Right Hip Balance",
          data: resolvedDataFront?.rightHipBalanceMean,
        },
      ]);
    }

    if (tried.current) return;
    setChartData().then(() => setAllSet(true));
    tried.current = true;
  }, [graphSideSlices, graphSideSlicesFront]);

  return {
    mainChartData,
    ankleChartData,
    kneeChartData,
    hipChartData,
    hipRotationChartData,
    ankleChartDataFront,
    kneeChartDataFront,
    hipChartDataFront,
    hipRotationChartDataFront,
    hipBalanceChartDataFront,
    meanAndResampledArrays,
    meanAndResampledArraysFront,
    allSet,
  };
}

export function useMeanStepTimeFromTimeWindow() {
  const gait = useSelector(({ gait }) => gait.values);
  const stepTimesLeft = gait.stanceTimeWindowsLeft.map((window) => {
    if (window.start && window.end) {
      return window.end - window.start;
    } else {
      return 0;
    }
  });
  const meanStepTimeLeft = _.mean({
    dataArray: removeOutliers(
      filterValidSteps(stepTimesLeft, gait.stepValidityLeft)
    ),
  });
  const stepTimesRight = gait.stanceTimeWindowsRight.map((window) => {
    if (window.start && window.end) {
      return window.end - window.start;
    } else {
      return 0;
    }
  });
  const meanStepTimeRight = _.mean({
    dataArray: removeOutliers(
      filterValidSteps(stepTimesRight, gait.stepValidityRight)
    ),
  });
  return (meanStepTimeLeft + meanStepTimeRight) / 2;
}

export function useMeanStepTime() {
  const gait = useSelector(({ gait }) => gait.values);
  const meanStrideTimeLeft = _.mean(
    removeOutliers({
      dataArray: filterValidSteps(
        getTimeWindowList(gait.strideTimeWindowsLeft),
        gait.stepValidityLeft
      ),
    })
  );
  const meanStrideTimeRight = _.mean(
    removeOutliers({
      dataArray: filterValidSteps(
        getTimeWindowList(gait.strideTimeWindowsRight),
        gait.stepValidityRight
      ),
    })
  );
  const meanStanceTimeLeft = _.mean(
    removeOutliers({
      dataArray: filterValidSteps(
        getTimeWindowList(gait.stanceTimeWindowsLeft),
        gait.stepValidityLeft
      ),
    })
  );
  const meanStanceTimeRight = _.mean(
    removeOutliers({
      dataArray: filterValidSteps(
        getTimeWindowList(gait.stanceTimeWindowsRight),
        gait.stepValidityRight
      ),
    })
  );
  const meanStepTime =
    (meanStrideTimeLeft +
      meanStrideTimeRight +
      meanStanceTimeRight +
      meanStanceTimeLeft) /
    4;
  const upscaleTimeInterval = 1000 / GAIT_TIME_INTERVAL;
  return meanStepTime / upscaleTimeInterval;
}

export const useCadence = () => {
  const meanStepTime = useMeanStepTime();
  return parseFloat((60 / meanStepTime).toFixed(1));
};

export const useWalkingSpeed = ({ metric }) => {
  const gait = useSelector(({ gait }) => gait.values);
  const meanStepTime = useMeanStepTime();
  const stepLengthsLeft = removeOutliers({
    dataArray: filterValidSteps(gait.stepLengthLeft, gait.stepValidityLeft),
  });
  const stepLengthsRight = removeOutliers({
    dataArray: filterValidSteps(gait.stepLengthRight, gait.stepValidityRight),
  });
  const stepLengthComplete = _.sum(stepLengthsLeft) + _.sum(stepLengthsRight);
  const stepTimeComplete =
    (stepLengthsLeft.length + stepLengthsRight.length) * meanStepTime;
  const stepLengthCompleteMeters = stepLengthComplete / 100;
  const metersPerSecond = stepLengthCompleteMeters / stepTimeComplete;
  if (metric === "mps") {
    return parseFloat(metersPerSecond.toFixed(2));
  }
  if (metric === "kmh") {
    const kilometersPerSecond = metersPerSecond / 1000;
    const kilometersPerHour = kilometersPerSecond * 60 * 60;
    return Math.round(kilometersPerHour * 100) / 100;
  } else {
    throw new Error("Invalid metric");
  }
};

export const useGaitCycleVariability = () => {
  const gait = useSelector(({ gait }) => gait.values);
  return standardDeviation(
    divideSeries(
      removeOutliers({
        dataArray: filterValidSteps(
          getTimeWindowList(gait.stanceTimeWindowsRight),
          gait.stepValidityRight
        ),
      }),
      removeOutliers({
        dataArray: filterValidSteps(
          getTimeWindowList(gait.strideTimeWindowsRight),
          gait.stepValidityRight
        ),
      })
    ).concat(
      divideSeries(
        removeOutliers({
          dataArray: filterValidSteps(
            getTimeWindowList(gait.stanceTimeWindowsLeft),
            gait.stepValidityLeft
          ),
        }),
        removeOutliers({
          dataArray: filterValidSteps(
            getTimeWindowList(gait.strideTimeWindowsLeft),
            gait.stepValidityLeft
          ),
        })
      )
    )
  );
};

export const useGaitCycleMeanOptimumDeviation = () => {
  const gait = useSelector(({ gait }) => gait.values);
  return Math.floor(
    _.mean([
      Math.abs(
        _.mean(
          divideSeries(
            filterValidSteps(
              getTimeWindowList(gait.stanceTimeWindowsLeft),
              gait.stepValidityLeft
            ),
            sumSeries(
              filterValidSteps(
                getTimeWindowList(gait.stanceTimeWindowsLeft),
                gait.stepValidityLeft
              ),
              filterValidSteps(
                getTimeWindowList(gait.strideTimeWindowsLeft),
                gait.stepValidityLeft
              )
            )
          )
        ) - OPTIMAL_GAIT_CYCLE_PCT
      ),
      Math.abs(
        _.mean(
          divideSeries(
            filterValidSteps(
              getTimeWindowList(gait.stanceTimeWindowsRight),
              gait.stepValidityRight
            ),
            sumSeries(
              filterValidSteps(
                getTimeWindowList(gait.stanceTimeWindowsRight),
                gait.stepValidityRight
              ),
              filterValidSteps(
                getTimeWindowList(gait.strideTimeWindowsRight),
                gait.stepValidityRight
              )
            )
          )
        ) - OPTIMAL_GAIT_CYCLE_PCT
      ),
    ]) * 100
  );
};

export function useMetricsRefactored() {
  const gait = useSelector(({ gait }) => gait.values);
  const validityChecker = new ValidityChecker({ gaitResults: gait });

  if (!validityChecker.checkIfSufficientCyclePhaseData()) {
    return {
      notEnoughData: true,
    };
  }

  console.log(validityChecker, "validity checker");

  const meanComputer = new MeanComputer({ validityChecker });
  const meanStepTimeComputer = new MeanStepTimeComputer({
    meanComputer,
  });
  const cadenceComputer = new CadenceComputer({
    meanStepTimeComputer,
  });
  const walkingSpeedComputer = new WalkingSpeedComputer({
    meanStepTimeComputer,
    meanComputer,
  });
  console.log(walkingSpeedComputer, "walking speed computer");
  const gaitCycleVariabilityComputer = new GaitCycleVariabilityComputer({
    validityChecker,
  });
  const gaitCycleMeanOptimumDeviationComputer =
    new GaitCycleMeanOptimumDeviationComputer({
      meanComputer,
    });
  const rOMSymmetryIndexComputer = new ROMSymmetryIndexComputer({
    validityChecker,
  });
  const symmetryIndexComputer = new SymmetryIndexComputer({
    validityChecker,
  });
  console.log(validityChecker);

  return {
    cadence: cadenceComputer.cadence,
    walkingSpeed: walkingSpeedComputer.walkingSpeed,
    strideTimeLeft: meanComputer.meanStrideTimeLeft,
    strideTimeRight: meanComputer.meanStrideTimeRight,
    strideTimeSymmetryIndex: symmetryIndexComputer.strideTimeSymmetryIndex,
    strideTimeROMSymmetryIndex:
      rOMSymmetryIndexComputer.strideTimeROMSymmetryIndex,
    stepLengthLeft: meanComputer.meanStepLengthLeft,
    stepLengthRight: meanComputer.meanStepLengthRight,
    stepLengthSymmetryIndex: symmetryIndexComputer.stepLengthSymmetryIndex,
    stepLengthROMSymmetryIndex:
      rOMSymmetryIndexComputer.stepLengthROMSymmetryIndex,
    stanceTimeLeft: meanComputer.meanStanceTimeLeft,
    stanceTimeRight: meanComputer.meanStanceTimeRight,
    stanceTimeSymmetryIndex: symmetryIndexComputer.stanceTimeSymmetryIndex,
    stanceTimeROMSymmetryIndex:
      rOMSymmetryIndexComputer.stanceTimeROMSymmetryIndex,
    gaitCycleVariability: gaitCycleVariabilityComputer.gaitCycleVariability,
    gaitCycleMeanOptimumDeviation:
      gaitCycleMeanOptimumDeviationComputer.gaitCycleMeanOptimumDeviation,
    com: undefined,
    comFront: 0,
  };
}

export function useMetrics(useRefactored = true) {
  if (useRefactored) return useMetricsRefactored();
  const cadence = useCadence();
  const walkingSpeed = useWalkingSpeed({ metric: "mps" });
  const gaitCycleVariability = useGaitCycleVariability();
  const gait = useSelector(({ gait }) => gait.values);
  const strideTimeLeft =
    _.mean(
      removeOutliers({
        dataArray: filterValidSteps(
          getTimeWindowList(gait.strideTimeWindowsLeft),
          gait.stepValidityLeft
        ),
      })
    ) /
    (1000 / GAIT_TIME_INTERVAL);
  const strideTimeRight =
    Number(
      _.mean(
        removeOutliers({
          dataArray: filterValidSteps(
            getTimeWindowList(gait.strideTimeWindowsRight),
            gait.stepValidityRight
          ),
        })
      ).toFixed(2)
    ) /
    (1000 / GAIT_TIME_INTERVAL);
  const strideTimeSymmetryIndex = cptSymmetryIndex([
    {
      data: removeOutliers({
        dataArray: filterValidSteps(
          getTimeWindowList(gait.strideTimeWindowsLeft),
          gait.stepValidityLeft
        ),
      }),
    },
    {
      data: removeOutliers({
        dataArray: filterValidSteps(
          getTimeWindowList(gait.strideTimeWindowsRight),
          gait.stepValidityRight
        ),
      }),
    },
  ]);
  const strideTimeROMSymmetryIndex = cptROMSymmetryIndex([
    {
      data: removeOutliers({
        dataArray: filterValidSteps(
          getTimeWindowList(gait.strideTimeWindowsLeft),
          gait.stepValidityLeft
        ),
      }),
    },
    {
      data: removeOutliers({
        dataArray: filterValidSteps(
          getTimeWindowList(gait.strideTimeWindowsRight),
          gait.stepValidityRight
        ),
      }),
    },
  ]);
  const stepLengthLeft = Number(
    _.mean(
      filterValidSteps(gait.stepLengthLeft, gait.stepValidityLeft)
    ).toFixed(2)
  );
  const stepLengthRight = Number(
    _.mean(
      filterValidSteps(gait.stepLengthRight, gait.stepValidityRight)
    ).toFixed(2)
  );
  const stepLengthSymmetryIndex = cptSymmetryIndex([
    { data: filterValidSteps(gait.stepLengthLeft, gait.stepValidityLeft) },
    { data: filterValidSteps(gait.stepLengthRight, gait.stepValidityRight) },
  ]);
  const stepLengthROMSymmetryIndex = cptROMSymmetryIndex([
    { data: filterValidSteps(gait.stepLengthLeft, gait.stepValidityLeft) },
    { data: filterValidSteps(gait.stepLengthRight, gait.stepValidityRight) },
  ]);
  const stanceTimeLeft =
    Number(
      _.mean(
        removeOutliers({
          dataArray: filterValidSteps(
            gait.stanceTimeLeft,
            gait.stepValidityLeft
          ),
        })
      ).toFixed(2)
    ) /
    (1000 / GAIT_TIME_INTERVAL);
  const stanceTimeRight =
    Number(
      _.mean(
        removeOutliers({
          dataArray: filterValidSteps(
            gait.stanceTimeRight,
            gait.stepValidityRight
          ),
        })
      ).toFixed(2)
    ) /
    (1000 / GAIT_TIME_INTERVAL);
  const stanceTimeSymmetryIndex = cptSymmetryIndex([
    { data: filterValidSteps(gait.stanceTimeLeft, gait.stepValidityLeft) },
    { data: filterValidSteps(gait.stanceTimeRight, gait.stepValidityRight) },
  ]);
  const stanceTimeROMSymmetryIndex = cptROMSymmetryIndex([
    { data: filterValidSteps(gait.stanceTimeLeft, gait.stepValidityLeft) },
    { data: filterValidSteps(gait.stanceTimeRight, gait.stepValidityRight) },
  ]);
  const com = computeCenterOfMass(
    useCenterOfMassTimeSeries(),
    gait.hipShoulderDelta
  );
  const comFront = computeCenterOfMass(
    useCenterOfMassTimeSeries(true),
    gait.hipShoulderDelta
  );
  const gaitCycleMeanOptimumDeviation = cptGaitCycleOptimumDeviation(
    strideTimeLeft,
    stanceTimeLeft,
    strideTimeRight,
    stanceTimeRight
  );

  return {
    cadence,
    walkingSpeed,
    strideTimeLeft,
    strideTimeRight,
    strideTimeSymmetryIndex,
    strideTimeROMSymmetryIndex,
    stepLengthLeft,
    stepLengthRight,
    stepLengthSymmetryIndex,
    stepLengthROMSymmetryIndex,
    stanceTimeLeft,
    stanceTimeRight,
    stanceTimeSymmetryIndex,
    stanceTimeROMSymmetryIndex,
    gaitCycleVariability,
    gaitCycleMeanOptimumDeviation,
    com,
    comFront,
  };
}

export function useGaitResults() {
  // const sensorData = useSensorData();
  const sensorData = useSensorDataRefactored();
  const graphData = useGraphData();
  const relativeLandmarks = useRelativeLandmarks();
  const sentResults = useRef(false);
  const sentMetricResults = useRef(false);
  const metrics = useMetrics();
  const gaitResults = useSelector(({ gaitResults }) => gaitResults);
  // const collectionData = useValidJointCollections();
  // const collectionDataFront = useValidJointCollections(true);
  // const seriesSIs = cptSeriesSIs(graphData);
  // const romAll = cptROMAll(collectionData);
  // const romAllFront = cptROMAll(collectionDataFront);
  // const meanRomAll = cptMeanROMAll(graphData.meanAndResampledArrays);
  // const meanRomAllFront = cptMeanROMAll(graphData.meanAndResampledArraysFront);
  const { updateResults } = useUpdatingGaitResultsActions();

  useEffect(() => {
    if (
      metrics &&
      !sentMetricResults.current &&
      graphData.allSet &&
      relativeLandmarks
    ) {
      console.log("updating metrics");
      console.log("updating graphData", graphData);
      updateResults({
        id: gaitResults.id,
        payload: {
          metrics: metrics,
          graphData,
          // relativeLandmarks,
        },
      }).then();
      sentMetricResults.current = true;
    } else {
      console.log("not updating metrics");
    }
  }, [metrics, sentMetricResults.current, graphData]);

  useEffect(() => {
    console.log(relativeLandmarks);
    if (
      // romAll &&
      // romAllFront &&
      // meanRomAll &&
      sensorData.meanLeft &&
      sensorData.meanRight &&
      sensorData.leftSensorDataCollection &&
      sensorData.rightSensorDataCollection &&
      // meanRomAllFront &&
      // seriesSIs &&
      graphData.allSet &&
      relativeLandmarks &&
      !sentResults.current
    ) {
      console.log("updating results", relativeLandmarks);
      updateResults({
        id: gaitResults.id,
        payload: {
          // romAll: romAll,
          // romAllFront: romAllFront,
          // meanRomAll: meanRomAll,
          // meanRomAllFront: meanRomAllFront,
          // seriesSIs: seriesSIs,
          graphData: graphData,
          sensorData: {
            leftStrikeDistribution: sensorData.leftStrikeDistribution,
            rightStrikeDistribution: sensorData.rightStrikeDistribution,
            meanLeft: sensorData.meanLeft,
            meanRight: sensorData.meanRight,
          },
          // relativeLandmarks,
        },
      }).then(() => {
        sentResults.current = true;
      });
    } else {
      console.log("not updating results");
    }
  }, [
    // meanRomAll,
    // meanRomAllFront,
    // romAll,
    // romAllFront,
    // seriesSIs,
    updateResults,
    graphData,
    // graphData.allSet,
    relativeLandmarks,
    sensorData,
  ]);

  return metrics;
}
