import { CircularProgress, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import { getExercises } from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import CloseBadge from "../CloseBadge";

export default function ExerciseDialog({ onClose, setExercise, open }) {
  const [searchValue, setSearchValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [exercises, setExercises] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    getExercises({
      searchTerm: searchValue,
    }).then((exercises) => {
      setExercises(exercises || []);
      setLoading(false);
    });
  }, [searchValue]);

  const handleListItemClick = (value) => {
    setExercise({ ...value, endTime: "00:00" });
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <Badge badgeContent={<CloseBadge setConfig={onClose} />}>
        {" "}
        <DialogTitle
          sx={{
            mr: 5,
          }}
        >
          {" "}
          Select Exercise{" "}
        </DialogTitle>{" "}
      </Badge>{" "}
      <TextField
        autoFocus
        margin="dense"
        id="search"
        label="Search"
        type="search"
        fullWidth
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
      />{" "}
      {loading && <CircularProgress />}{" "}
      <List
        sx={{
          pt: 0,
        }}
      >
        {" "}
        {exercises.map((exercise) => (
          <ListItem disableGutters key={exercise.id}>
            <ListItemButton
              onClick={() => handleListItemClick(exercise)}
              key={exercise.id}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: blue[100],
                    color: blue[600],
                  }}
                >
                  <PersonIcon />
                </Avatar>{" "}
              </ListItemAvatar>{" "}
              <ListItemText primary={exercise.name} />{" "}
            </ListItemButton>{" "}
          </ListItem>
        ))}{" "}
      </List>{" "}
    </Dialog>
  );
}
