import React from "react";
import exit from "../assets/exit.png";
import { useNavigate } from "react-router-dom";

export default function ExitButton({
  style = {},
  redirectTarget,
  demoType,
  callback = () => {},
}) {
  const navigate = useNavigate();

  return (
    <img
      src={exit}
      alt="Exar"
      className="exit-btn"
      style={{
        objectFit: "contain",
        cursor: "pointer",
        float: "right",
        height: "50px",
        // margin: 'auto 2rem auto 0',
        position: "absolute",
        right: "2rem",
        top: "50%",
        transform: "translateY(-50%)",
        ...style,
      }}
      onClick={() => {
        callback();
        if (redirectTarget === "homepage") {
          window.location.href = "https://www.exar.fit";
        } else {
          navigate(redirectTarget, {
            state: {
              demoType,
            },
          });
        }
      }}
    />
  );
}
