import { useEffect, useRef, useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { GAIT_MIN_STEPS } from "../../../../../constants/Demo/gaitAnalysis";

function LinearProgressWithLabel(props) {
  const value = Math.min(props.value, 100);
  const [height, setHeight] = useState(null);
  const parentRef = useRef(null);

  useEffect(() => {
    function updateHeight() {
      if (parentRef.current) {
        const parentHeight = parentRef.current.offsetHeight;
        setHeight(parentHeight);
      }
    }

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <Box
      ref={parentRef}
      sx={{ display: "flex", alignItems: "center", height: "100%" }}
    >
      <Box sx={{ width: "100%", mr: 1, height: "100%" }}>
        {height && (
          <LinearProgress
            variant="determinate"
            value={value}
            style={{ height: `${height}px`, borderRadius: 50 }}
          />
        )}
      </Box>
      <Box sx={{ minWidth: 35, marginRight: 1 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ front }) {
  const [progress, setProgress] = React.useState(0);
  const gaitFront = useSelector(({ gaitFront }) => gaitFront.values);
  const gaitVal = useSelector(({ gait }) => gait.values);
  const gait = !front ? gaitVal : gaitFront;

  useEffect(() => {
    const stepsLeft = Math.min(GAIT_MIN_STEPS / 2, gait.stepsLeft);
    const stepsRight = Math.min(GAIT_MIN_STEPS / 2, gait.stepsRight);
    const progressState = Math.min(
      GAIT_MIN_STEPS,
      (stepsLeft + stepsRight) / GAIT_MIN_STEPS
    );
    setProgress(progressState);
  }, [gait.stepsLeft, gait.stepsRight]);

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: 2,
        pb: 2,
      }}
    >
      <LinearProgressWithLabel value={progress * 100} />
    </Box>
  );
}
