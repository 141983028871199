import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useCountsInTimeCoreFeedback } from "../../../../../hooks/Demo/feedbackTypes";
import { safeIterScore } from "../../../../../hooks/Demo/sharedLogic";
import {
  checkIfRightSide,
  checkIfSide,
} from "../../../../../utilities/Demo/angles";
import { loadStandToSitAudio } from "../../../../../utilities/Demo/audioFeedback";
import {
  findAdjustedAngle,
  findDependentAngle,
} from "../../../../../utilities/Demo/physio";
import { checkArmsUsed } from "../../../../../utilities/Demo/Physio/basics";
import PoseDetection from "../../../PoseDetection";
import {
  backRomMetrics,
  computeMetrics,
  upperLegRomMetrics,
} from "../../../../../utilities/Demo/Physio/BergBalance/metrics";

export default function StandToSit({ onComplete, showWebcam = false }) {
  const dispatch = useDispatch();
  const leanedForward = useRef(false);
  const prePeaked = useRef(false);
  const isLeft = useRef(false);
  const armsUsed = useRef(false);
  const timeThresh = 11;
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadStandToSitAudio,
    maxScore: 1,
  });
  const metrics = useRef(upperLegRomMetrics().concat(backRomMetrics()));
  cptScore.current = () => {
    if (correctCount.current === 0) return 0;
    if (correctCount.current >= 1 && armsUsed.current) return 2 / 3;
    if (correctCount.current >= 1 && !armsUsed.current) return 1;
  };

  function observation(backAngle, kneeAngle, results) {
    if (checkArmsUsed(results, isLeft.current)) {
      updateFeedback("Do not use your arms!");
      armsUsed.current = true;
    }
    if (kneeAngle > 160) {
      setStartingTime(seconds);
      prePeaked.current = false;
      leanedForward.current = false;
      armsUsed.current = false;
    }
    // sitting down
    if (kneeAngle > 120 && !prePeaked.current) {
      prePeaked.current = true;
    }
    if (backAngle > 100 && prePeaked.current) {
      leanedForward.current = true;
    }
    if (
      kneeAngle < 100 &&
      backAngle < 100 &&
      leanedForward.current &&
      prePeaked.current
    ) {
      correctCount.current += 1;
      updateCounts(correctCount.current);
      const score = cptScore.current();
      playAudio(audioFeedback.current?.achievement, true);
      safeIterScore(dispatch, score);
      onComplete();
      seconds.current = timeThresh;
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback("Turn to the side!");
      setSide(false);
      return;
    } else {
      updateFeedback("");
      setSide(true);
    }
    isLeft.current = checkIfRightSide(results, "left");
    let kneeAngle;
    let backAngle;
    let hipKneeAngle;
    let displayConfigs;

    if (isLeft.current) {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      backAngle = findAdjustedAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      hipKneeAngle =
        360 -
        findAdjustedAngle(results.poseLandmarks[23], results.poseLandmarks[25]);
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[23],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[11],
          left: false,
        },
      };
    } else {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (kneeAngle > 180) {
        kneeAngle = 360 - kneeAngle;
      }
      backAngle =
        360 -
        findAdjustedAngle(
          results.poseLandmarks[12],
          results.poseLandmarks[24],
          0
        );
      hipKneeAngle = findAdjustedAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26]
      );
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[24],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[12],
          left: false,
        },
      };
    }
    computeMetrics({
      metrics: metrics.current,
      angles: {
        current: {
          backAngle,
          hipKneeAngle,
        },
      },
      dispatch,
      displayConfigs,
      canvasCtx,
    });
    observation(backAngle, kneeAngle, results);
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
