import { baseUrl } from "../../constants";
import axios from "axios";

export const getRewards = async (dispatch, setLoad) => {
  setLoad(true);
  let token = await localStorage.getItem("token");
  let response = await axios.get(`${baseUrl}/get-all-rewards`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("Rewards are ::" + JSON.stringify(response));
  dispatch({
    type: "REWARDS",
    payload: response,
  });
  setLoad(false);
};

export const saveRewards = async (
  user_id,
  product_id,
  price,
  price_type,
  setLoad,
  setMsg,
  setType
) => {
  axios
    .post(`${baseUrl}/save-user-rewards`, {
      user_id: user_id,
      rewards: [product_id],
      price: price,
      price_type: price_type,
    })
    .then(async (response) => {
      console.log("Rewards Save ::" + JSON.stringify(response));
      setMsg(response.data.message);
      switch (await response.data.status) {
        case 200:
          setType("success");
          break;
        case 406:
          setType("alert");
          break;
        case 208:
          setType("claimedalert");
          break;
        case 202:
          setType("error");
          break;
        default:
          setType("noresponse");
      }

      setLoad(false);
    })
    .catch((res2) => {
      console.log("eror::" + JSON.stringify(res2));
      setMsg(res2);
      setLoad(false);
      setType("error");
    });
};
