import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ListItemButton from "@mui/material/ListItemButton";
import { baseUrl } from "../../constants";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import success from "../../assets/sucess.png";
import wrong from "../../assets/wrong.png";

const ReferAFriend = () => {
  let { name, email } = JSON.parse(localStorage.getItem("user_data"));
  const { t } = useTranslation();
  const [refemail, setRefEmail] = useState("");
  const [show, setShow] = useState(false);
  const [result, setResult] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const style = {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    margin: "auto",
    height: "70vh",
    width: "70vw",
    bgcolor: "white",
    borderRadius: "2.5em",
    p: 0,
  };
  const closeModal = () => {
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 2000);
    setShow(false);
  };
  const handleEmailChange = (event) => {
    setRefEmail(event.target.value);
  };

  const generateReferralLink = async (data) => {
    console.log("inside submit");
    // Update the state with the referral link and code
    const url = `${baseUrl}/refer-a-friend`;
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200 && response.data.status === 200) {
        console.log("pass usecase");
        setShow(true);
        setResult("success");
        setMsg("Referred the user successfully");
      } else {
        console.log("fail usecase");
        setResult("error");
        setMsg(response ? response.data.message : "Something went wrong!");
      }
    } catch (error) {
      console.log("error happened", error);
      setShow(true);
      setResult("error");
      setMsg(error ? error.message : error.response.data.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const referralCode = await generateReferralCode();
    let refferalObj = {
      refferal_email: refemail,
      refferal_id: referralCode,
      reffered_user: name,
      reffered_user_email: email,
    };
    await generateReferralLink(refferalObj);
    // Clear the form fields
    setRefEmail("");
  };

  return (
    <div className="goals-profile-content">
      <div className="profile-settings-title">
        <div className="left">
          <div className="profile-page-orange-rect"></div>
          <h2>{t("ProfileComponent.refer")}</h2>
        </div>
      </div>
      <div className="goals-profile-content-inner">
        <div className="profile-settings-content">
          <h2>{t("referralPage.refer")}</h2>
          <br />
          <div className="form-group">
            <form onSubmit={handleSubmit} className="form-group">
              <label>
                {t("referralPage.yname")}:
                <input type="text" defaultValue={name} disabled />
              </label>
              <label>
                {t("referralPage.rname")}
                <input
                  type="email"
                  value={refemail}
                  onChange={handleEmailChange}
                />
              </label>
              <br />
              <br />
              <ListItemButton
                className="profile-settings-btn"
                type="submit"
                onClick={handleSubmit}
              >
                <div>{t("referralPage.send")}</div>
              </ListItemButton>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={show}
        onClose={() => closeModal()}
        height={"100vh"}
        sx={{ justify: "center", margin: "auto", pt: "10rem", border: "none" }}
        bordered={false}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style} border={"none"}>
          {result != "error" ? (
            <>
              <img className="success-img" src={success}></img>
              <h2 id="parent-modal-title">{t("referralPage.refer")}</h2>
              <div
                id="parent-modal-description"
                style={{ display: "flex", width: "40vw" }}
              >
                <div
                  className="mod_data"
                  style={{
                    display: "flex",
                    padding: "15% 1%",
                    width: "100%",
                    fontSize: "1rem",
                    fontWeight: "inherit",
                  }}
                >
                  <span>{t("referralPage.successmsg")}</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <img className="success-img" src={wrong}></img>
              <h2 id="parent-modal-title">{t("signUpComponent.failed")}</h2>

              <div className="mod_data">
                <center> {msg}</center>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

async function generateReferralCode() {
  // Generate a random 6-digit code
  const code = Math.floor(Math.random() * 900000) + 100000;
  return code.toString();
}

export default ReferAFriend;
