export const setWorkoutData = async (
  dispatch,
  sweatpoints,
  duration,
  setLoader
) => {
  dispatch({
    type: "POINTS",
    payload: {
      sweatpoints,
      duration,
    },
  });
  setLoader(false);
};
