import AddIcon from "@mui/icons-material/Add";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { Card, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import OptionsBadge from "../OptionsBadge";
import SubMetricBox from "./SubMetricBox";

export default function MetricConfig({
  metric,
  setMetric,
  refOptions,
  copyConfig,
  useAngleConfigs,
  stateOptions,
}) {
  const name = React.useRef(metric.name);
  const subMetrics = React.useRef(metric.subMetrics || []);

  return (
    <Badge
      badgeContent={
        <OptionsBadge setConfig={setMetric} copyConfig={copyConfig} />
      }
      sx={{ m: 0, width: "100%" }}
    >
      <Card
        sx={{
          overflow: "auto",
          height: "100%",
          width: "100%",
          padding: 4,
          backgroundColor: "rgba(241,166,12,0.26)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name={metric.name}
              type="text"
              variant="outlined"
              // className="inputRounded"
              InputLabelProps={{ disableAnimation: true }}
              sx={{ width: "70%", marginBottom: 1 }}
              shrink={"true"}
              fullWidth
              label="Name"
              value={metric.name}
              onChange={(e) => {
                name.current = e.target.value;
                setMetric({ ...metric, name: e.target.value });
              }}
            />
          </Grid>
          {subMetrics.current.map((subMetric, index) => (
            <Grid item xs={6} key={index}>
              <SubMetricBox
                subMetric={subMetric}
                stateOptions={stateOptions}
                useAngleConfigs={useAngleConfigs}
                setSubMetric={(value) => {
                  subMetrics.current[index] = value;
                  setMetric({ ...metric, subMetrics: subMetrics.current });
                }}
                copyConfig={() => {
                  subMetrics.current.push(subMetric);
                  setMetric({ ...metric, subMetrics: subMetrics.current });
                }}
                refOptions={refOptions}
              />
            </Grid>
          ))}
          <Grid item xs={6}>
            <IconButton
              aria-label="add"
              onClick={() => {
                subMetrics.current.push({
                  name: "",
                  ref: "",
                  computation: "",
                });
                setMetric({ ...metric, subMetrics: subMetrics.current });
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Badge>
  );
}
