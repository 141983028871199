import FootMap from "../../../SensorResults/FootMap";
import React from "react";
import Grid from "@mui/material/Grid";

export default function PressureMap({ setLoading, sensorData, gaitResults }) {
  if (!sensorData) {
    return null;
  } else {
    setLoading(false);
  }
  return (
    <Grid
      style={{
        marginLeft: 50,
        marginTop: 20,
        overflow: "hidden",
      }}
    >
      <div style={{ position: "relative", marginLeft: "20vw" }}>
        <FootMap sensorData={sensorData} generalResults={gaitResults} />
      </div>
    </Grid>
  );
}
