export function drawSegmentationMask(results) {
    // const context = canvas.getContext('2d');
    const context = new OffscreenCanvas(
        results.canvasWidth,
        results.canvasHeight
    ).getContext('2d');

    // Draw the image on the canvas
    context.drawImage(
        results.segmentationMask,
        0,
        0,
        results.canvasWidth,
        results.canvasHeight
    );
    return context;
}

export function getImageBitmapData(imageBitmap) {
    const canvas = new OffscreenCanvas(imageBitmap.width, imageBitmap.height);
    const context = canvas.getContext('2d', {
        willReadFrequently: true
    });
    context.drawImage(imageBitmap, 0, 0);

    // Get the pixel data from the canvas
    const imageData = context.getImageData(
        0,
        0,
        imageBitmap.width,
        imageBitmap.height
    );
    return imageData.data;
}

export function getHighestAndLowestPixels(imageBitmap) {
    const canvas = new OffscreenCanvas(imageBitmap.width, imageBitmap.height);
    const context = canvas.getContext('2d');
    context.drawImage(imageBitmap, 0, 0);

    const imageData = context.getImageData(
        0,
        0,
        imageBitmap.width,
        imageBitmap.height
    ).data;
    let highestPixel = null;
    let lowestPixel = null;

    const threshold = 0.2 * 255; // adjust this value according to your needs

    // Iterate over the pixel data
    for (let i = 0; i < imageData.length; i += 4) {
        // Calculate the grayscale value of the pixel
        const grayscaleValue =
            (imageData[i] + imageData[i + 1] + imageData[i + 2]) / 3;

        if (grayscaleValue > threshold) {
            // Calculate the x and y coordinates of the pixel
            const pixelIndex = i / 4;
            const x = pixelIndex % imageBitmap.width;
            const y = Math.floor(pixelIndex / imageBitmap.width);

            if (highestPixel == null || y < highestPixel.y) {
                highestPixel = {
                    x: x,
                    y: y
                };
            }
            if (lowestPixel == null || y > lowestPixel.y) {
                lowestPixel = {
                    x: x,
                    y: y
                };
            }
        }
    }

    return {
        highestPixel,
        lowestPixel
    };
}