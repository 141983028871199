import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import CandlestickChartOutlinedIcon from "@mui/icons-material/CandlestickChartOutlined";
import ScoreboardRoundedIcon from "@mui/icons-material/ScoreboardRounded";
import StarRounded from "@mui/icons-material/StarRounded";
import * as React from "react";

export default function TestResult({ name, value }) {
  let icon;
  switch (name) {
    case "Date":
      icon = <EventNoteRoundedIcon />;
      break;
    case "Overall Score":
      icon = <StarRounded />;
      break;
    case "Strength":
      icon = <ScoreboardRoundedIcon />;
      break;
    case "Endurance":
      icon = <CandlestickChartOutlinedIcon />;
      break;
    case "Cardio":
      icon = <CandlestickChartOutlinedIcon />;
      break;
    case "Flexibility":
      icon = <CandlestickChartOutlinedIcon />;
      break;
    case "PoseHeld":
      icon = <CandlestickChartOutlinedIcon />;
      break;
    case "Squats":
      icon = <CandlestickChartOutlinedIcon />;
      break;
    case "Ninja":
      icon = <CandlestickChartOutlinedIcon />;
      break;
  }

  return (
    <>
      <div className="schedule-left left">
        {icon}
        <span>{name}</span>
      </div>
      <div className="right">
        <span>{value}</span>
      </div>
    </>
  );
}
