import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";
import { turnToSide } from "../../utilities/Demo/feedbackLang";

export const Feedback = ({ FeedbackComponent, translation = 0 }) => {
  const correctSide = useSelector(({ side }) => side.values.correct);
  const language = useSelector(({ iter }) => iter.values.language);

  if (correctSide) return <FeedbackComponent translation={translation} />;
  return (
    <div
      className="sideFeedback"
      style={{ transform: `translateX(${translation}%)` }}
    >
      {turnToSide(language)}
    </div>
  );
};

export const Feedbacks = () => {
  const counts = useSelector(({ counts }) => counts.values);
  return (
    <div style={{ margin: 10 }}>
      <div className="counts_container">
        <div className="counts">
          INCORRECT COUNT : <span id="inc">{counts.incorrect}</span>
        </div>
        <div className="counts" style={{ backgroundColor: "#41B857" }}>
          CORRECT COUNT : {counts.correct}
        </div>
      </div>
      {counts.feedback && <div className="feedback">{counts.feedback}</div>}
    </div>
  );
};

export const CountsInTimeFeedbacks = ({ translation = 0 }) => {
  const counts = useSelector(({ countsInTime }) => countsInTime.values);
  return (
    <div style={{ margin: 10 }}>
      <div
        className="transformableCountsContainer"
        style={{ transform: `translateX(${-translation * 3}%)` }}
      >
        <div className="counts">
          TIME : <span id="inc">{counts.time}</span>
        </div>
        <div className="counts" style={{ backgroundColor: "#41B857" }}>
          CORRECT COUNT : {counts.correct}
        </div>
      </div>
      {counts.feedback && (
        <div
          className="feedback"
          style={{ transform: `translateX(${translation * 2}%)` }}
        >
          {counts.feedback}
        </div>
      )}
    </div>
  );
};

export const CountsInTimeSurvivalFeedbacks = ({ translation = 0 }) => {
  const counts = useSelector(({ countsInTime }) => countsInTime.values);
  const lives = useSelector(({ lives }) => lives.values.lives);

  return (
    <div style={{ margin: 10 }}>
      <div
        className="transformableCountsContainer"
        style={{ transform: `translateX(${-translation * 3}%)` }}
      >
        <div className="counts">
          TIME : <span id="inc">{counts.time}</span>
        </div>
        <div className="counts" style={{ backgroundColor: "#41B857" }}>
          CORRECT COUNT : {counts.correct}
        </div>
        <div className="counts" style={{ backgroundColor: "#37a4fb" }}>
          LIVES : {lives}
        </div>
      </div>
      {counts.feedback && (
        <div
          className="feedback"
          style={{ transform: `translateX(${translation * 2}%)` }}
        >
          {counts.feedback}
        </div>
      )}
    </div>
  );
};

export const BalanceFeedbacks = () => {
  const balance = useSelector(({ balance }) => balance.values);
  return (
    <div style={{ margin: 10 }}>
      <div className="counts_container">
        <div className="counts">
          INTERRUPTIONS : <span id="inc">{balance.interruptions}</span>
        </div>
        <div className="counts" style={{ backgroundColor: "green" }}>
          POSE HELD : {balance.secondsPoseHeld}
        </div>
      </div>
      {balance.feedback && <div className="feedback">{balance.feedback}</div>}
    </div>
  );
};

export const StretchFeedbacks = () => {
  const stretch = useSelector(({ stretch }) => stretch.values);
  return (
    <div style={{ margin: 10 }}>
      <div className="counts_container">
        <div className="counts" style={{ backgroundColor: "green" }}>
          BEST : {stretch.bestValue}
        </div>
      </div>
      {stretch.feedback && <div className="feedback">{stretch.feedback}</div>}
    </div>
  );
};

export const StretchCountFeedbacks = () => {
  const stretch = useSelector(({ stretchCount }) => stretchCount.values);
  const iterResults = useSelector(({ iter }) => iter.values.results);
  const iteration = useSelector(({ iter }) => iter.values.iteration);
  const best = stretch.bestValue[stretch.bestValue.length - 1];
  let executionTime;
  if (iterResults) {
    executionTime = iterResults[iteration]?.stretchTimes?.slice(-1);
    if (executionTime) executionTime = executionTime[0];
  }
  return (
    <div style={{ margin: 10 }}>
      <div className="counts_container">
        <div className="counts" style={{ backgroundColor: "red" }}>
          Time : {stretch.time}
        </div>
        <div className="counts" style={{ backgroundColor: "green" }}>
          Count : {stretch.correct}
        </div>
        <div className="counts" style={{ backgroundColor: "green" }}>
          Units : {Number(best)?.toFixed(0)}
        </div>
        <div className="counts" style={{ backgroundColor: "green" }}>
          Execution Time : {executionTime?.toFixed(1)}
        </div>
      </div>
      {stretch.feedback && <div className="feedback">{stretch.feedback}</div>}
    </div>
  );
};

export const GaitFeedbacks = ({ front = false }) => {
  const gaitFront = useSelector(({ gaitFront }) => gaitFront.values);
  const gaitVal = useSelector(({ gait }) => gait.values);
  const gait = front ? gaitFront : gaitVal;
  return (
    <div style={{ margin: 10 }}>
      <div className="counts_container">
        <div className="counts">
          STEPS RIGHT : <span id="inc">{gait.stepsRight}</span>
        </div>
        <div className="counts">
          STEPS LEFT : <span id="inc">{gait.stepsLeft}</span>
        </div>
        <div
          className="counts"
          style={{
            backgroundColor: gait.stepValidityLeft[
              gait.stepValidityLeft.length - 1
            ]
              ? "green"
              : "orange",
          }}
        >
          strideTime LEFT :{" "}
          {gait.strideTimeLeft[gait.strideTimeLeft.length - 1]}
        </div>
        <div
          className="counts"
          style={{
            backgroundColor: gait.stepValidityRight[
              gait.stepValidityRight.length - 1
            ]
              ? "green"
              : "orange",
          }}
        >
          strideTime RIGHT :{" "}
          {gait.strideTimeRight[gait.strideTimeRight.length - 1]}
        </div>
        <div
          className="counts"
          style={{
            backgroundColor: gait.stepValidityLeft[
              gait.stepValidityLeft.length - 1
            ]
              ? "green"
              : "orange",
          }}
        >
          stanceTime Right :{" "}
          {gait.stanceTimeRight[gait.strideTimeLeft.length - 1]}
        </div>
        <div
          className="counts"
          style={{
            backgroundColor: gait.stepValidityRight[
              gait.stepValidityRight.length - 1
            ]
              ? "green"
              : "orange",
          }}
        >
          stanceTime Left :{" "}
          {gait.stanceTimeLeft[gait.strideTimeRight.length - 1]}
        </div>
        {!front && (
          <div className="counts" style={{ backgroundColor: "black" }}>
            step length LEFT :{" "}
            {gait.stepLengthLeft[gait.stepLengthLeft.length - 1] &&
              gait.stepLengthLeft[gait.stepLengthLeft.length - 1].value}
          </div>
        )}
        {!front && (
          <div className="counts" style={{ backgroundColor: "black" }}>
            step length RIGHT :{" "}
            {gait.stepLengthRight[gait.stepLengthRight.length - 1] &&
              gait.stepLengthRight[gait.stepLengthRight.length - 1].value}
          </div>
        )}
      </div>
      {gait.feedback && (
        <div className="feedback" style={{ fontSize: 38 }}>
          {gait.feedback}
        </div>
      )}
    </div>
  );
};
