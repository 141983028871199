import {
    getDownloadURL,
    ref
} from 'firebase/storage';
import {
    storage
} from '../../../firebase';

export async function createVideoStreamFromGS(filePath) {
    try {
        const fileRef = ref(storage, filePath);
        // Get the URL of the file
        return await getDownloadURL(fileRef);
    } catch (error) {
        console.log(error);
    }
}