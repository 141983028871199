import React from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useStretchCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import { findAngle, findDependentAngle } from "../../../utilities/Demo/physio";
import PoseDetection from "../PoseDetection";
import {
  checkIfSide,
  findSideFromSittingPose,
} from "../../../utilities/Demo/angles";
import { leftBackArc, rightBackArc } from "../../../utilities/Demo/arcs";
import { loadVStretchAudio } from "../../../utilities/Demo/audioFeedback";

function calculatePoints(angle) {
  if (angle < 20) {
    return 5;
  } else {
    const diff = angle - 20;
    const pointsLost = diff / 7;
    return (10 - pointsLost) / (10 * 2);
  }
}

function VStretchDemo({ onComplete }) {
  const timeThresh = 60;
  const {
    updateFeedback,
    candidates,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    finishedIntro,
  } = useStretchCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadVStretchAudio,
    calculatePoints,
  });

  function giveFeedback(leftKneeAngle, rightKneeAngle) {
    if (leftKneeAngle <= 175 || rightKneeAngle <= 175) {
      updateFeedback("Straighten your legs!");
      playAudio(audioFeedback.current?.keepLegsStraight);
    }
  }

  function observeBalance(
    leftKneeAngle,
    rightKneeAngle,
    leftLegAngle,
    rightLegAngle,
    backAngle
  ) {
    if (
      leftKneeAngle > 150 &&
      rightKneeAngle > 150 &&
      leftLegAngle > 70 &&
      rightLegAngle > 70
    ) {
      candidates.current.push(backAngle);
      setStartingTime(seconds);
      updateFeedback("");
    } else {
      giveFeedback(leftKneeAngle, rightKneeAngle);
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    let rightKneeAngle = findDependentAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      results.poseLandmarks[28]
    );
    const leftKneeAngle = findDependentAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      results.poseLandmarks[27]
    );
    const side = findSideFromSittingPose(results);
    let backAngle;
    if (side === "right") {
      backAngle = rightBackArc(canvasCtx, results, 40, 180);
    } else {
      backAngle = leftBackArc(canvasCtx, results, 40, 0);
    }
    const rightLegAngle = findAngle(
      [results.poseLandmarks[24].x, results.poseLandmarks[24].y],
      [results.poseLandmarks[28].x, results.poseLandmarks[28].y],
      90
    );
    const leftLegAngle = findAngle(
      [results.poseLandmarks[23].x, results.poseLandmarks[23].y],
      [results.poseLandmarks[27].x, results.poseLandmarks[27].y],
      90
    );
    let isSide = checkIfSide(results);

    if (isSide) {
      setSide(true);
      updateFeedback("");
      observeBalance(
        leftKneeAngle,
        rightKneeAngle,
        leftLegAngle,
        rightLegAngle,
        backAngle,
        canvasCtx
      );
    } else {
      setSide(false);
      updateFeedback("Turn to the side!");
      playAudio(audioFeedback.current?.turnToSide);
    }
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(VStretchDemo);
