import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { alpha, styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../utilities/Demo/auth";
import MenuIcon from "@mui/icons-material/Menu";
import BasicSelect from "./ExerciseManagement/Configurator/BasicSelect";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function OverviewAppBar({
  setSearchValue,
  setInfoDialogOpen,
  DrawerContent = null,
  drawerOpen,
  setDrawerOpen = () => {},
  setOrderByField = () => {},
  setSearchTermField = () => {},
  setOrderByDirection = () => {},
  setSearchTag = (value) => {
    console.log(value);
  },
  searchTagOptions = [],
  setSearchType = () => {},
  searchTypeOptions = [],
}) {
  const drawerWidth = 240;
  const theme = useTheme();
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen((isOpen) => !isOpen)}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => {
              setInfoDialogOpen(true);
            }}
          >
            <AddIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          ></Typography>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            sx={{ padding: 1 }}
          >
            {searchTagOptions.length !== 0 && (
              <Grid item xs={2}>
                <BasicSelect
                  setValue={(value) => {
                    setSearchTag(
                      searchTagOptions.find((tag) => tag.name === value).id
                    );
                  }}
                  options={searchTagOptions.map((tag) => tag.name)}
                  label={"Tag"}
                />
              </Grid>
            )}
            {searchTypeOptions.length !== 0 && (
              <Grid item xs={2}>
                <BasicSelect
                  setValue={(value) => {
                    setSearchType(value);
                  }}
                  options={searchTypeOptions}
                  label={"Type"}
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <BasicSelect
                setValue={setOrderByField}
                options={["createdAt", "name"]}
                label={"orderBy"}
              />
            </Grid>
            <Grid item xs={2}>
              <BasicSelect
                setValue={setOrderByDirection}
                options={["asc", "desc"]}
                label={"direction"}
              />
            </Grid>
            <Grid item xs={2}>
              <BasicSelect
                setValue={setSearchTermField}
                options={["createdAt", "name"]}
                label={"searchTermField"}
              />
            </Grid>
          </Grid>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearchChange}
            />
          </Search>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 0, ml: 4 }}
            onClick={() => {
              logout();
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
      >
        <DrawerHeader>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {DrawerContent}
      </Drawer>
    </Box>
  );
}
