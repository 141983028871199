import React from "react";
import "./ranking.css";
import av1 from "../../assets/av1.png";
import { colorBelts } from "../../utilities/colorbelts";
import { useNavigate } from "react-router";
import { getDashboardData } from "../../redux/actions/getDashboard";
import { useDispatch } from "react-redux";

const StudentRow = ({ details }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      className="studet-row"
      style={{
        cursor: "pointer",
      }}
      onClick={async () => {
        await getDashboardData(dispatch, details.email);
        navigate("/progress");
      }}
    >
      <div className="student-row-item">
        <div className="student-row-item-inner">
          <img
            src={details.profile_pic ? details.profile_pic : av1}
            alt="Avatar"
          />
          <h3> {details.name} </h3>{" "}
        </div>{" "}
      </div>{" "}
      <div className="val-item">
        <h3
          style={{
            background: details && colorBelts[details.bmi.split(" ")[0]].bg,
            color: details && colorBelts[details.bmi.split(" ")[0]].color,
          }}
        >
          {details.bmi}{" "}
        </h3>{" "}
      </div>{" "}
      <div className="val-item">
        <h3> {details.sweat_points} </h3>{" "}
      </div>{" "}
      <div className="val-item">
        <h3> {details.rank} </h3>{" "}
      </div>{" "}
      <div className="val-item">
        <h3> {details.workouts ? details.workouts : 0} </h3>{" "}
      </div>{" "}
      <div className="val-item">
        <h3> {details.coins ? details.coins : 0} </h3>{" "}
      </div>{" "}
    </div>
    // <div className='cont3'>
    //   <Grid container className="gridder">
    //     <Grid item xs={3}>
    //       <div
    //         style={{
    //           display: 'flex',
    //           paddingLeft: '10%',
    //         }}
    //       >
    //         <img src={av1} className='avtar' />
    //         <div className='studname'>{details.name}</div>
    //       </div>
    //     </Grid>
    //     <Grid item xs={3} className='horicenter'>
    //       <div
    //         className='bmi'
    //         style={{
    //           background: details && colorBelts[details.bmi.split(' ')[0]].bg,
    //           color: details && colorBelts[details.bmi.split(' ')[0]].color,
    //         }}
    //       >
    //         {details.bmi}
    //       </div>
    //     </Grid>
    //     <Grid item xs={3} className='horicenter'>
    //       <div className='bgpoints'>{details.sweat_points}</div>
    //     </Grid>
    //     <Grid item xs={3} className='horicenter'>
    //       <div className='bgpoints'>{details.rank}</div>
    //     </Grid>
    //   </Grid>
    // </div>
  );
};

export default StudentRow;
