import * as React from "react";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import CandlestickChartOutlinedIcon from "@mui/icons-material/CandlestickChartOutlined";
import ScoreboardRoundedIcon from "@mui/icons-material/ScoreboardRounded";
import moment from "moment";
import "./schedule.css";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const TomSessionComponent = ({ schedule, loader }) => {
  const { t } = useTranslation();
  console.log("SCG::", schedule);
  return (
    <div className="tomorrow-session-container">
      {" "}
      {loader ? (
        <CircularProgress size={45} color="success" />
      ) : (
        <>
          <div className="box-container">
            <div className="today-session-box">
              {" "}
              {t("TomSessionComponent.title")}{" "}
            </div>{" "}
          </div>
          <div className="list list-container">
            <div className="left">
              <EventNoteRoundedIcon />
              <span> {t("SessionComponent.class")} </span>{" "}
            </div>{" "}
            <div className="right">
              <span>
                {" "}
                {schedule && schedule.class_no ? schedule.class_no : "NA"}{" "}
              </span>{" "}
            </div>{" "}
            <div className="left">
              <AccessTimeRoundedIcon />
              <span> {t("SessionComponent.Duration")} </span>{" "}
            </div>{" "}
            <div className="right">
              <span>
                {" "}
                {schedule && schedule.duration ? schedule.duration : "NA"}{" "}
                minutes{" "}
              </span>{" "}
            </div>{" "}
            <div className="left">
              <CandlestickChartOutlinedIcon />
              <span> {t("SessionComponent.Start")} </span>{" "}
            </div>{" "}
            <div className="right">
              <span>
                {" "}
                {schedule && Object.keys(schedule).length > 0
                  ? moment
                      .tz(schedule.start.dateTime, "Europe/Berlin")
                      .local()
                      .format("HH:mm")
                  : "NA"}{" "}
              </span>{" "}
            </div>{" "}
            <div className="left">
              <ScoreboardRoundedIcon />
              <span> {t("SessionComponent.Difficulty")} </span>{" "}
            </div>{" "}
            <div className="right">
              <span>
                {" "}
                {schedule && Object.keys(schedule).length > 0
                  ? t("SessionComponent.Beginner")
                  : "NA"}{" "}
              </span>{" "}
            </div>{" "}
          </div>
          <div className="schedule-divider"> </div>
          <p> {schedule ? schedule.session_desc : "NA"} </p>{" "}
        </>
      )}{" "}
    </div>
  );
};

export default TomSessionComponent;
