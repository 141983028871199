import * as React from "react";
import { TextField } from "@mui/material";
import "./loginbox/loginbox.css";

const InputBarComponent = ({ label, type, value, name, onChange }) => {
  return (
    <TextField
      name={name}
      label={label}
      type={type}
      variant="outlined"
      className="inputRounded"
      InputLabelProps={{
        disableAnimation: true,
      }}
      shrink={"true"}
      sx={{
        width: "100%",
        height: "50%",
        marginBottom: 1,
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default InputBarComponent;
