import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import * as React from "react";
import { Paper } from "@mui/material";
import { useStore } from "react-redux";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import { useGaitResultsActions } from "../../../../../hooks/GaitAnalysis/actions";
import { useRelativeLandmarks } from "../../../../../hooks/GaitAnalysis/results";
import ControlledDateRangePicker from "../../ControlledDateRangePicker";
import { HistoricalResultsBox } from "../../HistoricalResultsBox";
import Replay from "./Replay";

HistoricalResultsBox.propTypes = {
  dataKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
};
export default function HistoricalResults() {
  const { fetchLastResults } = useGaitResultsActions();
  const [replayDocIndex, setReplayDocIndex] = useState(0);
  const relativeLandmarks = useRelativeLandmarks(replayDocIndex);
  const store = useStore();
  const { user } = useContext(FirebaseAuthContext);
  const [data, setData] = React.useState([]);
  const [dateRange, setDateRange] = React.useState([
    dayjs().subtract(1, "month"),
    dayjs(),
  ]);

  React.useEffect(() => {
    if (!user) return;
    const lastGaitResults = store.getState().lastGaitResults;
    if (lastGaitResults.docs.length === 0) {
      fetchLastResults({
        startDate: dateRange[0],
        endDate: dateRange[1],
        n: 2,
      }).then((fetchedData) => fetchedData && setData(fetchedData.docs));
    } else {
      setData(lastGaitResults.docs);
    }
  }, [user]);

  React.useEffect(() => {
    if (!user) return;
    fetchLastResults({
      startDate: dateRange[0],
      endDate: dateRange[1],
      n: 30,
    }).then((fetchedData) => {
      setData(fetchedData.docs);
    });
  }, [dateRange, user]);

  return (
    <div>
      <Paper sx={{ paddingBottom: 2, marginBottom: -2 }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "50%",
              border: 0.5,
              borderRadius: 5,
              padding: 3,
              boxShadow: 3,
              mt: 1,
            }}
          >
            <ControlledDateRangePicker
              value={dateRange}
              setValue={setDateRange}
            />
          </Box>
        </div>
        <Box sx={{ height: "75vh" }}>
          <Grid container spacing={2} sx={{ padding: 2, height: "50%" }}>
            <Grid item xs={6}>
              <HistoricalResultsBox
                data={data}
                name={"Cadence"}
                dataKey={"cadence"}
              />
            </Grid>
            <Grid item xs={6}>
              <HistoricalResultsBox
                data={data}
                name={"Velocity"}
                dataKey={"walkingSpeed"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ padding: 2, height: "50%" }}>
            <Grid item xs={6}>
              <HistoricalResultsBox
                data={data}
                name={"Cycle Variability %"}
                dataKey={"gaitCycleVariability"}
                mirror={true}
                worsenedText={"increased"}
              />
            </Grid>
            <Grid item xs={6}>
              <HistoricalResultsBox
                data={data}
                name={"Cycle Optimum Deviation %"}
                dataKey={"gaitCycleMeanOptimumDeviation"}
                mirror={true}
                worsenedText={"increased"}
              />
            </Grid>
            <Grid item xs={3} sx={{ mt: 5 }}>
              {data?.slice(0, 5).map((result, index) => (
                <div key={result.id}>
                  <Button
                    variant={replayDocIndex === index ? "outlined" : "inline"}
                    onClick={() => {
                      setReplayDocIndex(index);
                    }}
                  >
                    {dayjs(result.timeStamp).format("MM/DD/YYYY")}
                  </Button>
                </div>
              ))}
            </Grid>
            <Grid item xs={5} sx={{ mt: 5 }}>
              <Replay
                relativeLandmarks={relativeLandmarks}
                key={"replay-" + replayDocIndex}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
