import React, { useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { updateStudentDp } from "../../redux/actions/updateAvtar";
import { useDispatch } from "react-redux";

const ReadyPlayerMe = ({ show, setShow, setDpurl, setLoad, setParse }) => {
  const refs = useRef();
  const dispatch = useDispatch();
  const style = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    margin: "auto",
    height: "70vh",
    width: "70vw",
    bgcolor: "#e5e5e5",
    p: 2,
  };
  function subscribe(event) {
    const json = parse(event);

    if (json && json.source !== "readyplayerme") {
      return;
    }

    // Susbribe to all events sent from Ready Player Me once frame is ready
    if (refs.current && json && json.eventName === "v1.frame.ready") {
      console.log("REf is::", refs);
      refs.current.contentWindow.postMessage(
        JSON.stringify({
          target: "readyplayerme",
          type: "subscribe",
          eventName: "v1.**",
        }),
        "*"
      );
    }

    // Get avatar GLB URL
    if (json && json.eventName === "v1.avatar.exported") {
      console.log("Avatar URL: ", json.data.url);
      //call api to update user profile and dispatch
      updateStudentDp(json.data.url, setDpurl, setLoad, setParse, dispatch);
    }

    // Get user id
    if (json && json.eventName === "v1.user.set") {
      console.log(
        "User with id " + json.data.id + " set: " + JSON.stringify(json)
      );
    }
  }

  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }
  useEffect(() => {
    window.addEventListener("message", subscribe);
    document.addEventListener("message", subscribe);
  }, [refs]);
  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      height={"100vh"}
      sx={{ justify: "center", margin: "auto", pt: "10rem" }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={style}>
        <iframe
          src="https://demo.readyplayer.me/avatar?frameApi"
          height="100%"
          width="100%"
          allowFullScreen
          allow="camera https://demo.readyplayer.me; microphone https://demo.readyplayer.me"
          ref={refs}
        ></iframe>
      </Box>
    </Modal>
  );
};

export default ReadyPlayerMe;
