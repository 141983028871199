export function generateRandomArray(length, min, max) {
    let arr = [];
    for (let i = 0; i < length; i++) {
        let rand = Math.floor(Math.random() * (max - min + 1)) + min;
        arr.push(rand);
    }
    return arr;
}

export const checkIfNumber = (value) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(value);
};

export function parseTimeToSeconds(timeStr) {
    if (!timeStr) return;
    if (Number(timeStr)) return Number(timeStr);
    const [minutes, seconds] = timeStr.split(':').map(Number);
    return minutes * 60 + seconds;
}

export function withTimeout(promise, timeout) {
    return new Promise((resolve, reject) => {
        // Start the timeout, reject the promise when it's reached
        const timer = setTimeout(
            () => reject(new Error('Request timed out')),
            timeout
        );

        promise
            .then((value) => {
                clearTimeout(timer);
                resolve(value);
            })
            .catch((error) => {
                clearTimeout(timer);
                reject(error);
            });
    });
}