import { useNavigate } from "react-router-dom";
import { loginTargetMap } from "../../../constants/Demo/auth";

export const useRedirectAfterSignIn = ({ prefix = "/login" }) => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  //   const queryParams = new URLSearchParams(location.search);

  return (user) => {
    if (
      user.claims.patientId &&
      window.location.pathname.includes(prefix + "/physio")
    ) {
      navigate(loginTargetMap["patient"]);
    }
    if (window.location.pathname.includes(prefix + "/physio")) {
      navigate(loginTargetMap["physio"]);
    }
    if (window.location.pathname.includes(prefix + "/schedule_results")) {
      navigate(
        loginTargetMap["schedule_results"] + "/" + queryParams.get("scheduleId")
      );
    }
    if (window.location.pathname.includes(prefix + "/roadmap")) {
      navigate(loginTargetMap["roadmap"] + "/" + queryParams.get("roadmapId"));
    }
  };
};
