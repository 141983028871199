import React from "react";
import "../Highlights/highlights.css";

export const Metrics = ({ labels, counts }) => {
  return (
    <div className="my-rank box--container progress-article progress-rank">
      <div className="top-bar">
        {" "}
        {labels.map((item, index) =>
          index === 0 ? (
            <div
              className="left"
              key={index}
              style={{
                width: "50%",
              }}
            >
              <div className="orange-box"> </div> <h2> {item} </h2>{" "}
            </div>
          ) : (
            <div
              className="right"
              key={index}
              style={{
                width: "50%",
              }}
            >
              <div className="orange-box"> </div> <h2> {item} </h2>{" "}
            </div>
          )
        )}{" "}
      </div>{" "}
      <div className="my-rank-content">
        {" "}
        {counts.map((item, index) =>
          index === 0 ? (
            <div
              className="left"
              key={index}
              style={{
                width: "50%",
              }}
            >
              <h1> {item} </h1>{" "}
            </div>
          ) : (
            <div
              className="right"
              key={index}
              style={{
                width: "50%",
              }}
            >
              <h1> {item} </h1>{" "}
            </div>
          )
        )}{" "}
      </div>{" "}
    </div>
  );
};
