import {
    storage
} from '../../../../firebase';
import {
    ref,
    uploadBytes,
    getBlob,
    deleteObject
} from 'firebase/storage';

export const uploadFile = async ({
    file,
    filePath
}) => {
    const fileRef = ref(storage, filePath);
    await uploadBytes(fileRef, file);
    return fileRef;
};

export const downloadFile = async ({
    filePath
}) => {
    const fileRef = ref(storage, filePath);
    return await getBlob(fileRef);
};

export const getFileRef = (filePath) => {
    try {
        return ref(storage, filePath);
    } catch (e) {
        console.log(e);
    }
};

export const deleteFile = async ({
    filePath
}) => {
    const fileRef = ref(storage, filePath);
    await deleteObject(fileRef);
};