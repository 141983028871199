import React from 'react';
import './AdminUiComponent.css';
import AdminUiComponent from './AdminUiComponent';

const AdminUiContainer = ({
    classes
}) => {
    return <AdminUiComponent classes = {
        classes
    }
    />;
};

export default AdminUiContainer;