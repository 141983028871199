import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

const getColor = (score) => {
  if (score >= 41) return "green";
  if (score >= 21) return "yellow";
  if (score >= 0) return "red";
};
const getText = (score) => {
  if (score >= 30)
    return "With a score of 30 or more, you are at low risk of falling.";
  if (score >= 15)
    return "With a score of 15 to 29, you are at medium risk of falling.";
  if (score >= 0)
    return "With a score of 0 to 14, you are at high risk of falling.";
};

const getColorMini = (score) => {
  if (score >= 11) return "green";
  if (score >= 6) return "yellow";
  if (score >= 0) return "red";
};
const getTextMini = (score) => {
  if (score >= 11)
    return "With a score of 11 or more, you are at low risk of falling.";
  if (score >= 6)
    return "With a score of 6 to 10, you are at medium risk of falling.";
  if (score >= 0)
    return "With a score of 0 to 5, you are at high risk of falling.";
};

export default function IndicationHealthStatusBox({ score, fullTest = true }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: fullTest ? getColor(score) : getColorMini(score),
        boxShadow: 5,
        padding: 2,
        mt: 4,
        borderRadius: 3,
      }}
    >
      <Typography
        sx={{
          fontSize: "1.5rem",
          fontWeight: "bold",
        }}
      >
        {fullTest ? getText(score) : getTextMini(score)}
      </Typography>
    </Box>
  );
}
