import Grid from "@mui/material/Grid";
import React from "react";
import { useSelector } from "react-redux";
import HeaderBox from "../../../Summary/HeaderBox";
import MetricBox from "../../../Summary/MetricBox";

export default function MetricObservation() {
  const iteration = useSelector(({ iter }) => iter.values.iteration);
  const metrics = useSelector(({ iter }) => iter.values.metrics);

  return (
    <div
      style={{
        marginLeft: 30,
        marginBottom: 30,
      }}
    >
      {" "}
      {metrics[iteration] && <HeaderBox> Metrics: </HeaderBox>}{" "}
      {metrics[iteration] && (
        <Grid container spacing={2}>
          {" "}
          {metrics[iteration].map((metric, index) => {
            return (
              <Grid item xs={6} key={index}>
                <MetricBox metric={metric} />{" "}
              </Grid>
            );
          })}{" "}
        </Grid>
      )}{" "}
    </div>
  );
}
