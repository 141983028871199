export function parseRecommendationConfig(config, score) {
    const {
        operator,
        value
    } = config;
    switch (operator) {
        case '>':
            return score > value;
        case '<':
            return score < value;
        case '>=':
            return score >= value;
        case '<=':
            return score <= value;
        case '==':
            return score === value;
        default:
            return false;
    }
}