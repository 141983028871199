import Calendar from "react-calendar";
import "./calendar.css";
import React from "react";
// import Divider from "@mui/material/Divider";
import moment from "moment";
import { useDispatch } from "react-redux";
import "./calendar.css";
import { getScheduleData } from "../../redux/actions/getDashboard";
import { useTranslation } from "react-i18next";
export default function CalendarBigComponent({ setLoader }) {
  const { t } = useTranslation();
  // console.log("STYLES::", JSON.stringify(styles));
  const dispatch = useDispatch();
  const calculateDiff = (value) => {
    getScheduleData(
      dispatch,
      moment(value).add(1, "day").format("YYYY-MM-DD"),
      setLoader,
      "TOM-SCHEDULE"
    );
    getScheduleData(dispatch, moment(value).format("YYYY-MM-DD"), setLoader);
  };
  return (
    <div className="calendar-container">
      <Calendar
        locale={t("CalendarComponent.locale")}
        // className='calendr_big'
        navigationLabel={({ date }) => (
          <div className="cln">
            <h4>
              {" "}
              {date.toLocaleString(t("CalendarComponent.locale"), {
                month: "long",
              }) +
                "\t" +
                date.getFullYear()}{" "}
            </h4>{" "}
            <div className="cal-divider"> </div>{" "}
          </div>
        )}
        tileClassName="dayview"
        showNeighboringMonth={false}
        value={new Date()}
        onChange={(value) => calculateDiff(value)}
        defaultValue={new Date()}
        formatDay={(locale, date) =>
          moment(date).format("D") == moment().format("D") ? (
            <div className="orange"> {moment(date).format("D")} </div>
          ) : (
            <> {moment(date).format("D")} </>
          )
        }
        // formatShortWeekday={(locale, date) => moment(date).format('dd')}
      />{" "}
    </div>
  );
}
