import React, { useRef } from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useCountsInTimeCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import { findAngle } from "../../../utilities/Demo/physio";
import PoseDetection from "../PoseDetection";
import { safeIterScore } from "../../../hooks/Demo/sharedLogic";
import { loadHighKneesAudio } from "../../../utilities/Demo/audioFeedback";
import { useDispatch } from "react-redux";

function HighKneesDemo({ onComplete, showWebcam = false, timeThresh = 60 }) {
  const dispatch = useDispatch();
  const side = useRef("left");
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    startingTime,
    raiseLevel,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadHighKneesAudio,
  });

  cptScore.current = () => {
    return correctCount.current / (100 * 2);
  };

  function observeBalance(knee, hip, otherKnee, backAngleLeft, backAngleRight) {
    if (backAngleLeft > 95 || backAngleRight < 85) {
      updateFeedback("Keep your back straight!");
      playAudio(audioFeedback.current?.keepBackStraight);
      return;
    }
    if (otherKnee.y >= hip.y * 1.4) {
      if (knee.y <= hip.y * 1.1) {
        side.current = side.current === "left" ? "right" : "left";
        correctCount.current++;
        updateCounts(correctCount.current);
        setStartingTime(seconds);
        const score = cptScore.current();
        if (!raiseLevel(score)) {
          playAudio(audioFeedback.current?.achievement);
        } else {
          safeIterScore(dispatch, score);
        }
      }
      if (seconds.current - startingTime.current >= timeThresh) {
        onComplete();
        safeIterScore(dispatch, cptScore.current());
      }
    }
    updateFeedback(`raise your ${side.current} knee`);
  }

  function onResults(results) {
    if (!finishedIntro.current) return;

    let knee;
    let otherKnee;
    let hip;
    const backAngleRight = findAngle(
      results.poseLandmarks[12],
      results.poseLandmarks[24],
      180
    );
    const backAngleLeft = findAngle(
      results.poseLandmarks[11],
      results.poseLandmarks[23],
      180
    );

    if (side.current === "left") {
      knee = results.poseLandmarks[25];
      otherKnee = results.poseLandmarks[26];
      hip = results.poseLandmarks[23];
    } else {
      knee = results.poseLandmarks[26];
      otherKnee = results.poseLandmarks[25];
      hip = results.poseLandmarks[24];
    }

    observeBalance(knee, hip, otherKnee, backAngleLeft, backAngleRight);
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}

export default React.memo(HighKneesDemo);
