import {
    drawAngleText,
    drawArc,
    findAngle,
    findDependentAngle,
} from './physio';

export function rightShoulderArc(
    canvasCtx,
    results,
    display = true,
    radius = 120,
    shoulderJoint = 12,
    elbowJoint = 14
) {
    let leftShoulder = [
        results.poseLandmarks[shoulderJoint].x,
        results.poseLandmarks[shoulderJoint].y,
    ];
    let leftElbow = [
        results.poseLandmarks[elbowJoint].x,
        results.poseLandmarks[elbowJoint].y,
    ];
    let leftArmAngle = findAngle(leftShoulder, leftElbow);

    // Draw the angle arc and text on top of the pose landmarks
    let leftArcStart = Math.PI / 2;
    if (leftArmAngle > 90) {
        leftArmAngle = 90 + (270 - leftArmAngle);
    }
    let left_arc_end = leftArcStart + leftArmAngle * (Math.PI / 180);
    if (display) {
        drawArc(
            canvasCtx,
            leftShoulder,
            radius,
            leftArcStart,
            left_arc_end,
            'white',
            3,
            false
        );
        drawAngleText(
            canvasCtx,
            leftArmAngle,
            leftShoulder[0] - radius + 20,
            leftShoulder[1] + 30
        );
    }
    return leftArmAngle;
}

export function leftShoulderArc(
    canvasCtx,
    results,
    display = true,
    radius = 120,
    shoulderJoint = 11,
    elbowJoint = 13
) {
    // Calculate the angle between the right shoulder and elbow
    let rightShoulder = [
        results.poseLandmarks[shoulderJoint].x,
        results.poseLandmarks[shoulderJoint].y,
    ];
    let rightElbow = [
        results.poseLandmarks[elbowJoint].x,
        results.poseLandmarks[elbowJoint].y,
    ];
    const rightArmAngle = findAngle(rightShoulder, rightElbow);

    // Draw the angle arc and text on top of the pose landmarks
    let rightArcStart = Math.PI / 2;
    let rightArcEnd = rightArcStart - rightArmAngle * (Math.PI / 180);
    if (display) {
        drawArc(
            canvasCtx,
            rightShoulder,
            radius,
            rightArcStart,
            rightArcEnd,
            'white',
            3
        );
        drawAngleText(
            canvasCtx,
            rightArmAngle,
            rightShoulder[0] + radius - 50,
            rightShoulder[1] + 30
        );
    }
    return rightArmAngle;
}

export function leftHipArc(canvasCtx, results, display = true, radius = 120) {
    let leftHip = [results.poseLandmarks[24].x, results.poseLandmarks[24].y];
    let leftKnee = [results.poseLandmarks[26].x, results.poseLandmarks[26].y];
    let leftLegAngle = findAngle(leftHip, leftKnee);

    // Draw the angle arc and text on top of the pose landmarks
    let leftLegArcStart = Math.PI / 2;
    if (leftLegAngle > 90) {
        leftLegAngle = 90 + (270 - leftLegAngle);
    }
    let leftLegArcEnd = leftLegArcStart + leftLegAngle * (Math.PI / 180);
    if (display) {
        drawArc(
            canvasCtx,
            leftHip,
            radius,
            leftLegArcStart,
            leftLegArcEnd,
            'white',
            3,
            false
        );
        drawAngleText(
            canvasCtx,
            leftLegAngle,
            leftHip[0] - radius + 40,
            leftHip[1] + 50
        );
    }
    return leftLegAngle;
}

export function rightHipArc(canvasCtx, results, radius = 120, display = true) {
    // Calculate the angle between the right shoulder and elbow
    let rightHip = [results.poseLandmarks[23].x, results.poseLandmarks[23].y];
    let rightKnee = [results.poseLandmarks[25].x, results.poseLandmarks[25].y];
    const rightLegAngle = findAngle(rightHip, rightKnee);

    // Draw the angle arc and text on top of the pose landmarks
    let rightLegArcStart = Math.PI / 2;
    let rightLegArcEnd = rightLegArcStart - rightLegAngle * (Math.PI / 180);
    if (display) {
        drawArc(
            canvasCtx,
            rightHip,
            radius,
            rightLegArcStart,
            rightLegArcEnd,
            'white',
            3
        );
        drawAngleText(
            canvasCtx,
            rightLegAngle,
            rightHip[0] + radius - 90,
            rightHip[1] + 50
        );
    }
    return rightLegAngle;
}

export function rightKneeArc(canvasCtx, results, radius = 120) {
    let leftHip = results.poseLandmarks[24];
    let leftKnee = results.poseLandmarks[26];
    let leftAnkle = results.poseLandmarks[28];
    let leftKneeAngle = findDependentAngle(leftHip, leftKnee, leftAnkle);

    // Draw the angle arc and text on top of the pose landmarks
    let leftLegArcStart = Math.PI / 2;
    if (leftKneeAngle > 90) {
        leftKneeAngle = 90 + (270 - leftKneeAngle);
    }
    let leftLegArcEnd = leftLegArcStart + leftKneeAngle * (Math.PI / 180);

    drawArc(
        canvasCtx,
        leftKnee,
        radius,
        leftLegArcStart,
        leftLegArcEnd,
        'white',
        3,
        false
    );
    drawAngleText(
        canvasCtx,
        leftKneeAngle,
        leftKnee.x - radius + 40,
        leftKnee.y + 60
    );
    return leftKneeAngle;
}

export function leftKneeArc(canvasCtx, results, radius = 120) {
    // Calculate the angle between the right shoulder and elbow
    let rightHip = results.poseLandmarks[23];
    let rightKnee = results.poseLandmarks[25];
    let rightAnkle = results.poseLandmarks[27];
    const rightKneeAngle = findDependentAngle(rightHip, rightKnee, rightAnkle);

    // Draw the angle arc and text on top of the pose landmarks
    let rightLegArcStart = Math.PI / 2;
    let rightLegArcEnd = rightLegArcStart - rightKneeAngle * (Math.PI / 180);
    drawArc(
        canvasCtx,
        rightKnee,
        radius,
        rightLegArcStart,
        rightLegArcEnd,
        'white',
        3
    );
    drawAngleText(
        canvasCtx,
        rightKneeAngle,
        rightKnee.x + radius - 90,
        rightKnee.y + 60
    );
    return rightKneeAngle;
}

export function leftBackArc(canvasCtx, results, radius = 120, respect = 90) {
    let leftShoulder = [results.poseLandmarks[12].x, results.poseLandmarks[12].y];
    let leftHip = [results.poseLandmarks[24].x, results.poseLandmarks[24].y];
    let leftBackAngle = findAngle(leftShoulder, leftHip, respect);
    let leftBackAngleDisplay = findAngle(leftShoulder, leftHip, 180);

    // Draw the angle arc and text on top of the pose landmarks
    let leftBackArcStart = Math.PI / 2;
    let leftBackArcEnd = leftBackArcStart + leftBackAngle * (Math.PI / 180);

    drawArc(
        canvasCtx,
        leftHip,
        radius,
        leftBackArcStart,
        leftBackArcEnd,
        'white',
        3,
        false
    );
    drawAngleText(
        canvasCtx,
        leftBackAngleDisplay,
        leftHip[0] - radius + 40,
        leftHip[1] + 60
    );
    return leftBackAngleDisplay;
}

export function rightBackArc(canvasCtx, results, radius = 120, respect = 90) {
    // Calculate the angle between the right shoulder and elbow
    let rightShoulder = [
        results.poseLandmarks[11].x,
        results.poseLandmarks[11].y,
    ];
    let rightHip = [results.poseLandmarks[23].x, results.poseLandmarks[23].y];
    const rightBackAngle = findAngle(rightShoulder, rightHip, respect);
    const rightBackAngleDisplay = findAngle(rightShoulder, rightHip, 0);

    // Draw the angle arc and text on top of the pose landmarks
    let rightBackArcStart = Math.PI / 2;
    let rightBackArcEnd = rightBackArcStart - rightBackAngle * (Math.PI / 180);
    drawArc(
        canvasCtx,
        rightHip,
        radius,
        rightBackArcStart,
        rightBackArcEnd,
        'white',
        3
    );
    drawAngleText(
        canvasCtx,
        rightBackAngleDisplay,
        rightHip[0] + radius - 90,
        rightHip[1] + 60
    );
    return rightBackAngleDisplay;
}