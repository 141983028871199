import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Card, CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast, ToastContainer } from "react-toastify";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import { updateToastCaseFunction } from "../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts";
import {
  addRoadmap,
  getSchedule,
  updateRoadmap,
} from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import DeleteButton from "../../DeleteButton";
import TagConfig from "../TagConfig";
import RecommendationConfigDialog from "./RecommendationConfigDialog";
import ScheduleDialog from "./ScheduleDialog";

export default function RoadmapConfigurator({
  roadmapData,
  addRoadmapToList,
  updateRoadmapInList,
  onClose,
}) {
  const [name, setName] = React.useState(roadmapData.name || "");
  const [description, setDescription] = React.useState(
    roadmapData.description || ""
  );
  const [schedules, setSchedules] = React.useState(roadmapData.schedules || []);
  const [saving, setSaving] = React.useState(false);
  const buttonText = roadmapData.id ? "Update" : "Create";
  const [scheduleDialogOpen, setScheduleDialogOpen] = React.useState(false);
  const [tags, setTags] = React.useState(roadmapData.tags || []);
  const [id, setId] = React.useState(roadmapData.id || "");
  const [
    recommendationConfigDialogScheduleId,
    setRecommendationConfigDialogScheduleId,
  ] = React.useState(null);
  const [recommendations, setRecommendations] = React.useState(
    roadmapData.recommendations || {}
  );
  const { user } = React.useContext(FirebaseAuthContext);

  React.useEffect(() => {
    async function fetchSchedules() {
      const promises = schedules.map(async (schedule) => {
        if (typeof schedule === "string") {
          return await getSchedule({ scheduleId: schedule });
        } else {
          return schedule;
        }
      });

      // Use Promise.all() to wait for all the promises to resolve
      return Promise.all(promises);
    }

    fetchSchedules().then((scheduleDocs) => {
      setSchedules(scheduleDocs);
    });
  }, []);

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...schedules];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setSchedules(updatedList);
  };

  const saveRoadmap = () => {
    setSaving(true);
    const roadmapData = {
      name,
      description,
      schedules: schedules.map((schedule) => schedule.id),
      recommendations: recommendations,
      id: id,
    };
    const toastId = toast.loading("Saving...");
    const updateToastCase = updateToastCaseFunction({
      id: toastId,
      successRender: "Saved Schedule!",
      errorRender: "Error saving Schedule!",
    });
    if (id) {
      console.log(roadmapData);
      updateRoadmap({ roadmapData, user }).then((roadmap) => {
        updateToastCase(roadmap);
        roadmap && updateRoadmapInList(roadmap);
      });
    } else {
      addRoadmap({ roadmapData, user }).then((roadmap) => {
        updateToastCase(roadmap);
        roadmap && addRoadmapToList(roadmap);
        setId(roadmap.id);
      });
    }
    setSaving(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <RecommendationConfigDialog
        open={recommendationConfigDialogScheduleId}
        onClose={() => setRecommendationConfigDialogScheduleId(null)}
        schedules={schedules}
        recommendations={recommendations[recommendationConfigDialogScheduleId]}
        setRecommendations={(recommendationsUpdate) => {
          setRecommendations({
            ...recommendations,
            [recommendationConfigDialogScheduleId]: recommendationsUpdate,
          });
        }}
      />
      <ScheduleDialog
        open={scheduleDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
        setSchedule={(schedule) => {
          setSchedules([...schedules, schedule]);
        }}
      />
      <h1>Roadmap Configurator</h1>
      <Grid
        container
        spacing={2}
        direction={"column"}
        sx={{ mb: 3, mt: 3, ml: 0 }}
      >
        <Grid container spacing={2} direction={"column"} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ height: "50%", width: "50%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ height: "50%", width: "50%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TagConfig tags={tags} setTags={setTags} type={"roadmap"} />
          </Grid>
        </Grid>
        <h3>Schedules / Transitions</h3>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <Grid
                {...provided.droppableProps}
                container
                spacing={4}
                sx={{ padding: 2 }}
                ref={provided.innerRef}
              >
                {schedules.map((schedule, index) => (
                  <Draggable
                    key={"schedule-" + index}
                    draggableId={"schedule-" + index}
                    index={index}
                  >
                    {(provided) => (
                      <Grid
                        item
                        key={"exercise-" + index}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        <Card
                          onClick={() => {}}
                          sx={{
                            "&:hover": { cursor: "pointer" },
                            height: "100%",
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h1>{schedule.name}</h1>
                          <Button
                            variant={"contained"}
                            onClick={() => {
                              setRecommendationConfigDialogScheduleId(
                                schedule.id
                              );
                            }}
                          >
                            Recommendation
                          </Button>
                          <DeleteButton
                            onDelete={() => {
                              setSchedules(
                                schedules.filter((_, i) => i !== index)
                              );
                            }}
                            deletionTarget={"Schedule"}
                          />
                        </Card>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      padding: 2,
                      border: 1,
                      borderRadius: 5,
                      m: 2,
                    }}
                  >
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => {
                        setScheduleDialogOpen(true);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                  {provided.placeholder}
                </Grid>
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          variant={"contained"}
          disabled={
            saving ||
            name.length === 0 ||
            description.length === 0 ||
            schedules.length === 0
          }
          onClick={saveRoadmap}
          sx={{ width: "10%", mt: 3 }}
        >
          {saving ? <CircularProgress /> : buttonText}
        </Button>
        <ToastContainer />
      </Grid>
    </Box>
  );
}
