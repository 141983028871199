import React from "react";
import Planet from "../../assets/big_planet.png";

const HighlightBg = () => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "150%",
        zIndex: -100,
        backgroundColor: "#FCFCF9",
        overflow: "hidden",
        overflowX: "hidden",
      }}
    >
      <img
        src={Planet}
        alt="Exar"
        style={{
          objectFit: "contain",
          position: "absolute",
          top: "-2%",
          left: "-5%",
          height: "70%",
          width: "80%",
          backgroundColor: "#FCFCF9",
        }}
      />{" "}
    </div>
  );
};

export default HighlightBg;
