const initialState = {
  values: {
    stepsLeft: 0,
    stepsRight: 0,
    strideTimeLeft: [],
    strideTimeRight: [],
    stanceTimeLeft: [],
    stanceTimeRight: [],
    stepLengthLeft: [],
    stepLengthRight: [],
    stepTimeLengthLeft: [],
    stepTimeLengthRight: [],
    stanceTimeLengthLeft: [],
    stanceTimeLengthRight: [],
    stanceTimeWindowsLeft: [],
    stanceTimeWindowsRight: [],
    strideTimeWindowsLeft: [],
    strideTimeWindowsRight: [],
    graphData: [],
    centerOfMassData: [],
    hipShoulderDelta: {
      pixels: 0,
      RWU: 0,
    },
    feedback: "",
    stepValidityLeft: [],
    stepValidityRight: [],
    sensorData: {
      left: [],
      right: [],
    },
    overPronatedLeft: [],
    overPronatedRight: [],
    relativeLandmarks: [],
    invalidMarkerEvents: [],
    validMarkerEvents: [],
    notEnoughData: false,
  },
};

export const gaitReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE-GAIT-OBSERVATION": {
      return {
        values: { ...state.values, ...action.payload },
      };
    }
    case "APPEND-COM-GAIT-OBSERVATION": {
      state.values.centerOfMassData.push(action.payload);
      return {
        values: { ...state.values },
      };
    }
    case "GAIT-FEEDBACK": {
      return {
        values: { ...state.values, ...action.payload },
      };
    }
    case "RESET-GAIT-OBSERVATION":
      return initialState;
    default: {
      return state;
    }
  }
};
