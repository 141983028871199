import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import DoubleSideConfig from "./DoubleSideConfig";

export default function LeftRightConfig({ config, setConfig }) {
  return (
    <Box
      sx={{
        padding: 2,
        ml: 2,
      }}
    >
      <Typography
        variant={"h6"}
        sx={{
          mt: 2,
        }}
      >
        Left Side Of Body{" "}
      </Typography>{" "}
      <DoubleSideConfig
        config={config.left || {}}
        setConfig={(value) => {
          setConfig({ ...config, left: value });
        }}
        colors={["rgba(9,196,9,0.41)", "#3ce8aa"]}
      />{" "}
      <Typography
        variant={"h6"}
        sx={{
          mt: 2,
        }}
      >
        Right Side Of Body{" "}
      </Typography>{" "}
      <DoubleSideConfig
        config={config.right || {}}
        setConfig={(value) => {
          setConfig({ ...config, right: value });
        }}
        colors={["rgb(241,166,12)", "#f1730c"]}
      />{" "}
    </Box>
  );
}
