import React from "react";
import { colorBelts } from "../../utilities/colorbelts";
import ProgressArticleNavigation from "./ProgressArticleNavigation";
import { useTranslation } from "react-i18next";

const Progress = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="progress-component-container progress-article progress-health">
      <ProgressArticleNavigation
        title={t("progressHealthComponent.title")}
        selectVal={"This month"}
      />{" "}
      <div className="health-content">
        <div className="single-health-box height--health">
          <span> {t("progressHealthComponent.height")} </span>{" "}
        </div>{" "}
        <div className="single-health-box weight--health">
          <span> {t("progressHealthComponent.weight")} </span>{" "}
        </div>{" "}
        <div className="single-health-box status--health">
          <span> {t("progressHealthComponent.status")} </span>{" "}
        </div>{" "}
        <div className="single-health-box single-health-box-bottom cm--health">
          <span> {data && `${data.height} cm`} </span>{" "}
        </div>{" "}
        <div className="single-health-box single-health-box-bottom kg--health">
          <span> {data && `${data.weight} kg`} </span>{" "}
        </div>{" "}
        <div
          className="single-health-box single-health-box-bottom healthiness--health"
          style={{
            background:
              Object.keys(data).length > 0 &&
              colorBelts[data.status.split(" ")[0]].bg,
            color:
              Object.keys(data).length > 0 &&
              colorBelts[data.status.split(" ")[0]].color,
          }}
        >
          <span> {data && `${data.status}`} </span>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Progress;
