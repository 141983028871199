import React, { useState } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/system";
import Backdrop from "@mui/material/Backdrop";
import InputBarComponent from "../InputBarComponent";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";

const UserSignUpDetails = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    nextStep,
    // eslint-disable-next-line react/prop-types
    open,
    // eslint-disable-next-line react/prop-types
    setOpen,
    // eslint-disable-next-line react/prop-types
    setParentsName,
    // eslint-disable-next-line react/prop-types
    parentsName,
    // eslint-disable-next-line react/prop-types
    childsName,
    // eslint-disable-next-line react/prop-types
    setChildsName,
    // eslint-disable-next-line react/prop-types
    email,
    // eslint-disable-next-line react/prop-types
    setEmail,
    // eslint-disable-next-line react/prop-types
    setPassword,
    // eslint-disable-next-line react/prop-types
    setRepeatPassword,
    // eslint-disable-next-line react/prop-types
    password,
    // eslint-disable-next-line react/prop-types
    repeatPassword,
  } = props;
  const { t } = useTranslation();
  const { control } = useForm();
  const [progress] = React.useState(30);
  const [buffer] = React.useState(65);
  const [valid, setValid] = useState(true);

  const continueNext = () => {
    // e.preventDefault();
    nextStep();
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line react/prop-types
      parentsName.length &&
      // eslint-disable-next-line react/prop-types
      childsName.length &&
      // eslint-disable-next-line react/prop-types
      email.length && // eslint-disable-next-line react/prop-types
      password.length &&
      !(password !== repeatPassword)
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [props]);

  return (
    <Box>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 10,
            maxheight: "53%",
            width: "95vw",
            padding: 2,
          },
        }}
        scroll="body"
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          backgroundColor: "rgb(242, 247, 247,0.5)",
        })}
        open={open}
        onClose={() => setOpen(false)}
      >
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
          color="secondary"
        />
        <form>
          <DialogContent
            style={{
              overflow: "hidden",
            }}
          >
            <DialogTitle
              sx={{
                fontSize: 18,
              }}
            >
              {" "}
              {t("signUpComponent.signup-exar")}{" "}
              <span
                style={{
                  float: "right",
                  color: "grey",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              >
                & #x2715;{" "}
              </span>{" "}
            </DialogTitle>{" "}
            <Controller
              name="parents-name"
              control={control}
              render={() => (
                <InputBarComponent
                  name="parents-name"
                  label={t("ProfileComponent.name")}
                  type="text"
                  value={parentsName}
                  onChange={(e) => {
                    setParentsName(e.target.value);
                  }}
                />
              )}
            />{" "}
            <Controller
              name="childs-name"
              control={control}
              render={() => (
                <InputBarComponent
                  name="childs-name"
                  label={t("signUpComponent.childsName")}
                  type="text"
                  value={childsName}
                  onChange={(e) => {
                    setChildsName(e.target.value);
                  }}
                />
              )}
            />{" "}
            <Controller
              name="email"
              control={control}
              render={() => (
                <InputBarComponent
                  name="email"
                  label={t("ProfileComponent.email")}
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              )}
            />{" "}
            <Controller
              name="password"
              control={control}
              render={({ field: { value } }) => (
                <InputBarComponent
                  name="password"
                  label={t("welcomeView.password")}
                  type="password"
                  value={value}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              )}
            />{" "}
            <Controller
              name="repeat-password"
              control={control}
              render={({ field: { value } }) => (
                <InputBarComponent
                  name="password"
                  label={t("welcomeView.repeatpass")}
                  type="password"
                  value={value}
                  onChange={(e) => {
                    setRepeatPassword(e.target.value);
                  }}
                />
              )}
            />{" "}
          </DialogContent>{" "}
          <DialogActions
            disableSpacing={true}
            sx={{
              justifyContent: "center",
              height: "10%",
            }}
          >
            <Button
              // type="submit"
              variant="contained"
              disabled={valid}
              sx={{
                borderRadius: 20,
                backgroundColor: "#FF592C",
              }}
              onClick={() => continueNext()}
            >
              {t("signUpComponent.continue")}{" "}
            </Button>{" "}
            {valid && (
              <Tooltip title="Please enter the correct details and password should match !">
                <span>
                  <ErrorIcon
                    style={{
                      float: "centre",
                      color: "red",
                      cursor: "pointer",
                    }}
                  />{" "}
                </span>{" "}
              </Tooltip>
            )}{" "}
          </DialogActions>{" "}
        </form>{" "}
      </Dialog>{" "}
    </Box>
  );
};

export default UserSignUpDetails;
