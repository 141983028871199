import _ from 'lodash';
import {
    drawAngle
} from '../../angles';
import {
    drawAngleText
} from '../../physio';

export function applyAdjustments(
    metric,
    angle,
    absolute = true,
    flipIfAboveMax = false
) {
    // console.log(
    //   angle,
    //   'before adjustment',
    //   angle + Number(metric.adjustment),
    //   (angle + Number(metric.adjustment)) % 360,
    //   metric.revertAngle,
    //   metric
    // );
    if (metric.adjustment) {
        angle = (angle + Number(metric.adjustment)) % 360;
        if (absolute && angle < 0) angle += 360;
    }
    if (metric.revertAngle) {
        angle = 360 - angle;
    }
    if (metric.maxValue) {
        if (angle > Number(metric.maxValue)) {
            if (flipIfAboveMax) {
                angle = 360 - angle;
            } else {
                angle = 0;
            }
        }
    }
    // console.log(angle);
    return angle;
}

const rangeOfMotion = (metric, angles) => {
    let angle = angles.current[metric.ref];
    angle = applyAdjustments(metric, angle);
    if (!metric.value) metric.value = metric.type === 'min' ? 100000 : 0;
    if (metric.type === 'min') metric.value = Math.min(angle, metric.value);
    if (metric.type === 'max') metric.value = Math.max(angle, metric.value);
};

const mean = (metric, angles) => {
    let angle = angles.current[metric.ref];
    angle = applyAdjustments(metric, angle, false);
    if (!metric.collection) metric.collection = [];
    metric.collection.push(angle);
    metric.value = Math.round(_.mean(metric.collection));
};

export const metricsComputations = {
    rangeOfMotion,
    mean,
};

export const drawAdjustedAngles = (
    canvasCtx,
    metricAngles,
    angles,
    flipIfAboveMax = false
) => {
    Object.keys(metricAngles.current).forEach((metricAngle) => {
        const {
            coord,
            left,
            isExtendedLine,
            metric
        } =
        metricAngles.current[metricAngle];
        if (!coord || !metric) return;
        const angle = applyAdjustments(
            metric,
            angles.current[metricAngle],
            metric.computation === 'rangeOfMotion',
            flipIfAboveMax
        );
        if (!isExtendedLine) {
            drawAngle(canvasCtx, angle, coord, left);
        } else {
            drawAngleText(canvasCtx, angle, coord.x, coord.y);
        }
    });
};