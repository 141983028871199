import React, { useState } from "react";
import "./AdminUiComponent.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import { CircularProgress } from "@mui/material";
import uploadIcon from "../../assets/upload.png";
import success from "../../assets/success.png";
import wrong from "../../assets/wrong.png";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import {
  createExerciseSet,
  updateExerciseSet,
} from "../../redux/actions/superadmin";

export const CreateExerciseSets = ({ mode = "add", sets = [] }) => {
  const { t } = useTranslation();
  const [modelList, setModelList] = useState(null);
  const [audioList, setAudioList] = useState(null);
  const [setname, setSetName] = useState("");
  const [msg, setMsg] = useState("");
  const [result, setResult] = useState("");
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [resp, setResp] = useState();
  const closeModal = () => {
    window.location.reload();
    setShow(false);
  };
  const style = {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    margin: "auto",
    height: "70vh",
    width: "70vw",
    bgcolor: "white",
    borderRadius: "2.5em",
    p: 0,
  };
  const handleModelUploadClick = async (e) => {
    e.preventDefault();
    setLoad(true);

    const data = new FormData();
    if (modelList && audioList) {
      if (mode === "add") data.append("setName", setname);
      else data.append("setId", setsvalue);
      models.forEach((file) => {
        data.append("videoFiles", file);
      });

      audios.forEach((audio) => {
        data.append("audioFiles", audio);
      });
      if (mode === "add") {
        await createExerciseSet(
          data,
          setShow,
          setLoad,
          setResp,
          setResult,
          setMsg
        );
      } else {
        await updateExerciseSet(
          data,
          setShow,
          setLoad,
          setResp,
          setResult,
          setMsg
        );
      }
    }
  };

  const models = modelList ? [...modelList] : [];
  const audios = audioList ? [...audioList] : [];
  const [setsvalue, setSetsValue] = useState("");

  return (
    <div className="admin-settings-container">
      <div className="admin-settings-title">
        <div className="admin-page-orange-rect"></div>
        <h2>
          {mode === "add" ? "Create Exercise Set" : "Update Exercise Set"}
        </h2>
      </div>
      <br />
      <div className="admin-page-content">
        <div className="form-group">
          <div className="form-group-heading">
            {t("adminDashboard.exerciseFiles")}
          </div>
          <div className="upload-btns">
            <div className="file-upload">
              <label htmlFor="file-input">
                <img src={uploadIcon} /> {t("adminDashboard.chooseFiles")}
              </label>
              <input
                id="file-input"
                type="file"
                multiple
                onChange={(e) => {
                  setModelList(e.target.files);
                }}
              />
            </div>
            <div className="filers">
              {models.map((model, i) => (
                <ul key={model.name + "ul"} style={{ marginLeft: "0.7%" }}>
                  {i + 1}. {model.name} {model.type}
                </ul>
              ))}
            </div>
          </div>
        </div>
        <br />
        <div className="form-group">
          <div className="form-group-heading">
            {" "}
            {t("adminDashboard.audioFiles")}
          </div>
          <div className="upload-btns">
            <div className="file-upload">
              <label htmlFor="audio_uploads">
                <img src={uploadIcon} /> {t("adminDashboard.uploadMp3")}
              </label>
              <input
                id="audio_uploads"
                type="file"
                onChange={(e) => {
                  setAudioList(e.target.files);
                }}
                name="audio_uploads"
                multiple
              />
            </div>
            <div className="filers">
              {audios.map((audio, i) => (
                <ul key={audio.name + "-" + i} style={{ marginLeft: "0.7%" }}>
                  {i + 1}. {audio.name} {audio.type}
                </ul>
              ))}
            </div>
          </div>
        </div>
        <br />
        {mode === "edit" ? (
          <div className="form-group">
            <div className="form-group-heading">Select Sets</div>
            <div className="class-dropdown">
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  className="select-dialog"
                  value={setsvalue}
                  onChange={(e) => {
                    setSetsValue(e.target.value);
                  }}
                >
                  {sets.map((option, index) => (
                    <MenuItem
                      value={option.set_id}
                      key={option.set_name + "-" + index}
                    >
                      <ListItemText primary={option.set_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        ) : (
          <div className="form-group">
            <div className="form-group-desciption-box">
              <div className="form-group-heading">Set Name</div>
              <input
                type="text"
                maxLength={140}
                value={setname}
                required
                onChange={(e) => {
                  setSetName(e.target.value);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="form-grp">
        {load ? (
          <CircularProgress
            size={42}
            color="success"
            sx={{ justifyContent: "center" }}
          />
        ) : (
          <Button
            type="submit"
            variant="contained"
            onClick={handleModelUploadClick}
          >
            Upload
          </Button>
        )}
      </div>
      <Modal
        open={show}
        onClose={() => closeModal()}
        height={"100vh"}
        sx={{
          justify: "center",
          margin: "auto",
          pt: "10rem",
          border: "none",
        }}
        bordered={false}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style} border={"none"}>
          {result !== "error" ? (
            <>
              <img className="success-img" src={success} alt="" />
              <h2 id="parent-modal-title">Set has been added</h2>
              <div
                id="parent-modal-description"
                style={{ display: "flex", width: "40vw" }}
              >
                <div className="mod_head">Set Name</div>
                <div className="mod_data">{resp && resp.set_name}</div>
              </div>
              <div style={{ display: "flex", width: "40vw" }}>
                <div className="mod_head">Set ID</div>
                <div className="mod_data">{resp && resp.set_id}</div>
              </div>
            </>
          ) : (
            <>
              <img className="success-img" src={wrong} alt="" />
              <h2 id="parent-modal-title">{t("adminDashboard.failed")}</h2>
              <div className="mod_data">
                <center>{msg}</center>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CreateExerciseSets;
