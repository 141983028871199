import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const CustomDialog = ({ open, handleClose, title, children, actions }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle> {title} </DialogTitle>{" "}
      <DialogContent> {children} </DialogContent>{" "}
      {actions && (
        <DialogActions>
          {" "}
          {actions.map((action, index) => (
            <Button key={index} onClick={action.onClick} color={action.color}>
              {" "}
              {action.label}{" "}
            </Button>
          ))}{" "}
        </DialogActions>
      )}{" "}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      color: PropTypes.oneOf(["primary", "secondary"]),
    })
  ),
};

CustomDialog.defaultProps = {
  actions: [],
};

export default CustomDialog;
