import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Image,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import gaitCycleImage from "../../../../../../assets/GaitAnalysis/gait_cycle.png";
import Footer from "./Footer";
const colors = {
  green: "#549d50",
  blue: "#6abcfe",
};
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    height: "840pt",
    padding: 10,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
    // margin: 20,
  },
  topTableCol: {
    borderTopColor: "#26e9ff",
    borderTopWidth: 7,
  },
  bottomTableCol: {
    borderBottomWidth: 1,
    borderBottomColor: "#ff8f26",
  },
  subTitleInfoRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    fontSize: 24,
    marginTop: 20,
    color: "#192c52",
  },
  date: {
    fontSize: 12,
    marginRight: 12,
  },
  patientInfoLabel: {
    fontSize: 12,
    margin: 2,
  },
  patientInfoValue: {
    fontSize: 12,
    margin: 2,
    fontWeight: "bold", // Ensure the value is normal weight
  },
  patientInfoContainer: {
    margin: 12,
    marginBottom: 2,
    padding: 10,
    flexGrow: 1,
  },
  patientInfoText: {
    fontSize: 12,
    margin: 2,
    color: "#192c52",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#26e9ff",
    // borderBottomColor: '#ff8f26',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 10,
    margin: 5,
    marginTop: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#ff8f26",
    borderBottomColor: "#26e9ff",
    borderWidth: 4,
    borderLeftWidth: 4,
    borderTopWidth: 4,
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#ffffff",
    backgroundColor: "rgba(255,143,38,0.3)",
    borderWidth: 1,
    borderLeftWidth: 1,
    borderLeftColor: "#ff8f26",
  },
  tableCellHeader: {
    margin: "auto",
    marginTop: 5,
    fontSize: 12,
    fontWeight: "extrabold",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  tableCellGreen: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    color: "green",
  },
  tableCellRed: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    color: "red",
  },
});

// Create styles
const cycleStyles = StyleSheet.create({
  row: {
    flexDirection: "row",
    width: "100%",
    paddingLeft: 110,
    paddingRight: 100,
    fontSize: 10,
  },
  stanceBox: {
    width: "55%",
    backgroundColor: colors.blue,
    color: "white",
    textAlign: "center",
    padding: 10,
  },
  doubleSupportBox: {
    width: "10%",
    backgroundColor: "grey",
    color: "black",
    textAlign: "center",
    fontSize: 8,
    paddingTop: 10,
    paddingBottom: 10,
  },
  swingBox: {
    width: "35%",
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    padding: 10,
    borderWidth: 1,
    borderColor: "black",
  },
  emptyBox: {
    width: "10%",
    // no border, background color or text as per requirement
  },
});

const legendStyles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    height: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 110,
    marginRight: 100,
  },
  lineContainer: {
    position: "absolute",
    left: "55%", // Position where your line should be. Adjust as needed
    top: 0,
    bottom: 0,
    width: 1,
    backgroundColor: "black",
  },
  line: {
    borderStyle: "dotted",
    borderWidth: 1,
    borderColor: "black",
    height: "100%",
  },
  percentageText: {
    position: "absolute",
    fontSize: 8,
  },
  zeroPercent: {
    left: "0%",
  },
  hundredPercent: {
    right: "43%",
  },
});

const Spacer = ({ height }) => <View style={{ height }}></View>;

export default function ResultPdf({
  cycleImage,
  pressureImage,
  pressureMapImage,
  doctorMetricsImage,
  patientInfo,
  metrics = {},
}) {
  const [base64PressureMapImage, setBase64PressureMapImage] = useState("");
  const [base64PressureImage, setBase64PressureImage] = useState("");
  const [base64DoctorMetricsImage, setBase64DoctorMetricsImage] = useState("");

  useEffect(() => {
    if (pressureMapImage) {
      imageToCanvasToBase64(pressureMapImage, setBase64PressureMapImage);
    }
    if (pressureImage) {
      imageToCanvasToBase64(pressureImage, setBase64PressureImage);
    }
    if (doctorMetricsImage) {
      imageToCanvasToBase64(doctorMetricsImage, setBase64DoctorMetricsImage);
    }
  }, [pressureMapImage, pressureImage, doctorMetricsImage]);

  if (!metrics) {
    return <div>Loading...</div>;
  }

  const imageToCanvasToBase64 = (imageUrl, setBase64Function) => {
    const img = new window.Image(); // Use `window.Image()` to explicitly refer to the DOM's Image
    img.crossOrigin = "Anonymous"; // This might be needed depending on CORS
    img.onload = () => {
      // Create canvas
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      // Convert canvas to Base64
      const base64String = canvas.toDataURL("image/png");
      setBase64Function(base64String);
    };
    img.src = imageUrl;
    if (img.complete || img.complete === undefined) {
      // Force reload for cached images
      img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = imageUrl;
    }
  };

  return (
    <Document>
      <Page size="A4">
        <View style={styles.headerContainer}>
          <Text style={styles.title}>EXAR Gait Analysis Report</Text>
        </View>
        <View style={styles.subTitleInfoRow}>
          <Text style={styles.patientInfoContainer}>Profile Information</Text>
        </View>
        <View style={styles.table}>
          {/* Table Header */}
          <View key={"name"} style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                ...styles.topTableCol,
              }}
            >
              <Text style={styles.tableCell}>Patient name:</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                ...styles.topTableCol,
              }}
            >
              <Text style={styles.tableCell}>{patientInfo.name}</Text>
            </View>
          </View>
          <View key={"gender"} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Gender:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{patientInfo.gender}</Text>
            </View>
          </View>
          <View key={"height"} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Height:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{patientInfo.height}</Text>
            </View>
          </View>
          <View key={"weight"} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Weight:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{patientInfo.weight}</Text>
            </View>
          </View>
          <View key={"age"} style={styles.tableRow}>
            <View style={[styles.tableCol, styles.bottomTableCol]}>
              <Text style={styles.tableCell}>Age:</Text>
            </View>
            <View style={[styles.tableCol, styles.bottomTableCol]}>
              <Text style={styles.tableCell}>{patientInfo.age}</Text>
            </View>
          </View>
        </View>
        <View style={styles.subTitleInfoRow}>
          <Text style={styles.patientInfoContainer}>Assessment Overview</Text>
        </View>
        <View style={styles.table}>
          {/* Table Header */}
          <View key={"name"} style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                ...styles.topTableCol,
                ...styles.bottomTableCol,
              }}
            >
              <Text style={styles.tableCell}>Date of Assessment:</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                ...styles.topTableCol,
                ...styles.bottomTableCol,
              }}
            >
              <Text style={styles.tableCell}>
                {new Date().toLocaleString() + ""}
              </Text>
            </View>
          </View>
        </View>
        {cycleImage && (
          <Image
            src={cycleImage}
            style={{ width: "100%", height: "50%" }} // Assuming the aspect ratio is maintained by the image itself
          />
        )}
        <Spacer height={40} />
        <Footer
          patientName={patientInfo.name}
          page={1}
          date={new Date().toLocaleString()}
        />
      </Page>
      <Page size="A4">
        <View style={styles.headerContainer}>
          <Text style={styles.title}>Gait Cycle Analysis</Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
          }}
        >
          <Image
            src={gaitCycleImage}
            style={{ width: "80%" }} // Adjust these values based on actual aspect ratios
          />
        </View>
        <View style={legendStyles.container}>
          <Text
            style={{
              ...legendStyles.percentageText,
              ...legendStyles.zeroPercent,
              color: colors.blue,
            }}
          >
            0%
          </Text>
          <View style={{ ...legendStyles.lineContainer, left: "6%" }}>
            <View style={legendStyles.line} />
          </View>
          <View style={{ ...legendStyles.lineContainer, left: "29%" }}>
            <View style={legendStyles.line} />
          </View>
          <View style={{ ...legendStyles.lineContainer, left: "64.55%" }}>
            <View style={legendStyles.line} />
          </View>
          <Text
            style={{
              ...legendStyles.percentageText,
              ...legendStyles.hundredPercent,
              color: colors.blue,
            }}
          >
            100%
          </Text>
        </View>
        <View style={cycleStyles.row}>
          <View style={cycleStyles.stanceBox}>
            <Text>Right Stance Phase</Text>
            <Text>
              {metrics.stanceTimeRight?.toFixed(2)} s (
              {(
                metrics.stanceTimeRight /
                (metrics.stanceTimeRight + metrics.strideTimeRight)
              )?.toFixed(2)}{" "}
              %)
            </Text>
          </View>
          <View style={cycleStyles.swingBox}>
            <Text>Right Swing Phase</Text>
            <Text>
              {metrics.strideTimeRight?.toFixed(2)} s (
              {(
                metrics.strideTimeRight /
                (metrics.stanceTimeRight + metrics.strideTimeRight)
              )?.toFixed(2)}{" "}
              %)
            </Text>
          </View>
          <View style={cycleStyles.emptyBox} />
          <View style={{ ...legendStyles.lineContainer, left: "128.3%" }}>
            <View style={legendStyles.line} />
          </View>
        </View>
        <View style={cycleStyles.row}>
          <View style={cycleStyles.doubleSupportBox}>
            <Text>Double Support</Text>
          </View>
          <View style={{ ...legendStyles.lineContainer, left: "38%" }}>
            <View style={legendStyles.line} />
          </View>
          <View style={{ width: "35%" }} />
          <View style={cycleStyles.doubleSupportBox}>
            <Text>Double Support</Text>
          </View>
          <View style={{ ...legendStyles.lineContainer, left: "73.3%" }}>
            <View style={legendStyles.line} />
          </View>
          <View style={{ width: "35%" }} />
          <View style={cycleStyles.doubleSupportBox}>
            <Text>Double Support</Text>
          </View>
          <View style={{ ...legendStyles.lineContainer, left: "128.3%" }}>
            <View style={legendStyles.line} />
          </View>
        </View>
        <View style={cycleStyles.row}>
          <View style={cycleStyles.emptyBox}></View>
          <View style={cycleStyles.swingBox}>
            <Text>Left Swing Phase</Text>
            <Text>
              {metrics.strideTimeLeft?.toFixed(2)} s (
              {(
                metrics.strideTimeLeft /
                (metrics.stanceTimeLeft + metrics.strideTimeLeft)
              )?.toFixed(2)}{" "}
              %)
            </Text>
          </View>
          <View
            style={{
              ...cycleStyles.stanceBox,
              backgroundColor: colors.green,
            }}
          >
            <Text>Left Stance Phase</Text>
            <Text>
              {metrics.stanceTimeLeft?.toFixed(2)} s (
              {(
                metrics.stanceTimeLeft /
                (metrics.stanceTimeLeft + metrics.strideTimeLeft)
              )?.toFixed(2)}{" "}
              %)
            </Text>
          </View>
        </View>
        <View style={legendStyles.container}>
          <Text
            style={{
              ...legendStyles.percentageText,
              ...legendStyles.zeroPercent,
              color: colors.green,
              left: "6%",
            }}
          >
            0%
          </Text>
          <Text
            style={{
              ...legendStyles.percentageText,
              ...legendStyles.hundredPercent,
              color: colors.green,
              left: "64%",
            }}
          >
            100%
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          {base64DoctorMetricsImage && (
            <Image
              src={doctorMetricsImage}
              style={{ width: "90%" }} // Adjust these values based on actual aspect ratios
            />
          )}
        </View>
        <Footer
          patientName={patientInfo.name}
          page={2}
          date={new Date().toLocaleString()}
        />
      </Page>
      {pressureMapImage === "data:," && pressureImage === "data:," ? null : (
        <Page size="A4">
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 60,
            }}
          >
            {base64PressureMapImage && (
              <Image
                src={base64PressureMapImage}
                style={{ width: "100%" }} // Adjust these values based on actual aspect ratios
              />
            )}
            {base64PressureImage && (
              <Image
                src={base64PressureImage}
                style={{ width: "70%" }} // Adjust these values based on actual aspect ratios
              />
            )}
          </View>
          <Spacer height={40} />
          <Footer
            patientName={patientInfo.name}
            page={3}
            date={new Date().toLocaleString()}
          />
        </Page>
      )}
    </Document>
  );
}
