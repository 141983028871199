import * as React from "react";
import InputBarComponent from "./InputBarComponent";
import { Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/system";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import OtpInput from "react-otp-input";
import { useLocation } from "react-router-dom";
import { sendOtp, updatePwd, verifyOtp } from "../redux/actions/forgotpwd";
import { useTranslation } from "react-i18next";

export default function FpComponent({ fp, setFp, setOpen }) {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const [type, setType] = useState("");
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState("");
  const [stage, setStage] = useState(0);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const { state } = useLocation();
  console.log("Params::", state);
  const onSubmit = (data) => {
    setLoad(true);
    setEmail(data.email);
    console.log("Name is ::" + JSON.stringify(data));
    sendOtp(data.email, setLoad, setMsg, setType, setStage);
  };
  const onSubmit2 = (data) => {
    setLoad(true);
    console.log("Name is ::" + JSON.stringify(data));
    verifyOtp(email, otp, setLoad, setMsg, setType, setStage);
  };
  const onSubmit3 = (data) => {
    setLoad(true);
    if (data.pwd1 !== data.pwd2) {
      setType("error");
      setMsg("Passwords didnt matched");
      setLoad(false);
    } else
      updatePwd(email, data.pwd1, setLoad, setMsg, setType, setOpen, setFp);
  };
  return (
    <Box>
      <Snackbar
        open={msg !== ""}
        autoHideDuration={60000}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        onClose={() => {
          setMsg("");
        }}
      >
        <Alert severity={type}> {msg} </Alert>{" "}
      </Snackbar>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 10,
            maxheight: "53%",
            width: "95vw",
            padding: 2,
          },
        }}
        scroll="body"
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          backgroundColor: "rgb(242, 247, 247,0.5)",
        })}
        open={fp}
        onClose={() => setFp(false)}
      >
        {stage == 0 && (
          <>
            <DialogTitle
              sx={{
                fontSize: 18,
              }}
            >
              {" "}
              {t("fpComponent.title")}{" "}
              <span
                style={{
                  float: "right",
                  color: "grey",
                  cursor: "pointer",
                }}
                onClick={() => setFp(false)}
              >
                & #x2715;{" "}
              </span>{" "}
            </DialogTitle>{" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent
                style={{
                  overflow: "hidden",
                }}
              >
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputBarComponent
                      name="email"
                      label={t("ProfileComponent.email")}
                      type="email"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />{" "}
              </DialogContent>{" "}
              <DialogActions
                disableSpacing={true}
                sx={{
                  justifyContent: "center",
                  height: "10%",
                }}
              >
                {load ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: 20,
                        backgroundColor: "#FF592C",
                      }}
                    >
                      {t("ChatScreen.send")}{" "}
                    </Button>{" "}
                  </>
                )}{" "}
              </DialogActions>{" "}
            </form>{" "}
          </>
        )}{" "}
        {stage == 1 && (
          <>
            <DialogTitle
              sx={{
                fontSize: 18,
              }}
            >
              {" "}
              {t("fpComponent.verfyOtp")}{" "}
              <span
                style={{
                  float: "right",
                  color: "grey",
                  cursor: "pointer",
                }}
                onClick={() => setFp(false)}
              >
                & #x2715;{" "}
              </span>{" "}
            </DialogTitle>{" "}
            <form onSubmit={handleSubmit(onSubmit2)}>
              <DialogContent
                style={{
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    paddingBottom: 8,
                  }}
                >
                  <center> {t("fpComponent.verifyAction")} </center>{" "}
                </div>{" "}
                <OtpInput
                  value={otp}
                  onChange={(o) => {
                    setOtp(o);
                  }}
                  numInputs={6}
                  inputStyle={{
                    border: "1px solid black",
                    borderRadius: "0.5rem",
                    height: "2.5rem",
                    width: "80%",
                    marginLeft: "10%",
                  }}
                />{" "}
              </DialogContent>{" "}
              <DialogActions
                disableSpacing={true}
                sx={{
                  justifyContent: "center",
                  height: "10%",
                }}
              >
                {load ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: 20,
                        backgroundColor: "#FF592C",
                      }}
                    >
                      {t("fpComponent.verify")}{" "}
                    </Button>{" "}
                  </>
                )}{" "}
              </DialogActions>{" "}
            </form>{" "}
          </>
        )}{" "}
        {stage == 2 && (
          <>
            <DialogTitle
              sx={{
                fontSize: 18,
              }}
            >
              {" "}
              {t("welcomeView.newPassword")}{" "}
              <span
                style={{
                  float: "right",
                  color: "grey",
                  cursor: "pointer",
                }}
                onClick={() => setFp(false)}
              >
                & #x2715;{" "}
              </span>{" "}
            </DialogTitle>{" "}
            <form onSubmit={handleSubmit(onSubmit3)}>
              <DialogContent
                style={{
                  overflow: "hidden",
                }}
              >
                <Controller
                  name="pwd1"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputBarComponent
                      name="pwd1"
                      label={t("welcomeView.newPassword")}
                      type="password"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />{" "}
                <Controller
                  name="pwd2"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputBarComponent
                      name="pwd2"
                      label={t("fpComponent.confirmPass")}
                      type="password"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />{" "}
              </DialogContent>{" "}
              <DialogActions
                disableSpacing={true}
                sx={{
                  justifyContent: "center",
                  height: "10%",
                }}
              >
                {load ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: 20,
                        backgroundColor: "#FF592C",
                      }}
                    >
                      {t("fpComponent.update")}{" "}
                    </Button>{" "}
                  </>
                )}{" "}
              </DialogActions>{" "}
            </form>{" "}
          </>
        )}{" "}
      </Dialog>{" "}
    </Box>
  );
}
