function dotProduct(v1, v2) {
    return v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2];
}

function vectorMagnitude(v) {
    return Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
}

function angleBetweenVectors(v1, v2) {
    const dot = dotProduct(v1, v2);
    const magnitudeV1 = vectorMagnitude(v1);
    const magnitudeV2 = vectorMagnitude(v2);
    const cosTheta = dot / (magnitudeV1 * magnitudeV2);
    const angleRad = Math.acos(cosTheta);
    return (angleRad * 180) / Math.PI;
}

export function angleBetweenTwoConnectedLines(p1, p2, p3) {
    const v1 = [p2.x - p1.x, p2.y - p1.y, p2.z - p1.z];
    const v2 = [p3.x - p2.x, p3.y - p2.y, p3.z - p2.z];
    return angleBetweenVectors(v1, v2);
}

export function drawAngle(
    ctx,
    angle,
    joint,
    left,
    color = 'white',
    lineWidth = 7
) {
    ctx.beginPath();
    ctx.arc(joint.x, joint.y, 30, 0, (angle * Math.PI) / 180, false);
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.stroke();

    // Mirror text only
    ctx.save(); // Save current state
    ctx.scale(-1, 1); // Apply horizontal flip
    ctx.fillStyle = color;
    ctx.font = '38px sans-serif';
    const xPosition = left ? -joint.x - 90 : -joint.x + 90;
    ctx.fillText(`${Math.round(angle)}°`, xPosition, joint.y + 60); // Note the negative value for x-coordinate
    ctx.restore(); // Restore original state
}

export function displayFeedback(canvasCtx, text) {
    // Draw the red background for the incorrect count
    canvasCtx.fillStyle = '#F9C444';
    canvasCtx.fillRect(200, 365, 262, 30);

    // Draw the text with white font
    canvasCtx.fillStyle = 'black';
    canvasCtx.font = '20px sans-serif';
    canvasCtx.fillText(text, 210, 390);
}

export function displayTestFeedback(canvasCtx, text) {
    // Draw the red background for the incorrect count
    canvasCtx.fillStyle = '#F9C444';
    canvasCtx.fillRect(200, 365, 262, 140);

    // Draw the text with white font
    canvasCtx.fillStyle = 'black';
    canvasCtx.font = '34px sans-serif';
    canvasCtx.fillText(text, 210, 400);
}

export function findSideFromHorizontalPose(results) {
    if (results.poseLandmarks[24].x - results.poseLandmarks[12].x > 0) {
        return 'left';
    } else {
        return 'right';
    }
}

export function findSideFromSittingPose(results) {
    if (results.poseLandmarks[28].x - results.poseLandmarks[24].x > 0) {
        return 'left';
    } else {
        return 'right';
    }
}

export function checkIfSide(results, threshold = 2) {
    let dist_z = Math.abs(
        Math.round(results.poseLandmarks[23].z * 1000) -
        Math.round(results.poseLandmarks[24].z * 1000)
    );
    let dist_x = Math.abs(
        Math.round(results.poseLandmarks[23].x) -
        Math.round(results.poseLandmarks[24].x)
    );
    let sideIndicator = Math.abs(dist_z / dist_x);
    if (sideIndicator === Infinity) {
        sideIndicator = 0;
    }
    return sideIndicator > threshold;
}

export function checkIfRightSide(results, legSide) {
    if (legSide === 'left') {
        return results.poseLandmarks[24].z > 0;
    } else {
        return results.poseLandmarks[24].z < 0;
    }
}

export function findMiddlePoint(p1, p2) {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
        z: (p1.z + p2.z) / 2,
    };
}

export function computeRotationAngle(midPoint, leftHip, rightHip) {
    const rightHipDistance = Math.abs(midPoint.x - rightHip.x);
    const rightZDist = Math.abs(midPoint.z - rightHip.z) * 1000;
    let rightHipAngle = Math.atan2(rightHipDistance, rightZDist);

    rightHipAngle *= 180 / Math.PI;
    if (leftHip.z < 0) {
        rightHipAngle = -rightHipAngle + 180;
    }
    return rightHipAngle;
}