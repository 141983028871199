import React, { useEffect, useRef } from "react";
import foot_lt from "../../../assets/GaitAnalysis/foot_lt.png";
import foot_rt from "../../../assets/GaitAnalysis/foot_rt.png";
import simpleHeat from "./SimpleHeat";
import BalanceInfo from "./BalanceInfo";

export const zonesLeft = {
  toes: {
    x: 40,
    y: 45,
  },
  hallux: {
    x: 100,
    y: 35,
  },
  front: {
    x: 80,
    y: 100,
  },
  front_2: {
    x: 30,
    y: 120,
  },
  mid: {
    x: 30,
    y: 170,
  },
  arch: {
    x: 50,
    y: 180,
  },
  heel2: {
    x: 50,
    y: 260,
  },
  heel: {
    x: 30,
    y: 280,
  },
};
const zonesRight = {
  toes: {
    x: 210,
    y: 45,
  },
  hallux: {
    x: 150,
    y: 35,
  },
  front: {
    x: 170,
    y: 100,
  },
  front_2: {
    x: 220,
    y: 120,
  },
  mid: {
    x: 220,
    y: 170,
  },
  arch: {
    x: 200,
    y: 180,
  },
  heel2: {
    x: 200,
    y: 260,
  },
  heel: {
    x: 220,
    y: 280,
  },
};

export function toPercent(value) {
  return `${(value * 100).toFixed(1)}%`;
}

const FootMap = ({ sensorData, generalResults }) => {
  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  let { meanLeft = 0, meanRight = 0 } = Object.keys(sensorData || {}).length
    ? sensorData
    : generalResults?.sensorData || {};
  const maxValue = Math.max(
    ...Object.keys(zonesLeft).map((key) => meanLeft[key]),
    ...Object.keys(zonesRight).map((key) => meanRight[key])
  );
  const balance = 1 - meanLeft.balance_sum / meanRight.balance_sum;

  useEffect(() => {
    drawCanvas();
    window.addEventListener("resize", () => {
      drawCanvas();
    });
  }, [zonesLeft, zonesRight, meanLeft, meanRight]);

  if (!meanLeft || !meanRight) {
    if (!meanLeft || !meanRight)
      return <div> No Data Sensor Collected... </div>;
  }
  // Assuming canvas is your canvas element
  function resizeCanvasToDisplaySize(canvas) {
    const { width, height } = canvas.getBoundingClientRect() || {};
    const dpr = window.devicePixelRatio || 1;
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    const ctx = canvas.getContext("2d");
    ctx.scale(dpr * 2, dpr * 2); // scale context after setting canvas size
    return ctx;
  }

  function drawCanvas() {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = resizeCanvasToDisplaySize(canvas);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const heat = simpleHeat(canvas);
    heat.clear();
    // console.log(maxValue);
    heat.max(maxValue * 0.6 || 10);
    heat.globalAlpha = 0.7;

    Object.keys(zonesLeft).forEach((key) => {
      heat.add([zonesLeft[key].x, zonesLeft[key].y, meanLeft[key] || 0]);
    });
    Object.keys(zonesRight).forEach((key) => {
      heat.add([zonesRight[key].x + 30, zonesRight[key].y, meanRight[key]]);
    });
    heat.radius(28, 25);
    heat.draw(0);
  }

  return (
    <div>
      <div>
        <div
          style={{
            position: "absolute",
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <img
            ref={imageRef}
            src={foot_lt}
            alt="Foot Sole Left"
            style={{
              marginTop: 0,
              marginBottom: 0,
              height: "600px",
            }}
          />{" "}
          <img
            ref={imageRef}
            src={foot_rt}
            alt="Foot Sole Right"
            style={{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: "100px",
              height: "600px",
            }}
          />{" "}
        </div>{" "}
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginTop: 0,
            marginBottom: 0,
            height: "600px",
            width: "600px",
          }}
        />{" "}
      </div>{" "}
      <div
        style={{
          height: "600px",
          marginRight: "0px",
        }}
      >
        {" "}
      </div>{" "}
      <div
        style={{
          position: "relative",
        }}
      >
        <BalanceInfo balance={balance} />{" "}
      </div>{" "}
    </div>
  );
};

export default FootMap;
