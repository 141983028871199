import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useContext } from "react";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import { useWebcamSettings } from "../../../../../hooks/webcam";
import {
  addAngle,
  updateAngle,
} from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import FeedbackGenerator from "../../Generators/FeedbackGenerator";
import BasicSelect from "../BasicSelect";
import LeftRightConfig from "./LeftRightConfig";
import OneSideConfig from "./OneSideConfig";

export default function AngleConfigurator({
  onClose,
  angleData,
  addAngleToList,
  updateAngleInList,
}) {
  const [name, setName] = React.useState(angleData.name || "");
  const [description, setDescription] = React.useState(
    angleData.description || ""
  );
  const [type, setType] = React.useState(angleData.type || "");
  const [saving, setSaving] = React.useState(false);
  const [id, setId] = React.useState(angleData.id || null);
  const [angleCptConfig, setAngleCptConfig] = React.useState(
    angleData.angleCptConfig || {}
  );
  const [testForceSide, setTestForceSide] = React.useState("left");
  const { updateWebcamSettings } = useWebcamSettings();
  const { user } = useContext(FirebaseAuthContext);
  const buttonText = id ? "Update" : "Create";

  React.useEffect(() => {
    updateWebcamSettings(false);
  }, []);

  const angleCptFunctionConfigDone = () => {
    if (type === "Front") {
      return (
        angleCptConfig.function &&
        angleCptConfig.functionConfig &&
        angleCptConfig.functionConfig.coord0 &&
        angleCptConfig.functionConfig.coord1
      );
    }
    if (type === "Side") {
      return (
        angleCptConfig.left?.left?.function &&
        angleCptConfig.left?.left?.functionConfig &&
        angleCptConfig.left?.left?.functionConfig.coord0 &&
        angleCptConfig.left?.left?.functionConfig.coord1 &&
        (angleCptConfig.left?.left?.functionConfig.coord2 ||
          angleCptConfig.left?.left?.function === "simpleAngle") &&
        angleCptConfig.right?.right?.function &&
        angleCptConfig.right?.right?.functionConfig &&
        angleCptConfig.right?.right?.functionConfig.coord0 &&
        angleCptConfig.right?.right?.functionConfig.coord1 &&
        (angleCptConfig.right?.right?.functionConfig.coord2 ||
          angleCptConfig.right?.right?.function === "simpleAngle")
      );
    }
  };

  const saveAngle = () => {
    setSaving(true);
    const newAngleData = {
      ...angleData,
      name,
      id,
      description,
      type: type,
      angleCptConfig: angleCptConfig,
    };
    if (id) {
      updateAngle({ user, angleData: newAngleData }).then(() => {
        setSaving(false);
        updateAngleInList({ ...newAngleData, id });
      });
    } else {
      addAngle({ user, angleData: newAngleData }).then((angle) => {
        setSaving(false);
        setId(angle.id);
        addAngleToList({ ...newAngleData, id: angle.id });
      });
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant={"h3"} sx={{ mb: 2 }}>
        Angle Configurator
      </Typography>
      <Grid container spacing={2} direction={"column"}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={4}
            minRows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item={6}>
          <BasicSelect
            label={"Type"}
            value={type}
            setValue={(value) => setType(value)}
            options={["Front", "Side"]}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {type === "Front" && (
          <OneSideConfig
            config={angleCptConfig}
            setConfig={(value) => {
              setAngleCptConfig(value);
            }}
          />
        )}{" "}
        {type === "Side" && (
          <LeftRightConfig
            config={angleCptConfig}
            setConfig={(value) => {
              setAngleCptConfig(value);
            }}
          />
        )}
        {type === "Side" && (
          <Grid item xs={4}>
            <BasicSelect
              label={"Test Force Side"}
              value={testForceSide}
              setValue={(value) => setTestForceSide(value)}
              options={["left", "right", "undefined"]}
            />
          </Grid>
        )}
      </Grid>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          // className="box1"
          style={{
            position: "relative", // Add this line
            overflow: "hidden",
            backgroundColor: "black",
            width: "70%",
            transform: "scaleX(-1)",
          }}
        >
          <FeedbackGenerator
            updateFeedback={() => {}}
            config={{
              view: type.toLowerCase(),
              showBackbones: false,
              angles: [],
              states: [],
              feedback: [],
              angleConfigs: [
                {
                  ...angleData,
                  forceSide: testForceSide !== "undefined" && testForceSide,
                  name,
                  id,
                  description,
                  type: type,
                  angleCptConfig: angleCptConfig,
                },
              ],
            }}
            finishedIntro={{ current: true }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
          marginRight: 40,
        }}
      >
        <Button
          variant={"contained"}
          disabled={
            saving ||
            name.length === 0 ||
            description.length === 0 ||
            !angleCptFunctionConfigDone()
          }
          onClick={saveAngle}
        >
          {saving ? <CircularProgress /> : buttonText}
        </Button>
      </div>
    </div>
  );
}
