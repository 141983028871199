const initialState = () => {
  return {};
};

const initialStateResults = initialState();

export const bergBalanceResultsReducer = (
  state = initialStateResults,
  action
) => {
  switch (action.type) {
    case "ADD-BERG-BALANCE-RESULTS": {
      return { ...state, ...action.payload };
    }
    case "SET-BERG-BALANCE-RESULTS": {
      return { ...state, ...action.payload };
    }
    case "RESET-BERG-BALANCE-RESULTS":
      return initialStateResults;
    default: {
      return state;
    }
  }
};

export const bergBalanceLastResultsReducer = (
  state = {
    docs: [],
  },
  action
) => {
  switch (action.type) {
    case "SET-LAST-BERG-BALANCE-RESULTS": {
      return { ...state, ...action.payload };
    }
    case "RESET-LAST-BERG-BALANCE-RESULTS":
      return {
        docs: [],
      };
    default: {
      return state;
    }
  }
};
