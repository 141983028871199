const initialState = {
  values: {
    lives: 3,
  },
};

export const livesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE-LIVES-OBSERVATION": {
      if (action.payload.lives >= 0) {
        return {
          values: { ...state.values, ...action.payload },
        };
      }
      return state;
    }
    case "RESET-LIVES-OBSERVATION":
      return initialState;
    default: {
      return state;
    }
  }
};
