const initialState = {
  values: {
    useOffset: true,
  },
};

export const webcamReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE-WEBCAM-SETTINGS": {
      return {
        values: { ...state.values, ...action.payload },
      };
    }
    case "RESET-WEBCAM-SETTINGS":
      console.log("resetting webcam settings");
      return initialState;
    default: {
      return state;
    }
  }
};
