import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { cptRWUDistance } from "../../utilities/Demo/ExerciseManagement/Generators/rWUDistance";
import { safeIterScore, useTimer } from "./sharedLogic";
import {
  useBalanceFeedback,
  useCountsInTimeFeedback,
  useStretchCountFeedback,
  useStretchFeedback,
} from "./visualFeedback";
import { useFeedbackBasics } from "./feedbackBasics";

export function useBalanceCoreFeedback({
  onComplete,
  timeThresh,
  loadAudio,
  maxScore = 5,
}) {
  const dispatch = useDispatch();
  const incorrectCount = useRef(0);
  const holdsPose = useRef(false);
  const secondsPoseHeld = useRef(0);
  const secondPoseHeldStarted = useRef(0);
  const secondsPoseHeldComplete = useRef(0);
  const firstTimePoseHeldCorrectly = useRef(true);

  const {
    updateFeedback,
    updateInterruptions,
    updateSecondsPoseHeld,
    updateTime,
  } = useBalanceFeedback();
  const seconds = useTimer((seconds) => {
    if (started.current) {
      updateTime(seconds.current - startingTime.current);
      const score = cptScore.current();
      raiseLevel(score);
      safeIterScore(dispatch, score);
      if (score >= maxScore) {
        setTimeout(() => {
          onComplete();
        }, 500);
      }
      if (seconds.current - startingTime.current >= timeThresh) {
        onComplete();
      }
    }
  });
  const {
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    cptScore,
    raiseLevel,
    startingTime,
    started,
    finishedIntro,
  } = useFeedbackBasics({
    loadAudio,
    seconds,
    maxScore,
  });

  function updatePoseHeldDeps(state) {
    if (state === true && holdsPose.current === false) {
      incorrectCount.current += 1;
      secondsPoseHeldComplete.current += secondsPoseHeld.current;
      updateInterruptions(incorrectCount.current);
      secondsPoseHeld.current = 0;
    } else if (state === false && holdsPose.current === true) {
      secondPoseHeldStarted.current = seconds.current;
      if (firstTimePoseHeldCorrectly.current) {
        firstTimePoseHeldCorrectly.current = false;
        playAudio(audioFeedback.current?.thatIsPerfect, true);
      } else {
        playAudio(audioFeedback.current?.achievement, false, 3);
      }
      setStartingTime(seconds);
    } else if (state === true && holdsPose.current === true) {
      secondsPoseHeld.current = seconds.current - secondPoseHeldStarted.current;
    }
  }

  return {
    incorrectCount,
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateInterruptions,
    updateSecondsPoseHeld,
    updateTime,
    seconds,
    playAudio,
    startingTime,
    audioFeedback,
    setStartingTime,
    started,
    raiseLevel,
    setSide,
    cptScore,
    finishedIntro,
    updatePoseHeldDeps,
  };
}

export function useCountsInTimeCoreFeedback({
  onComplete,
  timeThresh,
  loadAudio,
  maxScore = 5,
}) {
  const dispatch = useDispatch();
  const correctCount = useRef(0);
  const correctCountLeft = useRef(0);
  const correctCountRight = useRef(0);
  const incorrectCount = useRef(0);

  useEffect(() => {
    return () => {
      seconds.current = 0;
      correctCount.current = 0;
      correctCountLeft.current = 0;
      correctCountRight.current = 0;
      incorrectCount.current = 0;
      startingTime.current = 0;
    };
  }, []);

  const seconds = useTimer((seconds) => {
    if (started.current) {
      updateTime(seconds.current - startingTime.current);
      const score = cptScore.current();
      raiseLevel(score);
      safeIterScore(dispatch, score);
      if (seconds.current - startingTime.current >= timeThresh) {
        safeIterScore(dispatch, score);
        onComplete();
      }
    }
  });

  const { updateFeedback, updateCounts, updateTime } =
    useCountsInTimeFeedback();

  const {
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    cptScore,
    raiseLevel,
    startingTime,
    started,
    finishedIntro,
  } = useFeedbackBasics({
    loadAudio,
    seconds,
    maxScore,
  });

  return {
    correctCount,
    incorrectCount,
    raiseLevel,
    updateFeedback,
    updateCounts,
    updateTime,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    startingTime,
    setSide,
    cptScore,
    finishedIntro,
    correctCountLeft,
    correctCountRight,
  };
}

export function useStretchCoreFeedback({
  onComplete,
  timeThresh,
  loadAudio,
  calculatePoints,
  min = true,
  maxScore = 5,
}) {
  const dispatch = useDispatch();
  const correctCount = useRef(0);
  const incorrectCount = useRef(0);
  const bestValue = useRef(min ? 360 : 0);
  const candidates = useRef([]);

  const minCondition = () => {
    const maxMinCandidate = Math.max(...candidates.current);
    if (maxMinCandidate < bestValue.current && maxMinCandidate > 0) {
      updateBestValue(maxMinCandidate);
      bestValue.current = maxMinCandidate;
      const score = calculatePoints(bestValue.current);
      safeIterScore(dispatch, score);
      if (!raiseLevel(score)) {
        playAudio(audioFeedback.current?.achievement, true);
      }
      console.log(score, maxScore, "SCORE");
      if (score >= maxScore) {
        setTimeout(() => {
          onComplete();
        }, 500);
      }
    }
  };

  const maxCondition = () => {
    const minMaxCandidate = Math.min(...candidates.current);
    if (minMaxCandidate > bestValue.current && minMaxCandidate !== Infinity) {
      updateBestValue(minMaxCandidate);
      bestValue.current = minMaxCandidate;
      const score = calculatePoints(bestValue.current);
      safeIterScore(dispatch, score);
      if (!raiseLevel(score)) {
        playAudio(audioFeedback.current?.achievement, true);
      }
    }
  };

  const seconds = useTimer((seconds) => {
    if (!started.current) return;
    updateTime(seconds.current - startingTime.current);
    if (seconds.current % 2 === 0) {
      if (min) {
        minCondition();
      } else {
        maxCondition();
      }
      candidates.current = [];
    }
    if (seconds.current - startingTime.current >= timeThresh) {
      console.log(timeThresh, "timeThresh");
      onComplete();
    }
  });

  const { updateFeedback, updateBestValue, updateTime } = useStretchFeedback();

  const {
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    cptScore,
    raiseLevel,
    startingTime,
    started,
    finishedIntro,
  } = useFeedbackBasics({
    loadAudio,
    seconds,
    maxScore,
  });

  return {
    correctCount,
    incorrectCount,
    raiseLevel,
    updateFeedback,
    updateBestValue,
    updateTime,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    startingTime,
    setSide,
    cptScore,
    candidates,
    finishedIntro,
  };
}

export function useStretchCountCoreFeedback({
  onComplete,
  timeThresh,
  loadAudio,
  maxScore = 5,
  clampBestValue = (value) => value,
}) {
  const dispatch = useDispatch();
  const correctCount = useRef(0);
  const incorrectCount = useRef(0);
  const bestValues = useRef([]);
  const candidates = useRef([]);
  const candidateTimes = useRef([]);
  const lowering = useRef(false);
  const stretchTimes = useRef([]);
  const framingMotionCandidates = useRef([]);
  const upThreshold = 20;

  function addCandidate(candidate, framingMotionCandidate) {
    candidates.current.push(candidate);
    candidateTimes.current.push(Date.now());
    framingMotionCandidates.current.push(framingMotionCandidate);
  }

  function computeStretchTime() {
    let minTimeIndex = framingMotionCandidates.current.findIndex(
      (el) => el > upThreshold
    );
    if (minTimeIndex >= 0) {
      minTimeIndex = minTimeIndex - 1;
    }
    const minTime = candidateTimes.current[minTimeIndex];
    const maxTime = candidateTimes.current[candidates.current.length - 1];
    const stretchTime = maxTime - minTime;
    stretchTimes.current.push(stretchTime / 1000);
    console.log(
      "computing stretch time",
      stretchTime,
      candidateTimes.current,
      minTimeIndex
    );
  }

  const motionDownCondition = () =>
    framingMotionCandidates.current.slice(-1)[0] < 1;
  const motionUpCondition = () =>
    framingMotionCandidates.current.slice(-1)[0] > upThreshold;

  const condition = ({
    results,
    personHeightPixels,
    personHeightRWU,
    motion = false,
  }) => {
    let maxFramingCandidate = Math.max(...framingMotionCandidates.current);
    let minFramingCandidate = Math.min(...framingMotionCandidates.current);
    let maxCandidate = Math.max(...candidates.current);
    let minCandidate = Math.min(...candidates.current);

    if (lowering.current) {
      // console.log(
      //   'down',
      //   // maxCandidate,
      //   // minCandidate,
      //   // candidates.current,
      //   minFramingCandidate,
      //   maxFramingCandidate,
      //   framingMotionCandidates.current
      // );
      let down = false;
      if (motion) {
        if (motionDownCondition()) {
          down = true;
        }
      } else if (
        maxFramingCandidate - framingMotionCandidates.current.slice(-1)[0] >
        10
      ) {
        down = true;
      }
      if (down) {
        let bestValueCandidate;
        if (personHeightPixels) {
          bestValueCandidate = cptRWUDistance({
            stretchLengthPixels: maxCandidate - minCandidate,
            personHeightRWU,
            personHeightPixels,
            results,
          });
        } else {
          if (personHeightPixels) {
            bestValueCandidate = maxCandidate - minCandidate;
          } else {
            bestValueCandidate = maxCandidate;
          }
        }
        bestValues.current.push(clampBestValue(bestValueCandidate));
        updateBestValue(bestValues.current);
        // candidates.current = [];
        lowering.current = true;
        correctCount.current += 1;
        updateCounts(correctCount.current);
        setStartingTime(seconds);

        lowering.current = false;
        computeStretchTime({
          maxFramingCandidate,
          minFramingCandidate,
        });
        updateFeedback("end");
        updateResults({
          stretchTimes: stretchTimes.current,
          units: bestValues.current,
        });
        candidates.current = [];
        framingMotionCandidates.current = [];
        candidateTimes.current = [];
        const score = cptScore.current();
        safeIterScore(dispatch, score);
        if (!raiseLevel(score)) {
          playAudio(audioFeedback.current?.achievement, true);
        }
        if (score >= maxScore) {
          setTimeout(() => {
            onComplete();
          }, 500);
        }
        return true;
      }
    } else {
      // console.log(
      //   'up',
      //   maxFramingCandidate - framingMotionCandidates.current.slice(-1)[0],
      //   maxFramingCandidate,
      //   framingMotionCandidates.current.slice(-1)[0],
      //   framingMotionCandidates.current
      // );
      if (motion) {
        if (motionUpCondition()) {
          lowering.current = true;
        }
      } else {
        if (
          maxFramingCandidate - framingMotionCandidates.current.slice(-1)[0] >
          upThreshold
        ) {
          lowering.current = true;
        }
      }
    }
  };

  const {
    updateFeedback,
    updateBestValue,
    updateTime,
    updateCounts,
    updateResults,
  } = useStretchCountFeedback();

  const {
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    started,
    seconds,
    cptScore,
    raiseLevel,
    startingTime,
    finishedIntro,
  } = useFeedbackBasics({
    loadAudio,
    maxScore,
  });

  useEffect(() => {
    // updateTime(seconds.current - startingTime.current);
    if (!started.current) return;
    if (seconds.current - startingTime.current >= timeThresh) {
      onComplete();
    } else {
      updateTime(seconds.current - startingTime.current);
    }
  }, [seconds?.current]);

  return {
    correctCount,
    incorrectCount,
    raiseLevel,
    updateFeedback,
    updateBestValue,
    updateTime,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    startingTime,
    setSide,
    cptScore,
    candidates,
    finishedIntro,
    condition,
    updateCounts,
    addCandidate,
  };
}
