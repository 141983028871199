import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import BasicSelect from "./BasicSelect";
import Counts from "./ScoreConfigs/Counts";
import MaxStretch from "./ScoreConfigs/MaxStretch";
import PoseHeld from "./ScoreConfigs/PoseHeld";

export default function ScoreConfig({
  exerciseType = "balance",
  config,
  setConfig,
}) {
  const scoreTypeOptions = () => {
    switch (exerciseType) {
      case "count":
        return [
          { id: "counts", name: "Counts" },
          { id: "countsInTime", name: "Counts in Time" },
        ];
      case "balance":
        return [{ id: "poseHeld", name: "Pose Held" }];
      case "stretch":
        return [{ id: "maxStretch", name: "Max Stretch" }];
      case "stretchCount":
        return [
          { id: "maxStretch", name: "Max Stretch" },
          { id: "counts", name: "Counts" },
          { id: "countsInTime", name: "Counts in Time" },
        ];
    }
    if (exerciseType === "count") {
      return ["counts", "countsInTime"];
    }
  };
  const [configurator, setConfigurator] = React.useState(<></>);
  const [cptConfig, setCptConfig] = React.useState(config?.cptConfig || {});
  const scoreType = React.useRef(config?.scoreType || scoreTypeOptions()[0].id);

  React.useEffect(() => {
    function onCptConfigChange(newConfig) {
      setCptConfig(newConfig);
      setConfig({ ...config, cptConfig: newConfig });
    }

    switch (scoreType.current) {
      case "counts":
        setConfigurator(
          <Counts
            key={scoreType.current}
            config={cptConfig}
            setConfig={onCptConfigChange}
            inTime={false}
          />
        );
        break;
      case "countsInTime":
        setConfigurator(
          <Counts
            key={scoreType.current}
            config={cptConfig}
            setConfig={onCptConfigChange}
            inTime={true}
          />
        );
        break;
      case "poseHeld":
        setConfigurator(
          <PoseHeld
            key={scoreType.current}
            config={cptConfig}
            setConfig={onCptConfigChange}
          />
        );
        break;
      case "maxStretch":
        setConfigurator(
          <MaxStretch
            key={scoreType.current}
            config={cptConfig}
            setConfig={onCptConfigChange}
          />
        );
        break;
      default:
        setConfigurator(<></>);
    }
  }, [scoreType.current, cptConfig, config]); // dependency array);
  return (
    <Box sx={{ padding: 4 }}>
      <h3>ScoreConfig</h3>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <BasicSelect
            name="Score Type"
            value={scoreType.current || scoreTypeOptions()[0].id}
            options={scoreTypeOptions()}
            setValue={(value) => {
              scoreType.current = value;
              setConfig({ ...config, scoreType: value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {configurator}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Max Score"
            variant="outlined"
            type="number"
            value={config?.maxScore}
            onChange={(e) => setConfig({ ...config, maxScore: e.target.value })}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
