import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { blue } from "@mui/material/colors";
import FirebaseAuthContext from "../../../../contexts/auth/FirebaseAuthContext";
import {
  addTag,
  getTags,
} from "../../../../utilities/Demo/ExerciseManagement/firestore";
import CloseBadge from "./CloseBadge";

export default function TagDialog({ setTag, open, onClose, type }) {
  const [searchValue, setSearchValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const { user } = React.useContext(FirebaseAuthContext);

  React.useEffect(() => {
    setLoading(true);
    getTags({ searchTerm: searchValue, type: type }).then((tags) => {
      setTags(tags || []);
      setLoading(false);
    });
  }, [searchValue]);

  const handleListItemClick = (value) => {
    setTag((prev) => [...prev, value]);
  };

  return (
    <Dialog open={open} sx={{ padding: 3 }}>
      <Badge
        badgeContent={<CloseBadge setConfig={onClose} sx={{ mr: 5, mt: 5 }} />}
      >
        <Box sx={{ padding: 3 }}>
          <DialogTitle sx={{ mr: 5 }}>Select Tag</DialogTitle>
          <TextField
            autoFocus
            margin="dense"
            id="search"
            label="Search"
            type="search"
            fullWidth
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
          <CircularProgress sx={{ opacity: loading ? 1 : 0 }} />
          <List sx={{ pt: 0 }}>
            {tags.map((tag) => (
              <ListItem disableGutters key={tag.id}>
                <ListItemButton
                  onClick={() => handleListItemClick(tag)}
                  key={tag.id}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <LocalOfferIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={tag.name} />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem disableGutters key={"add"}>
              <ListItemButton
                onClick={() => {
                  if (searchValue?.length === 0) return;
                  setLoading(true);
                  addTag({ user, type, tagData: { name: searchValue } }).then(
                    (tag) => {
                      handleListItemClick(tag);
                      setLoading(false);
                      onClose();
                    }
                  );
                }}
                key={"add"}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#f1a60c", color: "#37a4fb" }}>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"create"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Badge>
    </Dialog>
  );
}
