import React from "react";
import { useState } from "react";
import LegendComponent from "../Highlights/LegendComponent";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "../Highlights/highlights.css";
import { PieChart } from "react-minimal-pie-chart";

const WorkOutScore = ({ points, indi, title = "Workout Score" }) => {
  const [hovered, setHovered] = useState(null);
  return (
    <div className="box--container bottom-box">
      <div className="top-bar">
        <div className="orange-box"></div>
        <h2>{title}</h2>
      </div>
      <div className="pie-chart" data-tip="" data-for="chart">
        <PieChart
          style={{ width: "100%", height: "100%" }}
          viewBoxSize={[100, 100]}
          center={[50, 50]}
          lineWidth={20}
          totalValue={27.5}
          rounded
          data={[
            { title: "Duration", value: points, color: "#CABDFF" },
            { title: "Total duration", value: 27.5 - points, color: "#B1E5FC" },
          ]}
          labelPosition={30}
          label={(prop) =>
            prop.dataEntry.title === "Duration" &&
            `${parseFloat(prop.dataEntry.value).toFixed(1)}`
          }
          labelStyle={{
            fontWeight: "bold",
          }}
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(null);
          }}
        />

        <ReactTooltip
          id="chart"
          place={"bottom"}
          backgroundColor={"#272B30"}
          textColor={"#EFEFEF"}
          multiline={true}
          getContent={() =>
            typeof hovered === "number" ? (
              <LegendComponent
                title={"Duration"}
                gcolor={indi[hovered].color}
                pts={indi[hovered].duration}
              />
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default WorkOutScore;
