// import * as React from "react";
// import { clearThreeJS } from "./threejs/ThreeJsLoader";
// import Toolbar from "@mui/material/Toolbar";
// import Container from "@mui/material/Container";
// import logo from "../assets/EXAR_logo.png";
// import exit from "../assets/exit.png";
// import {
//   CircularProgress,
//   ToggleButtonGroup,
//   ToggleButton,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { setWorkoutData } from "../redux/actions/setDatas";
// import { updateStudentPoints } from "../redux/actions/updatepoints";
// import { getDashboardData, getRankings } from "../redux/actions/getDashboard";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import Badge from "@mui/material/Badge";
// import Notification from "./Notification/Notification";
// import { io } from "socket.io-client";
// import { notifsocket } from "../service/socket";
// import { getClassHome, getClassProgress } from "../redux/actions/getClassHome";
// import { useTranslation } from "react-i18next";
// import enLogo from "../assets/en.svg";
// import deLogo from "../assets/de.svg";
// import "./header.css";

// const lngs = {
//   en: { nativeName: "EN", logo: enLogo },
//   de: { nativeName: "DE", logo: deLogo },
// };

// const HeaderComponent = ({ exer, sp, duration, pageName }) => {
//   const { i18n } = useTranslation();
//   const dispatch = useDispatch();
//   const [load, setLoad] = React.useState(false);
//   const [unread, setUnread] = React.useState(0);
//   const [socket, setSocket] = React.useState(notifsocket);
//   const [notif, setNotif] = React.useState([]);
//   const user = useSelector(({ user }) => user.values);
//   const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
//   const navigate = useNavigate();
//   React.useEffect(() => {
//     const newSocket = io(
//       `${process.env.REACT_APP_NOTIF_SOCKET}?token=${localStorage.getItem(
//         "token"
//       )}`
//     );
//     setSocket(newSocket);
//     newSocket.on("connect", () => {
//       console.log("CHat Socket::", newSocket.connected); // true
//       newSocket.emit("giveNotifs", "data");
//     });
//   }, [setSocket]);
//   socket &&
//     socket.on("receiveNotifs", (data) => {
//       console.log("Data rec::", data);
//       let dat = JSON.parse(data);
//       setUnread(dat.unread);
//       setNotif(dat.notification);
//     });
//   return (
//     <Container
//       maxWidth="xxl"
//       sx={{
//         // backgroundColor: bgcol ? bgcol : '#FCFCF9',
//         backgroundColor: pageName == "chat" ? "#fff" : "transparent",
//         display: "flex",
//         alignContent: "center",
//         position: "relative",
//         padding: ".5rem 0",
//       }}
//     >
//       <Toolbar
//         disableGutters
//         sx={{
//           justifyContent: "center",
//           maxWidth: "50%",
//           margin: "auto",
//           alignSelf: "center",
//         }}
//         elevation={0}
//       >
//         <img
//           className="exar-logo"
//           src={logo}
//           alt="Exar"
//           style={{ objectFit: "contain" }}
//         />
//       </Toolbar>
//       <div
//         className="header-profile-container"
//         style={{
//           display:
//             pageName == "welcome" || pageName == "exercise" ? "none" : "",
//         }}
//       >
//         <Badge
//           badgeContent={unread}
//           color="error"
//           overlap="circular"
//           sx={{
//             "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 },
//           }}
//         >
//           <NotificationsNoneIcon
//             className="badge-notification"
//             onClick={() => {
//               setIsNotificationOpen(!isNotificationOpen);
//               setUnread(0);
//               socket.emit("readNotifs", "data");
//             }}
//             style={{ cursor: "pointer", fontSize: "1.5rem" }}
//           />
//         </Badge>
//         <div className="avatar-icon" onClick={() => navigate("/profile")}>
//           <img
//             src={
//               user && user.profile_pic
//                 ? user.profile_pic
//                 : "https://exargermanybucket.s3.eu-west-3.amazonaws.com/placeholder.png"
//             }
//             style={{ objectFit: "cover" }}
//             alt=""
//           />
//         </div>
//         <div className="language-placement">
//           &nbsp; &nbsp;
//           {Object.keys(lngs).map((lng) => (
//             <ToggleButtonGroup
//               key={lng}
//               color="primary"
//               fullWidth
//               className="toggle-button-language"
//             >
//               <ToggleButton
//                 value={lng}
//                 onClick={() => i18n.changeLanguage(lng)}
//               >
//                 <img className="language-icons" src={lngs[lng].logo} alt="en" />
//                 {i18n.resolvedLanguage === lng ? "|" : ""}
//               </ToggleButton>
//             </ToggleButtonGroup>
//           ))}
//         </div>
//       </div>
//       {exer &&
//         (load ? (
//           <CircularProgress
//             color="success"
//             size={30}
//             style={{ margin: "auto 2rem auto 0" }}
//           />
//         ) : (
//           <img
//             src={exit}
//             alt="Exar"
//             className="exit-btn"
//             style={{
//               objectFit: "contain",
//               cursor: "pointer",
//               float: "right",
//               height: "50px",
//               // margin: 'auto 2rem auto 0',
//               position: "absolute",
//               right: "2rem",
//               top: "50%",
//               transform: "translateY(-50%)",
//             }}
//             onClick={async () => {
//               setLoad(true);
//               await updateStudentPoints(
//                 dispatch,
//                 { sweatpoints: sp, duration: duration },
//                 setLoad
//               );
//               await setWorkoutData(dispatch, sp, duration, setLoad);
//               await getRankings(dispatch, setLoad);
//               await getDashboardData(dispatch);
//               let { role } = JSON.parse(localStorage.getItem("user_data"));
//               if (role == "teacher") {
//                 await getClassProgress(dispatch, setLoad);
//                 await getClassHome(dispatch);
//               }
//               clearThreeJS();
//               navigate("/highlights", { state: { from: "header" } });
//             }}
//           />
//         ))}
//       <Notification
//         isNotificationOpen={isNotificationOpen}
//         socket={socket}
//         notif={notif}
//       />
//     </Container>
//   );
// };

// export default HeaderComponent;

import * as React from "react";
import { clearThreeJS } from "./threejs/ThreeJsLoader";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import logo from "../assets/EXAR_logo.png";
import exit from "../assets/exit.png";
import {
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setWorkoutData } from "../redux/actions/setDatas";
import { updateStudentPoints } from "../redux/actions/updatepoints";
import { getDashboardData, getRankings } from "../redux/actions/getDashboard";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Badge from "@mui/material/Badge";
import Notification from "./Notification/Notification";
import { useTranslation } from "react-i18next";
// import enLogo from "../assets/en.svg";
// import deLogo from "../assets/de.svg";
import "./header.css";

const lngs = {
  en: { nativeName: "EN", logo: "" },
  de: { nativeName: "DE", logo: "" },
};

const HeaderComponent = ({ exer, sp, duration, pageName }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [load, setLoad] = React.useState(false);
  const [unread, setUnread] = React.useState(0);
  const user = useSelector(({ user }) => user.values);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="xxl"
      sx={{
        // backgroundColor: bgcol ? bgcol : '#FCFCF9',
        backgroundColor: pageName == "chat" ? "#fff" : "transparent",
        display: "flex",
        alignContent: "center",
        position: "relative",
        padding: ".5rem 0",
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          justifyContent: "center",
          maxWidth: "50%",
          margin: "auto",
          alignSelf: "center",
        }}
        elevation={0}
      >
        <img
          className="exar-logo"
          src={logo}
          alt="Exar"
          style={{ objectFit: "contain" }}
        />
      </Toolbar>
      <div
        className="header-profile-container"
        style={{
          display:
            pageName == "welcome" || pageName == "exercise" ? "none" : "",
        }}
      >
        <Badge
          badgeContent={unread}
          color="error"
          overlap="circular"
          sx={{
            "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 },
          }}
        >
          <NotificationsNoneIcon
            className="badge-notification"
            onClick={() => {
              setIsNotificationOpen(!isNotificationOpen);
              setUnread(0);
            }}
            style={{ cursor: "pointer", fontSize: "1.5rem" }}
          />
        </Badge>
        <div className="avatar-icon" onClick={() => navigate("/profile")}>
          <img
            src={
              user && user.profile_pic
                ? user.profile_pic
                : "https://exargermanybucket.s3.eu-west-3.amazonaws.com/placeholder.png"
            }
            style={{ objectFit: "cover" }}
            alt=""
          />
        </div>
        <div className="language-placement">
          &nbsp; &nbsp;
          {Object.keys(lngs).map((lng) => (
            <ToggleButtonGroup
              key={lng}
              color="primary"
              fullWidth
              className="toggle-button-language"
            >
              <ToggleButton
                value={lng}
                onClick={() => i18n.changeLanguage(lng)}
              >
                <img className="language-icons" src={lngs[lng].logo} alt="en" />
                {i18n.resolvedLanguage === lng ? "|" : ""}
              </ToggleButton>
            </ToggleButtonGroup>
          ))}
        </div>
      </div>
      {exer &&
        (load ? (
          <CircularProgress
            color="success"
            size={30}
            style={{ margin: "auto 2rem auto 0" }}
          />
        ) : (
          <img
            src={exit}
            alt="Exar"
            className="exit-btn"
            style={{
              objectFit: "contain",
              cursor: "pointer",
              float: "right",
              height: "50px",
              // margin: 'auto 2rem auto 0',
              position: "absolute",
              right: "2rem",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={async () => {
              setLoad(true);
              await updateStudentPoints(
                dispatch,
                { sweatpoints: sp, duration: duration },
                setLoad
              );
              await setWorkoutData(dispatch, sp, duration, setLoad);
              await getRankings(dispatch, setLoad);
              await getDashboardData(dispatch);
              let { role } = JSON.parse(localStorage.getItem("user_data"));
              // if (role == "teacher") {
              //   await getClassProgress(dispatch, setLoad);
              //   await getClassHome(dispatch);
              // }
              clearThreeJS();
              navigate("/highlights", { state: { from: "header" } });
            }}
          />
        ))}
      <Notification
        isNotificationOpen={isNotificationOpen}
        notif={[]} // Placeholder for notif prop
      />
    </Container>
  );
};

export default HeaderComponent;
