import React, { useRef } from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useDispatch } from "react-redux";
import { useCountsInTimeCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import { safeIterScore } from "../../../hooks/Demo/sharedLogic";
import PoseDetection from "../PoseDetection";
import { checkIfSide } from "../../../utilities/Demo/angles";
import {
  leftHipArc,
  leftShoulderArc,
  rightHipArc,
  rightShoulderArc,
} from "../../../utilities/Demo/arcs";
import { loadTreePoseAudio } from "../../../utilities/Demo/audioFeedback";

function JumpingJacks({ legSide, onComplete }) {
  const dispatch = useDispatch();
  const extendedPose = useRef(false);
  const peaked = useRef(false);
  const peakedBack = useRef(false);
  const timeThresh = 60;
  const peakTracker = useRef({});

  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadTreePoseAudio,
  });
  cptScore.current = () => {
    return correctCount.current / (timeThresh * 2);
  };

  function giveFeedbackExtended(
    leftArmAngle,
    rightArmAngle,
    legAngle,
    standingLegAngle
  ) {
    if (leftArmAngle <= 110) {
      updateFeedback("Raise your left arm higher!");
      playAudio(audioFeedback.current?.raiseLeftArm);
      return;
    }
    if (rightArmAngle <= 110) {
      updateFeedback("Raise your right arm higher!");
      playAudio(audioFeedback.current?.raiseRightArm);
      return;
    }
    if (legAngle <= 20) {
      updateFeedback("Spread your leg a little more!");
      playAudio(audioFeedback.current?.raiseLeg);
      return;
    }
    if (standingLegAngle <= 20) {
      updateFeedback("Spread your other leg a little more!");
      playAudio(audioFeedback.current?.raiseLeg);
    }
  }

  function giveFeedbackStraight(
    leftArmAngle,
    rightArmAngle,
    legAngle,
    standingLegAngle
  ) {
    if (leftArmAngle >= 30) {
      updateFeedback("Lower your left arm!");
      playAudio(audioFeedback.current?.raiseLeftArm);
      return;
    }
    if (rightArmAngle >= 30) {
      updateFeedback("Lower your right arm!");
      playAudio(audioFeedback.current?.raiseRightArm);
      return;
    }
    if (legAngle >= 15) {
      updateFeedback("Keep your leg straight!");
      playAudio(audioFeedback.current?.keepStandingLegStraight);
      return;
    }
    if (standingLegAngle >= 15) {
      updateFeedback("Keep your other leg straight!");
      playAudio(audioFeedback.current?.keepStandingLegStraight);
    }
  }

  function checkPeakStraight(
    leftArmAngle,
    rightArmAngle,
    legAngle,
    standingLegAngle
  ) {
    if (
      leftArmAngle < 50 ||
      rightArmAngle < 50 ||
      legAngle < 15 ||
      standingLegAngle < 15
    ) {
      if (leftArmAngle < 50) {
        peakTracker.current.leftArm = true;
      }
      if (rightArmAngle < 50) {
        peakTracker.current.rightArm = true;
      }
      if (legAngle < 15) {
        peakTracker.current.leftLeg = true;
      }
      if (standingLegAngle < 15) {
        peakTracker.current.rightLeg = true;
      }
      peaked.current = true;
    }
  }

  function checkPeakExtended(
    leftArmAngle,
    rightArmAngle,
    legAngle,
    standingLegAngle
  ) {
    if (
      leftArmAngle > 90 ||
      rightArmAngle > 90 ||
      legAngle > 10 ||
      standingLegAngle > 10
    ) {
      if (leftArmAngle > 90) {
        peakTracker.current.leftArm = true;
      }
      if (rightArmAngle > 90) {
        peakTracker.current.rightArm = true;
      }
      if (legAngle > 10) {
        peakTracker.current.leftLeg = true;
      }
      if (standingLegAngle > 10) {
        peakTracker.current.rightLeg = true;
      }
      peaked.current = true;
    }
  }

  function observeBalance(
    leftArmAngle,
    rightArmAngle,
    legAngle,
    standingLegAngle
  ) {
    if (extendedPose.current) {
      if (
        leftArmAngle > 110 &&
        rightArmAngle > 110 &&
        legAngle > 20 &&
        standingLegAngle > 20
      ) {
        updateFeedback("");
        extendedPose.current = false;
        correctCount.current += 1;
        peakTracker.current = {};
        updateCounts(correctCount.current);
        setStartingTime(seconds);
        const score = cptScore.current();
        if (!raiseLevel(score)) {
          playAudio(audioFeedback.current?.achievement, true);
        } else {
          safeIterScore(dispatch, score);
        }
        peaked.current = false;
        peakedBack.current = false;
      } else {
        if (peaked.current) {
          if (
            (leftArmAngle < 90 && peakTracker.current.leftArm) ||
            (rightArmAngle < 90 && peakTracker.current.rightArm) ||
            (legAngle < 10 && peakTracker.current.leftLeg) ||
            (standingLegAngle < 10 && peakTracker.current.rightLeg)
          ) {
            peakedBack.current = true;
          }
          if (peakedBack.current) {
            giveFeedbackExtended(
              leftArmAngle,
              rightArmAngle,
              legAngle,
              standingLegAngle
            );
          }
        }
        checkPeakExtended(
          leftArmAngle,
          rightArmAngle,
          legAngle,
          standingLegAngle
        );
      }
    } else {
      if (
        leftArmAngle < 30 &&
        rightArmAngle < 30 &&
        legAngle < 15 &&
        standingLegAngle < 15
      ) {
        updateFeedback("");
        extendedPose.current = true;
        peaked.current = false;
        peakedBack.current = false;
        peakTracker.current = {};
      } else {
        if (peaked.current) {
          if (
            (leftArmAngle > 50 && peakTracker.current.leftArm) ||
            (rightArmAngle > 50 && peakTracker.current.rightArm) ||
            (legAngle > 15 && peakTracker.current.leftLeg) ||
            (standingLegAngle > 15 && peakTracker.current.rightLeg)
          ) {
            peakedBack.current = true;
          }
          if (peakedBack.current) {
            giveFeedbackStraight(
              leftArmAngle,
              rightArmAngle,
              legAngle,
              standingLegAngle
            );
          }
        }
        checkPeakStraight(
          leftArmAngle,
          rightArmAngle,
          legAngle,
          standingLegAngle
        );
      }
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    const leftArmAngle = leftShoulderArc(canvasCtx, results, 60);
    const rightArmAngle = rightShoulderArc(canvasCtx, results, 60);
    const leftLegAngle = leftHipArc(canvasCtx, results, 60);
    const rightLegAngle = rightHipArc(canvasCtx, results, 60);
    const side = checkIfSide(results);

    if (!side) {
      updateFeedback("");
      observeBalance(
        leftArmAngle,
        rightArmAngle,
        legSide === "left" ? leftLegAngle : rightLegAngle,
        legSide === "left" ? rightLegAngle : leftLegAngle,
        canvasCtx
      );
    } else {
      updateFeedback("Face the camera!");
      playAudio(audioFeedback.current?.faceCamera);
    }
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(JumpingJacks);
