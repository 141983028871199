//#F0ECEB
import React from "react";
import HeaderComponent from "../../components/HeaderComponent";
import DrawerComponent from "../../components/DrawerComponent";
// import div from "@mui/material/Container";
import { useState } from "react";
import RankingBg from "../../components/Ranking/RankingBg";
import "../../components/Ranking/ranking.css";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <div className="ranking-container">
      <HeaderComponent bgcol={"#F0ECEB"} /> <RankingBg />
      <DrawerComponent open={open} setOpen={setOpen} />
      <div className="ranking-grid">
        <div className="top-performers-container">
          <center>
            <h3>
              {" "}
              {t("errorMsg.errorPage1")}{" "}
              <a href="/"> {t("errorMsg.errorPage2")} </a>{" "}
              {t("errorMsg.errorPage3")}{" "}
            </h3>{" "}
          </center>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default ErrorPage;
