import { Dialog, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  useGaitResultsActions,
  useUpdatingGaitResultsActions,
} from "../../../../../hooks/GaitAnalysis/actions";
import {
  useGaitResults,
  useRelativeLandmarks,
  useSensorDataRefactored,
} from "../../../../../hooks/GaitAnalysis/results";
import CycleChart from "./CycleChart";
import PressureMetrics from "./PressureMetrics";
import Headline from "./Headline";
import Replay from "./Replay";
import ResultsMetrics from "./ResultsMetrics";
import ViewToggle from "./viewToggle";
import html2canvas from "html2canvas";
import PressureMap from "./PressureMap";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ResultCharts from "./ResultCharts";
import ResultPdfDownloadButton from "./pdf/ResultPdfDownloadButton";
import ResultPdfSendButton from "./pdf/ResultPdfSendButton";
import { ToastContainer } from "react-toastify";

export default function ObservationResult() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [viewType, setViewType] = React.useState("patient");
  const gaitResults = useSelector(({ gaitResults }) => gaitResults);
  const lastGaitResults = useSelector(({ lastGaitResults }) => lastGaitResults);
  const { updateResultsLocally } = useUpdatingGaitResultsActions();
  const relativeLandmarks = useRelativeLandmarks(0);

  const cycleRef = React.useRef(null);
  const [cycleImage, setCycleImage] = React.useState(null);
  const pressureRef = React.useRef(null);
  const [pressureImage, setPressureImage] = React.useState(null);
  const pressureMapRef = React.useRef(null);
  const [pressureMapImage, setPressureMapImage] = React.useState(null);
  const doctorsMetricsRef = React.useRef(null);
  const [doctorMetricsImage, setDoctorMetricsImage] = React.useState(null);
  const [sensorData, setSensorData] = useState(useSensorDataRefactored());
  const [bannerNotEnoughSteps, setBannerNotEnoughSteps] = useState(false);
  const { addResults } = useGaitResultsActions();
  const dispatch = useDispatch();
  const store = useStore();
  const [metrics, setMetrics] = React.useState(null);
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );
  console.log("gait id!", gaitResults.id);

  React.useEffect(() => {
    const gaitResults = store.getState().gaitResults;
    if (gaitResults.metrics) {
      setMetrics(gaitResults.metrics);
      console.log("gait id", gaitResults.id, gaitResults.userId);

      // failed to add to firestore
      if (gaitResults.userId && !gaitResults.id && !gaitResults.userId) {
        addResults().then((results) => {
          dispatch({
            type: "SET_GAIT_RESULTS",
            payload: { id: results?.id },
          });
        });
      }
    } else {
      const lastGaitResults = store.getState().lastGaitResults;
      // if (!lastGaitResults.docs) return;
      if (lastGaitResults.docs.length > 0) {
        setMetrics(lastGaitResults.docs[0].metrics);
      } else {
        const gaitResults = store.getState().gaitResults;
        setMetrics(gaitResults.metrics);
      }
    }
  });

  useEffect(() => {
    setLoading(true);
    if (sensorData && Object.keys(sensorData).length === 0) {
      setSensorData(gaitResults.sensorData);
    }
  }, [gaitResults.sensorData]);

  let availability = {};
  let gaitResult = useGaitResults();
  if (lastGaitResults.docs.length === 0) {
    availability = gaitResult;
  }

  useEffect(() => {
    if (!bannerNotEnoughSteps) {
      setBannerNotEnoughSteps(availability.notEnoughData);
    }
  }, [availability.notEnoughData]);

  useEffect(() => {
    if (lastGaitResults.docs.length > 0) {
      console.log(lastGaitResults.docs[0], "last gait results");
      updateResultsLocally(lastGaitResults.docs[0]);
    }
  }, [lastGaitResults.docs]);

  useEffect(() => {
    const captureDivToImage = async () => {
      try {
        // delay by 2 seconds to allow the charts to render
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const canvas = await html2canvas(cycleRef.current);
        const imgData = canvas.toDataURL("image/cycleRef.png");
        setCycleImage(imgData);
      } catch (error) {
        setCycleImage(null);
        console.error(error);
      }

      try {
        const canvas2 = await html2canvas(pressureRef.current);
        const imgData2 = canvas2.toDataURL("image/pressureRef.png");
        setPressureImage(imgData2);
      } catch (error) {
        setPressureImage(null);
        console.error(error);
      }

      try {
        const canvas3 = await html2canvas(pressureMapRef.current);
        const imgData3 = canvas3.toDataURL("image/pressureMapRef.png");
        setPressureMapImage(imgData3);
      } catch (error) {
        setPressureMapImage(null);
        console.error(error);
      }

      try {
        const canvas4 = await html2canvas(doctorsMetricsRef.current, {
          scrollY: -window.scrollY,
          scrollX: -window.scrollX,
          useCORS: true,
        });
        const imgData4 = canvas4.toDataURL("image/doctorMetricsImage.png");
        setDoctorMetricsImage(imgData4);
      } catch (error) {
        setDoctorMetricsImage(null);
        console.error(error);
      }
    };
    captureDivToImage().catch((err) => {
      console.error(err);
    });
  }, [loading, metrics]);

  return (
    <div>
      <div
        ref={doctorsMetricsRef}
        style={{
          height: 900,
          position: "absolute",
          opacity: 1,
          zIndex: -1,
        }}
      >
        <Grid container sx={{ margin: 2 }}>
          <Grid item xs={12} sx={{ ml: 2 }}>
            <Headline
              fontSize={30}
              text={"Metrics"}
              height={"5vh"}
              // backgroundColor={'rgb(60,232,170)'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12}>
            <ResultsMetrics />
          </Grid>
        </Grid>
      </div>
      <Dialog open={bannerNotEnoughSteps} fullWidth={true} maxWidth={"lg"}>
        <div style={{ padding: 50 }}>
          <Typography variant={"h4"}>
            Not enough valid steps collected, please collect more next time!
          </Typography>
          <Button
            onClick={() => {
              navigate("/gait_analysis");
            }}
          >
            close
          </Button>
        </div>
      </Dialog>
      <Paper sx={{ paddingBottom: 3, marginBottom: 2 }}>
        <Grid
          container
          sx={{ justifyContent: "center", display: "flex", mt: 2 }}
        >
          <ViewToggle viewType={viewType} setViewType={setViewType} />
        </Grid>
        <div ref={cycleRef}>
          <CycleChart setLoading={setLoading} metrics={metrics} />
        </div>
        <div ref={pressureMapRef}>
          <PressureMap
            setLoading={setLoading}
            sensorData={sensorData}
            gaitResults={gaitResults}
          />
        </div>
        <div ref={pressureRef}>
          <PressureMetrics setLoading={setLoading} sensorData={sensorData} />
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <ResultPdfDownloadButton
            doctorMetricsImage={doctorMetricsImage}
            cycleImage={cycleImage}
            pressureImage={pressureImage}
            pressureMapImage={pressureMapImage}
            metrics={metrics}
            patientInfo={patientInfo}
          />
          <ResultPdfSendButton
            doctorMetricsImage={doctorMetricsImage}
            cycleImage={cycleImage}
            pressureImage={pressureImage}
            pressureMapImage={pressureMapImage}
            metrics={metrics}
            patientInfo={patientInfo}
          />
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <Replay relativeLandmarks={relativeLandmarks} />
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </div>
        {viewType === "doctor" && (
          <div>
            <Grid container sx={{ margin: 2 }}>
              {/*<Grid item xs={3.8}>
                <Headline
                  text={'Movement'}
                  height={'5vh'}
                  // backgroundColor={'rgb(55,164,251)'}
                />
              </Grid>*/}
              <Grid item xs={12 /*7.6*/} sx={{ ml: 2 }}>
                <Headline
                  text={"Metrics"}
                  height={"5vh"}
                  // backgroundColor={'rgb(60,232,170)'}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              {/*<Grid item xs={4} sm={4} md={4}>
                <AnglesChart
                  chartKey={'leg-flex-extension'}
                  data={gaitResults.graphData?.mainChartDataAll}
                  height={'100%'}
                  slice={false}
                  title={'Leg Flex/Extension'}
                />
              </Grid>*/}
              <Grid item xs={viewType === "doctor" ? 12 : 12}>
                <ResultsMetrics />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <ResultCharts />
              </Grid>
              <Grid item xs={12}>
                {/*<SymmetryInfo />*/}
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
      <ToastContainer />
    </div>
  );
}
