import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import React from 'react';

export default function CopyBadge({
    copyConfig,
    sx
}) {
    return ( <
        IconButton onClick = {
            (e) => {
                e.stopPropagation();
                copyConfig(null);
            }
        }
        sx = {
            { ...sx
            }
        } >
        <
        ContentCopyIcon / >
        <
        /IconButton>
    );
}