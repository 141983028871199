const initialState = () => {
  return {
    id: null,
    timeStamp: null,
    loadingResults: false,
  };
};

const initialStateResults = initialState();

export const gaitResultsReducer = (state = initialStateResults, action) => {
  switch (action.type) {
    case "ADD-GAIT-RESULTS": {
      return { ...state, ...action.payload };
    }
    case "UPDATE-GAIT-RESULTS": {
      return { ...state, ...action.payload };
    }
    case "RESET-GAIT-RESULTS":
      return initialStateResults;
    default: {
      return state;
    }
  }
};

export const lastGaitResultsReducer = (
  state = {
    docs: [],
  },
  action
) => {
  switch (action.type) {
    case "SET-LAST-GAIT-RESULTS": {
      console.log("SET-LAST-GAIT-RESULTS", action.payload);
      return { ...state, ...action.payload };
    }
    case "RESET-LAST-GAIT-RESULTS":
      return {
        docs: [],
      };
    case "SET-LAST-GAIT-RESULTS-LOADING-STATE": {
      console.log("SET-LAST-GAIT-RESULTS-LOADING-STATE", action.payload);
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};
