import React from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useBalanceCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import { findDependentAngle } from "../../../utilities/Demo/physio";
import PoseDetection from "../PoseDetection";
import { checkIfRightSide, checkIfSide } from "../../../utilities/Demo/angles";
import {
  leftHipArc,
  leftShoulderArc,
  rightHipArc,
  rightShoulderArc,
} from "../../../utilities/Demo/arcs";
import { loadFlamingoPoseAudio } from "../../../utilities/Demo/audioFeedback";

function FlamingoPoseDemo({ legSide = "right", onComplete }) {
  const timeThresh = 60;
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    playAudio,
    audioFeedback,
    setSide,
    cptScore,
    updatePoseHeldDeps,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadFlamingoPoseAudio,
  });

  cptScore.current = () => {
    return (
      (secondsPoseHeldComplete.current + secondsPoseHeld.current) /
      (timeThresh * 2)
    );
  };

  function giveFeedback(armAngle, legAngle, standingLegAngle, kneeAngle) {
    const feedbackSide = legSide === "left" ? "right" : "left";
    if (armAngle <= 50) {
      updateFeedback(`Raise your ${legSide} arm higher!`);
      playAudio(audioFeedback.current?.raiseArm);
      return;
    }
    if (legAngle <= 25) {
      updateFeedback(`Raise your ${feedbackSide} leg higher!`);
      playAudio(audioFeedback.current?.raiseLeg);
      return;
    }
    if (standingLegAngle >= 10) {
      updateFeedback("Keep your standing leg straight!");
      playAudio(audioFeedback.current?.keepStandingLegStraight);
      return;
    }
    if (kneeAngle >= 100) {
      updateFeedback(`Bend your ${feedbackSide} leg more!`);
      playAudio(audioFeedback.current?.bendKneeMore);
    }
  }

  function observeBalance(
    armAngle,
    legAngle,
    standingLegAngle,
    kneeAngle
    // canvasCtx
  ) {
    const state = holdsPose.current;
    if (
      armAngle > 50 &&
      legAngle > 25 &&
      standingLegAngle < 15 &&
      kneeAngle < 100
    ) {
      holdsPose.current = true;
      secondsPoseHeld.current = seconds.current - secondPoseHeldStarted.current;
      updateFeedback("");
    } else {
      holdsPose.current = false;
      giveFeedback(armAngle, legAngle, standingLegAngle, kneeAngle);
    }
    updatePoseHeldDeps(state);
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    let armAngle;
    const leftLegAngle = leftHipArc(canvasCtx, results, 60);
    const rightLegAngle = rightHipArc(canvasCtx, results, 60);
    let kneeAngle;
    if (legSide === "left") {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      armAngle = leftShoulderArc(canvasCtx, results, 60);
    } else {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      armAngle = rightShoulderArc(canvasCtx, results, 60);
    }
    let side = checkIfSide(results);
    if (side) {
      if (checkIfRightSide(results, legSide)) {
        updateFeedback("Turn to the other side!");
        // displayFeedback(canvasCtx, 'Turn to the other side!');
        playAudio(audioFeedback.current?.makeSureSide);
      } else {
        setSide(true);
        observeBalance(
          armAngle,
          legSide === "left" ? leftLegAngle : rightLegAngle,
          legSide === "left" ? rightLegAngle : leftLegAngle,
          kneeAngle,
          canvasCtx
        );
      }
    } else {
      updateFeedback("Turn to the side!");
      playAudio(audioFeedback.current?.makeSureSide);
      setSide(false);
    }

    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(FlamingoPoseDemo);
