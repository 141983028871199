import {
    useDispatch
} from 'react-redux';
import {
    useNavigate
} from 'react-router-dom';

export function useStartSchedule() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return ({
        scheduleDoc,
        setCalibrated = true,
        index = 0,
        queryParams = '',
    }) => {
        dispatch({
            type: 'SET-ITER-EXERCISES',
            payload: scheduleDoc.exercises,
        });
        dispatch({
            type: 'SET-ITER-OVERVIEW-REDIRECT',
            payload: scheduleDoc.overviewRedirect || '' + scheduleDoc.id,
        });
        dispatch({
            type: 'SET-ITER-SHOW-SUMMARIES',
            payload: scheduleDoc.showSummaries,
        });
        dispatch({
            type: 'SET-ITER',
            payload: {
                iteration: index
            },
        });
        dispatch({
            type: 'SET-ITER-SCHEDULE-ID',
            payload: scheduleDoc.id,
        });
        dispatch({
            type: 'SET-ITER-RAISE-LEVEL-SOUNDS',
            payload: false,
        });
        dispatch({
            type: 'SET-SCHEDULE-SPECIFIC-CONFIGS',
            payload: scheduleDoc.scheduleSpecificExerciseConfigs,
        });
        dispatch({
            type: 'SET-ITER-CORE-AUDIO-FEEDBACK-CONFIG',
            payload: scheduleDoc.coreAudioFeedbackConfig,
        });
        dispatch({
            type: 'SET-ITER-LANGUAGE',
            payload: scheduleDoc.language,
        });
        if (setCalibrated) {
            dispatch({
                type: 'ITER-CALIBRATED',
            });
        }
        navigate('/exercise/exerciseConfig?' + queryParams);
    };
}