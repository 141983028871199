import { CircularProgress, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  useAddResultToDB,
  useCompleteSession,
  useFeedbackComponent,
  useVideoLinks,
} from "../../../hooks/Arena/demoAnimation";
import { detectBrowser } from "../../../utilities/Demo/browser";
import ProgressBar from "../../Demo/ProgressBar";
import React, { useEffect, useLayoutEffect, useState } from "react";
import "./DemoAnimation.css";
import { onWindowResize } from "../../threejs/DemoThreeJsLoader.js";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player/lazy";
import styles from "../arena.module.css";
import FullscreenExitRoundedIcon from "@mui/icons-material/FullscreenExitRounded";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { useTranslation } from "react-i18next";
import StartButtonOverlay from "./StartButtonOverlay";
import Stridalizer from "../../Demo/Physio/GaitAnalysis/recording/Stridalizer";

function App({
  demoType,
  bottomBarComponent = undefined,
  handleExit = () => {},
  redirectTarget = "/summary",
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [introLoaded, setIntroLoaded] = useState(false);
  const handle = useFullScreenHandle();
  const correctSide = useSelector(({ side }) => side.values.correct);
  const schedule = useSelector(({ schedule }) => schedule.values);
  const [isLooping, setIsLooping] = useState(true);
  const [totalDuration, setTotalDuration] = useState(0);
  const addResultToDB = useAddResultToDB({
    demoType,
  });
  const [breakScreen, setBreakScreen] = useState(false);
  const [duration, setDuration] = useState(0);
  const [vidIndex, setVidIndex] = useState(0);
  const isBergsBalance = String(demoType)
    .trim()
    .toLowerCase()
    .startsWith("berg_balance");
  const introGlobalState = useSelector(({ intro }) => intro.values.introState);
  const introState = process.env.REACT_APP_SKIP_INTRO_STATE || introGlobalState;
  const [startedObservation, setStartedObservation] = useState(!isBergsBalance);
  const completeSession = useCompleteSession({
    demoType,
    redirectTarget,
    introState,
    setIntroLoaded,
  });
  const [browser, setBrowser] = useState(null);
  const [showWebcam, setShowWebcam] = useState(isBergsBalance);
  const {
    exerciseConfig,
    observationComponent,
    splitScreen,
    feedbackComponent,
    transitionQTime,
    playIntroAudio,
  } = useFeedbackComponent({
    demoType,
    showWebcam,
    completeSession,
    handleExit,
    setLoad,
  });
  const videoUrl = useVideoLinks({
    demoType,
    exerciseConfig,
    vidIndex,
    setIntroLoaded,
  });
  useEffect(() => {
    // console.log('add results to db changed');
  }, [addResultToDB]);

  const showIntroVideo = () => {
    if (demoType === "school")
      return {
        visibility: "visible",
      };
    else
      return {
        visibility: introState === "started" ? "hidden" : false,
      };
  };

  const getAudioLinks = () => {
    return schedule.modelsdata[vidIndex].audioFile;
  };

  const handleSchoolsDuration = (duration) => {
    setTotalDuration(duration);
    if (duration > 40) {
      setIsLooping(false);
    } else {
      setIsLooping(true);
    }
  };

  const handleTransition = (secondsPlayed) => {
    if (secondsPlayed >= transitionQTime) {
      addResultToDB();
      dispatch({
        type: "ADD-ITER",
      });
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      onWindowResize();
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    setBrowser(detectBrowser());
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="Player">
      {" "}
      {breakScreen ? (
        <div className={styles.timer_container}>
          <div className={styles.timer2}>
            {" "}
            {t("animationComponent.break")} {t("animationComponent.walkAround")}{" "}
            <br /> {t("animationComponent.water")} <br />{" "}
            {t("animationComponent.getReady")}{" "}
          </div>{" "}
        </div>
      ) : (
        <FullScreen handle={handle}>
          <div
            className="container-demo"
            style={{
              visibility: "visible",
              position: "relative",
            }}
          >
            {demoType === "school" && (
              <audio id="audio" loop autoPlay>
                <source src={getAudioLinks()} type="audio/mpeg" />
              </audio>
            )}{" "}
            <div
              className="icons-cont"
              style={{
                width: "100%",
              }}
            >
              {" "}
              {load ? (
                <CircularProgress
                  color="success"
                  size={25}
                  style={{
                    margin: "auto 2rem auto 0",
                  }}
                />
              ) : (
                <div
                  onClick={() => {
                    addResultToDB();
                    handleExit(setLoad);
                  }}
                  style={{
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                >
                  {" "}
                  <ExitToAppRoundedIcon
                    htmlColor="white"
                    className="svg_icons"
                  />
                </div>
              )}{" "}
              <div
                style={{
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={handle.active ? handle.exit : handle.enter}
              >
                {handle.active ? (
                  <FullscreenExitRoundedIcon
                    htmlColor="white"
                    className="svg_icons"
                  />
                ) : (
                  <FullscreenRoundedIcon
                    htmlColor="white"
                    className="svg_icons"
                  />
                )}{" "}
              </div>{" "}
            </div>{" "}
            {introState === "started" && videoUrl && demoType !== "school" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  overflow: "hidden",
                  position: "relative",
                  height: "100%",
                  width: "100%",
                  zIndex: 195,
                }}
              >
                <ReactPlayer
                  url={videoUrl}
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  className="react-player"
                  width="100%"
                  height="110%"
                  onReady={() => {
                    setIntroLoaded(true);
                    if (playIntroAudio) {
                      playIntroAudio();
                    }
                  }}
                  config={{
                    file: {
                      attributes: {
                        preload: "auto",
                      },
                    },
                  }}
                />{" "}
              </div>
            )}{" "}
            {!splitScreen || videoUrl === "" ? (
              <div>
                <div
                  id={"__test__-demo"}
                  style={{
                    position: "absolute", // Add this line
                    // overflow: 'hidden',
                    // objectFit: 'contain',
                    height: "100%", // Add this line
                    width: browser === "Google Chrome" ? "75%" : "100%", // Add this line
                    left: browser === "Google Chrome" ? "12.5%" : "-25%",
                    transform: "scaleX(-1)",
                    zIndex: 5,
                    // transform: 'translateX(-25%)', // Add this line
                  }}
                >
                  {observationComponent}{" "}
                </div>{" "}
                <div> {feedbackComponent} </div>{" "}
              </div>
            ) : (
              videoUrl && (
                <div>
                  {" "}
                  {!introLoaded || introState !== "done" ? (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "black",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flex: 1,
                        zIndex: 194,
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          position: "absolute", // Overlay on top
                          visibility: "visible",
                          zIndex: 2,
                          backgroundColor: "black",
                          height: "100%",
                          width: "100%",
                          left: "50%",
                        }}
                      >
                        {}{" "}
                      </div>{" "}
                      <div
                        className="box1"
                        style={{
                          position: "absolute", // Add this line
                          // overflow: 'hidden',
                          // objectFit: 'contain',
                          height: "100%", // Add this line
                          width: browser === "Google Chrome" ? "75%" : "100%", // Add this line
                          left: browser === "Google Chrome" ? "-20%" : "-25%",
                          transform: "scaleX(-1)",
                          // transform: 'translateX(-25%)', // Add this line
                        }}
                      >
                        {!startedObservation && introState === "done" && (
                          <StartButtonOverlay
                            handleStart={setStartedObservation}
                          />
                        )}{" "}
                        {startedObservation &&
                          introState === "done" &&
                          observationComponent}{" "}
                        {!correctSide && introState === "done" && videoUrl && (
                          <div
                            className={"box1"}
                            style={{
                              position: "absolute", // Overlay on top
                              visibility: "visible",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "105%",
                              opacity: 0.5,
                              zIndex: 1, // to make sure this overlay video is on top of everything else
                            }}
                          >
                            <ReactPlayer
                              url={
                                "https://napply-open-access.s3.eu-central-1.amazonaws.com/turn_to_side.mp4"
                              }
                              controls={false}
                              playing={true}
                              loop={true}
                              className="react-player"
                              width="100%"
                              height="100%"
                              onEnded={completeSession}
                              config={{
                                file: {
                                  attributes: {
                                    preload: "auto",
                                  },
                                },
                              }}
                            />{" "}
                          </div>
                        )}{" "}
                      </div>{" "}
                      {startedObservation && (
                        <div
                          className="box1"
                          style={{
                            position: "absolute", // Add this line
                            // overflow: 'hidden',
                            // objectFit: 'contain',
                            height: "100%", // Add this line
                            width: "50%", // Add this line
                            zIndex: 3,
                            borderWidth: 10,
                            borderRadius: 1,
                            borderLeftWidth: 12,
                            borderRightWidth: 13,
                          }}
                        >
                          {feedbackComponent}{" "}
                        </div>
                      )}{" "}
                      <div
                        className="box2"
                        id="scene-demo"
                        style={{
                          position: "absolute", // Add this line
                          flex: 1,
                          padding: "10px",
                          visibility: "visible",
                          opacity: 1,
                          zIndex: 190,
                          height: "100%", // Add this line
                          width: "50%", // Add this line
                          left: "100%", // Add this line
                          transform: "translateX(-100%)", // Add this line
                          // borderLeftWidth: 40,
                        }}
                      >
                        <ReactPlayer
                          url={videoUrl}
                          controls={demoType === "transitionConfig"}
                          playing={true}
                          loop={demoType !== "transitionConfig" && isLooping}
                          className="react-player"
                          width="100%"
                          height="100%"
                          // onEnded={completeSession}
                          muted={demoType !== "transitionConfig"}
                          config={{
                            file: {
                              attributes: {
                                preload: "auto",
                              },
                            },
                          }}
                          onDuration={
                            demoType === "school"
                              ? handleSchoolsDuration
                              : (dur) => dur
                          }
                          onProgress={(props) => {
                            if (demoType === "school") {
                              setDuration(duration + 1);
                              if (
                                (duration === 40 && isLooping) ||
                                (!isLooping &&
                                  props.playedSeconds === totalDuration)
                              ) {
                                //setBreakScreen(true);
                                if (
                                  vidIndex + 1 ===
                                  schedule.modelsdata.length
                                ) {
                                  handleExit(setLoad);
                                }
                                setVidIndex(
                                  vidIndex + 1 === schedule.modelsdata.length
                                    ? 0
                                    : vidIndex + 1
                                );
                                setDuration(0);
                                setBreakScreen(false);
                              }
                            } else if (demoType === "transitionConfig") {
                              handleTransition(props.playedSeconds);
                            }
                          }}
                        />{" "}
                      </div>{" "}
                      {startedObservation && videoUrl && (
                        <div
                          className={
                            bottomBarComponent
                              ? "prog_bottom_bar"
                              : "prog_bottom_bar assessment_bottom"
                          }
                        >
                          <div
                            className="progress-demo"
                            style={showIntroVideo()}
                          >
                            <ProgressBar />
                          </div>{" "}
                          {bottomBarComponent}{" "}
                        </div>
                      )}{" "}
                    </div>
                  )}{" "}
                </div>
              )
            )}{" "}
          </div>{" "}
        </FullScreen>
      )}{" "}
      <div
        style={{
          marginTop: 2,
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Switch
          onClick={() => {
            setShowWebcam(!showWebcam);
          }}
        />{" "}
        <Typography> {exerciseConfig.name} </Typography>{" "}
      </div>{" "}
      <Stridalizer />
    </div>
  );
}

export default App;
