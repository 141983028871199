import React from "react";
import GraphComponent from "./GraphComponent";
import ProgressArticleNavigation from "./ProgressArticleNavigation";
import { useTranslation } from "react-i18next";
import ic1 from "../../assets/ic1.png";

const Progress = ({ data, dys, setDys }) => {
  const { t } = useTranslation();
  const moment = require("moment-timezone");
  let coins = {};
  for (var j = 6; j >= 0; j--) {
    dys[parseInt(moment().subtract(j, "days").format("DD"))] = 0;
    coins[parseInt(moment().subtract(j, "days").format("DD"))] = 0;
  }
  if (data && data.progress)
    for (var i = data.progress.length - 1; i >= 0; i--) {
      if (
        Object.keys(dys).includes(
          "" + parseInt(data.progress[i].date.split("-")[0])
        )
      )
        dys["" + parseInt(data.progress[i].date.split("-")[0])] =
          dys["" + parseInt(data.progress[i].date.split("-")[0])] +
          data.progress[i].sweat_points;
      coins["" + parseInt(data.progress[i].date.split("-")[0])] =
        coins["" + parseInt(data.progress[i].date.split("-")[0])] +
        data.progress[i].xr_coins;
      // else
      //   dys[parseInt(data.progress[i].date.split('-')[0])] =
      //     data.progress[i].sweat_points;
      if (Object.keys(dys).length > 7) {
        delete dys[parseInt(moment().subtract(7, "days").format("DD"))];
      }
    }
  setDys(dys);
  return (
    <div className="progress-component-container progress-article progressGraphFromPC">
      <ProgressArticleNavigation
        title={t("ProgressComponent.progress")}
        selectVal={""}
      />{" "}
      <div className="progress-component-content">
        <GraphComponent
          progress={data ? data.progress : null}
          dys={dys}
          setDys={setDys}
          coins={coins}
        />{" "}
        <div className="progress-component-right">
          <div className="progress-component-right-inner">
            <img src={ic1} alt="Bag" />
            <div className="right">
              <span> {t("ProgressViewComponent.day")} </span>{" "}
              <h3>
                {" "}
                {moment()
                  .locale(t("CalendarComponent.locale"))
                  .format("Do MMM YYYY")}{" "}
              </h3>{" "}
            </div>{" "}
          </div>{" "}
          <div className="progress-component-right-inner">
            <img src={ic1} alt="Bag" />
            <div className="right">
              <span> {t("PointsComponent.sweatPoints")} </span>{" "}
              <h3>
                {" "}
                {dys
                  ? dys[
                      moment()
                        .locale(t("CalendarComponent.locale"))
                        .format("DD")
                    ]
                    ? dys[
                        moment()
                          .locale(t("CalendarComponent.locale"))
                          .format("DD")
                      ]
                    : 0
                  : 0}{" "}
              </h3>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Progress;
