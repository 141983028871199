import { CircularProgress, Dialog } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import ReactPlayer from "react-player/lazy";
import { getVideo } from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import DeleteButton from "../../DeleteButton";
import CloseBadge from "../CloseBadge";
import VideoSelectionDialog from "./VideoSelectionDialog";

export default function VideoDialog({
  onClose,
  open,
  config,
  setVideoId,
  exercise,
}) {
  const videoId = config?.videoId ? config?.videoId : exercise?.videoId;
  const [loading, setLoading] = React.useState(false);
  const [videoDoc, setVideoDoc] = React.useState(null);
  const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!videoId) {
      setVideoDoc(null);
    }
    if (videoId) {
      setLoading(true);
      getVideo({ videoId }).then((video) => {
        setVideoDoc(video);
        setLoading(false);
      });
    } else {
      setVideoDoc(null);
    }
  }, [videoId]);
  return (
    <Dialog
      maxWidth="lg" // 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
      fullWidth={true}
      onClose={() => {
        setVideoDoc(null);
        onClose();
      }}
      open={open}
    >
      <VideoSelectionDialog
        setVideo={(video) => {
          setVideoId(video.id);
          setVideoDoc(video);
        }}
        open={selectionDialogOpen}
        onClose={() => setSelectionDialogOpen(false)}
      />
      <Badge
        badgeContent={<CloseBadge setConfig={onClose} sx={{ mt: 5, mr: 5 }} />}
      >
        <DialogTitle sx={{ mr: 5, mb: 5 }}>Edit Video</DialogTitle>
        <Box sx={{ mt: 5, mb: 5, mr: 5, width: "100%", padding: 5 }}>
          <Grid container spacing={2} sx={{ margin: 0 }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "space-between",
              }}
              sx={{ boxShadow: 5, padding: 3, borderRadius: 2 }}
            >
              <Button
                variant="contained"
                onClick={() => setSelectionDialogOpen(true)}
                sx={{ mr: 2 }}
              >
                SELECT VIDEO
              </Button>
              <DeleteButton
                onDelete={() => {
                  setVideoId(null);
                  setVideoDoc(null);
                }}
                deletionTarget={"Video"}
              />
            </Grid>
            {loading && <CircularProgress />}
            {videoDoc && (
              <div style={{ marginTop: 5, marginBottom: 20 }}>
                <Typography variant={"h2"}>{videoDoc.name}</Typography>
                <ReactPlayer
                  url={videoDoc.url}
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  className="react-player"
                  width="100%"
                  height="100%"
                  config={{ file: { attributes: { preload: "auto" } } }}
                />
              </div>
            )}
          </Grid>
        </Box>
      </Badge>
    </Dialog>
  );
}
