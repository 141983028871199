import * as React from 'react';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import '../assessments.css';
import '../../../Schedule/schedule.css';
import {
    useNavigate
} from 'react-router-dom';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import TestResult from './TestResult';

function parseAndRound(value) {
    const floatNumber = parseFloat(value);
    if (isNaN(floatNumber)) {
        return value;
    } else {
        return Math.round(floatNumber * 100) / 100;
    }
}

function checkScoreNumber(value) {
    if (isNaN(value) || value === undefined) {
        return 0;
    } else {
        return parseAndRound(value * 5);
    }
}

export const LastTest = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const iter = useSelector(({
        iter
    }) => iter.values);
    const balanceScore = (iter.scores[3] + iter.scores[4]) / 2;
    const overallScore =
        (iter.scores[0] +
            iter.scores[1] +
            iter.scores[2] +
            iter.scores[3] +
            balanceScore +
            iter.scores[5]) /
        5;

    const handleTryAgain = () => {
        dispatch({
            type: 'RESET-ITER',
            payload: {}
        });
        navigate('/demos/fitness_assessment');
    };
    return ( <
        div className = "today-session-container"
        style = {
            {
                height: '80vh',
                padding: '4em 1em',
                marginTop: '1em'
            }
        } >
        <
        div className = "box-container" >
        <
        div className = "today-session-box" > Last Test < /div> <
        /div>

        <
        div className = "list list-container" >
        <
        TestResult name = "Date"
        value = {
            moment().format('DD MMM YYYY')
        }
        /> <
        TestResult name = "Overall Score"
        value = {
            checkScoreNumber(overallScore)
        }
        /> <
        TestResult name = "Flexibility"
        value = {
            checkScoreNumber(iter.scores[0])
        }
        /> <
        TestResult name = "Endurance"
        value = {
            checkScoreNumber(iter.scores[1])
        }
        /> <
        TestResult name = "Strength"
        value = {
            checkScoreNumber(iter.scores[2])
        }
        /> <
        TestResult name = "Balance"
        value = {
            checkScoreNumber(iter.scores[3])
        }
        /> <
        TestResult name = "Cardio"
        value = {
            checkScoreNumber(iter.scores[4])
        }
        /> <
        /div> <
        div className = "join-session-btn-container" >
        <
        Button type = "submit"
        variant = "contained"
        className = "join-session-btn"
        sx = {
            {
                borderRadius: 2,
                fontSize: '1.5rem',
                backgroundColor: '#FF592C',
            }
        }
        onClick = {
            handleTryAgain
        } >
        Try Again <
        /Button> <
        /div> <
        /div>
    );
};