import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Paper,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteButton from "../../../ExerciseManagement/DeleteButton";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer } from "react-toastify";
import { emitToast } from "../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import ExerciseDialog from "../../../ExerciseManagement/Configurator/Schedule/ExerciseDialog";
import {
  addAssignment,
  updateAssignment,
} from "../../../../../utilities/Demo/Physio/Patient/Overview/assignments";
import CloseIcon from "@mui/icons-material/Close";
import {
  addSchedule,
  getExercise,
  getSchedule,
} from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import ScoringDialog from "../../../ExerciseManagement/Configurator/Schedule/ScoringDialog";
import ScheduleDialog from "../../../ExerciseManagement/Configurator/Schedule/ScheduleDialog";

export default function ProtocolConfig({
  patientId,
  config,
  onClose,
  updateProtocolInList,
  addProtocolToList,
}) {
  const [exercises, setExercises] = useState(config.exercises || []);
  const [schedules, setSchedules] = useState(config.schedules || []);
  const [id, setId] = useState(config.id || null);
  const [name, setName] = useState(config.name || "");
  const [description, setDescription] = useState(config.description || "");
  const [days, setDays] = useState(config.days || "");
  const [sessions, setSessions] = useState(config.sessions || "");
  const [scheduleSpecificExerciseConfigs, setScheduleSpecificExerciseConfigs] =
    useState(config.scheduleSpecificExerciseConfigs || {});
  const [scheduleSpecificScheduleConfigs, setScheduleSpecificScheduleConfigs] =
    useState(config.scheduleSpecificScheduleConfigs || {});
  const [saving, setSaving] = useState(false);
  const { user } = useContext(FirebaseAuthContext);
  const [exerciseDialogOpen, setExerciseDialogOpen] = useState(false);
  const [isAddScheduleDialogOpen, setIsAddScheduleDialogOpen] = useState(false);
  const [scoringDialogExerciseId, setScoringDialogExerciseId] = useState(null);

  const ctaBtnText = id ? "Update" : "Create";

  const addExercise = (exercise) => {
    setExercises([...exercises, exercise]);
  };

  const handleSelectSchedule = (schedule) => {
    setSchedules((prevSchedules) => [...prevSchedules, schedule]);
  };

  const timeThreshValue = (exercise) => {
    if (scheduleSpecificExerciseConfigs[exercise.id]) {
      if (
        scheduleSpecificExerciseConfigs[exercise.id]?.timeThresh !== undefined
      ) {
        return scheduleSpecificExerciseConfigs[exercise.id].timeThresh;
      } else {
        return exercise.timeThresh;
      }
    } else {
      return exercise.timeThresh;
    }
  };

  useEffect(() => {
    async function fetchExercises() {
      const promises = exercises.map(async (exercise) => {
        if (typeof exercise === "string") {
          return await getExercise({ exerciseId: exercise });
        } else {
          return exercise;
        }
      });

      // Use Promise.all() to wait for all the promises to resolve
      return Promise.all(promises);
    }

    fetchExercises().then((exerciseDocs) => {
      setExercises(exerciseDocs);
    });
  }, []);

  // Fetch schedule details if schedule is there in the config
  useEffect(() => {
    async function fetchSchedules() {
      const promises = schedules.map(async (schedule) => {
        if (typeof schedule === "string") {
          return await getSchedule({ scheduleId: schedule });
        } else {
          return schedule;
        }
      });

      return Promise.all(promises);
    }

    fetchSchedules().then((scheduleDocs) => {
      setSchedules(scheduleDocs);
    });
  }, []);

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...exercises];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setExercises(updatedList);
  };

  const saveProtocol = () => {
    setSaving(true);
    const newAssignmentData = {
      ...config,
      name,
      patientId,
      description,
      scheduleSpecificExerciseConfigs,
      scheduleSpecificScheduleConfigs,
      exercises: exercises.map((exercise) => exercise.id),
      schedules: schedules.map((schedule) => schedule?.id),
      days,
      sessions,
    };
    if (id) {
      updateAssignment({
        userId: user.uid,
        data: newAssignmentData,
        assignmentId: id,
      }).then((protocolDoc) => {
        setSaving(false);
        updateProtocolInList({ ...newAssignmentData, id });
        if (protocolDoc) {
          emitToast({
            render: "Saved!",
            type: "success",
          });
        } else {
          emitToast({
            render: "Error saving assignment!",
            type: "error",
          });
        }
      });
    } else {
      // Add assignment
      addAssignment({ userId: user.uid, data: newAssignmentData })
        .then(async (assignment) => {
          await addSchedule({ user, scheduleData: newAssignmentData });
          setSaving(false);
          setId(assignment.id);
          addProtocolToList({ ...newAssignmentData, id: assignment.id });
          if (assignment) {
            emitToast({
              render: "Saved!",
              type: "success",
            });
          } else {
            emitToast({
              render: "Error saving assignment!",
              type: "error",
            });
          }
        })
        ?.catch((err) => console.log(err));
    }
  };

  return (
    <Paper sx={{ padding: 10 }}>
      <ExerciseDialog
        open={exerciseDialogOpen}
        onClose={() => setExerciseDialogOpen(false)}
        setExercise={addExercise}
      />
      <ScheduleDialog
        open={isAddScheduleDialogOpen}
        onClose={() => setIsAddScheduleDialogOpen(false)}
        onSelectSchedule={handleSelectSchedule}
      />
      <ScoringDialog
        open={Boolean(scoringDialogExerciseId)}
        onClose={() => setScoringDialogExerciseId(null)}
        exercise={exercises.find(
          (exercise) => exercise.id === scoringDialogExerciseId
        )}
        config={
          scheduleSpecificExerciseConfigs[scoringDialogExerciseId] ||
          exercises.find((exercise) => exercise.id === scoringDialogExerciseId)
        }
        setConfig={(config) => {
          setScheduleSpecificExerciseConfigs({
            ...scheduleSpecificExerciseConfigs,
            [scoringDialogExerciseId]: {
              ...scheduleSpecificExerciseConfigs[scoringDialogExerciseId],
              scoreConfig: {
                ...scheduleSpecificExerciseConfigs[scoringDialogExerciseId]
                  ?.scoreConfig,
                ...config,
              },
            },
          });
        }}
      />
      <div style={{ display: "flex", justifyContent: "right" }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <h1>Protocol Configurator</h1>
      <Grid container spacing={2} direction="column" sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={4}
            minRows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name={"Days"}
            type="number"
            variant="outlined"
            // className="inputRounded"
            InputLabelProps={{ disableAnimation: true }}
            sx={{ width: "70%", marginBottom: 1 }}
            shrink={"true"}
            fullWidth
            label="days"
            value={days || ""}
            onChange={(e) => {
              setDays(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name={"Sessions"}
            type="number"
            variant="outlined"
            // className="inputRounded"
            InputLabelProps={{ disableAnimation: true }}
            sx={{ width: "70%", marginBottom: 1 }}
            shrink={"true"}
            fullWidth
            label="sessions"
            value={sessions || ""}
            onChange={(e) => {
              setSessions(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <h3>Exercises</h3>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <Grid
              {...provided.droppableProps}
              container
              spacing={4}
              sx={{ padding: 2 }}
              ref={provided.innerRef}
            >
              {exercises.map((exercise, index) => (
                <Draggable
                  key={"exercise-" + index}
                  draggableId={"exercise-" + index}
                  index={index}
                >
                  {(provided) => (
                    <Grid
                      item
                      key={"exercise-" + index}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <Card
                        sx={{
                          // show open hand cursor when hovering over card, not pointer
                          "&:hover": { cursor: "grab" },
                          height: "100%",
                          padding: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h1 style={{ cursor: "pointer", marginBottom: 10 }}>
                          {scheduleSpecificExerciseConfigs[exercise.id]?.name ||
                            exercise.name}
                        </h1>
                        <TextField
                          id="outlined-time-threshold"
                          label="Time Threshold"
                          variant="outlined"
                          type={"number"}
                          value={timeThreshValue(exercise)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            setScheduleSpecificExerciseConfigs({
                              ...scheduleSpecificExerciseConfigs,
                              [exercise.id]: {
                                ...scheduleSpecificExerciseConfigs[exercise.id],
                                timeThresh: e.target.value,
                              },
                            });
                          }}
                          sx={{ height: "100%", width: "50%" }}
                        />
                        <TextField
                          id="outlined-time-threshold"
                          label="Name"
                          variant="outlined"
                          type={"text"}
                          value={
                            scheduleSpecificExerciseConfigs[exercise.id]
                              ? scheduleSpecificExerciseConfigs[exercise.id]
                                  .name
                              : exercise.name
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            setScheduleSpecificExerciseConfigs(
                              (prevConfigs) => {
                                if (!prevConfigs[exercise.id]) {
                                  prevConfigs[exercise.id] = {};
                                }
                                return {
                                  ...prevConfigs,
                                  [exercise.id]: {
                                    ...prevConfigs[exercise.id],
                                    name: e.target.value,
                                  },
                                };
                              }
                            );
                          }}
                          sx={{ height: "100%", width: "50%", mt: 1 }}
                        />
                        <Button
                          variant={"contained"}
                          onClick={(e) => {
                            e.stopPropagation();
                            setScoringDialogExerciseId(exercise.id);
                          }}
                          sx={{ mt: 2, width: "70%" }}
                        >
                          Scoring
                        </Button>
                        <DeleteButton
                          onDelete={() => {
                            setExercises(
                              exercises.filter((_, i) => i !== index)
                            );
                          }}
                          deletionTarget={"Exercise"}
                        />
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}
              <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    padding: 2,
                    border: 1,
                    borderRadius: 5,
                    m: 2,
                  }}
                >
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      setExerciseDialogOpen(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {provided.placeholder}
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <h3>Schedules</h3>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <Grid
              {...provided.droppableProps}
              container
              spacing={4}
              sx={{ padding: 2 }}
              ref={provided.innerRef}
            >
              {schedules?.map((schedule, index) => (
                <Draggable
                  key={"schedule-" + index}
                  draggableId={"schedule-" + index}
                  index={index}
                >
                  {(provided) => (
                    <Grid
                      item
                      key={"schedule-" + index}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <Card
                        sx={{
                          // show open hand cursor when hovering over card, not pointer
                          "&:hover": { cursor: "grab" },
                          height: "100%",
                          padding: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h1 style={{ cursor: "pointer", marginBottom: 10 }}>
                          {scheduleSpecificScheduleConfigs[schedule.id]?.name ||
                            schedule.name}
                        </h1>
                        <TextField
                          label="Name"
                          variant="outlined"
                          value={
                            scheduleSpecificScheduleConfigs[schedule.id]
                              ? scheduleSpecificScheduleConfigs[schedule.id]
                                  .name
                              : schedule.name
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            setScheduleSpecificScheduleConfigs(
                              (prevConfigs) => {
                                if (!prevConfigs[schedule.id]) {
                                  prevConfigs[schedule.id] = {};
                                }
                                return {
                                  ...prevConfigs,
                                  [schedule.id]: {
                                    ...prevConfigs[schedule.id],
                                    name: e.target.value,
                                  },
                                };
                              }
                            );
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "50%", mt: 1 }}
                        />
                        <DeleteButton
                          onDelete={() => {
                            setSchedules(
                              schedules.filter((_, i) => i !== index)
                            );
                          }}
                          deletionTarget={"Schedule"}
                        />
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}
              <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    padding: 2,
                    border: 1,
                    borderRadius: 5,
                    m: 2,
                  }}
                >
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      setIsAddScheduleDialogOpen(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {provided.placeholder}
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        variant="contained"
        disabled={
          saving ||
          name.length === 0 ||
          description.length === 0 ||
          exercises.length === 0
        }
        onClick={saveProtocol}
      >
        {saving ? <CircularProgress /> : ctaBtnText}
      </Button>
      <ToastContainer />
    </Paper>
  );
}
