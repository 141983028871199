import React from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useBalanceCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import { loadBoatPoseAudio } from "../../../utilities/Demo/audioFeedback";
import { findAngle, findCounterAngle } from "../../../utilities/Demo/physio";
import PoseDetection from "../PoseDetection";
import { checkIfRightSide, checkIfSide } from "../../../utilities/Demo/angles";
import {
  leftKneeArc,
  leftShoulderArc,
  rightKneeArc,
  rightShoulderArc,
} from "../../../utilities/Demo/arcs";

function BoatPoseDemo({ onComplete }) {
  const timeThresh = 60;
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    playAudio,
    audioFeedback,
    setSide,
    cptScore,
    updatePoseHeldDeps,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadBoatPoseAudio,
  });

  cptScore.current = () => {
    return (
      (secondsPoseHeldComplete.current + secondsPoseHeld.current) /
      (timeThresh * 2)
    );
  };

  function giveFeedback(
    leftArmAngle,
    rightArmAngle,
    leftKneeAngle,
    rightKneeAngle,
    leftLegAngle,
    rightLegAngle
  ) {
    if (leftArmAngle <= 90) {
      updateFeedback("Raise your left arm higher!");
      playAudio(audioFeedback.current?.raiseLeftArm);
      return;
    }
    if (rightArmAngle <= 90) {
      updateFeedback("Raise your right arm higher!");
      playAudio(audioFeedback.current?.raiseRightArm);
      return;
    }
    if (leftKneeAngle <= 120) {
      updateFeedback("Straighten your left leg!");
      playAudio(audioFeedback.current?.keepLeftLegStraight);
      return;
    }
    if (rightKneeAngle <= 120) {
      updateFeedback("Straighten your right leg!");
      playAudio(audioFeedback.current?.keepRightLegStraight);
      return;
    }
    if (leftLegAngle <= 90) {
      updateFeedback("Lift your left leg!");
      playAudio(audioFeedback.current?.raiseRightLeg);
      return;
    }
    if (rightLegAngle <= 90) {
      updateFeedback("Lift your right leg!");
      playAudio(audioFeedback.current?.raiseLeftLeg);
    }
  }

  function observeBalance(
    leftArmAngle,
    rightArmAngle,
    leftKneeAngle,
    rightKneeAngle,
    leftLegAngle,
    rightLegAngle
    // canvasCtx
  ) {
    const state = holdsPose.current;
    if (
      leftArmAngle > 90 &&
      rightArmAngle > 90 &&
      leftKneeAngle > 120 &&
      rightKneeAngle > 120 &&
      leftLegAngle > 90 &&
      rightLegAngle > 90
    ) {
      holdsPose.current = true;
      secondsPoseHeld.current = seconds.current - secondPoseHeldStarted.current;
      updateFeedback("");
    } else {
      holdsPose.current = false;
      giveFeedback(
        leftArmAngle,
        rightArmAngle,
        leftKneeAngle,
        rightKneeAngle,
        leftLegAngle,
        rightLegAngle
      );
    }
    updatePoseHeldDeps(state);
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    let leftArmAngle;
    let rightArmAngle;
    let leftKneeAngle;
    let rightKneeAngle;
    let leftLegAngle;
    let rightLegAngle;
    let side = checkIfSide(results);
    if (side) {
      setSide(true);
      if (checkIfRightSide(results)) {
        leftArmAngle = findCounterAngle(
          results.poseLandmarks[11],
          results.poseLandmarks[13]
        );
        rightArmAngle = leftShoulderArc(canvasCtx, results, 80, 12, 14);
        leftKneeAngle = leftKneeArc(canvasCtx, results, 80);
        rightKneeAngle = rightKneeArc(canvasCtx, results, 80);
        leftLegAngle = findAngle(
          [results.poseLandmarks[24].x, results.poseLandmarks[24].y],
          [results.poseLandmarks[28].x, results.poseLandmarks[28].y]
        );
        rightLegAngle = findAngle(
          [results.poseLandmarks[23].x, results.poseLandmarks[23].y],
          [results.poseLandmarks[27].x, results.poseLandmarks[27].y]
        );
      } else {
        leftArmAngle = rightShoulderArc(canvasCtx, results, 80, 11, 13);
        rightArmAngle = findCounterAngle(
          results.poseLandmarks[12],
          results.poseLandmarks[14]
        );
        leftKneeAngle = leftKneeArc(canvasCtx, results, 80);
        rightKneeAngle = rightKneeArc(canvasCtx, results, 80);
        leftLegAngle = findAngle(
          [results.poseLandmarks[24].x, results.poseLandmarks[24].y],
          [results.poseLandmarks[28].x, results.poseLandmarks[28].y]
        );
        rightLegAngle = findAngle(
          [results.poseLandmarks[23].x, results.poseLandmarks[23].y],
          [results.poseLandmarks[27].x, results.poseLandmarks[27].y]
        );
      }
      observeBalance(
        leftArmAngle,
        rightArmAngle,
        leftKneeAngle,
        rightKneeAngle,
        leftLegAngle,
        rightLegAngle,
        canvasCtx
      );
    } else {
      playAudio(audioFeedback.current?.turnToSide);
      updateFeedback("Turn to the side!");
      setSide(false);
    }

    // Display the counts
    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(BoatPoseDemo);
