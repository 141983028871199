import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Card, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React, { useContext, useEffect } from "react";
import FirebaseAuthContext from "../../../../contexts/auth/FirebaseAuthContext";
import { useStartSchedule } from "../../../../hooks/Demo/exerciseManagement/startEntity";
import {
  deleteSchedule,
  getSchedules,
} from "../../../../utilities/Demo/ExerciseManagement/firestore";
import DeleteButton from "../DeleteButton";
import ScheduleConfigurator from "../Configurator/Schedule/ScheduleConfigurator";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

export default function Schedules({ searchValue }) {
  const [loading, setLoading] = React.useState(false);
  const [schedules, setSchedules] = React.useState([]);
  const [creatorOpen, setCreatorOpen] = React.useState(false);
  const [scheduleData, setScheduleData] = React.useState({});
  const { user } = useContext(FirebaseAuthContext);
  const startSchedule = useStartSchedule();

  const removeSchedule = (scheduleId) => {
    setSchedules(schedules.filter((schedule) => schedule.id !== scheduleId));
  };

  const addSchedule = (schedule) => {
    setSchedules([...schedules, schedule]);
  };

  const updateSchedule = (schedule) => {
    setSchedules(
      schedules.map((s) => {
        if (s.id === schedule.id) {
          return schedule;
        }
        return s;
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    getSchedules({ searchTerm: searchValue, userId: user.uid }).then(
      (schedules) => {
        setLoading(false);
        setSchedules(schedules || []);
      }
    );
  }, [searchValue]);

  return creatorOpen ? (
    <ScheduleConfigurator
      onClose={() => setCreatorOpen(false)}
      addScheduleToList={addSchedule}
      updateScheduleInList={updateSchedule}
      scheduleData={scheduleData}
    />
  ) : (
    <Box sx={{ padding: 3 }}>
      <h1>Schedule</h1>
      <Grid container spacing={2}>
        <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: 2,
              border: 1,
              borderRadius: 5,
              m: 2,
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setScheduleData({});
                setCreatorOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
        {loading && <CircularProgress />}
        {schedules.map((schedule) => {
          return (
            <Grid
              item
              key={schedule.name}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => {
                setScheduleData(schedule);
                setCreatorOpen(true);
              }}
              sx={{ ":hover": { cursor: "pointer" } }}
            >
              <Card sx={{ padding: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 1,
                  }}
                >
                  <Grid item xs={8}>
                    <h1>{schedule.name}</h1>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setScheduleData({
                          ...schedule,
                          id: null,
                          name: schedule.name + " (copy)",
                        });
                        setCreatorOpen(true);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spcacing={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <DeleteButton
                    onDelete={() => {
                      deleteSchedule({ scheduleId: schedule.id }).then(() => {
                        removeSchedule(schedule.id);
                      });
                    }}
                    deletionTarget={"Schedules " + schedule.name}
                  />
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="delete patient"
                    onClick={(e) => {
                      e.stopPropagation();
                      startSchedule({ scheduleDoc: schedule });
                    }}
                  >
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
