import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import { query, getDocs, collection, where, addDoc } from "firebase/firestore";

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    } else {
      if (docs.docs[0].data().authProvider !== "google") {
        alert(
          "This email is already registered with another account. Please use the other login method."
        );
      }
    }
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
  window.location.reload();
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    console.log("response is ", res);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name: user.displayName,
      authProvider: "emailAndPassword",
      email: user.email,
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
const registerWithEmailAndPassword = async (
  email,
  password,
  name,
  birthdate,
  sex
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      birthdate,
      sex: sex || "non-binary",
    });
  } catch (err) {
    console.error(err);
    return err;
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};
const logout = () => {
  try {
    signOut(auth);
  } catch (err) {
    console.error(err);
  }
};

function sendMagicLinkToEmail(email) {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `${window.location.href
      .split("/")
      .slice(0, 3)
      .join("/")}/login/physio?signInType=emailLink`,
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: `${
    //     process.env.REACT_APP_GIT_TAG ? 'production' : 'staging'
    //   }.app.exar.fit`,
    // },
    // android: {
    //   packageName: `${
    //     process.env.REACT_APP_GIT_TAG ? 'production' : 'staging'
    //   }.app.exar.fit`,
    //   installApp: true,
    //   minimumVersion: '12',
    // },
    // dynamicLinkDomain: `${
    //   process.env.REACT_APP_GIT_TAG ? 'production' : 'staging'
    // }.app.exar.fit`,
  };
  return sendSignInLinkToEmail(auth, email, actionCodeSettings);
}

const logInWithEmailLink = async (email) => {
  let success = true;
  // Confirm the link is a sign-in with email link.
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation");
    }
    // The client SDK will parse the code from the link for you.
    await signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        console.log(error);
        success = false;
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  }
  return success;
};

export {
  signInWithGoogle,
  logInWithEmailLink,
  sendMagicLinkToEmail,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
