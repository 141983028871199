// src/components/PressureMetrics.jsx
import Grid from "@mui/material/Grid";
import React from "react";
import Overpronation from "../../../SensorResults/Overpronation";
import StrikeDistribution from "../../../SensorResults/StrikeDistribution";

const PressureMetrics = ({ setLoading, sensorData }) => {
  if (!sensorData) {
    return null;
  } else {
    setLoading(false);
  }

  return (
    <Grid
      style={{
        marginLeft: 50,
        marginTop: 20,
        overflow: "hidden",
      }}
    >
      <div style={{ position: "relative" }}>
        <Overpronation
          meanLeft={sensorData?.meanLeft}
          meanRight={sensorData?.meanRight}
        />
        <StrikeDistribution
          leftStrikeDistribution={sensorData?.leftStrikeDistribution}
          rightStrikeDistribution={sensorData?.rightStrikeDistribution}
        />
      </div>
    </Grid>
  );
};

export default PressureMetrics;
