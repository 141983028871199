import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PatientInfoProvider = ({ children }) => {
  const navigate = useNavigate();
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );

  const protectedRoutes = [
    "/gait_analysis",
    "/gait_result",
    "/sit_and_stand",
    "/sit_and_stand_result",
  ];

  useEffect(() => {
    if (!patientInfo.name) {
      if (
        protectedRoutes.includes(window.location.pathname) &&
        window.location.pathname !== "/all_patients"
      ) {
        navigate("/all_patients");
      }
    }
  }, []);

  return children;
};
export default PatientInfoProvider;
