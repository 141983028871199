import React from 'react';
import pose from '../../assets/pose-progress-bcg.png';
import '../Progress/progress.css';

const ChatBgComponent = () => {
    return ( <
        div style = {
            {
                position: 'fixed',
                width: '100%',
                height: '100%',
                zIndex: '-100',
                backgroundColor: '#FCFCF9',
                overflow: 'hidden',
            }
        } >
        <
        img className = "progress-bcg"
        src = {
            pose
        }
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                bottom: '0',
                zIndex: '-100',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '30%',
            }
        }
        /> <
        /div>
    );
};

export default ChatBgComponent;