import React from "react";
// import { useTranslation } from 'react-i18next';
import "../assessments.css";
import cardio from "../../../../assets/cardio.png";
import flexiblity from "../../../../assets/flexiblity.png";
import mendurance from "../../../../assets/mendurance.png";
import mstrength from "../../../../assets/mstrength.png";
import sbalance1 from "../../../../assets/sbalance1.png";
import sbalance2 from "../../../../assets/sbalance2.png";
import { ArcherContainer, ArcherElement } from "react-archer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

export const AssessmentPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:900px)");
  const handleExerciseClick = (iteration) => {
    return () => {
      dispatch({
        type: "SET-ITER-VAlUES",
        payload: {
          iteration: iteration,
          overviewRedirect: null,
          exerciseIds: null,
        },
      });
      navigate("/demos/fitness_assessment");
    };
  };
  return (
    <>
      {" "}
      {matches ? (
        <div className="assessment-cont">
          <div className="assessment-path">
            <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="first_row">
                  <ArcherElement
                    id="root"
                    relations={[
                      {
                        targetId: "sb2",
                        targetAnchor: "top",
                        sourceAnchor: "bottom",
                        style: {
                          endMarker: false,
                        },
                      },
                    ]}
                  >
                    <div
                      className="img_container"
                      id="flexi"
                      onClick={handleExerciseClick(0)}
                    >
                      <div> Flexibility </div> <img src={flexiblity} />{" "}
                    </div>{" "}
                  </ArcherElement>{" "}
                  <ArcherElement id="mstren">
                    <div
                      className="img_container"
                      onClick={handleExerciseClick(2)}
                    >
                      <div> Muscular Strength </div> <img src={mstrength} />{" "}
                    </div>{" "}
                  </ArcherElement>{" "}
                </div>{" "}
                <div
                  className="first_row"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <ArcherElement
                    id="sb2"
                    relations={[
                      {
                        targetId: "mstren",
                        targetAnchor: "bottom",
                        sourceAnchor: "top",
                      },
                    ]}
                  >
                    <div
                      className="img_container"
                      onClick={handleExerciseClick(4)}
                    >
                      <div> Static Balance 2 </div> <img src={sbalance2} />{" "}
                    </div>{" "}
                  </ArcherElement>{" "}
                </div>{" "}
              </div>{" "}
            </ArcherContainer>{" "}
          </div>{" "}
          <div className="assessment-path">
            <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="first_row">
                  <ArcherElement
                    id="mend"
                    relations={[
                      {
                        targetId: "cdio",
                        targetAnchor: "top",
                        sourceAnchor: "bottom",
                        style: {
                          endMarker: false,
                        },
                      },
                    ]}
                  >
                    <div
                      className="img_container"
                      onClick={handleExerciseClick(1)}
                    >
                      <div> Muscular Endurance </div> <img src={mendurance} />{" "}
                    </div>{" "}
                  </ArcherElement>{" "}
                  <ArcherElement id="sb1">
                    <div
                      className="img_container"
                      onClick={handleExerciseClick(3)}
                    >
                      <div> Static Balance 1 </div> <img src={sbalance1} />{" "}
                    </div>{" "}
                  </ArcherElement>{" "}
                </div>{" "}
                <div
                  className="first_row"
                  style={{
                    justifyContent: "center",
                  }}
                >
                  <ArcherElement
                    id="cdio"
                    relations={[
                      {
                        targetId: "sb1",
                        targetAnchor: "bottom",
                        sourceAnchor: "top",
                        style: {
                          endMarker: true,
                        },
                      },
                    ]}
                  >
                    <div
                      className="img_container"
                      onClick={handleExerciseClick(5)}
                    >
                      <div> Cardio Vascular </div> <img src={cardio} />{" "}
                    </div>{" "}
                  </ArcherElement>{" "}
                </div>{" "}
              </div>{" "}
            </ArcherContainer>{" "}
          </div>{" "}
        </div>
      ) : (
        <div
          className="top-left article"
          style={{
            padding: "10% 4px",
            height: "80vh",
            width: "auto",
            marginTop: "1em",
          }}
        >
          <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
            <div
              style={{
                display: "flex",
                height: "60vh",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="first_row">
                <ArcherElement
                  id="root"
                  relations={[
                    {
                      targetId: "mend",
                      targetAnchor: "top",
                      sourceAnchor: "bottom",
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container"
                    id="flexi"
                    onClick={handleExerciseClick(0)}
                  >
                    <div> Flexibility </div> <img src={flexiblity} />{" "}
                  </div>{" "}
                </ArcherElement>{" "}
                <ArcherElement
                  id="mstren"
                  relations={[
                    {
                      targetId: "sb1",
                      targetAnchor: "top",
                      sourceAnchor: "bottom",
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container"
                    onClick={handleExerciseClick(2)}
                  >
                    <div> Muscular Strength </div> <img src={mstrength} />{" "}
                  </div>{" "}
                </ArcherElement>{" "}
                <ArcherElement
                  id="sb2"
                  relations={[
                    {
                      targetId: "cardio",
                      targetAnchor: "top",
                      sourceAnchor: "bottom",
                    },
                  ]}
                >
                  <div
                    className="img_container"
                    onClick={handleExerciseClick(4)}
                  >
                    <div> Static Balance 2 </div> <img src={sbalance2} />{" "}
                  </div>{" "}
                </ArcherElement>{" "}
              </div>{" "}
              <div
                className="first_row"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <ArcherElement
                  id="mend"
                  relations={[
                    {
                      targetId: "mstren",
                      targetAnchor: "bottom",
                      sourceAnchor: "top",
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container"
                    onClick={handleExerciseClick(1)}
                  >
                    <img src={mendurance} /> <div> Muscular Endurance </div>{" "}
                  </div>{" "}
                </ArcherElement>{" "}
                <ArcherElement
                  id="sb1"
                  relations={[
                    {
                      targetId: "sb2",
                      targetAnchor: "bottom",
                      sourceAnchor: "top",
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container"
                    onClick={handleExerciseClick(3)}
                  >
                    <img src={sbalance1} /> <div> Static Balance 1 </div>{" "}
                  </div>{" "}
                </ArcherElement>{" "}
                <ArcherElement id="cardio">
                  <div
                    className="img_container"
                    onClick={handleExerciseClick(5)}
                  >
                    <img src={cardio} /> <div> Cardio Vascular </div>{" "}
                  </div>{" "}
                </ArcherElement>{" "}
              </div>{" "}
            </div>{" "}
          </ArcherContainer>{" "}
        </div>
      )}{" "}
    </>
  );
};
