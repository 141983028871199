import {
    getBlob,
    getDownloadURL,
    ref
} from 'firebase/storage';
import {
    storage
} from '../../firebase';

const baseAudioUrl =
    'https://exarindia2.s3.ap-south-1.amazonaws.com/audio-feedback/';

async function fetchAudio(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
}

async function createAudioElementFromGS(filePath) {
    try {
        const fileRef = ref(storage, filePath);
        const fileBlob = await getBlob(fileRef);
        return new Audio(URL.createObjectURL(fileBlob));
    } catch (error) {
        console.log(error);
    }
}

export async function createAudioElement(url) {
    const objectUrl = await fetchAudio(url);
    return new Audio(objectUrl);
}

export class AudioQueue {
    constructor() {
        this.queue = [];
        this.isPlaying = false;
    }

    addAudio(newAudio) {
        if (newAudio !== undefined) {
            this.queue.push(newAudio);
            this.playNextAudio();
        }
    }

    deleteQueue() {
        this.queue = [];
    }

    playNextAudio() {
        if (!this.isPlaying && this.queue.length > 0) {
            this.isPlaying = true;
            const currentAudio = this.queue.shift();
            currentAudio.onended = () => {
                this.isPlaying = false;
                this.playNextAudio();
            };
            currentAudio.play();
        }
    }
}

async function createAudioStreamFromGS(filePath) {
    try {
        const fileRef = ref(storage, filePath);
        // Get the URL of the file
        const audioUrl = await getDownloadURL(fileRef);
        // Optionally, set event listeners for audio events (like 'loadeddata', 'error', etc.)
        // audio.addEventListener('loadeddata', () => {
        //   console.log('Audio is loaded and ready to play');
        // });

        return new Audio(audioUrl);
    } catch (error) {
        console.log(error);
    }
}

export async function loadIntroAudio(config) {
    if (config.introAudioFeedbackId) {
        return await createAudioStreamFromGS(
            'audios/' + config.introAudioFeedbackId
        );
    } else if (config.id) {
        return await createAudioStreamFromGS('audio/intros/' + config.id);
    }
    return null;
}

export async function loadExerciseConfigAudio(config, language = 'en') {
    const audio = {};
    if (config.introAudioFeedbackId) {
        audio.intro = await createAudioElementFromGS(
            'audios/' + config.introAudioFeedbackId
        );
    } else {
        audio.intro = await createAudioElementFromGS('audio/intros/' + config.id);
    }
    if (config.feedback) {
        for (const feedback of config.feedback) {
            if (feedback.audioFeedback) {
                audio[feedback.audioFeedback] = await createAudioElementFromGS(
                    'audios/' + feedback.audioFeedback
                );
            }
        }
    }
    if (config.type === 'balance') {
        audio.thatIsPerfect = await createAudioElement(
            baseAudioUrl + 'qs/that_is_perfect.mp3'
        );
    }
    switch (language) {
        case 'ger':
            if (config.view === 'side') {
                audio.turnToSide = await createAudioElement(
                    baseAudioUrl + 'qs/SBL-0001_Drehen-Sie-sich-zur-Seite_24k_stereo.mp3'
                );
                audio.turnToOtherSide = await createAudioElement(
                    baseAudioUrl +
                    'qs/SBL-0002_Drehen-Sie-sich-zur-anderen-Se_24k_stereo.mp3'
                );
                if (config.side) {
                    audio.makeSureRightSide = await createAudioElement(
                        baseAudioUrl +
                        'qs/SBL-0004_Drehen-Sie-ihre-rechte-Seite-z_24k_stereo.mp3'
                    );
                    audio.makeSureLeftSide = await createAudioElement(
                        baseAudioUrl +
                        'qs/SBL-0003_Drehen-Sie-ihre-linke-Seite-zu_24k_stereo.mp3'
                    );
                }
            } else if (config.view === 'front') {
                audio.faceCamera = await createAudioElement(
                    baseAudioUrl + 'qs/SBL-0005_Drehen-Sie-sich-zur-Kamera_24k_stereo.mp3'
                );
            }
            break;
        default:
            if (config.view === 'side') {
                audio.turnToSide = await createAudioElement(
                    baseAudioUrl + 'qs/turn_to_the_side.mp3'
                );
                audio.turnToOtherSide = await createAudioElement(
                    baseAudioUrl + 'qs/turn_to_the_other_side.mp3'
                );
                if (config.side) {
                    audio.makeSureRightSide = await createAudioElement(
                        baseAudioUrl + 'qs/make_sure_right_side.mp3'
                    );
                    audio.makeSureLeftSide = await createAudioElement(
                        baseAudioUrl + 'qs/make_sure_left_side.mp3'
                    );
                }
            } else if (config.view === 'front') {
                audio.faceCamera = await createAudioElement(
                    baseAudioUrl + 'qs/face_the_camera.mp3'
                );
            }
    }
    audio.achievement = await createAudioElement(
        baseAudioUrl + 'qs/achieve_sound.mp3'
    );
    if (config.coreAudioFeedbackConfig) {
        for (const key in config.coreAudioFeedbackConfig) {
            if (key === 'thatIsPerfect' && config.type !== 'balance') continue;
            if (config.coreAudioFeedbackConfig[key]) {
                audio[key] = await createAudioElementFromGS(
                    'audios/' + config.coreAudioFeedbackConfig[key]
                );
            }
        }
        if (Object.keys(config.coreAudioFeedbackConfig).length > 0) {
            return audio;
        }
    }
    return audio;
}

export async function loadFlamingoPoseAudio(side) {
    if (side === 'left') {
        return {
            intro: await createAudioElement(
                baseAudioUrl + 'intros/flamingo_pose.mp3'
            ),
            thatIsPerfect: await createAudioElement(
                baseAudioUrl + 'qs/that_is_perfect.mp3'
            ),
            achievement: await createAudioElement(
                baseAudioUrl + 'qs/achieve_sound.mp3'
            ),
            raiseLeg: await createAudioElement(
                baseAudioUrl + 'qs/raise_your_right_leg_higher.mp3'
            ),
            raiseArm: await createAudioElement(
                baseAudioUrl + 'qs/raise_your_left_arm_higher.mp3'
            ),
            keepStandingLegStraight: await createAudioElement(
                baseAudioUrl + 'qs/keep_your_left_leg_straight.mp3'
            ),
            bendKneeMore: await createAudioElement(
                baseAudioUrl + 'qs/bend_your_right_leg_more.mp3'
            ),
            makeSureSide: await createAudioElement(
                baseAudioUrl + 'qs/make_sure_right_side.mp3'
            ),
        };
    } else {
        return {
            intro: await createAudioElement(
                baseAudioUrl + 'intros/flamingo_pose.mp3'
            ),
            thatIsPerfect: await createAudioElement(
                baseAudioUrl + 'qs/that_is_perfect.mp3'
            ),
            achievement: await createAudioElement(
                baseAudioUrl + 'qs/achieve_sound.mp3'
            ),
            raiseLeg: await createAudioElement(
                baseAudioUrl + 'qs/raise_your_left_leg_higher.mp3'
            ),
            raiseArm: await createAudioElement(
                baseAudioUrl + 'qs/raise_your_right_arm_higher.mp3'
            ),
            keepStandingLegStraight: await createAudioElement(
                baseAudioUrl + 'qs/keep_your_right_leg_straight.mp3'
            ),
            bendKneeMore: await createAudioElement(
                baseAudioUrl + 'qs/bend_your_left_leg_more.mp3'
            ),
            makeSureSide: await createAudioElement(
                baseAudioUrl + 'qs/make_sure_left_side.mp3'
            ),
        };
    }
}

export async function loadTreePoseAudio(side) {
    let audio = {
        intro: await createAudioElement(baseAudioUrl + 'intros/tree_pose.mp3'),
        thatIsPerfect: await createAudioElement(
            baseAudioUrl + 'qs/that_is_perfect.mp3'
        ),
        achievement: await createAudioElement(
            baseAudioUrl + 'qs/achieve_sound.mp3'
        ),
        faceCamera: await createAudioElement(
            baseAudioUrl + 'qs/face_the_camera.mp3'
        ),
        raiseLeftArm: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_left_arm_higher.mp3'
        ),
        raiseRightArm: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_right_arm_higher.mp3'
        ),
        raiseOtherLeg: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_other_leg.mp3'
        ),
    };
    if (side === 'left') {
        audio = {
            ...audio,
            keepStandingLegStraight: await createAudioElement(
                baseAudioUrl + 'qs/keep_your_left_leg_straight.mp3'
            ),
            raiseLeg: await createAudioElement(
                baseAudioUrl + 'qs/raise_your_right_leg_higher.mp3'
            ),
        };
    } else {
        audio = {
            ...audio,
            keepStandingLegStraight: await createAudioElement(
                baseAudioUrl + 'qs/keep_your_right_leg_straight.mp3'
            ),
            raiseLeg: await createAudioElement(
                baseAudioUrl + 'qs/raise_your_left_leg_higher.mp3'
            ),
        };
    }
    return audio;
}

export async function loadBoatPoseAudio() {
    return {
        intro: await createAudioElement(baseAudioUrl + 'intros/boat_pose.mp3'),
        achievement: await createAudioElement(
            baseAudioUrl + 'qs/achieve_sound.mp3'
        ),
        thatIsPerfect: await createAudioElement(
            baseAudioUrl + 'qs/that_is_perfect.mp3'
        ),
        turnToSide: await createAudioElement(
            baseAudioUrl + 'qs/turn_to_the_side.mp3'
        ),
        raiseLeftLeg: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_left_leg_higher.mp3'
        ),
        raiseRightLeg: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_right_leg_higher.mp3'
        ),
        raiseLeftArm: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_left_arm_higher.mp3'
        ),
        raiseRightArm: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_right_arm_higher.mp3'
        ),
        keepLeftLegStraight: await createAudioElement(
            baseAudioUrl + 'qs/keep_your_left_leg_straight.mp3'
        ),
        keepRightLegStraight: await createAudioElement(
            baseAudioUrl + 'qs/keep_your_right_leg_straight.mp3'
        ),
    };
}

export async function loadVStretchAudio() {
    return {
        intro: await createAudioElement(baseAudioUrl + 'intros/v_sit_stretch.mp3'),
        achievement: await createAudioElement(
            baseAudioUrl + 'qs/achieve_sound.mp3'
        ),
        turnToSide: await createAudioElement(
            baseAudioUrl + 'qs/turn_to_the_side.mp3'
        ),
        keepLegsStraight: await createAudioElement(
            baseAudioUrl + 'qs/keep_your_legs_straight.mp3'
        ),
    };
}

export async function loadHighKneesAudio() {
    return {
        intro: await createAudioElement(baseAudioUrl + 'intros/high_knees.mp3'),
        achievement: await createAudioElement(
            baseAudioUrl + 'qs/achieve_sound.mp3'
        ),
        faceCamera: await createAudioElement(
            baseAudioUrl + 'qs/face_the_camera.mp3'
        ),
        raiseLeftLeg: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_left_leg_higher.mp3'
        ),
        raiseRightLeg: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_right_leg_higher.mp3'
        ),
        keepBackStraight: await createAudioElement(
            baseAudioUrl + 'qs/keep_your_back_straight.mp3'
        ),
    };
}

export async function loadPushUpAudio() {
    return {
        intro: await createAudioElement(baseAudioUrl + 'intros/push_ups.mp3'),
        achievement: await createAudioElement(
            baseAudioUrl + 'qs/achieve_sound.mp3'
        ),
        turnToSide: await createAudioElement(
            baseAudioUrl + 'qs/turn_to_the_side.mp3'
        ),
        goLower: await createAudioElement(baseAudioUrl + 'qs/go_lower.mp3'),
        straightenNeck: await createAudioElement(
            baseAudioUrl + 'qs/straighten_your_neck.mp3'
        ),
        hipsUp: await createAudioElement(baseAudioUrl + 'qs/keep_your_hips_up.mp3'),
    };
}

export async function loadSquatsAudio() {
    return {
        intro: await createAudioElement(baseAudioUrl + 'intros/squats.mp3'),
        turnToSide: await createAudioElement(
            baseAudioUrl + 'qs/turn_to_the_side.mp3'
        ),
        achievement: await createAudioElement(
            baseAudioUrl + 'qs/achieve_sound.mp3'
        ),
        lowerHips: await createAudioElement(
            baseAudioUrl + 'qs/lower_your_hips.mp3'
        ),
        kneesFallingOverToes: await createAudioElement(
            baseAudioUrl + 'qs/knees_falling_over_toes.mp3'
        ),
        keepBackStraight: await createAudioElement(
            baseAudioUrl + 'qs/keep_your_back_straight.mp3'
        ),
        tooDeep: await createAudioElement(baseAudioUrl + 'qs/too_deep.mp3'),
    };
}

export async function loadPhysioAudio() {
    return {
        // intro: await createAudioElement(baseAudioUrl + 'intros/physio.mp3'),
        faceCamera: await createAudioElement(
            baseAudioUrl + 'qs/face_the_camera.mp3'
        ),
        achievement: await createAudioElement(
            baseAudioUrl + 'qs/achieve_sound.mp3'
        ),
        raiseArmOver: await createAudioElement(
            baseAudioUrl + 'qs/raise_your_arm_over.mp3'
        ),
    };
}

export async function loadLevelsAudio() {
    const baseAudioUrl =
        'https://exarindia2.s3.ap-south-1.amazonaws.com/audio-feedback/levels/';
    const audios = {
        newLevel: await createAudioElement(baseAudioUrl + 'new_level.mp3'),
    };
    for (let i = 1; i <= 10; i++) {
        audios[i] = await createAudioElement(baseAudioUrl + 'level_' + i + '.mp3');
    }
    return audios;
}

// 'sittostand',
//   'standunsupported',
//   'situnsupported',
//   'standtosit',
//   'transferbetweenchairs',
//   'standunsupportedwitheyesclosed',
//   'standwithfeettogether',
//   'reachforwardwitharmoutstretched',
//   'pickupoffofthefloor',
//   'turnandlookbehind',
//   'turnaroundincompletecircle',
//   'placealternatefootonstep',
//   'standwithfeetinline',
//   'standononeleg',

async function loadBergBasicsAudio() {
    return {
        turnToSide: await createAudioElement(
            baseAudioUrl + 'qs/turn_to_the_side.mp3'
        ),
        achievement: await createAudioElement(
            baseAudioUrl + 'qs/achieve_sound.mp3'
        ),
        faceCamera: await createAudioElement(
            baseAudioUrl + 'qs/face_the_camera.mp3'
        ),
    };
}

export async function loadSitToStandAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/sitting_to_standing.mp3'
    );
    return audios;
}

export async function loadStandUnsupportedAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/standing_unsupported.mp3'
    );
    return audios;
}

export async function loadSitUnsupportedAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/sitting_unsupported.mp3'
    );
    return audios;
}

export async function loadStandToSitAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/standing_to_sitting.mp3'
    );
    return audios;
}

export async function loadTransferBetweenChairsAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/transfers.mp3'
    );
    return audios;
}

export async function loadStandUnsupportedWithEyesClosedAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/standing_with_eyes_closed.mp3'
    );
    return audios;
}

export async function loadStandWithFeetTogetherAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/standing_with_feet_together.mp3'
    );
    return audios;
}

export async function loadReachForwardWithArmOutstretchedAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/reaching_forward_with_outstretched_arm.mp3'
    );
    return audios;
}

export async function loadPickUpOffOfTheFloorAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/retrieve_object_from_floor.mp3'
    );
    return audios;
}

export async function loadTurnAndLookBehindAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/turning_to_look_behind.mp3'
    );
    return audios;
}

export async function loadTurnAroundInCompleteCircleAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/turning_360_degrees.mp3'
    );
    return audios;
}

export async function loadPlaceAlternateFootOnStepAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/placing_alternate_foot_on_step.mp3'
    );
    return audios;
}

export async function loadStandWithFeetInLineAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/standing_unsupported_with_feet_in_line.mp3'
    );
    return audios;
}

export async function loadStandOnOneLegAudio() {
    const audios = await loadBergBasicsAudio();
    audios.intro = await createAudioElement(
        baseAudioUrl + 'intros/standing_on_one_foot.mp3'
    );
    return audios;
}