import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Card, CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import { emitToast } from "../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts";
import {
  addSchedule,
  getExercise,
  getSchedule,
  updateSchedule,
} from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import DeleteButton from "../../DeleteButton";
import BasicSelect from "../BasicSelect";
import ExerciseConfigurator from "../Exercise/ExerciseConfigurator";
import TagConfig from "../TagConfig";
import CoreAudioConfig from "./CoreAudioConfig";
import ExerciseDialog from "./ExerciseDialog";
import IntroAudioDialog from "./IntroAudioDialog";
import ScoringDialog from "./ScoringDialog";
import VideoDialog from "./VideoDialog";

export default function ScheduleConfigurator({
  onClose,
  scheduleData = {},
  addScheduleToList,
  updateScheduleInList,
}) {
  const dispatch = useDispatch();
  const [id, setId] = React.useState(scheduleData.id || null);
  const [name, setName] = React.useState(scheduleData.name || "");
  const [description, setDescription] = React.useState(
    scheduleData.description || ""
  );
  const [overviewRedirect, setOverviewRedirect] = React.useState(
    scheduleData.overviewRedirect || ""
  );
  const [exercises, setExercises] = React.useState(
    scheduleData.exercises || []
  );
  const [scheduleSpecificExerciseConfigs, setScheduleSpecificExerciseConfigs] =
    React.useState(scheduleData.scheduleSpecificExerciseConfigs || {});
  const [exerciseDialogOpen, setExerciseDialogOpen] = React.useState(false);
  const [scoringDialogExerciseId, setScoringDialogExerciseId] =
    React.useState(null);
  const [videoUploadDialogExerciseId, setVideoUploadDialogExerciseId] =
    React.useState(null);
  const [introAudioDialogExerciseId, setIntroAudioDialogExerciseId] =
    React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [tags, setTags] = React.useState(scheduleData.tags || []);
  const [selectedExercise, setSelectedExercise] = React.useState(null);
  const [showSummaries, setShowSummaries] = React.useState(
    scheduleData.showSummaries === undefined ? true : scheduleData.showSummaries
  );
  const [showScore, setShowScore] = React.useState(
    scheduleData.showScore === undefined ? true : scheduleData.showScore
  );
  const [language, setLanguage] = React.useState(scheduleData.language || "en");
  const [exerciseConfiguratorOpen, setExerciseConfiguratorOpen] =
    React.useState(false);
  const [coreAudioFeedbackConfig, setCoreAudioFeedbackConfig] = React.useState(
    scheduleData.coreAudioFeedbackConfig || {}
  );
  const { user } = React.useContext(FirebaseAuthContext);
  const buttonText = id ? "Update" : "Create";

  console.log("data i receive", scheduleData);

  const addExercise = (exercise) => {
    setExercises([...exercises, exercise]);
  };

  const updateExercise = (exercise) => {
    setExercises(exercises.map((e) => (e.id === exercise.id ? exercise : e)));
  };

  React.useEffect(() => {
    async function fetchExercises() {
      const promises = exercises.map(async (exercise) => {
        if (typeof exercise === "string") {
          return await getExercise({ exerciseId: exercise });
        } else {
          return exercise;
        }
      });

      // Use Promise.all() to wait for all the promises to resolve
      return Promise.all(promises);
    }

    fetchExercises().then((exerciseDocs) => {
      console.log("excercirse", exerciseDocs);
      setExercises(exerciseDocs);
    });
    fetchSchedulesAndExercises();
  }, []);

  const fetchExercisesForSchedule = async (schedule) => {
    const promises = schedule.exercises.map(async (exerciseId) => {
      return await getExercise({ exerciseId });
    });
    return await Promise.all(promises);
  };

  const fetchSchedulesAndExercises = async () => {
    const schedulePromises = scheduleData.schedules.map(async (scheduleId) => {
      const schedule = await getSchedule({ scheduleId });
      const exercises = await fetchExercisesForSchedule(schedule);
      return { ...schedule, exercises };
    });
    const schedulesWithExercises = await Promise.all(schedulePromises);
    const allExercises = schedulesWithExercises.flatMap((s) => s.exercises);
    setExercises((prevExercises) => {
      const existingExerciseIds = new Set(prevExercises.map((e) => e.id));
      const newUniqueExercises = allExercises.filter(
        (e) => !existingExerciseIds.has(e.id)
      );
      return [...prevExercises, ...newUniqueExercises];
    });
  };

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...exercises];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setExercises(updatedList);
  };

  const saveSchedule = () => {
    setSaving(true);
    const newScheduleData = {
      ...scheduleData,
      name,
      description,
      overviewRedirect,
      scheduleSpecificExerciseConfigs,
      language,
      showSummaries: Boolean(showSummaries),
      showScore: Boolean(showScore),
      tags: tags.map((tag) => tag.id),
      exercises: exercises.map((exercise) => exercise.id),
      coreAudioFeedbackConfig: coreAudioFeedbackConfig,
    };
    if (id) {
      updateSchedule({ user, scheduleData: newScheduleData }).then(
        (scheduleDoc) => {
          setSaving(false);
          updateScheduleInList({ ...newScheduleData, id });
          if (scheduleDoc) {
            emitToast({
              render: "Saved!",
              type: "success",
            });
          } else {
            emitToast({
              render: "Error saving schedule!",
              type: "error",
            });
          }
        }
      );
    } else {
      addSchedule({ user, scheduleData: newScheduleData }).then((schedule) => {
        setSaving(false);
        setId(schedule.id);
        addScheduleToList({ ...newScheduleData, id: schedule.id });
        if (schedule) {
          emitToast({
            render: "Saved!",
            type: "success",
          });
        } else {
          emitToast({
            render: "Error saving schedule!",
            type: "error",
          });
        }
      });
    }
  };

  if (exerciseConfiguratorOpen) {
    return (
      <ExerciseConfigurator
        updateExerciseInParent={updateExercise}
        exerciseConfig={selectedExercise}
        onClose={() => setExerciseConfiguratorOpen(false)}
      />
    );
  }

  const timeThreshValue = (exercise) => {
    if (scheduleSpecificExerciseConfigs[exercise.id]) {
      if (
        scheduleSpecificExerciseConfigs[exercise.id].timeThresh !== undefined
      ) {
        return scheduleSpecificExerciseConfigs[exercise.id].timeThresh;
      } else {
        return exercise.timeThresh;
      }
    } else {
      return exercise.timeThresh;
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <ExerciseDialog
        open={exerciseDialogOpen}
        onClose={() => setExerciseDialogOpen(false)}
        setExercise={addExercise}
      />
      <ScoringDialog
        open={Boolean(scoringDialogExerciseId)}
        onClose={() => setScoringDialogExerciseId(null)}
        exercise={exercises.find(
          (exercise) => exercise.id === scoringDialogExerciseId
        )}
        config={
          scheduleSpecificExerciseConfigs[scoringDialogExerciseId] ||
          exercises.find((exercise) => exercise.id === scoringDialogExerciseId)
        }
        setConfig={(config) => {
          setScheduleSpecificExerciseConfigs({
            ...scheduleSpecificExerciseConfigs,
            [scoringDialogExerciseId]: {
              ...scheduleSpecificExerciseConfigs[scoringDialogExerciseId],
              scoreConfig: {
                ...scheduleSpecificExerciseConfigs[scoringDialogExerciseId]
                  ?.scoreConfig,
                ...config,
              },
            },
          });
        }}
      />
      <VideoDialog
        open={Boolean(videoUploadDialogExerciseId)}
        onClose={() => setVideoUploadDialogExerciseId(null)}
        exercise={exercises.find(
          (exercise) => exercise.id === videoUploadDialogExerciseId
        )}
        config={
          scheduleSpecificExerciseConfigs[videoUploadDialogExerciseId] ||
          exercises.find(
            (exercise) => exercise.id === videoUploadDialogExerciseId
          )
        }
        setVideoId={(videoId) => {
          setScheduleSpecificExerciseConfigs({
            ...scheduleSpecificExerciseConfigs,
            [videoUploadDialogExerciseId]: {
              ...scheduleSpecificExerciseConfigs[videoUploadDialogExerciseId],
              videoId,
            },
          });
        }}
      />
      <IntroAudioDialog
        open={Boolean(introAudioDialogExerciseId)}
        onClose={() => setIntroAudioDialogExerciseId(null)}
        exercise={exercises.find(
          (exercise) => exercise.id === introAudioDialogExerciseId
        )}
        config={
          scheduleSpecificExerciseConfigs[introAudioDialogExerciseId] ||
          exercises.find(
            (exercise) => exercise.id === introAudioDialogExerciseId
          )
        }
        setIntroAudioFeedbackId={(introAudioFeedbackId) => {
          setScheduleSpecificExerciseConfigs({
            ...scheduleSpecificExerciseConfigs,
            [introAudioDialogExerciseId]: {
              ...scheduleSpecificExerciseConfigs[introAudioDialogExerciseId],
              introAudioFeedbackId,
            },
          });
        }}
      />
      <div style={{ display: "flex", justifyContent: "right" }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <h1>Schedule Configurator</h1>
      <Grid container spacing={2} direction={"column"} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={4}
            minRows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-multiline-flexible"
            label="PatientOverview Path"
            variant={"outlined"}
            value={overviewRedirect}
            onChange={(e) => setOverviewRedirect(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicSelect
            label={"Language"}
            value={language}
            setValue={(value) => {
              setLanguage(value);
            }}
            options={["en", "ger"]}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TagConfig tags={tags} setTags={setTags} type={"schedule"} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box>
            <Typography variant="body1">
              Show summary between exercises
            </Typography>
            <Checkbox
              checked={showSummaries}
              onClick={() => setShowSummaries(!showSummaries)}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography variant="body1">Show score</Typography>
            <Checkbox
              checked={showScore}
              onClick={() => setShowScore(!showScore)}
            />
          </Box>
        </Grid>
      </Grid>
      <CoreAudioConfig
        config={coreAudioFeedbackConfig}
        setConfig={setCoreAudioFeedbackConfig}
      />
      <h3>Exercises</h3>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <Grid
              {...provided.droppableProps}
              container
              spacing={4}
              sx={{ padding: 2 }}
              ref={provided.innerRef}
            >
              {exercises.map((exercise, index) => (
                <Draggable
                  key={"exercise-" + index}
                  draggableId={"exercise-" + index}
                  index={index}
                >
                  {(provided) => (
                    <Grid
                      item
                      key={"exercise-" + index}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <Card
                        onClick={() => {
                          setSelectedExercise(exercise);
                          dispatch({
                            type: "SET-EXERCISE-CONFIG",
                            payload: exercise,
                          });
                          setExerciseConfiguratorOpen(true);
                          // navigate('/exercise_generator');
                        }}
                        sx={{
                          "&:hover": { cursor: "pointer" },
                          height: "100%",
                          padding: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h1
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          // }}
                          style={{ cursor: "pointer" }}
                        >
                          {scheduleSpecificExerciseConfigs[exercise.id]?.name ||
                            exercise.name}
                        </h1>
                        <Button
                          variant={"contained"}
                          onClick={(e) => {
                            e.stopPropagation();
                            setVideoUploadDialogExerciseId(exercise.id);
                          }}
                          sx={{ mt: 4, mb: 1, width: "100%" }}
                        >
                          Video
                        </Button>
                        <Button
                          variant={"contained"}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIntroAudioDialogExerciseId(exercise.id);
                            // (true);
                          }}
                          sx={{ mb: 1, width: "100%" }}
                        >
                          Audio Intro
                        </Button>
                        <Button
                          variant={"contained"}
                          onClick={(e) => {
                            e.stopPropagation();
                            setScoringDialogExerciseId(exercise.id);
                          }}
                          sx={{ mb: 4, width: "100%" }}
                        >
                          Scoring
                        </Button>
                        <TextField
                          id="outlined-time-threshold"
                          label="Time Threshold"
                          variant="outlined"
                          type={"number"}
                          value={timeThreshValue(exercise)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            setScheduleSpecificExerciseConfigs({
                              ...scheduleSpecificExerciseConfigs,
                              [exercise.id]: {
                                ...scheduleSpecificExerciseConfigs[exercise.id],
                                timeThresh: e.target.value,
                              },
                            });
                          }}
                          sx={{ height: "100%", width: "50%" }}
                        />
                        <TextField
                          id="outlined-time-threshold"
                          label="Name"
                          variant="outlined"
                          type={"text"}
                          value={
                            scheduleSpecificExerciseConfigs[exercise.id]
                              ? scheduleSpecificExerciseConfigs[exercise.id]
                                  .name
                              : exercise.name
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            setScheduleSpecificExerciseConfigs(
                              (prevConfigs) => {
                                if (!prevConfigs[exercise.id]) {
                                  prevConfigs[exercise.id] = {};
                                }
                                return {
                                  ...prevConfigs,
                                  [exercise.id]: {
                                    ...prevConfigs[exercise.id],
                                    name: e.target.value,
                                  },
                                };
                              }
                            );
                          }}
                          sx={{ height: "100%", width: "50%", mt: 1 }}
                        />
                        <DeleteButton
                          onDelete={() => {
                            setExercises(
                              exercises.filter((_, i) => i !== index)
                            );
                          }}
                          deletionTarget={"Exercise"}
                        />
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}
              <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    padding: 2,
                    border: 1,
                    borderRadius: 5,
                    m: 2,
                  }}
                >
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      setExerciseDialogOpen(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {provided.placeholder}
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        variant={"contained"}
        disabled={
          saving ||
          name.length === 0 ||
          description.length === 0 ||
          exercises.length === 0
        }
        onClick={saveSchedule}
      >
        {saving ? <CircularProgress /> : buttonText}
      </Button>
      <ToastContainer />
    </div>
  );
}