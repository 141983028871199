import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import cervicalNeckFront from "../../../../assets/images/pdf-images/cervical-neck-front.png";
import cervicalNeckRight from "../../../../assets/images/pdf-images/cervical-neck-right.png";
import shoulderAbduction from "../../../../assets/images/pdf-images/shoulder-abduction.png";
import shoulderFlexion from "../../../../assets/images/pdf-images/shoulder-flexion.png";
import squatsLowerBody from "../../../../assets/images/pdf-images/squat-lower-body.png";
import overheadSquats from "../../../../assets/images/pdf-images/overhead-squats.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 24,
    width: "80%",
    marginHorizontal: "auto",
  },
  heading: {
    textAlign: "center",
    fontWeight: "extrabold",
    color: "#17365d",
    fontSize: "24px",
    borderBottom: "2px solid #17365d",
  },
  sectionContainer: {
    marginTop: "16px",
  },
  sectionHeading: {
    color: "#366091",
    fontWeight: "bold",
    fontSize: "14px",
  },
  sectionMainText: {
    color: "#4f81bd",
    fontWeight: "bold",
    fontSize: "13px",
    marginBottom: 12,
  },
  sectionDesc: {
    fontSize: "11px",
  },
  gridContainer: {
    display: "flex",
    gap: 12,
    borderBottom: "1px solid #f79646",
    borderLeft: "1px solid #f79646",
    borderRight: "1px solid #f79646",
    borderTop: "2px solid #4bacc6",
  },
  gridInnerContainer: {
    display: "flex",
    gap: "2px",
    flexDirection: "row",
  },
  gridHeading: {
    backgroundColor: "#cccccc",
  },
  left: {
    backgroundColor: "#00ffff",
  },
  right: {
    backgroundColor: "#ff9900",
  },
  gridContent: {
    flex: 1,
    padding: "6px 8px",
    fontSize: "11px",
  },
});

// Create Document Component
const ResultsPdf = ({ tableData, personInfo, images }) => {
  const groupedData = tableData.reduce((acc, item) => {
    const { exerciseName, metricName } = item;
    if (!acc[exerciseName]) {
      acc[exerciseName] = {};
    }
    if (!acc[exerciseName][metricName]) {
      acc[exerciseName][metricName] = [];
    }
    acc[exerciseName][metricName].push(item);
    return acc;
  }, {});

  const shoulderAbductionData = {
    ...groupedData["Shoulder Adduction/Abduction Left"],
    ...groupedData["Shoulder Adduction/Abduction Right"],
  };

  const shoulderFlexionData = {
    ...groupedData["Shoulder Flexion/Extension Left"],
    ...groupedData["Shoulder Flexion/Extension Right Vertical"],
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.heading}>EXAR Biomechanical Analysis Report</Text>
        <View style={styles.sectionContainer}>
          <Text style={styles.sectionHeading}>Profile Information</Text>
          <View style={styles.gridContainer}>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Name:</Text>
              <Text style={styles.gridContent}>{personInfo?.name || "-"}</Text>
            </View>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Gender:</Text>
              <Text style={styles.gridContent}>
                {personInfo?.gender || "-"}
              </Text>
            </View>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Height:</Text>
              <Text style={styles.gridContent}>
                {personInfo?.height || "-"}
              </Text>
            </View>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Weight:</Text>
              <Text style={styles.gridContent}>
                {personInfo?.weight || "-"}
              </Text>
            </View>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Age:</Text>
              <Text style={styles.gridContent}>{personInfo?.age || "-"}</Text>
            </View>
          </View>

          <View style={styles.sectionContainer}>
            <Text style={styles.sectionHeading}>Assessment Overview</Text>
            <View style={styles.gridContainer}>
              <View style={styles.gridInnerContainer}>
                <Text style={styles.gridContent}>Date of Assessment:</Text>
                <Text style={styles.gridContent}>
                  {new Date(personInfo?.createdAt)?.toLocaleDateString()}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.sectionContainer}>
            <Text style={styles.sectionHeading}>Biomechanical Assessments</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                marginTop: 12,
              }}
            >
              <Image src={cervicalNeckFront} style={{ width: "100px" }} />
              <View style={{ flex: 1 }}>
                <Text style={styles.sectionMainText}>
                  Cervical Spine Lateral Flexion Range of Motion Assessment
                  (Neck front)
                </Text>
                <View>
                  <Text style={styles.sectionDesc}>
                    Cervical Spine Lateral Flexion (left and right) is
                    calculated by taking the inclination of the head relative to
                    the line of the trunkin the frontal plane (front view).
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.gridContainer, { marginTop: 12 }]}>
              <View style={[styles.gridInnerContainer, styles.gridHeading]}>
                <Text style={styles.gridContent}>Key Results</Text>
                <Text style={[styles.gridContent, styles.left]}>
                  Peak Flexion(Left)
                </Text>
                <Text style={[styles.gridContent, styles.right]}>
                  Peak Flexion(Right)
                </Text>
                <Text style={styles.gridContent}>Imbalance</Text>
              </View>
              {groupedData["Neck Front"] &&
                Object?.keys(groupedData["Neck Front"])?.map((key) => {
                  const rightData = groupedData["Neck Front"][key].find(
                    (item) => item.subMetricName === "Right"
                  );
                  const leftData = groupedData["Neck Front"][key].find(
                    (item) => item.subMetricName === "Left"
                  );
                  return (
                    <View style={styles.gridInnerContainer}>
                      <Text style={styles.gridContent}>{key}</Text>
                      <Text style={styles.gridContent}>
                        {leftData?.peakValue}
                      </Text>
                      <Text style={styles.gridContent}>
                        {rightData?.peakValue}
                      </Text>
                      <Text style={styles.gridContent}>
                        {Math.abs(
                          Number(leftData?.peakValue || 0) -
                            Number(rightData?.peakValue || 0)
                        )}
                      </Text>
                    </View>
                  );
                })}
            </View>
          </View>
        </View>

        <View style={styles.sectionContainer}>
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 12 }}
          >
            <Image src={cervicalNeckRight} style={{ width: "100px" }} />
            <View style={{ flex: 1 }}>
              <Text style={styles.sectionMainText}>
                Cervical Spine Flexion/Extension Range of Motion Assessment
                (Neck side)
              </Text>
              <View>
                <Text style={styles.sectionDesc}>
                  Cervical Spine Flexion (forward) / Extension (backwards)
                  calculated by taking the inclination of the head relative to
                  the line of the trunk in the sagittal plane (side view).
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.gridContainer, { marginTop: 12 }]}>
            <View style={[styles.gridInnerContainer, styles.gridHeading]}>
              <Text style={styles.gridContent}>Key Results</Text>
              <Text style={styles.gridContent}>Starting position</Text>
              <Text style={styles.gridContent}>Peak Flexion</Text>
              <Text style={styles.gridContent}>Peak Extension</Text>
              <Text style={styles.gridContent}>Total Range</Text>
            </View>
            {groupedData["Neck Side"] &&
              Object?.keys(groupedData["Neck Side"])?.map((key) => {
                const flexionData = groupedData["Neck Side"][key].find(
                  (item) => item.subMetricName === "Front"
                );
                const extensionData = groupedData["Neck Side"][key].find(
                  (item) => item.subMetricName === "Back"
                );
                return (
                  <View style={styles.gridInnerContainer}>
                    <Text style={styles.gridContent}>{key}</Text>
                    <Text style={styles.gridContent}>0</Text>
                    <Text style={styles.gridContent}>
                      {flexionData?.peakValue}
                    </Text>
                    <Text style={styles.gridContent}>
                      {extensionData?.peakValue}
                    </Text>
                    <Text style={styles.gridContent}>
                      {Number(flexionData?.peakValue || 0) +
                        Number(extensionData?.peakValue || 0)}
                    </Text>
                  </View>
                );
              })}
          </View>
        </View>

        <View style={styles.sectionContainer}>
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 12 }}
          >
            <Image src={shoulderFlexion} style={{ width: "100px" }} />
            <View style={{ flex: 1 }}>
              <Text style={styles.sectionMainText}>
                Shoulder Abduction/Adduction Range of Motion Assessment
              </Text>
              <View>
                <Text style={styles.sectionDesc}>
                  Shoulder Adduction/Abduction is calculated by taking the angle
                  created by the humerus (upper arm) relative to the line of the
                  trunk in the frontal plane (front view).
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.gridContainer, { marginTop: 12 }]}>
            <View style={[styles.gridInnerContainer, styles.gridHeading]}>
              <Text style={styles.gridContent}>Key Results</Text>
              <Text style={[styles.gridContent, styles.left]}>Left</Text>
              <Text style={[styles.gridContent, styles.right]}>Right</Text>
              <Text style={styles.gridContent}>Imbalance</Text>
            </View>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Shoulder Abduction</Text>
              <Text style={styles.gridContent}>
                {shoulderAbductionData &&
                  shoulderAbductionData["ROM Left Arm"] &&
                  shoulderAbductionData["ROM Left Arm"][0]?.peakValue}
              </Text>
              <Text style={styles.gridContent}>
                {shoulderAbductionData &&
                  shoulderAbductionData["ROM Right Arm"] &&
                  shoulderAbductionData["ROM Right Arm"][0]?.peakValue}
              </Text>
              <Text style={styles.gridContent}>
                {Math.abs(
                  Number(
                    (shoulderAbductionData &&
                      shoulderAbductionData["ROM Left Arm"] &&
                      shoulderAbductionData["ROM Left Arm"][0]?.peakValue) ||
                      0
                  ) -
                    Number(
                      (shoulderAbductionData &&
                        shoulderAbductionData["ROM Right Arm"] &&
                        shoulderAbductionData["ROM Right Arm"][0]?.peakValue) ||
                        0
                    )
                )}
              </Text>
            </View>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Shoulder Adduction</Text>
              <Text style={styles.gridContent}>
                {shoulderAbductionData &&
                  shoulderAbductionData["ROM Left Arm"] &&
                  shoulderAbductionData["ROM Left Arm"][1]?.peakValue}
              </Text>
              <Text style={styles.gridContent}>
                {shoulderAbductionData &&
                  shoulderAbductionData["ROM Right Arm"] &&
                  shoulderAbductionData["ROM Right Arm"][1]?.peakValue}
              </Text>
              <Text style={styles.gridContent}>
                {Math.abs(
                  Number(
                    (shoulderAbductionData &&
                      shoulderAbductionData["ROM Left Arm"] &&
                      shoulderAbductionData["ROM Left Arm"][1]?.peakValue) ||
                      0
                  ) -
                    Number(
                      (shoulderAbductionData &&
                        shoulderAbductionData["ROM Right Arm"] &&
                        shoulderAbductionData["ROM Right Arm"][1]?.peakValue) ||
                        0
                    )
                )}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.sectionContainer}>
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 12 }}
          >
            <Image src={shoulderFlexion} style={{ width: "100px" }} />
            <View style={{ flex: 1 }}>
              <Text style={styles.sectionMainText}>
                Shoulder Flexion/extension Range of Motion Assessment
              </Text>
              <View>
                <Text style={styles.sectionDesc}>
                  Shoulder Flexion/Extension is calculated by taking the angle
                  created by the humerus (upper arm) relative to the line of the
                  trunk in the sagittal plane (side view).
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.gridContainer, { marginTop: 12 }]}>
            <View style={[styles.gridInnerContainer, styles.gridHeading]}>
              <Text style={styles.gridContent}>Key Results</Text>
              <Text style={[styles.gridContent, styles.left]}>Left</Text>
              <Text style={[styles.gridContent, styles.right]}>Right</Text>
              <Text style={styles.gridContent}>Imbalance</Text>
            </View>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Shoulder Flexion</Text>
              <Text style={styles.gridContent}>
                {shoulderFlexionData &&
                  shoulderFlexionData["Left Arm ROM"] &&
                  shoulderFlexionData["Left Arm ROM"][1]?.peakValue}
              </Text>
              <Text style={styles.gridContent}>
                {shoulderFlexionData &&
                  shoulderFlexionData["Right Arm ROM"] &&
                  shoulderFlexionData["Right Arm ROM"][1]?.peakValue}
              </Text>
              <Text style={styles.gridContent}>
                {Math.abs(
                  Number(
                    (shoulderFlexionData &&
                      shoulderFlexionData["Left Arm ROM"] &&
                      shoulderFlexionData["Left Arm ROM"][1]?.peakValue) ||
                      0
                  ) -
                    Number(
                      (shoulderFlexionData &&
                        shoulderFlexionData["Right Arm ROM"] &&
                        shoulderFlexionData["Right Arm ROM"][1]?.peakValue) ||
                        0
                    )
                )}
              </Text>
            </View>
            <View style={styles.gridInnerContainer}>
              <Text style={styles.gridContent}>Shoulder Extension</Text>
              <Text style={styles.gridContent}>
                {shoulderFlexionData &&
                  shoulderFlexionData["Left Arm ROM"] &&
                  shoulderFlexionData["Left Arm ROM"][0]?.peakValue}
              </Text>
              <Text style={styles.gridContent}>
                {shoulderFlexionData &&
                  shoulderFlexionData["Right Arm ROM"] &&
                  shoulderFlexionData["Right Arm ROM"][0]?.peakValue}
              </Text>
              <Text style={styles.gridContent}>
                {Math.abs(
                  Number(
                    (shoulderFlexionData &&
                      shoulderFlexionData["Left Arm ROM"] &&
                      shoulderFlexionData["Left Arm ROM"][0]?.peakValue) ||
                      0
                  ) -
                    Number(
                      (shoulderFlexionData &&
                        shoulderFlexionData["Right Arm ROM"] &&
                        shoulderFlexionData["Right Arm ROM"][0]?.peakValue) ||
                        0
                    )
                )}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.sectionContainer}>
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 12 }}
          >
            <Image src={squatsLowerBody} style={{ width: "100px" }} />
            <View style={{ flex: 1 }}>
              <Text style={styles.sectionMainText}>
                Squat side Lower Body Dynamic Assessment -
              </Text>
              <View>
                <Text style={styles.sectionDesc}>
                  Squat is a dynamic movement assessment providing insight into
                  an individual’s balance, stability, flexibility, and strength.
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.gridContainer, { marginTop: 12 }]}>
            <View style={[styles.gridInnerContainer, styles.gridHeading]}>
              <Text style={styles.gridContent}>Key Results</Text>
              <Text style={[styles.gridContent]}>Flexion</Text>
              <Text style={[styles.gridContent]}>Extension</Text>
            </View>
            {groupedData["Squats side"] &&
              Object?.keys(groupedData["Squats side"])?.map((key) => {
                const flexionData = groupedData["Squats side"][key].find(
                  (item) =>
                    item.subMetricName === "Backward" ||
                    item.subMetricName === "Flexion"
                );
                const extensionData = groupedData["Squats side"][key].find(
                  (item) =>
                    item.subMetricName === "Forward" ||
                    item.subMetricName === "Extension"
                );
                return (
                  <View style={styles.gridInnerContainer}>
                    <Text style={styles.gridContent}>{key}</Text>
                    <Text style={styles.gridContent}>
                      {flexionData?.peakValue}
                    </Text>
                    <Text style={styles.gridContent}>
                      {extensionData?.peakValue}
                    </Text>
                  </View>
                );
              })}
          </View>
        </View>

        <View style={styles.sectionContainer}>
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 12 }}
          >
            <Image src={overheadSquats} style={{ width: "100px" }} />
            <View style={{ flex: 1 }}>
              <Text style={styles.sectionMainText}>
                Overhead Squat Lower Body Dynamic Assessment
              </Text>
              <View>
                <Text style={styles.sectionDesc}>
                  Overhead squat is a dynamic movement assessment providing
                  insight into an individual’s balance, stability, flexibility,
                  and strength
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.gridContainer, { marginTop: 12 }]}>
            <View style={[styles.gridInnerContainer, styles.gridHeading]}>
              <Text style={styles.gridContent}>Key Results</Text>
              <Text style={styles.gridContent}>
                Imbalance (- right, + left)
              </Text>
            </View>
            {groupedData["Squats Front Overhead"] &&
              Object?.keys(groupedData["Squats Front Overhead"])?.map((key) => {
                const metrics = groupedData["Squats Front Overhead"][key][0];

                if (key !== "Spine Balance Tilt") {
                  return (
                    <View style={styles.gridInnerContainer}>
                      <Text style={styles.gridContent}>{key}</Text>
                      <Text style={styles.gridContent}>
                        {metrics.peakValue}, [-5,5]
                      </Text>
                    </View>
                  );
                }
              })}
          </View>
        </View>

        <View style={styles.sectionContainer}>
          <Text style={styles.sectionMainText}>
            Good Mornings Lower Body Dynamic Assessment
          </Text>
          <Text style={styles.sectionDesc}>
            The Good Mornings exercise assesses and enhances posterior chain
            strength, including the lower back, glutes, and hamstrings, through
            a forward hinge motion at the hips while keeping the back straight
            and a weight positioned on the shoulders.
          </Text>
          <View style={[styles.gridContainer, { marginTop: 12 }]}>
            <View style={[styles.gridInnerContainer, styles.gridHeading]}>
              <Text style={styles.gridContent}>Key Results</Text>
              <Text style={[styles.gridContent]}>Starting Position</Text>
              <Text style={[styles.gridContent]}>Peak Flexion</Text>
              <Text style={styles.gridContent}>Peak Extension</Text>
            </View>
            {groupedData["Good Mornings"] &&
              Object.keys(groupedData["Good Mornings"])?.map((key) => {
                const flexionData = groupedData["Good Mornings"][key].find(
                  (item) => item.subMetricName === "Flexion"
                );
                const extensionData = groupedData["Good Mornings"][key].find(
                  (item) => item.subMetricName === "Extension"
                );
                return (
                  <View style={styles.gridInnerContainer}>
                    <Text style={styles.gridContent}>{key}</Text>
                    <Text style={styles.gridContent}>90</Text>
                    <Text style={styles.gridContent}>
                      {flexionData?.peakValue}
                    </Text>
                    <Text style={styles.gridContent}>
                      {extensionData?.peakValue}
                    </Text>
                  </View>
                );
              })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ResultsPdf;
