import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import InsoleConnectionStatus from './InsoldeConnectionStatus';

function Stridalizer() {
  const iframeRef = useRef(null);
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );
  const status = React.useRef('Initializing...');
  const [statusUpdate, setStatusUpdate] = React.useState(false);
  const stridalizerUrl = process.env.REACT_APP_STRIDALIZER_URL +( window.electronAPI?.getHwRevNum ? '/lib' : '');
  const [leftDeviceConnected, setLeftDeviceConnected] = React.useState(false);
  const [rightDeviceConnected, setRightDeviceConnected] = React.useState(false);

  useEffect(() => {
    setStatusUpdate(false);
    console.log('status', status.current);
  }, [statusUpdate]);

  useEffect(() => {
    if (
      leftDeviceConnected &&
      rightDeviceConnected &&
      window.electronAPI?.getConnectionInfo()
    ) {
      initUser();
    }
  }, [leftDeviceConnected, rightDeviceConnected]);

  useEffect(() => {
    const connectionStatus = window.electronAPI?.getConnectionInfo();
    if (connectionStatus) {
      setLeftDeviceConnected(connectionStatus.leftDeviceConnected);
      setRightDeviceConnected(connectionStatus.rightDeviceConnected);
    }

    function deviceEventListener(e) {
      if (e.detail.type === 'connectionInfo') {
        console.log('state changed!');
        if (e.detail.state === 'connected') {
          if (e.detail.side === 'left') {
            setLeftDeviceConnected(true);
            status.current = 'Reconnected Left Device!';
            setStatusUpdate(true);
          }
          if (e.detail.side === 'right') {
            setRightDeviceConnected(true);
            status.current = 'Reconnected Right Device!';
            setStatusUpdate(true);
          }
        }
        if (e.detail.state === 'disconnected') {
          if (e.detail.side === 'left') {
            setLeftDeviceConnected(false);
            status.current = 'Disconnected Left Device!';
            setStatusUpdate(true);
          }
          if (e.detail.side === 'right') {
            setRightDeviceConnected(false);
            status.current = 'Disconnected Right Device!';
            setStatusUpdate(true);
          }
        }
      }
      if (
        e.detail.type === 'characteristicValueChanged' &&
        status.current === 'Initialized!'
      ) {
        // console.log('sending data to wasm', e.detail.side, e.detail.value);
        try {
          sendDataToWasm({
            data: {
              data: e.detail.value,
              isLeft: e.detail.side === 'left',
            },
            type: 'observation',
          });
        } catch (e) {
          console.log(e);
        }
      }
    }

    window.addEventListener('deviceEvent', deviceEventListener);
    window.scrollTo(0, 0);
    return () => {
      window.removeEventListener('deviceEvent', deviceEventListener);
    };
  }, [status.current, leftDeviceConnected, rightDeviceConnected]);

  function initUser() {
    const data = {
      ht: Number(patientInfo.height),
      wt: Number(patientInfo.weight) * 1000,
      arch: Number(patientInfo.arch),
      footsize: Number(patientInfo.footSize),
      no_of_devices: 2,
      isShod: 1,
      reportPressure: 1,
    };
    console.log('initUser!', data);
    sendDataToWasm({ data, type: 'initUser' });
  }

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        // Ensure you validate the origin for security reasons!
        // if (event.origin !== 'http://localhost:8080') return;

        const connectionStatus = window.electronAPI?.getConnectionInfo();
        if (connectionStatus) {
          if (
            !connectionStatus.leftDeviceConnected ||
            !connectionStatus.rightDeviceConnected
          ) {
            console.log('reconnectBluetooth!');
            window.electronAPI?.reconnectBluetooth();
            status.current = 'Reconnecting...';
            setStatusUpdate(true);
            console.log('reconnecting');
            return;
          }
        }

        const dataFromWasm = event.data;
        switch (dataFromWasm) {
          case 'ready':
            initUser();
            break;
          case 'initializedUser':
            console.log('initializedUser', window.electronAPI?.getHwRevNum());
            sendDataToWasm({
              data: window.electronAPI?.getHwRevNum(),
              type: 'configureDevice',
            });
            break;
          case 'initializingRight':
            status.current = 'Set right device!';
            setStatusUpdate(true);
            break;
          case 'initializedRight':
            status.current = 'Set left device!';
            setStatusUpdate(true);
            setRightDeviceConnected(true);
            break;
          case 'initializingLeft':
            status.current = 'Set left device!';
            setStatusUpdate(true);
            break;
          case 'configuredDevices':
            console.log('configuredDevices');
            status.current = 'Initialized!';
            setStatusUpdate(true);
            break;
          case 'initializedLeft':
            setLeftDeviceConnected(true);
            status.current = 'Initialized!';
            setStatusUpdate(true);
            break;
        }
      },
      false
    );
  }, []);

  const sendDataToWasm = ({ data, type }) => {
    iframeRef.current?.contentWindow?.postMessage(
      { value: data, type },
      stridalizerUrl
    );
  };

  if (
    Number(patientInfo.height || 0) <= 0 ||
    Number(patientInfo.weight || 0) <= 0 ||
    Number(patientInfo.arch || 0) <= 0 ||
    Number(patientInfo.footSize || 0) <= 0
  ) {
    return <div>Please fill in patient info</div>;
  } else {
    return (
      <Box
        sx={{
          margin: 5,
          boxShadow: 5,
          padding: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography variant="h5" gutterBottom component="div">
            Stridalizer Insoles Setup
          </Typography>
          <InsoleConnectionStatus
            side={'left'}
            deviceConnected={leftDeviceConnected}
          />
          <InsoleConnectionStatus
            side={'right'}
            deviceConnected={rightDeviceConnected}
          />
          <iframe
            loading="lazy"
            ref={iframeRef}
            src={stridalizerUrl}
            width="800"
            height="400"
            allow="bluetooth"
          ></iframe>
          <Box sx={{ boxShadow: 4, padding: 4 }}>{status.current}</Box>
        </div>
      </Box>
    );
  }
}

export default React.memo(Stridalizer);
