import React, { useState, useEffect } from "react";
import "./AdminUiComponent.css";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../../constants";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import { CircularProgress } from "@mui/material";
import uploadIcon from "../../assets/upload.png";
import success from "../../assets/success.png";
import wrong from "../../assets/wrong.png";
import { useTranslation } from "react-i18next";

const AdminUiComponent = (props) => {
  const { t } = useTranslation();
  const { classes } = props;
  let today = moment(new Date()).format("YYYY-MM-DD");
  const [currentdate, setDate] = useState(today);
  const [classvalue, setClassValue] = useState([]);
  const [modelList, setModelList] = useState(null);
  const [audioList, setAudioList] = useState(null);
  const [description, setDescription] = useState("Lower Body");
  const [classArray, setClassArray] = useState([]);
  const [msg, setMsg] = useState("");
  const [result, setResult] = useState("");
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [resp, setResp] = useState();

  const ITEM_HEIGHT = 96;
  const ITEM_PADDING_TOP = 16;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "max-content",
      },
    },
  };

  useEffect(() => {
    setClassArray(classes);
  }, [classes]);

  const closeModal = () => {
    window.location.reload();
    setShow(false);
  };

  const addModel = async (data) => {
    const url = `${baseUrl}/addmodel`;
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setShow(true);
      if (response.status === 200) {
        setResult("success");
        setLoad(false);
        setResp(response.data);
      } else {
        throw Error("Something went wrong");
      }
    } catch (error) {
      setLoad(false);
      setResult("error");
      setShow(true);
      setMsg(error.response ? error.response.data.message : error.message);
    }
  };

  const handleModelUploadClick = async (e) => {
    e.preventDefault();
    setLoad(true);

    const data = new FormData();
    let classListSelected = [];
    classvalue.forEach((clas) => {
      classListSelected.push(parseInt(clas));
    });
    let payload = {
      class_id: classListSelected,
      start: `${currentdate}T10:00:00`,
      session_type: description ? description : "Lower Body",
    };

    if (modelList && audioList) {
      modelList.forEach((file) => {
        data.append("videoFiles", file);
      });

      audioList.forEach((audio) => {
        data.append("audioFiles", audio);
      });

      data.append("other_details", JSON.stringify(payload));
      await addModel(data);
    }
  };

  const models = modelList ? [...modelList] : [];
  const audios = audioList ? [...audioList] : [];

  const style = {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    margin: "auto",
    height: "70vh",
    width: "70vw",
    bgcolor: "white",
    borderRadius: "2.5em",
    p: 0,
  };

  return (
    <div className="admin-settings-container">
      <div className="admin-settings-title">
        <div className="admin-page-orange-rect"></div>
        <h2>{t("adminDashboard.title")}</h2>
      </div>
      <br />
      <div className="admin-page-content">
        <div className="form-group">
          <div className="form-group-heading">
            {t("adminDashboard.exerciseFiles")}
          </div>
          <div className="upload-btns">
            <div className="file-upload">
              <label htmlFor="file-input">
                <img src={uploadIcon} /> {t("adminDashboard.chooseFiles")}
              </label>
              <input
                id="file-input"
                type="file"
                multiple
                onChange={(e) => {
                  setModelList(e.target.files);
                }}
              />
            </div>
            <div className="filers">
              {models.map((model, i) => (
                <ul key={model.name + "ul"} style={{ marginLeft: "0.7%" }}>
                  {i + 1}. {model.name} {model.type}
                </ul>
              ))}
            </div>
          </div>
        </div>
        <br />
        {/* Rest of your form elements */}
      </div>
      <div className="form-grp">
        {load ? (
          <CircularProgress
            size={42}
            color="success"
            sx={{ justifyContent: "center" }}
          />
        ) : (
          <Button
            type="submit"
            variant="contained"
            onClick={handleModelUploadClick}
          >
            {t("DrawerComponent.schedule")}
          </Button>
        )}
      </div>
      <Modal
        open={show}
        onClose={() => closeModal()}
        height={"100vh"}
        sx={{ justify: "center", margin: "auto", pt: "10rem", border: "none" }}
        bordered={false}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style} border={"none"}>
          {result !== "error" ? (
            <>
              <img className="success-img" src={success} alt="Success" />
              <h2 id="parent-modal-title">{t("adminDashboard.scheduled")}</h2>
              <div
                id="parent-modal-description"
                style={{ display: "flex", width: "40vw" }}
              >
                <div className="mod_head">
                  {t("adminDashboard.totalDuration")}
                </div>
                <div className="mod_data">{resp && resp.total_duration}</div>
              </div>
              <div style={{ display: "flex", width: "40vw" }}>
                <div className="mod_head">
                  {" "}
                  {t("adminDashboard.totalStudents")}
                </div>
                <div className="mod_data">{resp && resp.total_studs}</div>
              </div>
            </>
          ) : (
            <>
              <img className="success-img" src={wrong} alt="Failure" />
              <h2 id="parent-modal-title">{t("adminDashboard.failed")}</h2>
              <div className="mod_data">
                <center>{msg}</center>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AdminUiComponent;
