import { Card, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import { useRef } from "react";
import { getAngle } from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import { blazePoseCoordinateNames } from "../../../../../utilities/Demo/ExerciseManagement/Generators/coordinates";
import BasicSelect from "../BasicSelect";
import OptionsBadge from "../OptionsBadge";
import AngleDialog from "./AngleDialog";

export default function ConditionBox({
  config,
  setConfig,
  refOptions,
  assignedRefOptions,
  copyConfig,
  useAngleConfigs,
}) {
  const type = useRef(config.type);
  const axis = useRef(config.axis);
  const ref = useRef(config.ref);
  const operator = useRef(config.operator);
  const value = useRef(config.value);
  const coordinateOperator = useRef(config.coordinateOperator || "");
  const coordinateOperatingRef = useRef(config.coordinateOperatingRef);
  const [angleDialogOpen, setAngleDialogOpen] = React.useState(false);
  const clip = useRef(config.clip || null);

  useEffect(() => {
    if (typeof config.ref === "string" && useAngleConfigs && config.ref === 0)
      getAngle({ angleId: config.ref }).then((angle) => {
        setConfig({ ...config, ref: angle });
      });
  }, [config.ref]);

  return (
    <Badge
      badgeContent={
        <OptionsBadge setConfig={setConfig} copyConfig={copyConfig} />
      }
      sx={{ m: 0, width: "50%" }}
    >
      <Card sx={{ padding: 3, m: 2, ml: 0, width: "100%" }}>
        <BasicSelect
          value={type.current}
          setValue={(value) => {
            type.current = value;
            setConfig({ ...config, type: value });
          }}
          label="type"
          options={["angle", "coordinate"]}
          sx={{ width: "100%", mt: 4 }}
        />
        {useAngleConfigs && type.current === "angle" ? (
          <div>
            <AngleDialog
              setAngle={(value) => setConfig({ ...config, ref: value })}
              open={angleDialogOpen}
              onClose={() => setAngleDialogOpen(false)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAngleDialogOpen(true);
              }}
              sx={{ mb: 1, width: "100%" }}
            >
              {config.ref?.name || "Select Angle"}
            </Button>
            <BasicSelect
              value={config.side}
              setValue={(value) => {
                setConfig({ ...config, side: value });
              }}
              options={["left", "right", null]}
              sx={{ width: "100%" }}
            />
          </div>
        ) : (
          <BasicSelect
            value={ref.current}
            setValue={(value) => {
              ref.current = value;
              setConfig({ ...config, ref: value });
            }}
            label="ref"
            options={(type.current === "angle"
              ? refOptions
              : Object.keys(blazePoseCoordinateNames).concat(assignedRefOptions)
            ).sort()}
            sx={{ width: "100%" }}
          />
        )}
        <BasicSelect
          value={operator.current}
          setValue={(value) => {
            operator.current = value;
            setConfig({ ...config, operator: value });
          }}
          label="operator"
          options={[">", "<", ">=", "<=", "=="]}
          sx={{ width: "100%" }}
        />
        <TextField
          name={value.current}
          label="value"
          type="number"
          variant="outlined"
          // className="inputRounded"
          InputLabelProps={{ disableAnimation: true }}
          shrink={"true"}
          sx={{ marginBottom: 1, width: "100%" }}
          value={value.current}
          onChange={(event) => {
            value.current = event.target.value;
            setConfig({ ...config, value: event.target.value });
          }}
        />
        {type.current === "coordinate" ? (
          <div>
            <BasicSelect
              value={axis.current}
              setValue={(value) => {
                axis.current = value;
                setConfig({ ...config, axis: value });
              }}
              label="axis"
              options={["x", "y", "z", "visibility"]}
              sx={{ width: "100%" }}
            />
            <BasicSelect
              value={config.coordinateOperator}
              setValue={(value) => {
                coordinateOperator.current = value;
                setConfig({ ...config, coordinateOperator: value });
              }}
              label="coordinateOperator"
              options={["*", "/", "+", "-", ""]}
              sx={{ width: "100%" }}
            />
            {config.coordinateOperator && (
              <BasicSelect
                value={config.coordinateOperatingRef}
                setValue={(value) => {
                  coordinateOperatingRef.current = value;
                  setConfig({ ...config, coordinateOperatingRef: value });
                }}
                label="coordinateOperatingRef"
                options={Object.keys(blazePoseCoordinateNames).concat(
                  assignedRefOptions
                )}
                sx={{ width: "100%" }}
              />
            )}
          </div>
        ) : (
          <TextField
            name={clip.current}
            label="clip value"
            type="number"
            variant="outlined"
            // className="inputRounded"
            InputLabelProps={{ disableAnimation: true }}
            shrink={"true"}
            sx={{ marginBottom: 1, width: "100%" }}
            value={clip.current}
            onChange={(event) => {
              clip.current = event.target.value;
              setConfig({ ...config, clip: event.target.value });
            }}
          />
        )}
      </Card>
    </Badge>
  );
}
