import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Switch, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { refOptions } from "../../../../../utilities/Demo/ExerciseManagement/Configurators/shared";
import { updateToastCaseFunction } from "../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts";
import { blazePoseCoordinateNames } from "../../../../../utilities/Demo/ExerciseManagement/Generators/coordinates";
import StretchCountFeedbackGenerator from "../../Generators/StretchCountFeedbackGenerator";
import StretchFeedbackGenerator from "../../Generators/StretchFeedbackGenerator";
import VideoUpload from "../VideoUpload";
import MetricConfig from "./MetricConfig";
import TagConfig from "../TagConfig";
import BalanceFeedbackGenerator from "../../Generators/BalanceFeedbackGenerator";
import StretchRWUFeedbackGenerator from "../../Generators/StretchRWUFeedbackGenerator";
import {
  BalanceFeedbacks,
  CountsInTimeFeedbacks,
  Feedback,
  StretchCountFeedbacks,
  StretchFeedbacks,
} from "../../../Feedbacks";
import BasicSelect from "../BasicSelect";
import CountsFeedbackGenerator from "../../Generators/CountsFeedbackGenerator";
import FeedbackConfig from "./FeedbackConfig";
import AddIcon from "@mui/icons-material/Add";
import ScoreConfig from "../ScoreConfig";
import MetricObservation from "./MetricObservation";
import StateConfig from "./StateConfig";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import { useWebcamSettings } from "../../../../../hooks/webcam";
import {
  addExercise,
  getAngle,
  updateExercise,
} from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import { uploadFile } from "../../../../../utilities/Demo/ExerciseManagement/Generators/storage";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getDownloadURL } from "firebase/storage";

export default function ExerciseConfigurator({
  exerciseConfig,
  updateExerciseInParent,
  addExerciseInParent,
  onClose,
}) {
  const dispatch = useDispatch();
  const { updateWebcamSettings } = useWebcamSettings();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(exerciseConfig?.name);
  const [observationComponent, setObservationComponent] = React.useState(
    <div></div>
  );
  const [feedbackComponent, setFeedbackComponent] = React.useState(<div></div>);
  const [type, setType] = useState(exerciseConfig?.type);
  const [view, setView] = useState(exerciseConfig?.view || "side");
  const [side, setSide] = useState(exerciseConfig?.side || null);
  const [timeThreshold, setTimeThreshold] = useState(exerciseConfig.timeThresh);
  const [feedbackConfigs, setFeedbackConfigs] = useState(
    exerciseConfig.feedback || []
  );
  const [stateConfigs, setStateConfigs] = useState(exerciseConfig.states || []);
  const [tags, setTags] = useState(exerciseConfig.tags || []);

  // prevents re-init after dnd
  useEffect(() => {
    setStateConfigs(exerciseConfig.states || []);
    setFeedbackConfigs(exerciseConfig.feedback || []);
  }, [exerciseConfig]);

  const [metrics, setMetrics] = useState(exerciseConfig.metrics || []);
  const [videoUrl, setVideoUrl] = useState(exerciseConfig.videoUrl || "");
  const [introAudioFile, setIntroAudioFile] = useState(null);
  const [introAudioUrl, setIntroAudioUrl] = useState(
    exerciseConfig.introAudioUrl || ""
  );
  const [uniqueAngles, setUniqueAngles] = useState([]);
  const [angleDisplay, setAngleDisplay] = useState(
    exerciseConfig.angleDisplay || {}
  );
  const [stretchTargetType, setStretchTargetType] = useState(
    exerciseConfig.stretchTargetType || null
  );
  const [stretchTarget, setStretchTarget] = useState(
    exerciseConfig.stretchTarget || null
  );
  const [stretchTargetAxis, setStretchTargetAxis] = useState(
    exerciseConfig.stretchTargetAxis || "y"
  );
  const [stretchTargetFramingMotionAxis, setStretchTargetFramingMotionAxis] =
    useState(exerciseConfig.stretchTargetFramingMotionAxis || "y");
  const [stretchTargetExtreme, setStretchTargetExtreme] = useState(
    exerciseConfig.stretchTargetExtreme || "max"
  );
  const [showBackbones, setShowBackbones] = useState(
    exerciseConfig.showBackbones
  );
  const [sideThreshold, setSideThreshold] = useState(
    exerciseConfig.sideThreshold
  );
  const [useAngleConfigs, setUseAngleConfigs] = useState(
    exerciseConfig.useAngleConfigs
  );
  const [scoreConfig, setScoreConfig] = useState(
    exerciseConfig.scoreConfig || {}
  );
  const [language, setLanguage] = useState(exerciseConfig.language || "en");
  const { user } = useContext(FirebaseAuthContext);

  useEffect(() => {
    dispatch({
      type: "SET-INTRO-SKIPPED",
      payload: true,
    });
    updateWebcamSettings(false);
    stateConfigs.forEach((stateConfig) => {
      if (!stateConfig.id) stateConfig.id = _.uniqueId();
    });
    feedbackConfigs.forEach((feedbackConfig) => {
      if (!feedbackConfig.id) feedbackConfig.id = _.uniqueId();
      if (String(feedbackConfig.id).length < 5)
        feedbackConfig.id = _.uniqueId();
    });
    applyConfig();
    return () => {
      dispatch({
        type: "SET-INTRO-SKIPPED",
        payload: false,
      });
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET-ITER-LANGUAGE",
      payload: language,
    });
  }, [language]);

  useEffect(() => {
    function setAngleDisplayHandler(angles) {
      angles.map((angle) => {
        if (angleDisplay[angle] === undefined) {
          const newAngleDisplay = { ...angleDisplay };
          newAngleDisplay[angle] = true;
          setAngleDisplay(newAngleDisplay);
        }
      });
    }

    let angles = getUniqueAngles().sort();
    setUniqueAngles(angles);
    if (useAngleConfigs) {
      const angleObjects = [];
      const promises = angles.map(async (angle) => {
        if (typeof angle === "string") {
          const angleDoc = await getAngle({ angleId: angle });
          if (angleDoc) {
            angleObjects.push(angleDoc);
          }
        } else {
          angleObjects.push(angle);
        }
      });
      Promise.all(promises).then(() => {
        setUniqueAngles(angleObjects);
        const angleNames = angles.map((angle) => angle.name);
        setAngleDisplayHandler(angleNames);

        feedbackConfigs.forEach((feedbackConfig) => {
          feedbackConfig.conditions.forEach((condition) => {
            if (!condition.ref.id) {
              condition.ref = angleObjects.filter(
                (angle) => angle.id === condition.ref
              )[0];
            }
          });
        });
        metrics?.forEach((metric) => {
          metric?.subMetrics?.forEach((subMetric) => {
            if (!subMetric.ref?.id) {
              subMetric.ref = angleObjects?.filter(
                (angle) => angle?.id === subMetric.ref
              )[0];
            }
          });
        });
        stateConfigs.forEach((stateConfig) => {
          stateConfig.conditions.forEach((condition) => {
            if (!condition.ref.id) {
              condition.ref = angleObjects.filter(
                (angle) => angle.id === condition.ref
              )[0];
            }
          });
        });
        applyConfig();
      });
    } else {
      setAngleDisplayHandler(angles);
    }
  }, [feedbackConfigs, stateConfigs, metrics]);

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...stateConfigs];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setStateConfigs(updatedList);
  };

  const handleVideoUpload = (videoFile) => {
    uploadFile({
      file: videoFile,
      filePath: "/exercise_videos/" + exerciseConfig.id,
    }).then((fileRef) => {
      getDownloadURL(fileRef).then((url) => setVideoUrl(url));
    });
  };

  const getAssignedRefOptionNames = () =>
    stateConfigs
      .map((state) =>
        state.assignedReferences?.map(
          (assignedReferenceConfig) => assignedReferenceConfig.name
        )
      )
      .concat(
        feedbackConfigs.map((feedback) =>
          feedback.assignedReferences?.map(
            (assignedReferenceConfig) => assignedReferenceConfig.name
          )
        )
      )
      .flat();

  function getUniqueAngles() {
    const flattenedAngles = [].concat(
      feedbackConfigs
        .map((config) =>
          config?.conditions.map((condition) => {
            if (condition.type === "angle") {
              return condition.ref;
            }
          })
        )
        .concat(
          stateConfigs?.map((config) => {
            return config.conditions?.map((condition) => {
              if (condition.type === "angle") {
                return condition.ref;
              }
            });
          })
        )
        .concat(
          metrics?.map((metric) =>
            metric?.subMetrics?.map((subMetric) => subMetric?.ref)
          )
        )
        .flat()
        .filter((ref) => ref !== undefined)
    );
    if (useAngleConfigs) {
      return flattenedAngles.filter((item, index, self) => {
        return index === self.findIndex((t) => t.id === item.id);
      });
    } else {
      return [...new Set(flattenedAngles)];
    }
  }

  function getStretchTargetOptions() {
    switch (stretchTargetType) {
      case "angle":
        return getUniqueAngles();
      case "assignedRef":
        return getAssignedRefOptionNames();
      case "coordinate":
        return Object.keys(blazePoseCoordinateNames);
      default:
        return [];
    }
  }

  function applyConfig() {
    const conf = {
      id: exerciseConfig.id,
      useAngleConfigs: Boolean(useAngleConfigs),
      stretchTarget: stretchTarget,
      stretchTargetType: stretchTargetType,
      stretchTargetExtreme: stretchTargetExtreme,
      showBackbones: Boolean(showBackbones),
      sideThreshold: sideThreshold || null,
      stretchTargetAxis: stretchTargetAxis,
      stretchTargetFramingMotionAxis: stretchTargetFramingMotionAxis,
      feedback: feedbackConfigs,
      states: stateConfigs,
      metrics: metrics,
      name: name,
      view: view,
      language: language,
      scoreConfig: scoreConfig,
      type: type,
      side: side,
      timeThresh: timeThreshold,
      angles: getUniqueAngles(),
      videoUrl: videoUrl,
      introAudioUrl: introAudioUrl,
      angleDisplay: Object.fromEntries(
        Object.entries(angleDisplay).filter(([key]) => key !== "")
      ),
      tags: tags.map((tag) => tag.id),
    };

    dispatch({ type: "SET-EXERCISE-CONFIG", payload: conf });

    const confClone = _.cloneDeep(conf);

    if (type === "count") {
      setObservationComponent(
        <CountsFeedbackGenerator
          key={JSON.stringify(conf)}
          onComplete={() => {
            console.log("complete");
          }}
          config={confClone}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
    } else if (type === "balance") {
      setObservationComponent(
        <BalanceFeedbackGenerator
          key={Math.random().toString()}
          config={confClone}
          onComplete={() => {
            console.log("complete");
          }}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
    } else if (type === "RWUStretch") {
      setObservationComponent(
        <StretchRWUFeedbackGenerator
          key={Math.random().toString()}
          config={confClone}
          onComplete={() => {
            console.log("complete");
          }}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={StretchFeedbacks} />);
    } else if (type === "stretch") {
      setObservationComponent(
        <StretchFeedbackGenerator
          key={Math.random().toString()}
          config={confClone}
          onComplete={() => {
            console.log("complete");
          }}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={StretchFeedbacks} />);
    } else if (type === "stretchCount") {
      setObservationComponent(
        <StretchCountFeedbackGenerator
          key={Math.random().toString()}
          config={confClone}
          onComplete={() => {
            console.log("complete");
          }}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={StretchCountFeedbacks} />
      );
    }

    confClone.feedback = feedbackConfigs.map((feedbackConfig) => {
      const feedbackConfigCopy = _.cloneDeep(feedbackConfig);
      feedbackConfigCopy.conditions = feedbackConfigCopy.conditions.map(
        (condition) => {
          if (condition.ref.id) {
            condition.ref = condition.ref.id;
          }
          return condition;
        }
      );
      return feedbackConfigCopy;
    });

    confClone.metrics = metrics.map((metric) => {
      const metricCopy = _.cloneDeep(metric);
      metricCopy.subMetrics = metricCopy.subMetrics?.map((subMetric) => {
        if (subMetric.ref.id) {
          subMetric.ref = subMetric.ref.id;
        }
        return subMetric;
      });
      return metricCopy;
    });

    confClone.states = stateConfigs.map((stateConfig) => {
      const stateConfigCopy = _.cloneDeep(stateConfig);
      stateConfigCopy.conditions = stateConfigCopy.conditions.map(
        (condition) => {
          if (condition.ref?.id) {
            condition.ref = condition.ref.id;
          }
          return condition;
        }
      );
      return stateConfigCopy;
    });

    dispatch({
      type: "SET-ITER-METRICS",
      payload: [],
    });

    return confClone;
  }

  const handleSave = () => {
    setLoading(true);

    const exerciseData = { ...applyConfig() };
    // Show saving toast initially
    const id = toast.loading("Saving...");

    const updateToastCase = updateToastCaseFunction({
      id,
      successRender: "Saved Exercise!",
      errorRender: "Error saving Exercise!",
    });

    if (exerciseConfig.id) {
      updateExerciseInParent(exerciseData);
      updateExercise({ exerciseData, user }).then((exercise) => {
        updateToastCase(exercise);
        setLoading(false);
      });
    } else {
      if (addExerciseInParent) {
        addExerciseInParent(exerciseData);
      }

      addExercise({ exerciseData, user }).then((response) => {
        if (response) {
          setLoading(false);
          updateToastCase(response);
          dispatch({
            type: "SET-EXERCISE-CONFIG",
            payload: { id: response?.id },
          });
        }
      });
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={2}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            InputLabelProps={{ disableAnimation: true }}
            shrink={"true"}
            sx={{ width: "100%", height: "50%", marginBottom: 1 }}
            value={name}
            onChange={(event) => {
              setName(event?.target?.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <BasicSelect
            value={view}
            setValue={setView}
            label={"View"}
            options={["side", "front"]}
          />
        </Grid>

        {view === "side" && (
          <Grid item xs={12} sm={2}>
            <BasicSelect
              value={side}
              setValue={setSide}
              label={"Side"}
              options={["left", "right"]}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={2}>
          <TextField
            name="sideThreshold"
            label="Side Threshold"
            type="number"
            variant="outlined"
            InputLabelProps={{ disableAnimation: true }}
            shrink={"true"}
            sx={{ width: "100%", height: "50%", marginBottom: 1 }}
            value={sideThreshold}
            onChange={(event) => {
              setSideThreshold(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <BasicSelect
            value={type}
            setValue={setType}
            label={"Type"}
            options={[
              "count",
              "balance",
              "stretch",
              "RWUStretch",
              "stretchCount",
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            name="timeThreshold"
            label="Time Threshold"
            type="number"
            variant="outlined"
            InputLabelProps={{ disableAnimation: true }}
            shrink={"true"}
            sx={{ width: "100%", height: "50%", marginBottom: 1 }}
            value={timeThreshold}
            onChange={(event) => {
              setTimeThreshold(event.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 0, ml: 3 }}>
        <Grid item xs={6} sm={2}>
          <Box sx={{ width: "90%" }}>
            <Typography variant="body1">Use Angle Configs</Typography>
            <Checkbox
              checked={useAngleConfigs}
              onClick={() => setUseAngleConfigs((prev) => !prev)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box sx={{ width: "90%" }}>
            <Typography variant="body1">Show Backbones</Typography>
            <Checkbox
              checked={showBackbones}
              onClick={() => setShowBackbones((prev) => !prev)}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={2}>
          <BasicSelect
            value={language}
            setValue={setLanguage}
            label={"Language"}
            options={["en", "ger"]}
          />
        </Grid>
      </Grid>
      <TagConfig tags={tags} setTags={setTags} type={"exercise"} />
      <ScoreConfig
        exerciseType={type}
        config={scoreConfig}
        setConfig={setScoreConfig}
      />
      {["stretch", "stretchCount", "RWUStretch"].includes(type) && (
        <Grid container spacing={2} sx={{ mt: 3, ml: 3 }}>
          <Grid item xs={6} sm={2}>
            <h3>Stretch Target Config</h3>
          </Grid>
          <Grid item xs={6} sm={2}>
            <BasicSelect
              label={"Stretch Target Type"}
              value={stretchTargetType}
              setValue={setStretchTargetType}
              options={["angle", "assignedRef", "coordinate"]}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <BasicSelect
              label={"Stretch Target"}
              value={stretchTarget}
              setValue={setStretchTarget}
              options={getStretchTargetOptions()}
            />
          </Grid>
          {stretchTargetType === "coordinate" && (
            <Grid item xs={6} sm={2}>
              <BasicSelect
                label={"Stretch Target Axis"}
                value={stretchTargetAxis}
                setValue={setStretchTargetAxis}
                options={["x", "y"]}
              />
            </Grid>
          )}
          <Grid item xs={6} sm={2}>
            <BasicSelect
              label={"Extreme"}
              value={stretchTargetExtreme}
              setValue={setStretchTargetExtreme}
              options={["min", "max"]}
            />
          </Grid>
          {stretchTargetType === "coordinate" &&
            ["stretchCount"].includes(type) && (
              <Grid item xs={6} sm={2}>
                <BasicSelect
                  label={"Framing Motion"}
                  value={stretchTargetFramingMotionAxis}
                  setValue={setStretchTargetFramingMotionAxis}
                  options={["y", "x", "motion", null]}
                />
              </Grid>
            )}
        </Grid>
      )}
      <Grid container spacing={2} sx={{ mt: 3, ml: 3 }}>
        <Grid item xs={6} sm={2}>
          <h3>Intro Audio</h3>
        </Grid>
        <Grid item xs={6} sm={2}>
          <input
            accept="video/mp3"
            style={{ display: "none" }}
            id="raised-button-audio-file"
            type="file"
            onChange={(event) => {
              setIntroAudioFile(event.target.files[0]);
            }}
          />
          <label htmlFor="raised-button-audio-file">
            <Button variant="raised" component="span">
              {!introAudioFile ? "Choose File" : introAudioFile.name}
            </Button>
          </label>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Button
            variant={"outlined"}
            disabled={!introAudioFile || !exerciseConfig.id}
            onClick={() => {
              uploadFile({
                file: introAudioFile,
                filePath: "/audio/intros/" + exerciseConfig.id,
              }).then((fileRef) => {
                getDownloadURL(fileRef).then((url) => {
                  setIntroAudioUrl(url);
                });
              });
            }}
          >
            Upload
          </Button>
        </Grid>
        <Grid item xs={6} sm={6}>
          <ReactAudioPlayer src={introAudioUrl} controls />
        </Grid>
      </Grid>
      <Typography fontSize={"28px"} sx={{ margin: 5 }}>
        Metrics
      </Typography>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {metrics.map((metric, index) => {
          return (
            <Grid key={index} item xs={12} sm={6}>
              <MetricConfig
                key={index}
                refOptions={refOptions(side)[view]}
                useAngleConfigs={useAngleConfigs}
                metric={metric}
                stateOptions={stateConfigs.map(
                  (stateConfig) => stateConfig.name
                )}
                copyConfig={() => {
                  const updatedMetrics = [...metrics];
                  const copiedMetric = _.cloneDeep(updatedMetrics[index]);
                  copiedMetric.name += " (copy)";
                  updatedMetrics.push(copiedMetric);
                  setMetrics(updatedMetrics);
                }}
                setMetric={(metric) => {
                  const updatedMetrics = [...metrics];
                  if (metric) {
                    updatedMetrics[index] = metric;
                  } else {
                    updatedMetrics.splice(index, 1);
                  }
                  setMetrics(updatedMetrics);
                }}
              />
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="delete patient"
            onClick={() => {
              setMetrics([...metrics, { name: "" }]);
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Typography fontSize={"28px"} sx={{ margin: 5 }}>
        Feedback
      </Typography>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {feedbackConfigs.map((feedbackConfig, index) => {
          return (
            <Grid key={feedbackConfig.id} item xs={12} sm={6}>
              <FeedbackConfig
                useAngleConfigs={useAngleConfigs}
                refOptions={refOptions(side)[view]}
                assignedRefOptions={getAssignedRefOptionNames()}
                key={index}
                config={feedbackConfig}
                setConfig={(config) => {
                  const updatedConfigs = [...feedbackConfigs];
                  if (config) {
                    updatedConfigs[index] = config;
                  } else {
                    updatedConfigs.splice(index, 1);
                  }
                  setFeedbackConfigs(updatedConfigs);
                }}
                copyConfig={() => {
                  const updatedConfigs = [...feedbackConfigs];
                  const copiedFeedback = _.cloneDeep(updatedConfigs[index]);
                  copiedFeedback.name += " (copy)";
                  copiedFeedback.id = _.uniqueId();
                  updatedConfigs.push(copiedFeedback);
                  setFeedbackConfigs(updatedConfigs);
                }}
                stateOptions={stateConfigs.map((stateConfig) => stateConfig)}
              />
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="delete patient"
            onClick={(e) => {
              e.stopPropagation();
              feedbackConfigs.push({
                name: "",
                conditions: [],
              });
              setFeedbackConfigs([...feedbackConfigs]);
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Typography fontSize={"28px"} sx={{ margin: 5 }}>
        States
      </Typography>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <Grid
              {...provided.droppableProps}
              container
              spacing={2}
              sx={{ padding: 2 }}
              ref={provided.innerRef}
            >
              {stateConfigs.map((stateConfig, index) => (
                <Draggable
                  key={stateConfig.id + index}
                  draggableId={stateConfig.id + index}
                  index={index}
                >
                  {(provided) => (
                    <Grid
                      key={"state" + index}
                      item
                      xs={12}
                      sm={6}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <StateConfig
                        useAngleConfigs={useAngleConfigs}
                        refOptions={refOptions(side)[view]}
                        assignedRefOptions={getAssignedRefOptionNames()}
                        key={"state" + index}
                        type={type}
                        stateOptions={stateConfigs.map(
                          (stateConfig) => stateConfig
                        )}
                        config={stateConfig}
                        setConfig={(config) => {
                          const updatedConfigs = [...stateConfigs];
                          if (config) {
                            updatedConfigs[index] = config;
                          } else {
                            updatedConfigs.splice(index, 1);
                          }
                          setStateConfigs(updatedConfigs);
                        }}
                        copyConfig={() => {
                          const updatedConfigs = [...stateConfigs];
                          const copiedState = _.cloneDeep(
                            updatedConfigs[index]
                          );
                          copiedState.name += " (copy)";
                          copiedState.id =
                            Math.random().toString() + Math.random().toString();
                          updatedConfigs.push(copiedState);
                          setStateConfigs(updatedConfigs);
                        }}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              <Grid item xs={12} sm={6}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="delete patient"
                  onClick={(e) => {
                    e.stopPropagation();
                    stateConfigs.push({
                      name: "",
                      conditions: [],
                      id: Math.random().toString() + Math.random().toString(),
                    });
                    setStateConfigs([...stateConfigs]);
                  }}
                >
                  <AddIcon />
                </IconButton>
                {provided.placeholder}
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Typography fontSize={"28px"} sx={{ margin: 5 }}>
        Angle Display
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3, padding: 2 }}>
        {uniqueAngles.map((angle) => {
          return (
            <Grid item xs={4} key={angle + "display"}>
              <Typography>{angle.name || angle}</Typography>
              <Switch
                label={useAngleConfigs ? angle.name : angle}
                checked={angleDisplay[useAngleConfigs ? angle.name : angle]}
                onChange={() => {
                  const newAngleDisplay = { ...angleDisplay };
                  newAngleDisplay[useAngleConfigs ? angle.name : angle] =
                    !angleDisplay[useAngleConfigs ? angle.name : angle];
                  setAngleDisplay(newAngleDisplay);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={1.5}>
          {" "}
        </Grid>
        <Grid item xs={12} sm={9}>
          <div
            style={{
              position: "relative", // Add this line
              overflow: "hidden",
              backgroundColor: "black",
              height: "100%",
            }}
          >
            {feedbackComponent}
            <div style={{ transform: "scaleX(-1)" }}>
              {observationComponent}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={1.5}>
          {" "}
        </Grid>
      </Grid>
      <MetricObservation />
      <Divider />
      <VideoUpload
        uploadId={exerciseConfig.id}
        videoUrl={videoUrl}
        handleVideoUpload={handleVideoUpload}
      />
      <Box
        sx={{
          justifyContent: "right",
          alignItems: "right",
          display: "flex",
          padding: 5,
        }}
      >
        <Button
          variant={"outlined"}
          onClick={handleSave}
          sx={{ marginRight: "12px" }}
        >
          {loading ? <CircularProgress /> : "Save"}
        </Button>
        <Button
          variant={"contained"}
          onClick={() => {
            applyConfig();
          }}
        >
          Generate
        </Button>
        <ToastContainer />
      </Box>
    </div>
  );
}
