import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/system";
import "./exercisearena.css";
import ProgressBar from "@ramonak/react-progress-bar";
import join from "../../assets/jscreen.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useWebcamSettings } from "../../hooks/webcam";
import HeaderComponent from "../HeaderComponent";
import PoseCalibration from "../Demo/PoseCalibration";
import { useDispatch } from "react-redux";

function CalibrationComponent({ load, setLoad, handleJoin }) {
  const [visiblepts, setVisiblepts] = useState(0);
  const [isPointsDetected, setIsPointsDetected] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { updateWebcamSettings } = useWebcamSettings();

  useEffect(() => {
    updateWebcamSettings(true);
  });

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    permissions
      .then(() => {
        //setLoad(true);
        console.log("accepted the permissions");
      })
      .catch((err) => {
        console.log(`${err.name} : ${err.message}`);
        alert(
          "Please allow camera for calibration. Seems you have blocked camera access"
        );
      });
  };
  const checkPoints = () => {
    if (visiblepts < 5) {
      return "red";
    } else if (visiblepts > 4 && visiblepts < 10) {
      return "#DEE41B";
    } else if (visiblepts > 10) {
      return "green";
    }
  };
  useEffect(() => {
    console.log("Mouneted");
    checkPermissions();
    return () => {
      console.log("WIndow unmonented");
      setLoad(false);
    };
  }, []);
  useEffect(() => {
    if (visiblepts >= 12) {
      setIsPointsDetected(true);
      setTimeout(() => {
        dispatch({ type: "ITER-CALIBRATED", payload: {} });
        handleJoin();
      }, 5000);
    }
  }, [visiblepts]);
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: 6,
          padding: 1,
          overflow: "hidden",
          backgroundColor: "#E8E8E8",
        },
      }}
      fullScreen
      BackdropComponent={styled(Backdrop, {
        name: "MuiModal",
        slot: "Backdrop",
        overridesResolver: (props, styles) => {
          return styles.backdrop;
        },
      })({
        zIndex: -1,
        backgroundColor: "#FCFCF9",
      })}
      open={load}
      onClose={() => setLoad(false)}
    >
      <DialogTitle sx={{ fontSize: 18, textAlign: "center" }}>
        <HeaderComponent />
      </DialogTitle>
      <DialogContent
        style={{
          overflow: "hidden",
          backgroundColor: "#E8E8E8",
          display: "flex",
        }}
      >
        <div className="all-cont">
          <div className="detect-container">
            <h1>
              Stand in a well lit space, stand back until you are in full view
            </h1>
            <br></br>
            <div>
              <h3>Joints Detected</h3>
              <ProgressBar
                className="progress-bar-profile"
                completed={visiblepts}
                maxCompleted={12}
                labelAlignment="left"
                labelColor="#646970"
                height="50px"
                borderRadius=".5rem"
                baseBgColor="#f4f4f4"
                padding="0"
                bgColor={checkPoints()}
                isLabelVisible={false}
              />
              <span style={{ paddingLeft: "2%" }}>
                <b>{visiblepts}/12</b>
              </span>
            </div>
            {!matches && <img src={join}></img>}
            {visiblepts === 12 && (
              <DialogActions
                disableSpacing={true}
                sx={{ justifyContent: "center", height: "10%" }}
              >
                <h3>All Set. You are now ready to go!!!</h3>
              </DialogActions>
            )}
          </div>
          <div className="frame-container iframe-loading">
            <div
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                }}
              >
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <PoseCalibration
                  setVisiblepts={setVisiblepts}
                  isPointsDetected={isPointsDetected}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
    // <NewWindow closeOnUnmount={true} onUnload={() => setLoad(false)}>
    //   {/* <img
    //     src={
    //       'https://exarindia2.s3.ap-south-1.amazonaws.com/assets/olined-rb.png'
    //     }
    //     style={{
    //       position: 'absolute',
    //       top: '58%',
    //       left: '50%',
    //       transform: 'translate(-50%, -50%)',
    //       zIndex: 100,
    //       height: '78%',
    //       width: '140%',
    //       opacity: '0.75',
    //       overflow: 'hidden',
    //     }}
    //   /> */}
    //   <div class="calibration-text">
    //     {' '}
    //     {' '}
    //   </div>
  );
}

export default CalibrationComponent;
