import React, { useState, useEffect } from "react";
import { Pose } from "@mediapipe/pose";
import PoseModelContext from "./PoseModelContext";
// import BackboneWorker from 'worker-loader!../../workers/Demo/backboneWorker';
import BackboneWorker from "../../workers/Demo/backboneWorker";

const PoseModelProvider = ({ children }) => {
  const [pose, setPose] = useState(null);
  const [backboneWorker, setBackboneWorker] = useState(null);

  useEffect(() => {
    setBackboneWorker(new BackboneWorker());
    const poseInstance = new Pose({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
      },
    });
    poseInstance.setOptions({
      modelComplexity: 1,
      smoothLandmarks: true,
      enableSegmentation: true,
      smoothSegmentation: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
      effect: "background",
    });
    setPose(poseInstance);
  }, []);

  return (
    <PoseModelContext.Provider
      value={{
        pose,
        backboneWorker,
      }}
    >
      {" "}
      {children}{" "}
    </PoseModelContext.Provider>
  );
};

export default PoseModelProvider;
