import { baseUrl } from "../../constants";
import axios from "axios";

export const updateStudentPoints = async (dispatch, data, setLoader) => {
  setLoader(true);
  let token = await localStorage.getItem("token");
  console.log("REC::", data);
  try {
    let response = await axios.put(`${baseUrl}/studentpoints`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Status is ::" + JSON.stringify(response));
    dispatch({
      type: "BOARD",
      payload: response.data,
    });
    setLoader(false);
  } catch (error) {
    console.log("eror::" + JSON.stringify(error));
    setLoader(false);
  }
};
