import React from "react";
import { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import LegendComponent from "./LegendComponent";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./highlights.css";
import { useTranslation } from "react-i18next";

const SweatPoints = ({ points, indi }) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(null);
  console.log("SP::", points);
  return (
    <div className="box--container bottom-box">
      <div className="top-bar">
        <div className="orange-box"></div>
        <h2>{t("PointsComponent.sweatPoints")}</h2>
      </div>
      <div className="pie-chart" data-tip="" data-for="chart2">
        <PieChart
          style={{ width: "100%", height: "100%" }}
          viewBoxSize={[100, 100]}
          center={[50, 50]}
          lineWidth={20}
          rounded
          totalValue={80}
          data={[
            { title: "Sweat Points", value: points, color: "#CABDFF" },
            { title: "Total Points", value: 80 - points, color: "#B1E5FC" },
          ]}
          label={(prop) =>
            prop.dataEntry.title === "Sweat Points" && `${prop.dataEntry.value}`
          }
          labelPosition={15}
          labelStyle={{ fontWeight: "bold" }}
          onMouseOver={(_, index) => {
            // console.log('INDEX::', _);
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(null);
          }}
        />

        <ReactTooltip
          id="chart2"
          place={"bottom"}
          backgroundColor={"#272B30"}
          textColor={"#EFEFEF"}
          multiline={true}
          getContent={() =>
            typeof hovered === "number" ? (
              <LegendComponent
                title={"Sweat Points"}
                gcolor={indi[hovered].color}
                pts={indi[hovered].points}
              />
            ) : null
          }
        />

        {/* <ReactTooltip
            id="chart2"
            place={'bottom'}
            backgroundColor={'#272B30'}
            textColor={'#EFEFEF'}
            multiline={true}
            getContent={() =>
              typeof hovered === 'number' ? (
                <LegendComponent
                  title={'Duration'}
                  gcolor={indi[hovered].color}
                  pts={indi[hovered].duration}
                />
              ) : null
            }
          /> */}
      </div>
    </div>
    // <div className='box2'>
    //   <Grid container padding={2} className="gridder">
    //     <Grid item xs={1}>
    //       <div className='orange'></div>
    //     </Grid>
    //     <Grid item xs={11}>
    //       <h3>
    //         <b>Sweat Points</b>
    //       </h3>
    //     </Grid>
    //   </Grid>
    //   <div data-tip="" data-for="chart">
    //     <PieChart
    //       viewBoxSize={[130, 100]}
    //       center={[65, 50]}
    //       lineWidth={16}
    //       rounded
    //       data={[
    //         { title: 'Sweat Points', value: points, color: '#CABDFF' },
    //         { title: 'Total Points', value: 15, color: '#B1E5FC' },
    //       ]}
    //       onMouseOver={(_, index) => {
    //         console.log('INDEX::', _);
    //         setHovered(index);
    //       }}
    //       onMouseOut={() => {
    //         setHovered(null);
    //       }}
    //     />
    //
    //   </div>
    // </div>
  );
};

export default SweatPoints;
