import { aiUrl } from "../../constants";
import axios from "axios";

export const clearaisession = async (userid) => {
  axios
    .post(`${aiUrl}/startuser`, {
      userid,
    })
    .then(async (response) => {
      console.log("Sent::", response);
    })
    .catch((res2) => {
      console.log("eror::" + JSON.stringify(res2));
    });
};
