"use strict";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import moment from "moment";
import { ObjectControls } from "threejs-object-controls";

const HdrFile =
  "https://exarindia.s3.eu-west-3.amazonaws.com/venice_sunset_128.hdr";
var container;
var camera;
var renderer;
var scene;
var ex;
var mixer;
var light;
const clock = new THREE.Clock();
var startTime = "";
let playerModel;
var objectControls;
var didCompleteFileDownload;
var animation;
// eslint-disable-next-line no-unused-vars
let x = 1;
var totalDuration = 0.0;
var didStartWaitTime;

let sound;

let currentAnimationIndex = 0;
let isAnimating = false;
let waitDuration = 30;
let elapsedTime = 0;
let shouldCheckElapsedTimeAfterDonwload = true;
let waitTimer;
let sessiondidend;
let futureStartTimer;
let arrFiles = [
  {
    modelFile: "https://exarindia2.s3.ap-south-1.amazonaws.com/T26W1+P2.glb",
    audioFile: "https://exarindia2.s3.ap-south-1.amazonaws.com/cut+1.mp3",
    audio: null,
    model: null,
    duration: 228.89166259765625,
  },
  {
    duration: waitDuration,
  },
  {
    modelFile:
      "https://exargermanybucket.s3.eu-west-3.amazonaws.com/Cut2_1907.glb",
    audioFile: "https://exarindia2.s3.ap-south-1.amazonaws.com/cut+2.mp3",
    audio: null,
    model: null,
    duration: 287.5833435058594,
  },
  {
    duration: waitDuration,
  },
  {
    modelFile:
      "https://exargermanybucket.s3.eu-west-3.amazonaws.com/Cut3_2507.glb",
    audioFile: "https://exarindia2.s3.ap-south-1.amazonaws.com/cut3_edited.mp3",
    audio: null,
    model: null,
    duration: 398.1666564941406,
  },
];
let threeJSCalled = false;
let currentDonwloadingAudioIndex = -1;
let currentDownloadingModelIndex = -1;

export function initThreeJS(
  models,
  apiStartTime,
  fileDownloadCompleted,
  waitTimeStarted,
  setSessionDone
) {
  if (threeJSCalled) {
    return;
  } else {
    threeJSCalled = true;
  }
  resetClassObjet();
  arrFiles = [];
  for (let i = 0; i < models.length; i++) {
    arrFiles.push({
      modelFile: models[i].modelFile,
      audioFile: models[i].audioFile,
      audio: models[i].audio,
      model: models[i].model,
      duration: models[i].duration,
    });
  }
  //arrFiles = models.slice(0);
  console.log("Three JS Called::", arrFiles);
  console.log("models::", models);
  didCompleteFileDownload = fileDownloadCompleted;
  didStartWaitTime = waitTimeStarted;
  startTime = apiStartTime; //"Thu Jul 21 2022 10:72:32 GMT+0000";
  sessiondidend = setSessionDone;
  container = document.querySelector("#scene");
  //Create scene
  scene = new THREE.Scene();
  let dup = scene.getObjectByProperty("class", "canvas-class");
  if (dup) scene.remove(dup);
  const fov = 33.5;
  const aspect = container.clientWidth / container.clientHeight;
  const near = 0.001;
  const far = 1000;

  //Camera setup
  camera = new THREE.PerspectiveCamera(fov, aspect, near, far);

  camera.position.set(0, 2.5, 10);

  const ambient = new THREE.AmbientLight(0xfffff0, 0.5);
  scene.add(ambient);

  light = new THREE.DirectionalLight(0xe0e0e0, 0.1);
  light.target.position.set(1, 1, 5);

  light.castShadow = true;
  light.shadow.mapSize.width = 1024;
  light.shadow.mapSize.height = 1024;
  light.shadow.camera.near = 0.5;
  light.shadow.camera.left = -10;
  light.shadow.camera.bottom = -10;
  light.shadow.camera.top = 10;
  light.shadow.camera.right = 10;
  scene.add(light);

  const geometry = new THREE.PlaneGeometry(40, 40);
  geometry.rotateX(-Math.PI / 2);

  const material = new THREE.ShadowMaterial();
  material.opacity = 0.2;

  const plane = new THREE.Mesh(geometry, material);

  plane.position.set(0, 0, 0);
  plane.scale.set(20, 20, 20);
  plane.receiveShadow = true;
  scene.add(plane);

  //Renderer
  renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
  });
  console.log("WebGL REndered");
  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.domElement.classList.add("canvas-class");
  renderer.setPixelRatio(window.devicePixelRatio);
  // renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.gammaOutput = true;
  renderer.shadowMap.enabled = true;
  container.appendChild(renderer.domElement);

  const controls = new OrbitControls(camera, renderer.domElement);
  console.log("OrbitControls REndered");
  controls.target.set(0, 0.5, 0);
  controls.minDistance = 7.9;
  controls.maxDistance = 8.4;

  controls.minPolarAngle = Math.PI / 2.1;
  controls.maxPolarAngle = Math.PI / 2.1;
  controls.minAzimuthAngle = Math.PI / 30;
  controls.maxAzimuthAngle = Math.PI / 30;
  controls.enablePan = false;
  controls.enableDamping = true;
  controls.DampingFactor = 0.25;
  // to disable zoom
  controls.enableZoom = false;
  // to disable rotation
  controls.enableRotate = false;
  // to disable pan
  controls.enablePan = false;
  controls.update();

  const listener = new THREE.AudioListener();
  camera.add(listener);

  sound = new THREE.Audio(listener);
  console.log("Audio Listner REndered");
  loadBackground(function () {
    console.log("I started load bground");
    animate();
    calculateElapsedTimeAndStartDownload();
  });
}

function resetClassObjet() {
  container = undefined;
  camera = undefined;
  renderer = undefined;
  scene = undefined;
  ex = undefined;
  mixer = undefined;
  light = undefined;

  startTime = "";
  playerModel = undefined;
  objectControls = undefined;
  didCompleteFileDownload = undefined;
  animation = undefined;
  x = 1;
  totalDuration = 0.0;
  didStartWaitTime = undefined;

  sound = undefined;
  // audioElement = undefined;

  currentAnimationIndex = 0;
  isAnimating = false;
  waitDuration = 30;
  elapsedTime = 0;
  shouldCheckElapsedTimeAfterDonwload = true;
  waitTimer = undefined;
  sessiondidend = undefined;
  futureStartTimer = undefined;
  currentDonwloadingAudioIndex = -1;
  currentDownloadingModelIndex = -1;
}

function calculateElapsedTimeAndStartDownload() {
  if (waitTimer != null) {
    clearTimeout(waitTimer);
    waitTimer = null;
  }
  currentAnimationIndex = 0;
  isAnimating = false;
  elapsedTime = 0;
  totalDuration = 0;
  for (let i = 0; i < arrFiles.length; i++) {
    totalDuration = totalDuration + parseFloat(arrFiles[i].duration);
  }

  console.log("totalDuration " + totalDuration);

  if (startTime != null && startTime != "") {
    elapsedTime = getTotalElapsedTime(startTime);
    console.log("elapsedTime " + elapsedTime);
    if (elapsedTime < 0) {
      //Making it 0 here for future time
      elapsedTime = 0;
    }
    if (totalDuration > elapsedTime) {
      for (let i = 0; i < arrFiles.length; i++) {
        if (elapsedTime < parseFloat(arrFiles[i].duration)) {
          currentAnimationIndex = i;
          break;
        } else {
          elapsedTime = elapsedTime - parseFloat(arrFiles[i].duration);
        }
      }
      loadFiles(currentAnimationIndex);
    }
  } else {
    loadFiles(currentAnimationIndex);
  }
}

function checkElapsedTimeAfterDonwload() {
  if (
    startTime != null &&
    startTime != "" &&
    shouldCheckElapsedTimeAfterDonwload
  ) {
    elapsedTime = getTotalElapsedTime(startTime);
    console.log("elapsedTime after download" + elapsedTime);
    if (totalDuration > elapsedTime && elapsedTime > 0) {
      for (let i = 0; i < arrFiles.length; i++) {
        if (elapsedTime < parseFloat(arrFiles[i].duration)) {
          if (
            arrFiles[i].audioFile != null &&
            arrFiles[i].model == null &&
            arrFiles[i].audio == null
          ) {
            calculateElapsedTimeAndStartDownload();
          }
          break;
        } else {
          elapsedTime = elapsedTime - parseFloat(arrFiles[i].duration);
        }
      }
    }
  }
}

function loadFiles(index) {
  if (arrFiles[index].audioFile == null) {
    index = index + 1;
  }

  if (index >= arrFiles.length) {
    return;
  }
  if (arrFiles[index].audio == null && currentDonwloadingAudioIndex != index) {
    console.log("Loading Audio File For Index " + index);
    currentDonwloadingAudioIndex = index;
    let audioLoader = new THREE.AudioLoader();
    audioLoader.load(
      arrFiles[index].audioFile,
      function (buffer) {
        arrFiles[index].audio = buffer;
        console.log("Audio loaded for index " + index);
        startAnimation();
      },
      function () {
        // console.log( 'Sound ' + ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
      },
      function () {
        console.log("Sound load An error happened");
      }
    );
  }

  if (arrFiles[index].model == null && currentDownloadingModelIndex != index) {
    console.log("Loading Model File For Index " + index);
    currentDownloadingModelIndex = index;
    let loader = new GLTFLoader();
    let dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
    );
    loader.setDRACOLoader(dracoLoader);
    loader.load(
      arrFiles[index].modelFile,
      function (gltf) {
        arrFiles[index].model = gltf;
        startAnimation();
        console.log("Model loaded for index " + index);
      },
      function () {
        // console.log('Model ' + (xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      function () {
        console.log("Model load An error happened");
      }
    );
  }
}

function startAnimation() {
  console.log("--Animation Called--" + isAnimating);

  if (!isAnimating) {
    checkElapsedTimeAfterDonwload();
    if (futureStartTimer != null) {
      clearTimeout(futureStartTimer);
      futureStartTimer = null;
    }
    if (elapsedTime < 0) {
      console.log(Math.abs(elapsedTime));
      futureStartTimer = setTimeout(() => {
        console.log("---Future time called---");
        startAnimation();
        clearTimeout(futureStartTimer);
        futureStartTimer = null;
      }, Math.abs(elapsedTime) * 1000);
      return;
    }
    if (currentAnimationIndex < arrFiles.length) {
      if (arrFiles[currentAnimationIndex].audioFile == null) {
        //Wait index
        shouldCheckElapsedTimeAfterDonwload = false;
        isAnimating = true;
        didStartWaitTime(waitDuration - elapsedTime);
        waitTimer = setTimeout(() => {
          isAnimating = false;
          currentAnimationIndex = currentAnimationIndex + 1;
          startAnimation();

          clearTimeout(waitTimer);
          waitTimer = null;
        }, (parseFloat(arrFiles[currentAnimationIndex].duration) - elapsedTime) * 1000);
        elapsedTime = 0;
      } else {
        //Animation index
        if (
          arrFiles[currentAnimationIndex].model != null &&
          arrFiles[currentAnimationIndex].audio != null
        ) {
          console.log("Animation for index" + currentAnimationIndex);
          didCompleteFileDownload();
          shouldCheckElapsedTimeAfterDonwload = false;
          sound.setBuffer(arrFiles[currentAnimationIndex].audio);
          sound.setLoop(false);
          sound.setVolume(0.5);
          playerModel = arrFiles[currentAnimationIndex].model;
          playAnimation();
          arrFiles[currentAnimationIndex].audio = null;
          arrFiles[currentAnimationIndex].model = null;
          if (currentAnimationIndex + 1 < arrFiles.length) {
            loadFiles(currentAnimationIndex + 1);
          }
        } else {
          loadFiles(currentAnimationIndex);
        }
      }
    }
  }
}

function playAnimation() {
  isAnimating = true;
  ex = playerModel.scene.children[0];
  ex.castShadow = true;
  if (objectControls == null) {
    objectControls = new ObjectControls(camera, renderer.domElement, ex);
    objectControls.disableVerticalRotation();
    objectControls.setMaxVerticalRotationAngle(Math.PI / 4, Math.PI / 4);
    objectControls.setRotationSpeed(0.05);
    objectControls.enableZoom();
    objectControls.setDistance(5.5, 7.5); // set min - max distance for zoom
    objectControls.setZoomSpeed(0.3); // set zoom speed
  } else {
    objectControls.setObjectToMove(ex);
  }

  // To move Model
  ex.position.set(0.5, 0, 3.5);

  let previouseObject = scene.getObjectByName("gltfmodel");
  if (previouseObject != null) {
    scene.remove(previouseObject);
  }
  playerModel.scene.name = "gltfmodel";
  scene.add(playerModel.scene);

  playerModel.scene.traverse(function (child) {
    if (child.isMesh) {
      child.castShadow = true;
    }
  });

  mixer = new THREE.AnimationMixer(ex);
  mixer.addEventListener("loop", function () {
    //Loop did complete
    console.log("Loop end animation");
  });
  mixer.addEventListener("finished", function () {
    console.log("Did end animation");
    try {
      sound.stop();
    } catch (err) {
      console.log(err);
    }
    isAnimating = false;
    currentAnimationIndex = currentAnimationIndex + 1;
    console.log("CAI::", currentAnimationIndex, arrFiles.length);
    if (currentAnimationIndex >= arrFiles.length) {
      //stop the session
      sessiondidend(true);
    } else startAnimation();
  });
  let animation = mixer.clipAction(playerModel.animations[0]);
  console.log("Animation duration DUR1 : " + animation.getClip().duration);
  animation.setLoop = 1;
  animation.loop = THREE.LoopOnce;
  // totalDuration = playerModel.animations[0].duration;
  // console.log(totalDuration);
  if (startTime != null && startTime !== "") {
    animation.play();
    mixer.setTime(elapsedTime);
    sound.offset = elapsedTime + 1;
    sound.play();
    elapsedTime = 0;
  } else {
    sound.offset = 0;
    animation.play();
    setTimeout(() => {
      sound.play();
    }, 1000);
  }
}

export function loadBackground(didLoadBackground) {
  console.log("I am inside load bg");
  let noOfDownload = 0;
  let backGroundLoader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
  ); // use a full url path
  backGroundLoader.setDRACOLoader(dracoLoader);
  console.log("Decoder loaded");
  backGroundLoader.load(
    "https://exarindia.s3.eu-west-3.amazonaws.com/updated_mars_colony_texture_v07_Optimized+V2+(3).glb",
    function (gltf) {
      scene.add(gltf.scene);
      // (xhr.loaded / xhr.total) * 100 + '% loaded'
      noOfDownload = noOfDownload + 1;
      if (noOfDownload == 2) {
        didLoadBackground();
      }
    }
  );

  new RGBELoader().load(HdrFile, function (texture) {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.environment = texture;
    noOfDownload = noOfDownload + 1;
    if (noOfDownload == 2) {
      didLoadBackground();
    }
  });
}

export function loadBackground2() {
  console.log("I am inside load bg");
  let noOfDownload = 0;
  let backGroundLoader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
  ); // use a full url path
  backGroundLoader.setDRACOLoader(dracoLoader);
  console.log("Decoder loaded");
  backGroundLoader.load(
    "https://exarapiserver.s3.us-west-2.amazonaws.com/models/updated_mars_colony_texture_v07.glb",
    function () {
      //scene.add(gltf.scene);
      // (xhr.loaded / xhr.total) * 100 + '% loaded'
      noOfDownload = noOfDownload + 1;
      console.log("GLTF loaded::", noOfDownload);
      if (noOfDownload == 2) {
        console.log("3mb file loaded in browser memory");
      }
    }
  );
}

function animate() {
  try {
    animation = requestAnimationFrame(animate);

    const delta = clock.getDelta();
    // console.log(delta);
    if (mixer != null) {
      mixer.update(delta);
    }

    light.position.set(
      camera.position.x + 5,
      camera.position.y + 5,
      camera.position.z + 10
    );

    // house.rotation.z += 0.005;
    renderer.render(scene, camera);
  } catch (err) {
    console.log(err);
  }
  // console.log(mixer.time);
}

// function changeExer() {
//   if (x == 1) {
//     x = 0;
//     console.log('x changed to 0');
//   } else if (x == 0) {
//     x = 1;
//     console.log('x changed to 1');
//   }
// }

function getTotalElapsedTime(startTime) {
  const now = moment.utc();
  var start = moment(startTime);
  var seconds = now.diff(start, "seconds");
  return seconds;
}

export function onWindowResize() {
  if (camera != null) {
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(container.clientWidth, container.clientHeight);
  }
}

export function updateAnimationTime(apiStartTime) {
  startTime = apiStartTime;
  if (startTime != null && startTime != "") {
    if (mixer != null) {
      mixer.stopAllAction();
      sound.stop();
    }
    let elapsedTime = getTotalElapsedTime(startTime);
    if (elapsedTime < totalDuration) {
      calculateElapsedTimeAndStartDownload();
    }
  }
}

export function clearThreeJS() {
  threeJSCalled = false;
  if (scene != null) {
    while (scene.children.length > 0) {
      scene.remove(scene.children[0]);
    }
  }

  if (container != null) {
    cancelAnimationFrame(animation);
    while (container.hasChildNodes()) {
      container.removeChild(container.firstChild);
    }
    renderer.clear();
    renderer = undefined;
    scene = undefined;
    container = undefined;
  }
  sound.stop();
  container = undefined;
  camera = undefined;
  renderer = undefined;
  scene = undefined;
  ex = undefined;
  mixer = undefined;
  light = undefined;
  startTime = "";
  playerModel = undefined;
  objectControls = undefined;
  animation = undefined;
  x = 1;
  totalDuration = 0.0;
  sound = undefined;
  // audioElement = undefined;
  for (let i = 0; i < arrFiles.length; i++) {
    arrFiles[i].model = undefined;
    arrFiles[i].audio = undefined;
  }
  arrFiles = undefined;
}

export function stopAudio() {
  sound.stop();
}
