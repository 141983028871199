import React from "react";
import { useState } from "react";
import TopThreeComponent from "../Dashboard/TopThreeComponent";
import ArticleNavigation from "../Dashboard/ArticleNavigation";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const TopPerformers = ({ topthree, classes }) => {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  return (
    <div className="top-left article teacher-top-left-container">
      <ArticleNavigation
        top={true}
        teacher={false}
        title={t("TopThreeComponent.topthreeperformerTitle")}
        classes={classes}
        setLoad={setLoad}
      />{" "}
      {load ? (
        <center>
          <CircularProgress size={30} color="success" />
        </center>
      ) : (
        <TopThreeComponent teacher={true} topthree={topthree} />
      )}{" "}
    </div>
  );
};

export default TopPerformers;
