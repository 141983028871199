import React from 'react';
import pose from '../../assets/green-pose.png';
import astro from '../../assets/left-min.png';
import '../ClassProgress/classprogress.css';

const ChatBgComponent = () => {
    return ( <
        div style = {
            {
                position: 'fixed',
                width: '100%',
                height: '100%',
                zIndex: '-100',
                backgroundColor: '#FCFCF9',
                overflow: 'hidden',
            }
        } >
        <
        img className = "clas-progress-bcg-1"
        src = {
            pose
        }
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                top: '0',
                height: '100%',
                zIndex: '-100',
                left: '-20rem',
                maxHeight: '1300px',
            }
        }
        /> <
        img className = "clas-progress-bcg-2"
        src = {
            astro
        }
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                top: '0',
                zIndex: '-100',
                right: '-30rem',
                height: '100%',
                maxHeight: '1300px',
            }
        }
        /> <
        /div>
    );
};

export default ChatBgComponent;