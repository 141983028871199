import React, { useRef } from "react";
import { useBalanceCoreFeedback } from "../../../../../hooks/Demo/feedbackTypes";
import { checkIfSide } from "../../../../../utilities/Demo/angles";
import { loadStandOnOneLegAudio } from "../../../../../utilities/Demo/audioFeedback";
import { findAdjustedAngle } from "../../../../../utilities/Demo/physio";
import PoseDetection from "../../../PoseDetection";
import {
  computeMetrics,
  leftUpperLegRomMetrics,
  rightUpperLegRomMetrics,
} from "../../../../../utilities/Demo/Physio/BergBalance/metrics";
import { useDispatch } from "react-redux";

export default function StandOnOneLeg({ onComplete, showWebcam = false }) {
  const timeThresh = 30;
  const dispatch = useDispatch();
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    cptScore,
    updatePoseHeldDeps,
    playAudio,
    audioFeedback,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadStandOnOneLegAudio,
    maxScore: 1,
  });
  cptScore.current = () => {
    const secondsCount =
      secondsPoseHeldComplete.current + secondsPoseHeld.current;
    if (secondsCount >= 15) return 1;
    if (secondsCount >= 7) return 2 / 3;
    return 0;
  };
  const metrics = useRef(
    leftUpperLegRomMetrics("Adduction", "Abduction").concat(
      rightUpperLegRomMetrics("Adduction", "Abduction")
    )
  );

  function observation(hipKneeAngleLeft, hipKneeAngleRight, results) {
    const state = holdsPose.current;
    if (
      results.poseLandmarks[24].visibility > 0.5 &&
      results.poseLandmarks[26].visibility > 0.5 &&
      results.poseLandmarks[28].visibility > 0.5 &&
      results.poseLandmarks[23].visibility > 0.5 &&
      results.poseLandmarks[25].visibility > 0.5 &&
      results.poseLandmarks[27].visibility > 0.5
    ) {
      let liftsFoot = false;
      if (
        (hipKneeAngleLeft < 10 || hipKneeAngleLeft > 345) &&
        results.poseLandmarks[28].y * 1.1 < results.poseLandmarks[27].y
      ) {
        liftsFoot = true;
      } else if (
        (hipKneeAngleRight < 10 || hipKneeAngleRight > 345) &&
        results.poseLandmarks[27].y * 1.1 < results.poseLandmarks[28].y
      ) {
        liftsFoot = true;
      }
      if (liftsFoot) {
        holdsPose.current = true;
        secondsPoseHeld.current =
          seconds.current - secondPoseHeldStarted.current;
        updateFeedback("");
      } else {
        updateFeedback("Lift one foot and stand straight!");
        holdsPose.current = false;
      }
    } else {
      updateFeedback("Person out of frame!");
      holdsPose.current = false;
    }
    updatePoseHeldDeps(state);
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    const side = checkIfSide(results, 4);
    if (side) {
      updateFeedback("Face the camera!");
      playAudio(audioFeedback.current?.faceCamera);
      return;
    }

    const hipKneeAngleLeft = findAdjustedAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25]
    );
    const hipKneeAngleRight =
      360 -
      findAdjustedAngle(results.poseLandmarks[24], results.poseLandmarks[26]);
    const displayConfigs = {
      hipKneeAngleLeft: {
        coord: results.poseLandmarks[25],
        left: true,
      },
      hipKneeAngleRight: {
        coord: results.poseLandmarks[26],
        left: false,
      },
    };
    computeMetrics({
      metrics: metrics.current,
      angles: {
        current: {
          hipKneeAngleLeft,
          hipKneeAngleRight,
        },
      },
      dispatch,
      displayConfigs,
      canvasCtx,
    });

    observation(hipKneeAngleLeft, hipKneeAngleRight, results);
    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
