import {
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
} from 'firebase/firestore';
import {
    db
} from '../../../firebase';

export const getFile = async ({
    fileId
}) => {
    const docRef = doc(db, 'files', fileId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
        console.log('No matching documents.');
        return null;
    }

    console.log(docSnap.data(), 'docSnap.data()', docSnap.id, 'docSnap.id');

    return {
        ...docSnap.data(),
        id: docSnap.id,
    };
};

export const listFiles = async ({
    doctorId,
    patientId,
    limitQuery = 15
}) => {
    let collectionQuery = collection(db, 'files');

    // Add orderBy
    collectionQuery = query(
        collectionQuery,
        where('doctor_id', '==', doctorId),
        where('patient_id', '==', patientId),
        orderBy('created_at', 'desc'),
        limit(limitQuery)
    );

    const snapshot = await getDocs(collectionQuery);

    if (snapshot.empty) {
        console.log('No matching documents.');
        return [];
    }

    return snapshot.docs.map((doc) => {
        return {
            ...doc.data(),
            id: doc.id,
        };
    });
};

export const listenForDocumentChanges = ({
    collection,
    documentId,
    callback,
}) => {
    const docRef = doc(db, collection, documentId);

    const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
            if (docSnapshot.exists()) {
                callback(docSnapshot.data());
                console.log('Current data: ', docSnapshot.data());
            } else {
                console.log('No such document!');
            }
        },
        (error) => {
            console.error('Error listening to document: ', error);
        }
    );

    return unsubscribe;
};

export async function getLatestSports2DResults({
    patientId,
    doctorId,
    queryLimit = 1,
}) {
    let collectionQuery = collection(db, 'sports_2d_results');

    // Add orderBy
    collectionQuery = query(
        collectionQuery,
        where('doctor_id', '==', doctorId),
        where('patient_id', '==', patientId),
        orderBy('created_at', 'desc'),
        limit(queryLimit)
    );

    const snapshot = await getDocs(collectionQuery);

    if (snapshot.empty) {
        console.log('No matching documents.');
        return [];
    }

    return snapshot.docs.map((doc) => {
        return {
            ...doc.data(),
            id: doc.id,
        };
    });
}