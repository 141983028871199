import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { getDownloadURL } from "firebase/storage";
import _ from "lodash";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPlayer from "react-player/lazy";
import { toast, ToastContainer } from "react-toastify";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import { updateToastCaseFunction } from "../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts";
import {
  addTransition,
  getExercise,
  updateTransition,
} from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import {
  getFileRef,
  uploadFile,
} from "../../../../../utilities/Demo/ExerciseManagement/Generators/storage";
import CloseBadge from "../CloseBadge";
import TagConfig from "../TagConfig";
import ExerciseCard from "./ExerciseCard";
import TransitionDialog from "./TransitionDialog";

export default function TransitionConfigurator({
  onClose,
  transitionData = {},
  addTransitionToList,
  updateTransitionInList,
}) {
  const [id, setId] = React.useState(transitionData.id || null);
  const [videoFile, setVideoFile] = React.useState(null);
  const [videoUploading, setVideoUploading] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(null);
  const [name, setName] = React.useState(transitionData.name || "");
  const [description, setDescription] = React.useState(
    transitionData.description || ""
  );
  const [overviewRedirect, setOverviewRedirect] = React.useState(
    transitionData.overviewRedirect || ""
  );
  const [exercises, setExercises] = React.useState(
    transitionData.exercises || []
  );
  const [tags, setTags] = React.useState(transitionData.tags || []);
  const [exerciseDialogOpen, setExerciseDialogOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const { user } = React.useContext(FirebaseAuthContext);
  const buttonText = id ? "Update" : "Create";

  const addExercise = (exercise) => {
    setExercises([...exercises, exercise]);
  };

  React.useEffect(() => {
    async function fetchExercises() {
      const promises = exercises.map(async (exercise) => {
        const fetchedEx = await getExercise({ exerciseId: exercise.id });
        fetchedEx.endTime = exercise.endTime;
        return fetchedEx;
      });

      // Use Promise.all() to wait for all the promises to resolve
      return Promise.all(promises);
    }

    fetchExercises().then((exerciseDocs) => {
      setExercises(exerciseDocs);
    });
    if (transitionData.id) {
      const fileRef = getFileRef("transition_videos/" + transitionData.id);
      getDownloadURL(fileRef)
        .then((url) => setVideoUrl(url))
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...exercises];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setExercises(updatedList);
  };

  const saveTransition = () => {
    setSaving(true);
    const newTransitionData = {
      ...transitionData,
      name,
      description,
      overviewRedirect,
      videoUrl,
      tags: tags.map((tag) => tag.id),
      exercises: exercises.map((exercise) => {
        return {
          id: exercise.id,
          endTime: exercise.endTime,
        };
      }),
    };
    const toastId = toast.loading("Saving...");
    const updateToastCase = updateToastCaseFunction({
      id: toastId,
      successRender: "Saved Transition!",
      errorRender: "Error saving Transition!",
    });
    if (id) {
      updateTransition({ user, transitionData: newTransitionData }).then(
        (transition) => {
          updateToastCase(transition);
          setSaving(false);
          updateTransitionInList({ ...newTransitionData, id });
        }
      );
    } else {
      addTransition({ user, transitionData: newTransitionData }).then(
        (transition) => {
          updateToastCase(transition);
          setSaving(false);
          setId(transition.id);
          addTransitionToList({ ...newTransitionData, id: transition.id });
        }
      );
    }
  };

  const setEndTime = (value, index) => {
    const updatedExercises = [...exercises];
    updatedExercises[index].endTime = value;
    setExercises(_.sortBy(updatedExercises, ["endTime"]));
  };

  return (
    <div style={{ padding: 20 }}>
      <TransitionDialog
        open={exerciseDialogOpen}
        onClose={() => setExerciseDialogOpen(false)}
        setExercise={addExercise}
      />
      <div style={{ display: "flex", justifyContent: "right" }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <h1>Transition Configurator</h1>
      <Grid container spacing={2} direction={"column"} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={4}
            minRows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-multiline-flexible"
            label="PatientOverview Path"
            variant={"outlined"}
            value={overviewRedirect}
            onChange={(e) => setOverviewRedirect(e.target.value)}
            sx={{ height: "50%", width: "50%" }}
          />
        </Grid>
      </Grid>
      <TagConfig tags={tags} setTags={setTags} type={"transition"} />
      <h3>Exercises</h3>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <Grid
              {...provided.droppableProps}
              container
              spacing={4}
              sx={{ padding: 2 }}
              ref={provided.innerRef}
            >
              {exercises.map((exercise, index) => (
                <Draggable
                  key={"exercise-" + exercise.name + index}
                  draggableId={"exercise-" + exercise.name + index}
                  index={index}
                >
                  {(provided) => (
                    <Grid
                      item
                      key={"exercise-" + exercise.name + index}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <ExerciseCard
                        exercise={exercise}
                        onDelete={() => {
                          setExercises(exercises.filter((_, i) => i !== index));
                        }}
                        index={index}
                        setEndTime={setEndTime}
                      ></ExerciseCard>
                    </Grid>
                  )}
                </Draggable>
              ))}
              <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    padding: 2,
                    border: 1,
                    borderRadius: 5,
                    m: 2,
                  }}
                >
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      setExerciseDialogOpen(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {provided.placeholder}
              </Grid>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <Grid container spacing={2} sx={{ mt: 3, ml: 3, mb: 3 }}>
        <Grid item xs={6} sm={2}>
          <h3>Video</h3>
        </Grid>
        <Grid item xs={12} sm={4}>
          <input
            accept="video/mp4"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={(event) => {
              setVideoFile(event.target.files[0]);
            }}
          />
          <label htmlFor="raised-button-file">
            <Badge
              invisible={!videoFile}
              badgeContent={<CloseBadge setConfig={() => setVideoFile(null)} />}
            >
              <Button variant="raised" component="span">
                {!videoFile ? "Choose File" : videoFile.name}
              </Button>
            </Badge>
          </label>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Button
            variant={"outlined"}
            disabled={!videoFile || !transitionData.id}
            onClick={() => {
              setVideoUploading(true);
              uploadFile({
                file: videoFile,
                filePath: "/transition_videos/" + transitionData.id,
              }).then((fileRef) => {
                setVideoFile(null);
                getDownloadURL(fileRef).then((url) => setVideoUrl(url));
                setVideoUploading(false);
              });
            }}
          >
            {videoUploading ? <CircularProgress /> : "Upload"}
          </Button>
        </Grid>
        <Grid item xs={10}>
          {videoUrl && (
            <ReactPlayer
              url={videoUrl}
              controls={true}
              playing={false}
              loop={false}
              className="react-player"
              width="100%"
              height="100%"
              config={{ file: { attributes: { preload: "auto" } } }}
            />
          )}
        </Grid>
      </Grid>
      <Button
        variant={"contained"}
        disabled={
          saving ||
          name.length === 0 ||
          description.length === 0 ||
          exercises.length === 0
        }
        onClick={saveTransition}
      >
        {saving ? <CircularProgress /> : buttonText}
      </Button>
      <ToastContainer />
    </div>
  );
}
