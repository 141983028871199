export const colorBelts = {
  white: {
    bg: "#F0F0F0",
    color: "black",
  },
  orange: {
    bg: "orange",
    color: "#83BF6E",
  },
  blue: {
    bg: "blue",
    color: "white",
  },
  yellow: {
    bg: "yellow",
    color: "#83BF6E",
  },
  green: {
    bg: "#EAFAE4",
    color: "#83BF6E",
  },
  brown: {
    bg: "brown",
    color: "#83BF6E",
  },
  black: {
    bg: "black",
    color: "white",
  },
};
