import { baseUrl } from "../../constants";
import axios from "axios";

export const getAllClasses = async (dispatch) => {
  let token = localStorage.getItem("token");
  axios
    .get(`${baseUrl}/getallclasses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      dispatch({
        type: "ALL-CLASSES",
        payload: response.data.class_details,
      });
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
    });
};

export const createExerciseSet = async (
  data,
  setShow,
  setLoad,
  setResp,
  setResult,
  setMsg
) => {
  setLoad(true);
  let token = localStorage.getItem("token");
  axios
    .post(`${baseUrl}/createset`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (response) => {
      setShow(true);
      setResult("success");
      setLoad(false);
      setResp(response.data.response[0]);
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      setLoad(false);
      setResult("error");
      setShow(true);
      setMsg(error.response ? error.response.data.message : error.message);
    });
};

export const updateExerciseSet = async (
  data,
  setShow,
  setLoad,
  setResp,
  setResult,
  setMsg
) => {
  setLoad(true);
  let token = localStorage.getItem("token");
  axios
    .put(`${baseUrl}/updateset`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (response) => {
      setShow(true);
      setResult("success");
      setLoad(false);
      setResp(response.data.response[0]);
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      setLoad(false);
      setResult("error");
      setShow(true);
      setMsg(error.response ? error.response.data.message : error.message);
    });
};

export const scheduleExerciseSet = async (
  data,
  setShow,
  setLoad,
  setResp,
  setResult,
  setMsg
) => {
  setLoad(true);
  let token = localStorage.getItem("token");
  axios
    .post(`${baseUrl}/schedulesessions`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async () => {
      setShow(true);
      setResult("success");
      setLoad(false);
      setResp("Sets have been scheduled successfully");
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      setLoad(false);
      setResult("error");
      setShow(true);
      setMsg(error.response ? error.response.data.message : error.message);
    });
};

export const getAllSets = async (dispatch) => {
  let token = localStorage.getItem("token");
  axios
    .get(`${baseUrl}/allsets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      dispatch({
        type: "ALL-SETS",
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
    });
};
