import { useEffect, useRef } from "react";

export const useTimer = () => {
  const time = useRef(0);
  const lastTimeLeft = useRef(0);
  const lastTimeRight = useRef(0);
  const timeRunning = useRef(false);

  useEffect(() => {
    let lastTimestamp = null;

    const updateFunction = (timestamp) => {
      if (lastTimestamp === null) {
        lastTimestamp = timestamp;
      }

      if (timeRunning.current) {
        const delta = timestamp - lastTimestamp;
        time.current += delta;
        lastTimestamp = timestamp;
      }

      requestAnimationFrame(updateFunction);
    };

    requestAnimationFrame(updateFunction);

    return () => {
      lastTimestamp = null;
    };
  }, []);

  const stopTimeDiffLeft = () => {
    const diff = time.current - lastTimeLeft.current;
    lastTimeLeft.current = time.current;
    return diff;
  };

  const stopTimeDiffRight = () => {
    const diff = time.current - lastTimeRight.current;
    lastTimeRight.current = time.current;
    return diff;
  };

  return {
    time,
    stopTimeDiffLeft,
    stopTimeDiffRight,
    timeRunning,
  };
};

export function useStepLength() {
  const stepLengthCandidates = useRef([]);

  function addCandidate(results, side) {
    let stepLength = results.poseLandmarks[31].x - results.poseLandmarks[32].x;
    if (side !== "left") {
      stepLength *= -1;
    }
    stepLengthCandidates.current.push(stepLength);
  }

  function getMaxStepLength() {
    const max = Math.max(...stepLengthCandidates.current);
    stepLengthCandidates.current = [];
    return max;
  }

  return {
    addCandidate,
    getMaxStepLength,
  };
}

export function checkPersonOutOfFrame(results, canvasCtx) {
  const videoHeight = canvasCtx.canvas.height;
  const videoWidth = canvasCtx.canvas.width;
  const res =
    results.poseLandmarks[30].y / videoHeight > 0.9 ||
    results.poseLandmarks[29].y / videoHeight > 0.9 ||
    results.poseLandmarks[29].y / videoHeight < 0.1 ||
    results.poseLandmarks[30].y / videoHeight < 0.1 ||
    results.poseLandmarks[12].y / videoHeight < 0.1 ||
    results.poseLandmarks[11].y / videoHeight < 0.1 ||
    results.poseLandmarks[30].x / videoWidth > 0.9 ||
    results.poseLandmarks[29].x / videoWidth > 0.9 ||
    results.poseLandmarks[29].x / videoWidth < 0.1 ||
    results.poseLandmarks[30].x / videoWidth < 0.1 ||
    results.poseLandmarks[12].x / videoWidth < 0.1 ||
    results.poseLandmarks[11].x / videoWidth < 0.1;
  return res;
}
