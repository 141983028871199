import * as React from 'react';
import {
    useState,
    useEffect
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import '../../components/Dashboard/PointsComponent.css';
import './RewardsCategory.css';
import {
    saveRewards,
    getRewards
} from '../../redux/actions/getRewards';
import {
    CircularProgress
} from '@mui/material';

import {
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
} from '@mui/material';
import Button from '@mui/material/Button';
import {
    styled
} from '@mui/system';
import Backdrop from '@mui/material/Backdrop';

export default function RewardsCategory({
    board
}) {
    const dispatch = useDispatch();
    const rewards = useSelector(({
        rewards
    }) => rewards.values);
    const {
        user_id
    } = JSON.parse(localStorage.getItem('full_user'));
    const [category, setCategory] = useState('characters');
    const [productData, setProductData] = useState();
    const [toggle, setToggle] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [type, setType] = useState('');
    const [load, setLoad] = useState(false);
    const [msg, setMsg] = useState('');

    const setData = async (rewards) => {
        setProductData(rewards.data);
    };

    React.useEffect(() => {
        if (!rewards || rewards.length) {
            getRewards(dispatch, (a) => a);
        }
    });

    useEffect(() => {
        if (rewards) {
            setData(rewards);
        }
    });
    const handleCategoryChange = (categoryName) => {
        setCategory(categoryName);
        console.log('categoryhandleCategoryChange', category);
    };
    const closeModal = () => {
        setToggle(false);
        setMsg('');
        setType('');
    };
    const openModal = (data) => {
        setToggle(true);
        setModalData(data);
    };

    const confirmClaim = (data) => {
        setLoad(true);
        saveRewards(
            user_id,
            data.product_id,
            data.price,
            data.price_type,
            setLoad,
            setMsg,
            setType
        );
        getRewards(dispatch, setLoad);
        // setTimeout(() => { window.location.reload() }, "3000")
    };
    return ( <
        >
        <
        div >
        <
        div className = "toggel-bar" >
        <
        button className = "rewards-toggel"
        onClick = {
            () => handleCategoryChange('characters')
        } >
        <
        span className = "dot"
        style = {
            {
                backgroundColor: '#30B28C'
            }
        } > < /span>
        Characters <
        /button> <
        button className = "rewards-toggel"
        onClick = {
            () => handleCategoryChange('planets')
        } >
        <
        span className = "dot"
        style = {
            {
                backgroundColor: '#E03E1A'
            }
        } > < /span>
        Planets <
        /button> <
        button className = "rewards-toggel"
        onClick = {
            () => handleCategoryChange('items')
        } >
        <
        span className = "dot"
        style = {
            {
                backgroundColor: '#FDC24F'
            }
        } > < /span>
        Items <
        /button> <
        button className = "rewards-toggel"
        onClick = {
            () => handleCategoryChange('sports')
        } >
        <
        span className = "dot"
        style = {
            {
                backgroundColor: '#9F73E6'
            }
        } > < /span>
        Sports Goodies <
        /button> <
        button className = "rewards-toggel"
        onClick = {
            () => handleCategoryChange('owned')
        } >
        <
        span className = "dot"
        style = {
            {
                backgroundColor: '#9ce5f2'
            }
        } > < /span>
        Owned <
        /button> <
        /div> {
            productData && productData[category].length > 0 ? ( <
                div className = "product-display" > {
                    productData[category].map((product) => ( <
                        div key = {
                            product.id
                        }
                        className = "product-card" >
                        <
                        img src = {
                            product.image_url
                        }
                        alt = {
                            product.reward_name
                        }
                        className = "product-image" /
                        >
                        <
                        div className = "product-details" >
                        <
                        button className = "product-hash" >
                        <
                        b > {
                            product.product_id
                        } < /b> <
                        /button> {
                            category == 'owned' ? ( <
                                button disabled = {
                                    product.price_type === 'xr' ?
                                    board.sweat_points < product.price :
                                        board.coins < product.price
                                }
                                className = "product-claim"
                                style = {
                                    {
                                        backgroundColor: 'white'
                                    }
                                } >
                                <
                                b > Owned < /b> <
                                /button>
                            ) : ( <
                                button disabled = {
                                    product.price_type === 'xr' ?
                                    board.sweat_points < product.price :
                                        board.coins < product.price
                                }
                                className = "product-claim"
                                onClick = {
                                    () => {
                                        openModal(product);
                                    }
                                } >
                                <
                                b > Claim < /b> <
                                /button>
                            )
                        } <
                        button className = "product-sp" >
                        <
                        b > {
                            product.price
                        } {
                            product.price_type
                        } <
                        /b> <
                        /button> <
                        /div>

                        {
                            toggle && ( <
                                Dialog PaperProps = {
                                    {
                                        sx: {
                                            borderRadius: 10,
                                            maxheight: '53%',
                                            width: '95vw',
                                            padding: 2,
                                        },
                                    }
                                }
                                scroll = "body"
                                BackdropComponent = {
                                    styled(Backdrop, {
                                        name: 'MuiModal',
                                        slot: 'Backdrop',
                                        overridesResolver: (props, styles) => {
                                            return styles.backdrop;
                                        },
                                    })({
                                        zIndex: -1,
                                        opacity: 0.5,
                                        backgroundColor: 'transparent',
                                    })
                                }
                                open = {
                                    toggle
                                }
                                onClose = {
                                    closeModal
                                } >
                                <
                                DialogContent style = {
                                    {
                                        overflow: 'transparent'
                                    }
                                } >
                                <
                                DialogTitle sx = {
                                    {
                                        fontSize: 18
                                    }
                                } > {
                                    modalData.product_id
                                } <
                                span style = {
                                    {
                                        float: 'right',
                                        color: 'grey',
                                        cursor: 'pointer',
                                    }
                                }
                                onClick = {
                                    closeModal
                                } >
                                &
                                #x2715; <
                                /span> <
                                /DialogTitle> <
                                div className = "product-cards" >
                                <
                                img src = {
                                    product.image_url
                                }
                                alt = {
                                    product.reward_name
                                }
                                className = "product-image" /
                                >
                                <
                                /div> {
                                    msg && type == 'success' ? ( <
                                        button className = "product-hash"
                                        style = {
                                            {
                                                marginTop: '2rem',
                                                marginLeft: '30%'
                                            }
                                        } >
                                        <
                                        b > {
                                            msg
                                        } < /b> <
                                        /button>
                                    ) : type == 'alert' ? ( <
                                        button className = "product-hash"
                                        style = {
                                            {
                                                marginTop: '2rem',
                                                marginLeft: '30%',
                                                backgroundColor: 'orange',
                                            }
                                        } >
                                        <
                                        b > {
                                            msg
                                        } < /b> <
                                        /button>
                                    ) : type == 'claimedalert' ? ( <
                                        button className = "product-hash"
                                        style = {
                                            {
                                                marginTop: '2rem',
                                                marginLeft: '30%',
                                                backgroundColor: 'yellow',
                                            }
                                        } >
                                        <
                                        b > {
                                            msg
                                        } < /b> <
                                        /button>
                                    ) : type == 'error' ? ( <
                                        button className = "product-hash"
                                        style = {
                                            {
                                                marginTop: '2rem',
                                                marginLeft: '30%',
                                                backgroundColor: 'red',
                                            }
                                        } >
                                        <
                                        b > {
                                            msg
                                        } < /b> <
                                        /button>
                                    ) : ( <
                                        button className = "product-hash"
                                        style = {
                                            {
                                                marginTop: '2rem',
                                                marginLeft: '30%',
                                                backgroundColor: 'white',
                                            }
                                        } >
                                        <
                                        b > {
                                            msg
                                        } < /b> <
                                        /button>
                                    )
                                } <
                                /DialogContent> {
                                    !msg && ( <
                                        DialogActions disableSpacing = {
                                            true
                                        }
                                        sx = {
                                            {
                                                justifyContent: 'center',
                                                height: '10%'
                                            }
                                        } >
                                        {
                                            load ? ( <
                                                CircularProgress size = {
                                                    28
                                                }
                                                color = "primary" / >
                                            ) : ( <
                                                Button
                                                // type="submit"
                                                variant = "contained"
                                                // disabled={valid}
                                                sx = {
                                                    {
                                                        borderRadius: 10,
                                                        backgroundColor: '#FF592C',
                                                    }
                                                }
                                                onClick = {
                                                    () => {
                                                        confirmClaim(modalData);
                                                    }
                                                } >
                                                <
                                                b > Confirm < /b> <
                                                /Button>
                                            )
                                        } <
                                        /DialogActions>
                                    )
                                } <
                                /Dialog>
                            )
                        } <
                        /div>
                    ))
                } <
                /div>
            ) : ( <
                div >
                <
                h1 className = "no-data" / >
                <
                /div>
            )
        } <
        /div> <
        />
    );
}

// <button className="product-hash" style={{ marginTop: "2rem", marginLeft: "30%" }}><b>{msg}</b></button>
// {/* <button className="product-hash"><b>{product.product_id}</b></button> */}