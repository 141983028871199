import React from "react";
import search from "../../assets/search.png";
import "./ranking.css";
import { useTranslation } from "react-i18next";

const SearchBar = ({ setFilter, filter }) => {
  const { t } = useTranslation();
  return (
    <div className="searchcont">
      <img src={search} className="sclip">
        {" "}
      </img>{" "}
      <input
        placeholder={t("ChatScreen.search")}
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
        className="inputRounded"
      />
    </div>
  );
};

export default SearchBar;
