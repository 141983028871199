import _ from 'lodash';

export function getStretchLength(
    personHeightRWU,
    personHeightPixels,
    results,
    isLeft,
    refCallBack = () => {}
) {
    const middleHeel = _.mean([
        results.poseLandmarks[29].x,
        results.poseLandmarks[30].x,
    ]);
    let wrist;
    let shoulderWrist;
    if (isLeft) {
        wrist = results.poseLandmarks[15].x;
        shoulderWrist = Math.abs(
            results.poseLandmarks[11].x - results.poseLandmarks[15].x
        );
    } else {
        wrist = results.poseLandmarks[16].x;
        shoulderWrist = Math.abs(
            results.poseLandmarks[12].x - results.poseLandmarks[16].x
        );
    }
    const stretchLengthPixels = Math.abs(
        Math.abs(middleHeel - wrist) - shoulderWrist
    );

    const pixelRWU = personHeightRWU / personHeightPixels;
    if (refCallBack) refCallBack(personHeightRWU, personHeightPixels, results);
    return Math.round(stretchLengthPixels * pixelRWU);
}