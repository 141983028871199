import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ViewToggle({ viewType, setViewType }) {
  const handleChange = (event, newViewType) => {
    setViewType(newViewType);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={viewType}
      exclusive
      onChange={handleChange}
      aria-label="view toggle"
    >
      <ToggleButton value="patient">Patient View</ToggleButton>
      <ToggleButton value="doctor">Doctor View</ToggleButton>
    </ToggleButtonGroup>
  );
}
