import React, { useState } from "react";
import LegendComponent from "./LegendComponent";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./highlights.css";
import { PieChart } from "react-minimal-pie-chart";
import { useTranslation } from "react-i18next";

function getMinutesSecondsFromSeconds(seconds) {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  return `${minutes}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`;
}

const Duration = ({ points, indi }) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(null);
  return (
    <div className="box--container bottom-box">
      <div className="top-bar">
        <div className="orange-box"></div>
        <h2>{t("SessionComponent.Duration")}</h2>
      </div>
      <div className="pie-chart" data-tip="" data-for="chart">
        <PieChart
          style={{ width: "100%", height: "100%" }}
          viewBoxSize={[100, 100]}
          center={[50, 50]}
          lineWidth={20}
          totalValue={27.5}
          rounded
          data={[
            { title: "Duration", value: points, color: "#CABDFF" },
            { title: "Total duration", value: 27.5 - points, color: "#B1E5FC" },
          ]}
          labelPosition={0}
          label={(prop) =>
            prop.dataEntry.title === "Duration" &&
            getMinutesSecondsFromSeconds(points)
          }
          labelStyle={{ fontWeight: "bold", textAlign: "center" }}
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(null);
          }}
        />
        <ReactTooltip
          id="chart"
          place="bottom"
          backgroundColor="#272B30"
          textColor="#EFEFEF"
          multiline={true}
          getContent={() =>
            typeof hovered === "number" ? (
              <LegendComponent
                title={"Duration"}
                gcolor={indi[hovered].color}
                pts={indi[hovered].duration}
              />
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default Duration;
