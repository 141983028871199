import * as React from "react";
import { useState } from "react";
import UserSignUpDetails from "./UserSignUpDetails";
import AgeGroup from "./AgeGroup";
import ConfirmAllDetails from "./ConfirmAllDetails";

// eslint-disable-next-line react/prop-types
export default function SignUpComponent({ open, setOpen }) {
  const [state, setState] = useState({
    step: 1,
  });
  const [parentsName, setParentsName] = useState("");
  const [childsName, setChildsName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const nextStep = () => {
    const { step } = state;
    setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  const prevStep = () => {
    const { step } = state;
    setState({
      step: step - 1,
    });
  };

  const { step } = state;

  switch (step) {
    case 1:
      return (
        <UserSignUpDetails
          nextStep={nextStep}
          open={open}
          setOpen={setOpen}
          parentsName={parentsName}
          setParentsName={setParentsName}
          childsName={childsName}
          setChildsName={setChildsName}
          email={email}
          setEmail={setEmail}
          setPassword={setPassword}
          setRepeatPassword={setRepeatPassword}
          password={password}
          repeatPassword={repeatPassword}
        />
      );
    case 2:
      return (
        <AgeGroup
          nextStep={nextStep}
          prevStep={prevStep}
          open={open}
          setOpen={setOpen}
          ageGroup={ageGroup}
          setAgeGroup={setAgeGroup}
        />
      );
    case 3:
      return (
        <ConfirmAllDetails
          nextStep={nextStep}
          prevStep={prevStep}
          open={open}
          setOpen={setOpen}
          parentsName={parentsName}
          childsName={childsName}
          email={email}
          password={password}
          ageGroup={ageGroup}
        />
      );
  }
}
