import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import FirebaseAuthContext from "../../../../../contexts/auth/FirebaseAuthContext";
import { updateToastCaseFunction } from "../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts";
import {
  addTag,
  updateTag,
} from "../../../../../utilities/Demo/ExerciseManagement/firestore";

export default function TagConfigurator({ config, onClose, setConfig }) {
  const [loading, setLoading] = React.useState(false);
  const [id, setId] = React.useState(config.id);
  const [name, setName] = React.useState(config.name);
  const [description, setDescription] = React.useState(config.description);
  const { user } = React.useContext(FirebaseAuthContext);

  const onSave = () => {
    setLoading(true);
    const newConfig = {
      name,
      description,
      id,
    };
    const toastId = toast.loading("Saving...");
    const updateToastCase = updateToastCaseFunction({
      id: toastId,
      successRender: "Saved Tag!",
      errorRender: "Error saving Tag!",
    });
    if (id) {
      updateTag({ tagData: newConfig, user }).then((tag) => {
        updateToastCase(tag);
        setConfig({ ...config, ...newConfig });
        setLoading(false);
      });
    } else {
      addTag({ tagData: newConfig, user }).then((newId) => {
        updateToastCase(newId);
        setId(newId);
        setConfig({ ...config, ...newConfig });
        setLoading(false);
      });
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container direction={"column"} spacing={2}>
        <Grid item={true} xs={12} sm={6} md={6}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4}>
          <TextField
            label="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          justifyContent: "left",
          alignItems: "left",
          display: "flex",
          padding: 5,
        }}
      >
        <Button variant="contained" disabled={!name} onClick={onSave}>
          {loading ? <CircularProgress /> : "Save"}
        </Button>
        <ToastContainer />
      </Box>
    </Box>
  );
}
