import moment from 'moment';
import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    getChats
} from '../../redux/actions/getChatsData';
import './chat.css';
import {
    CircularProgress
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
    useTranslation
} from 'react-i18next';

const Chat = ({
    chatsocket,
    tch,
    loadtchapi,
    setTchapi,
    showChat,
    setShowChat,
}) => {
    const {
        t
    } = useTranslation();
    const [cdata, setCdata] = useState([]);
    const chats = useSelector(({
        chats
    }) => chats.values);
    const [val, setVal] = useState('');
    const datesdone = [];
    const [load, setLoad] = useState(false);
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user_data'));
    const ref = useRef(null);
    const sendMessage = (val) => {
        console.log('TCSSSH::', tch.teacher_id ? tch.teacher_id : tch.stud_id);
        let id = tch.teacher_id ? tch.teacher_id : tch.stud_id;
        let data = {
            to: '' + id,
            message: val,
        };
        console.log('Data is::', data);
        let dt = {
            from: '' + user.user_id,
            profile_pic: user.profile_pic,
            message: val,
            name: user.name,
            date: moment().valueOf(),
        };
        setCdata([...cdata, dt]);
        setVal('');
        chatsocket.emit('message', data);
        setTchapi(!loadtchapi);
        //scrollToBottom();
    };
    chatsocket &&
        chatsocket.on('receivedMessage', (data) => {
            console.log('Data rec::', data);
            //callback to parent to pick msgs again
            setTchapi(!loadtchapi);
            setCdata([...cdata, JSON.parse(data)]);
            //   setLoad(!load);
            console.log('Cdata::', cdata);
            //scrollToBottom();
        });
    const scrollToBottom = () => {
        const scroll = ref.current.scrollHeight - ref.current.clientHeight;
        ref.current.scrollTo(0, scroll);
    };
    useEffect(() => {
        if (tch) {
            try {
                scrollToBottom();
            } catch (err) {
                console.log(err);
            }
        }
    });
    useEffect(() => {
        if (chats) {
            setCdata(chats);
        }
        console.log('Chats are::', chats);
    }, [chats]);
    useEffect(() => {
        if (tch) {
            console.log('TCS::', tch);
            getChats(
                dispatch,
                tch.teacher_id ? tch.teacher_id : tch.stud_id,
                setLoad
            );
        }
    }, [tch]);
    const calculateDateDifference = (dater) => {
        datesdone.push(moment(dater).format('MM/DD/YYYY'));
        const date1 = new Date(moment(dater).format('MM/DD/YYYY'));
        const date2 = new Date(moment().format('MM/DD/YYYY'));
        const diffTime = Math.abs(date1 - date2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };
    console.log('Cdata::', cdata);
    return ( <
        div className = {
            showChat ? 'dm-chat-container add-all-chat' : 'dm-chat-container remove'
        } >
        {
            tch && ( <
                >
                <
                div className = "dm-chat-top" >
                <
                IconButton onClick = {
                    () => setShowChat(false)
                }
                className = "dm_arrow" >
                <
                ChevronLeftIcon / >
                <
                /IconButton> <
                h1 > {
                    tch.name
                } < /h1> <
                div className = "three-dots" >
                <
                div className = "dot" > < /div> <
                div className = "dot" > < /div> <
                div className = "dot" > < /div> <
                /div> <
                /div> {
                    load ? ( <
                        CircularProgress size = "small"
                        color = "success"
                        sx = {
                            {
                                justify: 'center',
                                margin: 'auto'
                            }
                        }
                        />
                    ) : ( <
                        div className = "dm-chats"
                        ref = {
                            ref
                        } > {
                            cdata.map((item) => ( <
                                > {
                                    typeof item.date === 'number' &&
                                    !datesdone.includes(
                                        moment(item.date).format('MM/DD/YYYY')
                                    ) && ( <
                                        div className = "chat-days" > {
                                            calculateDateDifference(item.date) === 1 ?
                                            'Yesterday' :
                                                calculateDateDifference(item.date) === 0 ?
                                                'Today' :
                                                moment(item.date).format('DD/MM/YYYY')
                                        } {
                                            /* {calculateDateDifference(item.date)}
                                                                  {moment(item.date).format('DD/MM/YYYY')} */
                                        } <
                                        /div>
                                    )
                                } <
                                div className = "single-dm-container" >
                                <
                                img src = {
                                    item.profile_pic
                                }
                                style = {
                                    {
                                        objectFit: 'cover'
                                    }
                                }
                                /> <
                                div className = "dm-right" >
                                <
                                div className = "single-dm-top-info" >
                                <
                                h3 > {
                                    item.name
                                } < /h3> <
                                span > {
                                    typeof item.date === 'number' ?
                                    moment(item.date).format('HH:mm') :
                                        item.date
                                } <
                                /span> <
                                /div> <
                                div className = "dm-msg" > {
                                    item.message
                                } < /div> <
                                /div> <
                                /div> <
                                />
                            ))
                        } <
                        /div>
                    )
                } <
                div className = "type-dm-msg-container" >
                <
                div className = "search-message-input-container" >
                <
                div className = "searchcont" >
                <
                input placeholder = {
                    t('ChatScreen.enterChat')
                }
                value = {
                    val
                }
                onChange = {
                    (e) => setVal(e.target.value)
                }
                onKeyPress = {
                    (e) =>
                    e.key === 'Enter' && val && sendMessage(val)
                }
                /> <
                button onClick = {
                    () => val && sendMessage(val)
                } > {
                    t('ChatScreen.send')
                } <
                /button> <
                /div> <
                /div> <
                /div> <
                />
            )
        } <
        /div>
    );
};

export default Chat;