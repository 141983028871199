import { Card, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import React, { useRef } from "react";
import {
  blazePoseCoordinateNames,
  refComputations,
} from "../../../../../utilities/Demo/ExerciseManagement/Generators/coordinates";
import BasicSelect from "../BasicSelect";
import OptionsBadge from "../OptionsBadge";

export default function AssignedReferenceBox({
  config,
  setConfig,
  refOptions,
  copyConfig,
}) {
  const name = useRef(config.name);
  const type = useRef(config.type);
  const ref = useRef(config.ref);
  const secondRef = useRef(config.secondRef);

  return (
    <Badge
      badgeContent={
        <OptionsBadge setConfig={setConfig} copyConfig={copyConfig} />
      }
      sx={{
        m: 0,
        width: "50%",
      }}
    >
      <Card
        sx={{
          padding: 3,
          m: 2,
          ml: 0,
          width: "100%",
        }}
      >
        <TextField
          name={name.current}
          label="name"
          variant="outlined"
          // className="inputRounded"
          InputLabelProps={{
            disableAnimation: true,
          }}
          value={name.current}
          onChange={(e) => {
            name.current = e.target.value;
            setConfig({ ...config, name: e.target.value });
          }}
          sx={{
            width: "100%",
            mb: 1,
            mt: 4,
          }}
        />{" "}
        <BasicSelect
          value={type.current}
          setValue={(value) => {
            type.current = value;
            setConfig({ ...config, type: value });
          }}
          label="type"
          options={Object.keys(refComputations)}
          sx={{
            width: "100%",
          }}
        />{" "}
        <BasicSelect
          value={ref.current}
          setValue={(value) => {
            ref.current = value;
            setConfig({ ...config, ref: value });
          }}
          label="ref"
          options={Object.keys(blazePoseCoordinateNames)
            .concat(refOptions)
            .sort()}
          sx={{
            width: "100%",
          }}
        />{" "}
        <BasicSelect
          value={secondRef.current}
          setValue={(value) => {
            secondRef.current = value;
            setConfig({ ...config, secondRef: value });
          }}
          label="secondRef"
          options={Object.keys(blazePoseCoordinateNames)
            .concat(refOptions)
            .sort()}
          sx={{
            width: "100%",
          }}
        />{" "}
      </Card>{" "}
    </Badge>
  );
}
