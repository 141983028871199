import { baseUrl } from "../../constants";
import axios from "axios";

export const getChats = async (
  dispatch,
  chatter,
  setLoader,
  type = "CHATS"
) => {
  setLoader(true);
  let token = await localStorage.getItem("token");
  axios
    .get(`${baseUrl}/chatsdata`, {
      params: {
        chatter: chatter,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      console.log("Status is ::" + JSON.stringify(response));
      dispatch({
        type: type,
        payload: response.data.chats,
      });
      setLoader(false);
      return response.data.chats;
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      dispatch({
        type: "CHATS",
        payload: {},
      });
      setLoader(false);
    });
};
