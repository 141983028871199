import { Image, Text, View } from "@react-pdf/renderer";
import exarLogo from "../../../../../../assets/EXAR_logo.png";
import React from "react";

const styles = {
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
  },
  subTitleInfoRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 50,
    paddingRight: 50,
  },
  description: {
    fontSize: 8,
    color: "black",
  },
};

export default function Footer({ patientName, date, page }) {
  return (
    <View stlye={styles.footer}>
      <View style={styles.subTitleInfoRow}>
        <Text style={styles.description}>
          {" "}
          {page} - Patient Assessment {patientName} - {date}{" "}
        </Text>{" "}
        <Image
          src={exarLogo}
          style={{
            width: 50,
          }}
        />{" "}
      </View>{" "}
    </View>
  );
}
