import AddIcon from "@mui/icons-material/Add";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Card, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FirebaseAuthContext from "../../../../contexts/auth/FirebaseAuthContext";
import {
  deleteTransition,
  getTransitions,
} from "../../../../utilities/Demo/ExerciseManagement/firestore";
import DeleteButton from "../DeleteButton";
import TransitionConfigurator from "../Configurator/Transition/TransitionConfigurator";

export default function Transitions({ searchValue }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState();
  const { user } = React.useContext(FirebaseAuthContext);
  const [transitions, setTransitions] = React.useState([]);
  const [creatorOpen, setCreatorOpen] = React.useState(false);
  const [transitionData, setTransitionData] = React.useState({});

  React.useEffect(() => {
    setLoading(true);
    getTransitions({ searchTerm: searchValue, userId: user.uid }).then(
      (transitions) => {
        setLoading(false);
        setTransitions(transitions || []);
      }
    );
  }, [searchValue]);

  const removeTransition = (transitionId) => {
    setTransitions(
      transitions.filter((transition) => transition.id !== transitionId)
    );
  };

  const addTransition = (transition) => {
    setTransitions([...transitions, transition]);
  };

  const updateTransition = (transition) => {
    setTransitions(
      transitions.map((s) => {
        if (s.id === transition.id) {
          return transition;
        }
        return s;
      })
    );
  };

  return creatorOpen ? (
    <TransitionConfigurator
      onClose={() => setCreatorOpen(false)}
      addTransitionToList={addTransition}
      updateTransitionInList={updateTransition}
      transitionData={transitionData}
    />
  ) : (
    <Box sx={{ padding: 3 }}>
      <h1>Transitions</h1>
      <Grid container spacing={2}>
        {loading && <CircularProgress />}
        {transitions.map((transition) => {
          return (
            <Grid
              item
              key={transition.name}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => {
                setTransitionData(transition);
                setCreatorOpen(true);
              }}
              sx={{ ":hover": { cursor: "pointer" } }}
            >
              <Card
                sx={{
                  padding: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1>{transition.name}</h1>
                <div style={{ display: "flex" }}>
                  <DeleteButton
                    onDelete={() => {
                      deleteTransition({ transitionId: transition.id }).then(
                        () => {
                          removeTransition(transition.id);
                        }
                      );
                    }}
                    deletionTarget={"Transition " + transition.name}
                  />
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="delete patient"
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch({
                        type: "SET-ITER-EXERCISES",
                        payload: transition.exercises,
                      });
                      dispatch({
                        type: "ITER-CALIBRATED",
                      });
                      dispatch({
                        type: "SET-ITER-OVERVIEW-REDIRECT",
                        payload: transition.overviewRedirect,
                      });
                      dispatch({
                        type: "SET-ITER-TRANSITION-VIDEO-URL",
                        payload: transition.videoUrl,
                      });
                      navigate("/exercise/transitionConfig");
                    }}
                  >
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </div>
              </Card>
            </Grid>
          );
        })}
        <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: 2,
              border: 1,
              borderRadius: 5,
              m: 2,
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setTransitionData({});
                setCreatorOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
