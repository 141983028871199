import {
    cptROMSymmetryIndex,
    cptSymmetryIndex,
    divideSeries,
    filterValidSteps,
    getTimeWindowList,
    removeOutliers,
    standardDeviation,
} from '../results';
import _ from 'lodash';
import {
    GAIT_TIME_INTERVAL,
    OPTIMAL_GAIT_CYCLE_PCT,
} from '../../../../../constants/Demo/gaitAnalysis';

export class ValidityChecker {
    constructor({
        gaitResults
    }) {
        this.gaitResults = gaitResults;

        // applying invalid events
        this.updateStepValidityByInvalidEvents();

        this.strideTimeLeft = this.filterValidRemoveOutliersStrideTime(
            'strideTimeWindowsLeft'
        );
        this.strideTimeRight = this.filterValidRemoveOutliersStrideTime(
            'strideTimeWindowsRight'
        );
        this.stanceTimeLeft = this.filterValidRemoveOutliersStanceTime(
            'stanceTimeWindowsLeft'
        );
        this.stanceTimeRight = this.filterValidRemoveOutliersStanceTime(
            'stanceTimeWindowsRight'
        );
        this.stanceTimeLeftIndexes = this.filterValidRemoveOutliersStanceTimeIndex(
            'stanceTimeWindowsLeft'
        );
        this.stanceTimeRightIndexes = this.filterValidRemoveOutliersStanceTimeIndex(
            'stanceTimeWindowsRight'
        );
        this.strideTimeLeftIndexes = this.filterValidRemoveOutliersStanceTimeIndex(
            'strideTimeWindowsLeft'
        );
        this.strideTimeRightIndexes = this.filterValidRemoveOutliersStanceTimeIndex(
            'strideTimeWindowsRight'
        );
        this.stepLengthLeft =
            this.filterValidRemoveOutliersStepLength('stepLengthLeft');
        this.stepLengthRight =
            this.filterValidRemoveOutliersStepLength('stepLengthRight');
    }

    checkIfSufficientCyclePhaseData() {
        const keys = [
            'stanceTimeLeft',
            'stanceTimeRight',
            'strideTimeLeft',
            'strideTimeRight',
        ];
        const sufficientCyclePhaseData = keys.map((key) => {
            return this.gaitResults[key].length > 0;
        });
        return sufficientCyclePhaseData.every((value) => value === true);
    }

    updateStepValidityByInvalidEvents() {
        const windows = [
            'stanceTimeWindowsLeft',
            'stanceTimeWindowsRight',
            'strideTimeWindowsLeft',
            'strideTimeWindowsRight',
        ];

        windows.forEach((window) => {
            if (!this.gaitResults[window]) {
                console.error(`No ${window} in gaitResults`);
                return;
            }
            let validityTarget;
            if (window.includes('Left')) {
                if (window.includes('stride')) {
                    validityTarget = 'strideValidityLeft';
                } else {
                    validityTarget = 'stepValidityLeft';
                }
            } else {
                if (window.includes('stride')) {
                    validityTarget = 'strideValidityRight';
                } else {
                    validityTarget = 'stepValidityRight';
                }
            }

            this.gaitResults[validityTarget] = this.gaitResults[window].map(
                () => true
            );

            this.gaitResults[window].forEach((timeWindow, index) => {
                this.gaitResults.invalidMarkerEvents.forEach((invalidEvent) => {
                    if (
                        invalidEvent >= timeWindow.start &&
                        invalidEvent <= timeWindow.end
                    ) {
                        this.gaitResults[validityTarget][index] = false;
                    }
                });
            });
        });
    }

    getStepValidity(key) {
        if (!this.gaitResults[key])
            return console.error(`No key ${key} in gaitResults`);

        let stepValidity;
        if (key.toLowerCase().includes('left')) {
            stepValidity = this.gaitResults.stepValidityLeft;
        } else if (key.toLowerCase().includes('right')) {
            stepValidity = this.gaitResults.stepValidityRight;
        }

        return stepValidity;
    }

    getStrideValidity(key) {
        if (!this.gaitResults[key])
            return console.error(`No key ${key} in gaitResults`);

        let strideValidity;
        if (key.toLowerCase().includes('left')) {
            strideValidity = this.gaitResults.strideValidityLeft;
        } else if (key.toLowerCase().includes('right')) {
            strideValidity = this.gaitResults.strideValidityRight;
        }

        return strideValidity;
    }

    filterValidRemoveOutliersStanceTime(key) {
        const stepValidity = this.getStepValidity(key);
        const gaitValues = getTimeWindowList(this.gaitResults[key]);
        return removeOutliers({
            dataArray: filterValidSteps(gaitValues, stepValidity),
            log: false,
        });
    }

    filterValidRemoveOutliersStanceTimeIndex(key) {
        const stepValidity = this.getStepValidity(key);

        const gaitValues = getTimeWindowList(this.gaitResults[key]);
        const [validValues, validIndexes] = filterValidSteps(
            gaitValues,
            stepValidity,
            true
        );
        const inlierIndexes = removeOutliers({
            dataArray: validValues,
            getIndex: true,
        });
        return inlierIndexes.map((index) => validIndexes[index]);
    }

    filterValidRemoveOutliersStrideTime(key) {
        const strideValidity = this.getStrideValidity(key);

        const gaitValues = getTimeWindowList(this.gaitResults[key]);
        return removeOutliers({
            dataArray: filterValidSteps(gaitValues, strideValidity),
        });
    }

    filterValidRemoveOutliersStepLength(key) {
        // const stepValidity = this.getStepValidity(key);
        let stanceTimeIndices;
        let stanceTimeWindows;
        if (key.toLowerCase().includes('left')) {
            stanceTimeIndices = this.stanceTimeLeftIndexes;
            stanceTimeWindows = this.gaitResults.stanceTimeWindowsLeft;
        } else if (key.toLowerCase().includes('right')) {
            stanceTimeIndices = this.stanceTimeRightIndexes;
            stanceTimeWindows = this.gaitResults.stanceTimeWindowsRight;
        }
        const stepLengths = this.gaitResults[key];
        const stepLengthTimeWindowIndex = [];
        stepLengths.forEach((stepLength, index) => {
            stanceTimeWindows.forEach((stanceTimeWindow, stanceIndex) => {
                if (
                    stanceTimeWindow.start <= stepLength.time &&
                    stanceTimeWindow.end >= stepLength.time
                ) {
                    stepLengthTimeWindowIndex.push({
                        index,
                        stanceIndex
                    });
                } else {
                    // console.log('No match', stanceTimeWindow, stepLength);
                }
            });
        });
        const selectedStepLengths = stanceTimeIndices
            .map((index) => {
                const stepLengthIndex = stepLengthTimeWindowIndex.findIndex(
                    (stepLength) => stepLength.index === index
                );
                if (stepLengthIndex === -1) return;
                return stepLengths[stepLengthTimeWindowIndex[stepLengthIndex].index];
            })
            .filter((stepLength) => stepLength)
            .map((stepLength) => stepLength.value);
        if (selectedStepLengths.length === 0) {
            console.warn(
                'No valid step lengths detected',
                stepLengthTimeWindowIndex,
                stanceTimeIndices,
                stanceTimeWindows,
                stepLengths
            );
            return [];
        }
        return removeOutliers({
            dataArray: selectedStepLengths,
        });
    }
}

export class MeanComputer {
    constructor({
        validityChecker
    }) {
        this.validityChecker = validityChecker;
        this.upscaleTimeInterval = 1000 / GAIT_TIME_INTERVAL;
        this.meanStrideTimeLeft = this.computeMeanTime(
            this.validityChecker.strideTimeLeft
        );
        this.meanStrideTimeRight = this.computeMeanTime(
            this.validityChecker.strideTimeRight
        );
        this.meanStanceTimeLeft = this.computeMeanTime(
            this.validityChecker.stanceTimeLeft
        );
        this.meanStanceTimeRight = this.computeMeanTime(
            this.validityChecker.stanceTimeRight
        );
        this.meanStepLengthLeft = _.mean(this.validityChecker.stepLengthLeft);
        this.meanStepLengthRight = _.mean(this.validityChecker.stepLengthRight);
        this.meanStepLength = _.mean([
            this.meanStepLengthLeft,
            this.meanStepLengthRight,
        ]);
    }

    computeMeanTime(series) {
        return _.mean(series) / this.upscaleTimeInterval;
    }
}

export class MeanStepTimeComputer {
    constructor({
        meanComputer
    }) {
        this.meanComputer = meanComputer;
        this.meanStepTime = this.computeMeanStepTime();
    }

    computeMeanStepTime() {
        return (
            (this.meanComputer.meanStrideTimeLeft +
                this.meanComputer.meanStrideTimeRight +
                this.meanComputer.meanStanceTimeRight +
                this.meanComputer.meanStanceTimeLeft) /
            4
        );
    }
}

export class CadenceComputer {
    constructor({
        meanStepTimeComputer
    }) {
        this.meanStepTimeComputer = meanStepTimeComputer;
        this.cadence = this.computeCadence();
    }

    computeCadence() {
        return parseFloat((60 / this.meanStepTimeComputer.meanStepTime).toFixed(1));
    }
}

export class WalkingSpeedComputer {
    constructor({
        meanStepTimeComputer,
        meanComputer,
        metric = 'mps'
    }) {
        this.meanComputer = meanComputer;
        this.meanStepTimeComputer = meanStepTimeComputer;
        this.metric = metric;
        this.walkingSpeed = this.computeWalkingSpeed();
    }

    computeWalkingSpeed() {
        // const stepLengthComplete =
        //   _.sum(this.validityChecker.stepLengthLeft) +
        //   _.sum(this.validityChecker.stepLengthRight);
        // const stepTimeComplete =
        //   (this.validityChecker.stepLengthLeft.length +
        //     this.validityChecker.stepLengthRight.length) *
        //   this.meanStepTimeComputer.meanStepTime;
        // const stepLengthCompleteMeters = stepLengthComplete / 100;
        // const metersPerSecond = stepLengthCompleteMeters / stepTimeComplete;
        const stepLengthMeters = this.meanComputer.meanStepLength / 100;
        const metersPerSecond =
            stepLengthMeters / this.meanStepTimeComputer.meanStepTime;
        if (Number.isNaN(metersPerSecond)) {
            console.error(
                `Invalid result ${stepLengthMeters}, ${this.meanStepTimeComputer.meanStepTime}`
            );
        }
        if (this.metric === 'mps') {
            return parseFloat(metersPerSecond.toFixed(2));
        }
        if (this.metric === 'kmh') {
            const kilometersPerSecond = metersPerSecond / 1000;
            const kilometersPerHour = kilometersPerSecond * 60 * 60;
            return Math.round(kilometersPerHour * 100) / 100;
        } else {
            throw new Error('Invalid metric');
        }
    }
}

export class GaitCycleVariabilityComputer {
    constructor({
        validityChecker
    }) {
        this.validityChecker = validityChecker;
        this.gaitCycleVariability = this.computeGaitCycleVariability();
    }

    computeGaitCycleVariability() {
        const rightSide = divideSeries(
            this.validityChecker.stanceTimeRight,
            this.validityChecker.strideTimeRight
        );
        const leftSide = divideSeries(
            this.validityChecker.stanceTimeLeft,
            this.validityChecker.strideTimeLeft
        );
        return standardDeviation(rightSide.concat(leftSide));
    }
}

export class GaitCycleMeanOptimumDeviationComputer {
    constructor({
        meanComputer
    }) {
        this.meanComputer = meanComputer;
        this.gaitCycleMeanOptimumDeviation =
            this.computeGaitCycleMeanOptimumDeviation();
    }

    computeGaitCycleMeanOptimumDeviation() {
        const pctLeft =
            this.meanComputer.meanStanceTimeLeft /
            (this.meanComputer.meanStrideTimeLeft +
                this.meanComputer.meanStanceTimeLeft);
        const pctRight =
            this.meanComputer.meanStanceTimeRight /
            (this.meanComputer.meanStrideTimeRight +
                this.meanComputer.meanStanceTimeRight);
        const stanceDeviationLeft =
            Math.abs(pctLeft - OPTIMAL_GAIT_CYCLE_PCT) * 100;
        const stanceDeviationRight =
            Math.abs(pctRight - OPTIMAL_GAIT_CYCLE_PCT) * 100;
        const stanceDeviation = (stanceDeviationRight + stanceDeviationLeft) / 2;
        return stanceDeviation.toFixed(2);
    }
}

export class SymmetryIndexComputer {
    constructor({
        validityChecker
    }) {
        this.strideTimeSymmetryIndex = this.computeSymmetryIndex(
            validityChecker.strideTimeLeft,
            validityChecker.strideTimeRight
        );
        this.stepLengthSymmetryIndex = this.computeSymmetryIndex(
            validityChecker.stepLengthLeft,
            validityChecker.stepLengthRight
        );
        this.stanceTimeSymmetryIndex = this.computeSymmetryIndex(
            validityChecker.stanceTimeLeft,
            validityChecker.stanceTimeRight
        );
    }

    computeSymmetryIndex(seriesLeft, seriesRight) {
        return cptSymmetryIndex([{
            data: seriesLeft
        }, {
            data: seriesRight
        }]);
    }
}

export class ROMSymmetryIndexComputer {
    constructor({
        validityChecker
    }) {
        this.strideTimeROMSymmetryIndex = this.computeROMSymmetryIndex(
            validityChecker.strideTimeLeft,
            validityChecker.strideTimeRight
        );
        this.stepLengthROMSymmetryIndex = this.computeROMSymmetryIndex(
            validityChecker.stepLengthLeft,
            validityChecker.stepLengthRight
        );
        this.stanceTimeROMSymmetryIndex = this.computeROMSymmetryIndex(
            validityChecker.stanceTimeLeft,
            validityChecker.stanceTimeRight
        );
    }

    computeROMSymmetryIndex(seriesLeft, seriesRight) {
        return cptROMSymmetryIndex([{
            data: seriesLeft
        }, {
            data: seriesRight
        }]);
    }
}