import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import {
    ToastContainer
} from 'react-toastify';

export default function VideoUpload({
    videoUrl,
    uploadId,
    handleVideoUpload
}) {
    const [videoFile, setVideoFile] = React.useState(null);
    return ( <
        div >
        <
        Grid container spacing = {
            2
        }
        sx = {
            {
                mt: 3,
                ml: 3
            }
        } >
        <
        Grid item xs = {
            6
        }
        sm = {
            2
        } >
        <
        h3 > Video < /h3> <
        /Grid> <
        Grid item xs = {
            6
        }
        sm = {
            2
        } >
        <
        input accept = "video/*"
        style = {
            {
                display: 'none'
            }
        }
        id = "raised-button-file"
        type = "file"
        onChange = {
            (event) => {
                setVideoFile(event.target.files[0]);
            }
        }
        /> <
        label htmlFor = "raised-button-file" >
        <
        Button variant = "raised"
        component = "span" > {!videoFile ? 'Choose File' : videoFile.name
        } <
        /Button> <
        /label> <
        /Grid> <
        Grid item xs = {
            6
        }
        sm = {
            2
        } >
        <
        Button variant = {
            'outlined'
        }
        disabled = {!videoFile || !uploadId
        }
        onClick = {
            () => {
                handleVideoUpload(videoFile);
            }
        } >
        Upload <
        /Button> <
        /Grid> <
        /Grid> <
        Grid container spacing = {
            2
        }
        sx = {
            {
                margin: 2,
                padding: 2
            }
        } > {
            videoUrl && ( <
                ReactPlayer url = {
                    videoUrl
                }
                controls = {
                    false
                }
                playing = {
                    true
                }
                loop = {
                    true
                }
                className = "react-player"
                width = "100%"
                height = "100%"
                config = {
                    {
                        file: {
                            attributes: {
                                preload: 'auto'
                            }
                        }
                    }
                }
                />
            )
        } <
        /Grid> <
        ToastContainer / >
        <
        /div>
    );
}