import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSide } from "./visualFeedback";
import { safeIterScore, useAudioFeedback, useTaskTimer } from "./sharedLogic";

export function useFeedbackBasics({ loadAudio, seconds, maxScore = 5 }) {
  const dispatch = useDispatch();
  const cptScore = useRef(() => undefined);
  const {
    playAudio,
    audioFeedback,
    stopAudio,
    secondsRef,
    raiseLevel,
    audioQueue,
    finishedIntro,
  } = useAudioFeedback("", loadAudio, seconds, maxScore);
  const { setStartingTime, startingTime, started } = useTaskTimer();
  const { setSide } = useSide();

  useEffect(() => {
    safeIterScore(dispatch, 0);

    return () => {
      stopAudio();
      audioQueue.deleteQueue();
    };
  }, []);

  return {
    seconds: secondsRef,
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    cptScore,
    raiseLevel,
    startingTime,
    started,
    finishedIntro,
  };
}
