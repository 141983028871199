import { Card, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { getAngle } from "../../../../../utilities/Demo/ExerciseManagement/firestore";
import { metricsComputations } from "../../../../../utilities/Demo/ExerciseManagement/Generators/metrics";
import BasicSelect from "../BasicSelect";
import OptionsBadge from "../OptionsBadge";
import AngleDialog from "./AngleDialog";

export default function SubMetricBox({
  subMetric,
  setSubMetric,
  refOptions,
  copyConfig,
  useAngleConfigs,
  stateOptions,
}) {
  const [angleDialogOpen, setAngleDialogOpen] = React.useState(false);

  useEffect(() => {
    subMetric.value = null;
    if (
      typeof subMetric.ref === "string" &&
      useAngleConfigs &&
      subMetric.ref === 0
    ) {
      getAngle({ angleId: subMetric.ref }).then((angle) => {
        setSubMetric({ ...subMetric, ref: angle });
      });
    }
  }, []);
  return (
    <Badge
      badgeContent={
        <OptionsBadge setConfig={setSubMetric} copyConfig={copyConfig} />
      }
      sx={{ m: 0, width: "100%" }}
    >
      {!!subMetric && (
        <Card
          sx={{
            overflow: "auto",
            height: "100%",
            width: "100%",
            padding: 2,
            paddingTop: 10,
            backgroundColor: "rgba(241,166,12,0.1)",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name={subMetric?.name}
                type="text"
                variant="outlined"
                // className="inputRounded"
                InputLabelProps={{ disableAnimation: true }}
                sx={{ width: "100%", marginBottom: 1 }}
                shrink={"true"}
                fullWidth
                label="Name"
                value={subMetric.name}
                onChange={(e) => {
                  setSubMetric({ ...subMetric, name: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicSelect
                sx={{ width: "100%" }}
                label="State"
                value={subMetric.state}
                setValue={(value) => {
                  setSubMetric({
                    ...subMetric,
                    state: value.filter((item) => item !== undefined),
                  });
                }}
                options={stateOptions.concat([null])}
                multiple={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name={"adjustment"}
                type="number"
                variant="outlined"
                // className="inputRounded"
                InputLabelProps={{ disableAnimation: true }}
                sx={{ width: "100%", marginBottom: 1 }}
                shrink={"true"}
                fullWidth
                label="adjustment"
                value={subMetric.adjustment}
                onChange={(e) => {
                  setSubMetric({ ...subMetric, adjustment: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name={"maxValue"}
                type="number"
                variant="outlined"
                // className="inputRounded"
                InputLabelProps={{ disableAnimation: true }}
                sx={{ width: "100%", marginBottom: 1 }}
                shrink={"true"}
                fullWidth
                label="maxValue"
                value={subMetric.maxValue}
                onChange={(e) => {
                  setSubMetric({ ...subMetric, maxValue: e.target.value });
                }}
              />
            </Grid>
            <Grid xs={12}>
              <Box sx={{ width: "90%", marginLeft: 3 }}>
                <Typography variant="body1">Revert Angle</Typography>
                <Checkbox
                  checked={subMetric.revertAngle}
                  onClick={() =>
                    setSubMetric({
                      ...subMetric,
                      revertAngle: !subMetric.revertAngle,
                    })
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              {useAngleConfigs ? (
                <div>
                  <AngleDialog
                    setAngle={(value) =>
                      setSubMetric({ ...subMetric, ref: value })
                    }
                    open={angleDialogOpen}
                    onClose={() => setAngleDialogOpen(false)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAngleDialogOpen(true);
                    }}
                    sx={{ mb: 1, width: "100%" }}
                  >
                    {subMetric.ref?.name || "Select Angle"}
                  </Button>
                </div>
              ) : (
                <BasicSelect
                  label="Ref"
                  value={subMetric?.ref}
                  setValue={(value) => {
                    setSubMetric({ ...subMetric, ref: value });
                  }}
                  options={refOptions}
                  sx={{ width: "100%" }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <BasicSelect
                sx={{ width: "100%" }}
                label={"Computation"}
                value={subMetric.computation}
                setValue={(value) => {
                  setSubMetric({ ...subMetric, computation: value });
                }}
                options={Object.keys(metricsComputations)}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicSelect
                sx={{ width: "100%" }}
                label="Type"
                value={subMetric.type}
                setValue={(value) => {
                  setSubMetric({ ...subMetric, type: value });
                }}
                options={["min", "max"]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name={subMetric?.lowerNormalValue}
                type="number"
                variant="outlined"
                // className="inputRounded"
                InputLabelProps={{ disableAnimation: true }}
                sx={{ width: "100%", marginBottom: 1 }}
                shrink={"true"}
                fullWidth
                label="Lower Normal Value"
                value={subMetric.lowerNormalValue}
                onChange={(e) => {
                  setSubMetric({
                    ...subMetric,
                    lowerNormalValue: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={subMetric?.upperNormalValue}
                type="number"
                variant="outlined"
                // className="inputRounded"
                InputLabelProps={{ disableAnimation: true }}
                sx={{ width: "100%", marginBottom: 1 }}
                shrink={"true"}
                fullWidth
                label="Upper Normal Value"
                value={subMetric?.upperNormalValue}
                onChange={(e) => {
                  setSubMetric({
                    ...subMetric,
                    upperNormalValue: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </Badge>
  );
}
