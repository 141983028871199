import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStretchCoreFeedback } from "../../../../../hooks/Demo/feedbackTypes";
import {
  checkIfRightSide,
  checkIfSide,
} from "../../../../../utilities/Demo/angles";
import { loadReachForwardWithArmOutstretchedAudio } from "../../../../../utilities/Demo/audioFeedback";
import {
  findAdjustedAngle,
  findDependentAngle,
} from "../../../../../utilities/Demo/physio";
import { getStretchLength } from "../../../../../utilities/Demo/Physio/BergBalance/reachForwardWithArmOutStretched";
import { getHighestAndLowestPixels } from "../../../../../utilities/Demo/segmentation";
import PoseDetection from "../../../PoseDetection";
import {
  backRomMetrics,
  computeMetrics,
  upperArmRomMetrics,
  upperLegRomMetrics,
} from "../../../../../utilities/Demo/Physio/BergBalance/metrics";

function calculatePoints(reachedCentimeters) {
  if (reachedCentimeters < 5) return 0;
  if (reachedCentimeters >= 5 && reachedCentimeters < 12.5) return 1 / 3;
  if (reachedCentimeters >= 12.5 && reachedCentimeters < 25) return 2 / 3;
  if (reachedCentimeters >= 25) return 1;
}

export default function ReachForwardWithArmOutStretched({
  onComplete,
  showWebcam = false,
}) {
  const timeThresh = 30;
  const dispatch = useDispatch();
  const isLeft = useRef(false);
  const patientHeight = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values.height
  );
  const personHeightPixels = useRef(0);
  const {
    updateFeedback,
    candidates,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    finishedIntro,
  } = useStretchCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadReachForwardWithArmOutstretchedAudio,
    calculatePoints,
    min: false,
    maxScore: 1,
  });
  const metrics = useRef(
    upperLegRomMetrics().concat(backRomMetrics()).concat(upperArmRomMetrics())
  );

  function observation(backAngle, kneeAngle, shoulderElbowAngle, results) {
    if (kneeAngle > 100 && backAngle >= 90) {
      const stretchLength = getStretchLength(
        Number(patientHeight),
        personHeightPixels.current,
        results,
        isLeft.current
      );
      candidates.current.push(stretchLength);
      setStartingTime(seconds);
    } else if (
      kneeAngle < 190 &&
      kneeAngle > 170 &&
      backAngle > 80 &&
      backAngle < 90
    ) {
      const { highestPixel, lowestPixel } = getHighestAndLowestPixels(
        results.segmentationMask
      );
      const pHPCandidate = Math.abs(highestPixel.y - lowestPixel.y);
      if (pHPCandidate > personHeightPixels.current) {
        personHeightPixels.current = Math.abs(highestPixel.y - lowestPixel.y);
      }
      updateFeedback(
        `Reach forward with your ${
          isLeft.current ? "left" : "right"
        } arm outstretched!`
      );
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback("Turn to the side!");
      setSide(false);
      return;
    } else {
      updateFeedback("");
      setSide(true);
    }
    isLeft.current = checkIfRightSide(results, "left");
    let kneeAngle;
    let backAngle;
    let shoulderElbowAngle;
    let hipKneeAngle;
    let displayConfigs;

    if (isLeft.current) {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      backAngle = findAdjustedAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      shoulderElbowAngle = findAdjustedAngle(
        results.poseLandmarks[13],
        results.poseLandmarks[11]
      );
      hipKneeAngle = findAdjustedAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25]
      );
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[23],
          left: true,
        },
        backAngle: {
          coord: results.poseLandmarks[11],
          left: true,
        },
        shoulderElbowAngle: {
          coord: results.poseLandmarks[13],
          left: true,
        },
      };
    } else {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (kneeAngle > 180) {
        kneeAngle = 360 - kneeAngle;
      }
      shoulderElbowAngle =
        360 -
        findAdjustedAngle(results.poseLandmarks[14], results.poseLandmarks[12]);
      backAngle =
        360 -
        findAdjustedAngle(
          results.poseLandmarks[12],
          results.poseLandmarks[24],
          0
        );
      hipKneeAngle =
        360 -
        findAdjustedAngle(results.poseLandmarks[24], results.poseLandmarks[26]);
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[24],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[12],
          left: false,
        },
        shoulderElbowAngle: {
          coord: results.poseLandmarks[14],
          left: false,
        },
      };
    }
    computeMetrics({
      metrics: metrics.current,
      angles: {
        current: {
          backAngle,
          hipKneeAngle,
          shoulderElbowAngle,
        },
      },
      dispatch,
      displayConfigs,
      canvasCtx,
    });
    observation(backAngle, kneeAngle, shoulderElbowAngle, results);
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
