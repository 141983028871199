import {
    getLastExerciseResult
} from '../../firestore';

export async function getLastResults({
    exerciseIds,
    scheduleId,
    user,
    patientId,
}) {
    const resultObjects = [];
    const promises = exerciseIds.map(async (exerciseId) => {
        const resultDoc = await getLastExerciseResult({
            exerciseId,
            user,
            scheduleId,
            patientId,
        });
        if (resultDoc) {
            resultObjects.push(resultDoc);
        }
    });
    await Promise.all(promises);
    return resultObjects;
}