import React from "react";
import Button from "@mui/material/Button";
import HeaderComponent from "../../components/HeaderComponent";
import WelcomeComponent from "../../components/WelcomeComponent";
import { useState } from "react";
import LoginComponent from "../../components/Demo/Auth/LoginComponent";
import FpComponent from "../../components/Demo/Auth/FpComponent";
import { useAuthCheck } from "../../hooks/Demo/sharedLogic";
import { useTranslation } from "react-i18next";
import googleicon from "../../assets/btn_google_signin_dark_focus_web.png";
import { Link, useParams } from "react-router-dom";
import { signInWithGoogle } from "../../utilities/Demo/auth";
import { CircularProgress } from "@mui/material";

const Welcome = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [fp, setFp] = useState(false);
  const { loginTarget } = useParams();
  const pureQueryParams = location.search.slice(1);
  const [loginLoading, setLoginLoading] = useState(false);
  useAuthCheck();

  const getLoginTargetQuery = () => {
    if (pureQueryParams) {
      return `${loginTarget}?${pureQueryParams}`;
    } else {
      return loginTarget;
    }
  };

  if (loginLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="welcome-page-container">
      {open && (
        <LoginComponent
          open={open}
          setOpen={setOpen}
          setFp={setFp}
          setLoading={setLoginLoading}
        />
      )}
      {fp && <FpComponent fp={fp} setFp={setFp} setOpen={setOpen} />}
      <HeaderComponent pageName={"welcome"} />
      <WelcomeComponent />

      <div className="welcome-text">
        <h1>{t("welcomeView.welcome")}</h1>
        {/* Sign In Button */}
        <Button
          variant="contained"
          className="welcome-login-btn"
          sx={{
            borderRadius: 20,
            backgroundColor: "#FF592C",
          }}
          onClick={() => setOpen(true)}
        >
          <center>{t("welcomeView.login")}</center>
        </Button>
        <div>
          <p style={{ width: "70px", color: "black", textAlign: "center" }}>
            OR
          </p>
        </div>
        {/* Google Button */}
        <Button
          sx={{
            borderRadius: 20,
          }}
          onClick={() => signInWithGoogle()}
        >
          <img src={googleicon} alt="img" border="0" />
        </Button>
        <div
          className="signin-existing"
          style={{ position: "relative", color: "black" }}
        >
          <p>
            {" "}
            <br />
            <Link to={"/demosignup/" + getLoginTargetQuery()}>
              {t("signUpComponent.sign-up-here")}
            </Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
