import { baseUrl } from "../../constants";
import axios from "axios";

export const sendOtp = async (email, setLoad, setMsg, setType, setStage) => {
  axios
    .post(`${baseUrl}/sendotp`, {
      email,
    })
    .then(async (response) => {
      console.log("Forgot ::" + JSON.stringify(response));
      setMsg(response.data.message);
      setType("success");
      setLoad(false);
      setStage(1);
    })
    .catch((res2) => {
      console.log("eror::" + JSON.stringify(res2));
      setLoad(false);
      setType("error");
      setMsg(
        res2.response
          ? res2.response.data
            ? res2.response.data.message
            : res2.message
          : res2.message
      );
    });
};

export const verifyOtp = async (
  email,
  otp,
  setLoad,
  setMsg,
  setType,
  setStage
) => {
  axios
    .post(`${baseUrl}/verifyotp`, {
      email,
      otp,
    })
    .then(async (response) => {
      console.log("Forgot ::" + JSON.stringify(response));
      setMsg(response.data.message);
      setType("success");
      setLoad(false);
      setStage(2);
    })
    .catch((res2) => {
      console.log("eror::" + JSON.stringify(res2));
      setLoad(false);
      setType("error");
      setMsg(
        res2.response
          ? res2.response.data
            ? res2.response.data.message
            : res2.message
          : res2.message
      );
    });
};

export const updatePwd = async (
  email,
  password,
  setLoad,
  setMsg,
  setType,
  setOpen,
  setFp
) => {
  axios
    .put(`${baseUrl}/forgotpwd`, {
      email,
      password,
    })
    .then(async (response) => {
      console.log("Forgot ::" + JSON.stringify(response));
      setMsg("Password Changed. Please login via new password");
      setType("success");
      setLoad(false);
      setOpen(true);
      setFp(false);
    })
    .catch((res2) => {
      console.log("eror::" + JSON.stringify(res2));
      setLoad(false);
      setType("error");
      setMsg(
        res2.response
          ? res2.response.data
            ? res2.response.data.message
            : res2.message
          : res2.message
      );
    });
};
