import { baseUrl } from "../../constants";
import axios from "axios";

export const getClassHome = async (dispatch, setLoad = (a) => a) => {
  let token = await localStorage.getItem("token");
  return axios
    .get(`${baseUrl}/teacherclasses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      dispatch({
        type: "CLASS-HOME",
        payload: response.data,
      });
      if (response.data.data.length > 0) {
        console.log("Iam here::", response.data.data[0].class_id);
        await getClassProgress(
          dispatch,
          setLoad,
          response.data.data[0].class_id
        );
      }
      //setLoader(false);
    })
    .catch(async (error) => {
      console.log("eror::" + JSON.stringify(error));
      await dispatch({
        type: "CLASS-HOME",
        payload: {},
      });
      //setLoader(false);
    });
};

export const getClassStuds = async (
  dispatch,
  class_id,
  schedule = null,
  setLoader
) => {
  setLoader(true);
  let token = await localStorage.getItem("token");
  axios
    .get(`${baseUrl}/showclassdetails`, {
      params: {
        class_id,
        schedule,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      console.log("Status is ::" + JSON.stringify(response));
      dispatch({
        type: "CLASS_STUDS",
        payload: response.data,
      });
      setLoader(false);
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      dispatch({
        type: "CLASS_STUDS",
        payload: {},
      });
      setLoader(false);
    });
};

export const getClassChatStuds = async (dispatch, setLoader) => {
  setLoader(true);
  let token = await localStorage.getItem("token");
  axios
    .get(`${baseUrl}/showclasschats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      console.log("Status is ::" + JSON.stringify(response));
      dispatch({
        type: "CHAT_STUDS",
        payload: response.data.usr_ar,
      });
      setLoader(false);
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      dispatch({
        type: "CHAT_STUDS",
        payload: {},
      });
      setLoader(false);
    });
};
export const getClassProgress = async (
  dispatch,
  setLoader,
  class_id = null
) => {
  setLoader(true);
  let token = await localStorage.getItem("token");
  return axios
    .get(`${baseUrl}/showclassprogress`, {
      ...(class_id && {
        params: {
          class_id: class_id,
        },
      }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      console.log("CLASS Progress" + JSON.stringify(response));
      dispatch({
        type: "CLASS_PROGRESS",
        payload: response.data,
      });
      setLoader(false);
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      dispatch({
        type: "CLASS_PROGRESS",
        payload: {},
      });
      setLoader(false);
    });
};

export const getClassTeacher = async (dispatch, setLoader) => {
  setLoader(true);
  let token = await localStorage.getItem("token");
  axios
    .get(`${baseUrl}/showclassteacher`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      console.log("Status is ::" + JSON.stringify(response));
      dispatch({
        type: "CLASS_TEACHER",
        payload: response.data.rep,
      });
      setLoader(false);
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      dispatch({
        type: "CLASS_TEACHER",
        payload: {},
      });
      setLoader(false);
    });
};

export const getStudentAttendance = async (dispatch, user_id, setLoader) => {
  setLoader(true);
  let token = await localStorage.getItem("token");
  return axios
    .get(`${baseUrl}/getattendance`, {
      params: {
        user_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (response) => {
      dispatch({
        type: "STUD_ATTENDANCE",
        payload: response.data,
      });
      setLoader(false);
    })
    .catch((error) => {
      console.log("eror::" + JSON.stringify(error));
      dispatch({
        type: "STUD_ATTENDANCE",
        payload: {},
      });
      setLoader(false);
    });
};
