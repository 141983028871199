import React from 'react';
import {
    Bar
} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const GraphComponent = ({
    progress
}) => {
    let dys = {};
    let coins = {};
    const moment = require('moment-timezone');
    for (var j = 6; j >= 0; j--) {
        dys[parseInt(moment().subtract(j, 'days').format('DD'))] = 0;
        coins[parseInt(moment().subtract(j, 'days').format('DD'))] = 0;
    }
    console.log('DYS 0::', dys);
    if (progress)
        for (var i = progress.length - 1; i >= 0; i--) {
            if (
                Object.keys(dys).includes('' + parseInt(progress[i].date.split('-')[0]))
            )
                dys['' + parseInt(progress[i].date.split('-')[0])] =
                dys['' + parseInt(progress[i].date.split('-')[0])] +
                progress[i].sweat_points;
            coins['' + parseInt(progress[i].date.split('-')[0])] =
                coins['' + parseInt(progress[i].date.split('-')[0])] +
                progress[i].xr_coins;
            // else
            //   dys[parseInt(progress[i].date.split('-')[0])] =
            //     progress[i].sweat_points;
            if (Object.keys(dys).length > 7) {
                delete dys[parseInt(moment().subtract(7, 'days').format('DD'))];
                break;
            }
        }
    // let d1 = Object.keys(dys).map((item) => parseInt(item));
    // d1.sort((a, b) => a - b);

    // console.log(d1);
    return ( <
        Bar type = "bar"
        data = {
            {
                labels: Object.keys(dys),
                datasets: [{
                        label: '',
                        data: Object.values(dys),
                        backgroundColor: [
                            '#cabdff',
                            '#b1e5fc',
                            '#cabdff',
                            '#b1e5fc',
                            '#cabdff',
                            '#b1e5fc',
                            '#cabdff',
                        ],
                    },
                    {
                        label: '',
                        data: Object.values(coins),
                        backgroundColor: [
                            '#FF592C',
                            '#f53602',
                            '#FF592C',
                            '#f53602',
                            '#FF592C',
                            '#f53602',
                            '#FF592C',
                        ],
                    },
                ],
            }
        }
        options = {
            {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: false
                        },
                    },
                    x: {
                        grid: {
                            display: false
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            }
        }
        />
    );
};

export default GraphComponent;