import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export default function ResultBox({ value, color = "blue", fontSize = 16 }) {
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: color,
        borderRadius: 5,
        textAlign: "center",
        height: "100px",
        alignContent: "column",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography style={{ color: "black" }} sx={{ fontSize }}>
        {String(value)}
      </Typography>
    </Box>
  );
}
