import { Card, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import DeleteButton from "../../DeleteButton";

export default function ExerciseCard({
  exercise,
  setEndTime,
  index,
  onDelete,
}) {
  const [endTimeState, setEndTimeState] = React.useState(exercise.endTime);

  return (
    <Card
      sx={{
        padding: 2,
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant={"h5"}
        sx={{
          mb: 2,
        }}
      >
        {" "}
        {exercise.name}{" "}
      </Typography>{" "}
      <TextField
        id="outlined-basic"
        label="End Time"
        variant="outlined"
        defaultValue={exercise.endTime}
        value={endTimeState}
        onChange={(e) => {
          setEndTimeState(e.target.value);
        }}
        type="text"
        onBlur={(e) => {
          const regex = /^(?:[0-5][0-9]:[0-5][0-9])?$/;
          if (regex.test(e.target.value) || e.target.value === "") {
            setEndTime(e.target.value, index);
          } else {
            setEndTimeState(exercise.endTime || "00:00");
          }
        }}
        sx={{
          height: "50%",
          width: "50%",
          mb: 2,
        }}
        inputProps={{
          maxLength: 5,
        }}
      />{" "}
      <DeleteButton onDelete={onDelete} deletionTarget={"Exercise"} />{" "}
    </Card>
  );
}
