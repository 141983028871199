import React from 'react';
import planet from '../../assets/planet-chat.png';
import sand from '../../assets/sand-chat.png';
import '../Chat/chat.css';

const ChatBgComponent = () => {
    return ( <
        div style = {
            {
                position: 'fixed',
                width: '100%',
                height: '100%',
                zIndex: '-100',
                backgroundColor: '#FCFCF9',
                overflow: 'hidden',
            }
        } >
        <
        img src = {
            planet
        }
        alt = "Exar"
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                top: '0%',
                left: '0%',
                width: 'clamp(200px, 25%, 700px)',
                zIndex: '-10000',
            }
        }
        /> <
        img src = {
            sand
        }
        alt = "Exar"
        className = "left-sand-astro"
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                bottom: '-20rem',
                left: '-30rem',
                width: '1500px',
            }
        }
        /> <
        img src = {
            sand
        }
        alt = "Exar"
        className = "right-sand-astro"
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                bottom: '-20rem',
                right: '-30rem',
                width: '1500px',
            }
        }
        /> <
        img src = {
            sand
        }
        alt = "Exar"
        className = "middle-sand-astro"
        style = {
            {
                objectFit: 'contain',
                position: 'fixed',
                bottom: '-25rem',
                zIndex: '-100',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '2000px',
            }
        }
        /> <
        /div>
    );
};

export default ChatBgComponent;