import {
    drawAngle
} from '../../angles';
import {
    findAdjustedAngle,
    findAngle,
    findCounterAngle,
    findDependentAngle,
} from '../../physio';
import {
    blazePoseCoordinateNames
} from './coordinates';

const cptNeckAngleLeft = ({
    results,
    canvasCtx,
    display
}) => {
    let displayInfo;
    // const neckAngle =
    //   findDependentAngle(
    //     blazePoseCoordinateNames.leftHip(results),
    //     blazePoseCoordinateNames.leftShoulder(results),
    //     blazePoseCoordinateNames.Nose(results),
    //     true
    //   ) -
    //   90 -
    //   40;
    const neckAngle =
        findAngle(
            blazePoseCoordinateNames.leftShoulder(results),
            blazePoseCoordinateNames.Nose(results),
            0
        ) - 40;
    if (display) {
        drawAngle(
            canvasCtx,
            neckAngle,
            blazePoseCoordinateNames.leftShoulder(results),
            true
        );
    } else {
        displayInfo = {
            coord: blazePoseCoordinateNames.leftShoulder(results),
            left: true,
        };
    }
    return [neckAngle, displayInfo];
};

const cptNeckAngleRight = ({
    results,
    canvasCtx,
    display
}) => {
    let displayInfo;
    // const neckAngle =
    //   findDependentAngle(
    //     blazePoseCoordinateNames.rightHip(results),
    //     blazePoseCoordinateNames.rightShoulder(results),
    //     blazePoseCoordinateNames.Nose(results)
    //   ) -
    //   90 -
    //   40;
    const neckAngle =
        270 -
        findAngle(
            blazePoseCoordinateNames.rightShoulder(results),
            blazePoseCoordinateNames.Nose(results),
            90
        ) -
        40;
    if (display) {
        drawAngle(
            canvasCtx,
            neckAngle,
            blazePoseCoordinateNames.rightShoulder(results),
            false
        );
    } else {
        displayInfo = {
            coord: blazePoseCoordinateNames.rightShoulder(results),
            left: false,
        };
    }
    return [neckAngle, displayInfo];
};

const spineAngleLeft = ({
    results,
    canvasCtx,
    display
}) => {
    let displayInfo;
    const midShoulder = blazePoseCoordinateNames.midShoulder(results);
    const midHip = blazePoseCoordinateNames.midHip(results);
    const spineAngle = findAngle(midShoulder, midHip, 0);
    const midBody = {
        x: (midShoulder.x + midHip.x) / 2,
        y: (midShoulder.y + midHip.y) / 2,
        z: (midShoulder.z + midHip.z) / 2,
    };
    if (display) {
        drawAngle(canvasCtx, spineAngle, midBody, true);
    } else {
        displayInfo = {
            coord: midBody,
            left: true,
        };
    }
    return [spineAngle, displayInfo];
};

const spineAngleRight = ({
    results,
    canvasCtx,
    display
}) => {
    let displayInfo;
    const midShoulder = blazePoseCoordinateNames.midShoulder(results);
    const midHip = blazePoseCoordinateNames.midHip(results);
    const spineAngle = findCounterAngle(midShoulder, midHip, 180);
    const midBody = {
        x: (midShoulder.x + midHip.x) / 2,
        y: (midShoulder.y + midHip.y) / 2,
        z: (midShoulder.z + midHip.z) / 2,
    };
    if (display) {
        drawAngle(canvasCtx, spineAngle, midBody, false);
    } else {
        displayInfo = {
            coord: midBody,
            left: false,
        };
    }
    return [spineAngle, displayInfo];
};

const cptLeftFootAngle = ({
    results,
    canvasCtx,
    isLeft,
    display
}) => {
    let displayInfo;
    let leftFootAngle;
    const leftHeel = blazePoseCoordinateNames.leftHeel(results);
    const leftFootIndex = blazePoseCoordinateNames.leftFootIndex(results);
    if (isLeft) {
        leftFootAngle = findCounterAngle(leftHeel, leftFootIndex, 360);
    } else {
        leftFootAngle = findAngle(leftHeel, leftFootIndex, 180);
    }
    if (display) {
        drawAngle(canvasCtx, leftFootAngle, results.poseLandmarks[27], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[27],
            left: true,
        };
    }
    return [leftFootAngle, displayInfo];
};

const cptRightFootAngle = ({
    results,
    canvasCtx,
    isLeft,
    display
}) => {
    let displayInfo;
    let rightFootAngle;
    const rightHeel = blazePoseCoordinateNames.rightHeel(results);
    const rightFootIndex = blazePoseCoordinateNames.rightFootIndex(results);
    if (isLeft) {
        rightFootAngle = findCounterAngle(rightHeel, rightFootIndex, 360);
    } else {
        rightFootAngle = findAngle(rightHeel, rightFootIndex, 180);
    }
    if (display) {
        drawAngle(canvasCtx, rightFootAngle, results.poseLandmarks[28], false);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[28],
            left: false,
        };
    }
    return [rightFootAngle, displayInfo];
};

function cptLeftShoulderHipAnkleAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let angle;
    if (isLeft) {
        angle = findDependentAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[23],
            results.poseLandmarks[27],
            true
        );
    } else {
        angle = findDependentAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[23],
            results.poseLandmarks[27]
        );
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[23], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[23],
            left: true,
        };
    }
    return [angle, displayInfo];
}

function cptRightShoulderHipAnkleAngle({
    results,
    canvasCtx,
    isLeft,
    display,
}) {
    let displayInfo;
    let angle;
    if (isLeft) {
        angle = findDependentAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[24],
            results.poseLandmarks[28],
            true
        );
    } else {
        angle = findDependentAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[24],
            results.poseLandmarks[28]
        );
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[24], false);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[24],
            left: false,
        };
    }
    return [angle, displayInfo];
}

export const angleSideForceComputations = {
    leftHipKneeAnkle: cptHipKneeAnkleAngleLeft,
    rightHipKneeAnkle: cptHipKneeAnkleAngleRight,
    leftBack: backAngleLeft,
    rightBack: backAngleRight,
    leftHipKnee: cptLeftHipKneeAngle,
    rightHipKnee: cptRightHipKneeAngle,
    neckLeft: cptNeckAngleLeft,
    neckRight: cptNeckAngleRight,
    spineLeft: spineAngleLeft,
    spineRight: spineAngleRight,
    shoulderElbowWristLeft: cptLeftShoulderElbowWristAngle,
    shoulderElbowWristRight: cptRightShoulderElbowWristAngle,
    shoulderElbowLeft: cptLeftShoulderElbowAngle,
    shoulderElbowRight: cptRightShoulderElbowAngle,
    shoulderHipAnkleLeft: cptLeftShoulderHipAnkleAngle,
    shoulderHipAnkleRight: cptRightShoulderHipAnkleAngle,
    elbowWristLeft: cptLeftElbowWristAngle,
    elbowWristRight: cptRightElbowWristAngle,
    kneeHipShoulderLeft: cptLeftKneeHipShoulderAngle,
    kneeHipShoulderRight: cptRightKneeHipShoulderAngle,
    spineTileLeft: cptLeftSpineTiltAngle,
    spineTileRight: cptRightSpineTiltAngle,
    hipShoulderEarLeft: cptLeftHipShoulderEarAngle,
    hipShoulderEarRight: cptRightHipShoulderEarAngle,
    shoulderElbowHipLeft: cptLeftShoulderElbowHipAngle,
    shoulderElbowHipRight: cptRightShoulderElbowHipAngle,
    shoulderAnkleLeft: cptLeftShoulderAnkleAngle,
    shoulderAnkleRight: cptRightShoulderAnkleAngle,
    footLeft: cptLeftFootAngle,
    footRight: cptRightFootAngle,
    ankleKneeRight: cptAnkleKneeAngleLeft,
    ankleKneeLeft: cptAnkleKneeAngleRight,
};

export const angleSideComputations = {
    hipKneeAnkle: {
        left: cptHipKneeAnkleAngleLeft,
        right: cptHipKneeAnkleAngleRight,
    },
    back: {
        left: backAngleLeft,
        right: backAngleRight,
    },
    hipKnee: {
        left: cptLeftHipKneeAngle,
        right: cptRightHipKneeAngle,
    },
    neck: {
        left: cptNeckAngleLeft,
        right: cptNeckAngleRight,
    },
    spine: {
        left: spineAngleLeft,
        right: spineAngleRight,
    },
    shoulderElbowWrist: {
        left: cptLeftShoulderElbowWristAngle,
        right: cptRightShoulderElbowWristAngle,
    },
    shoulderElbow: {
        left: cptLeftShoulderElbowAngle,
        right: cptRightShoulderElbowAngle,
    },
    shoulderHipAnkle: {
        left: cptLeftShoulderHipAnkleAngle,
        right: cptRightShoulderHipAnkleAngle,
    },
    elbowWrist: {
        left: cptLeftElbowWristAngle,
        right: cptRightElbowWristAngle,
    },
    kneeHipShoulder: {
        left: cptLeftKneeHipShoulderAngle,
        right: cptRightKneeHipShoulderAngle,
    },
    spineTilt: {
        left: cptLeftSpineTiltAngle,
        right: cptRightSpineTiltAngle,
    },
    hipShoulderEar: {
        left: cptLeftHipShoulderEarAngle,
        right: cptRightHipShoulderEarAngle,
    },
    shoulderElbowHip: {
        left: cptLeftShoulderElbowHipAngle,
        right: cptRightShoulderElbowHipAngle,
    },
    shoulderAnkle: {
        left: cptLeftShoulderAnkleAngle,
        right: cptRightShoulderAnkleAngle,
    },
    foot: {
        left: cptLeftFootAngle,
        right: cptRightFootAngle,
    },
    ...angleSideForceComputations,
};

function cptLeftShoulderAnkleAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let leftShoulderAnkleAngle;
    if (isLeft) {
        leftShoulderAnkleAngle = findAdjustedAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[27]
        );
    } else {
        leftShoulderAnkleAngle = findAdjustedAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[27]
        );
        leftShoulderAnkleAngle = 360 - leftShoulderAnkleAngle;
    }
    if (display) {
        drawAngle(
            canvasCtx,
            leftShoulderAnkleAngle,
            results.poseLandmarks[11],
            true
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[11],
            left: true,
        };
    }
    return [leftShoulderAnkleAngle, displayInfo];
}

function cptRightShoulderAnkleAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let rightShoulderAnkleAngle;
    if (isLeft) {
        rightShoulderAnkleAngle = findAdjustedAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[28],
            90
        );
    } else {
        rightShoulderAnkleAngle = findAdjustedAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[28],
            90
        );
        rightShoulderAnkleAngle = 360 - rightShoulderAnkleAngle;
    }
    if (display) {
        drawAngle(
            canvasCtx,
            rightShoulderAnkleAngle,
            results.poseLandmarks[12],
            false
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[12],
            left: false,
        };
    }
    return [rightShoulderAnkleAngle, displayInfo];
}

function cptLeftElbowWristAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let leftElbowWristAngle;
    if (isLeft) {
        leftElbowWristAngle = findAngle(
            results.poseLandmarks[13],
            results.poseLandmarks[15], -180
        );
    } else {
        leftElbowWristAngle = findCounterAngle(
            results.poseLandmarks[13],
            results.poseLandmarks[15],
            180
        );
    }
    if (leftElbowWristAngle > 300) {
        leftElbowWristAngle = 360 - leftElbowWristAngle;
    }
    if (display) {
        drawAngle(canvasCtx, leftElbowWristAngle, results.poseLandmarks[13], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[13],
            left: true,
        };
    }
    return [leftElbowWristAngle, displayInfo];
}

function cptRightElbowWristAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let rightElbowWristAngle;
    if (isLeft) {
        rightElbowWristAngle = findCounterAngle(
            results.poseLandmarks[14],
            results.poseLandmarks[16],
            90
        );
    } else {
        rightElbowWristAngle = findAngle(
            results.poseLandmarks[14],
            results.poseLandmarks[16],
            0
        );
    }
    if (display) {
        drawAngle(
            canvasCtx,
            rightElbowWristAngle,
            results.poseLandmarks[14],
            false
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[14],
            left: false,
        };
    }
    return [rightElbowWristAngle, displayInfo];
}

function cptLeftShoulderElbowAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let leftShoulderElbowAngle;
    if (isLeft) {
        leftShoulderElbowAngle =
            360 -
            findAdjustedAngle(results.poseLandmarks[11], results.poseLandmarks[13]);
        // if (leftShoulderElbowAngle > 180) {
        //   leftShoulderElbowAngle = 360 - leftShoulderElbowAngle;
        // }
    } else {
        leftShoulderElbowAngle = findCounterAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[13]
        );
    }
    if (display) {
        drawAngle(
            canvasCtx,
            leftShoulderElbowAngle,
            results.poseLandmarks[11],
            true
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[11],
            left: true,
        };
    }
    return [leftShoulderElbowAngle, displayInfo];
}

function cptRightShoulderElbowAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let rightShoulderElbowAngle;
    if (isLeft) {
        rightShoulderElbowAngle =
            360 -
            findAdjustedAngle(
                results.poseLandmarks[12],
                results.poseLandmarks[14],
                90
            );
    } else {
        rightShoulderElbowAngle = findAdjustedAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[14]
        );
    }
    if (display) {
        drawAngle(
            canvasCtx,
            rightShoulderElbowAngle,
            results.poseLandmarks[12],
            false
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[12],
            left: false,
        };
    }
    return [rightShoulderElbowAngle, displayInfo];
}

function cptLeftShoulderElbowWristAngle({
    results,
    canvasCtx,
    isLeft,
    display,
}) {
    let displayInfo;
    let leftShoulderElbowWristAngle;
    if (isLeft) {
        leftShoulderElbowWristAngle = findDependentAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[13],
            results.poseLandmarks[15],
            true
        );
    } else {
        leftShoulderElbowWristAngle = findDependentAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[13],
            results.poseLandmarks[15]
        );
    }
    if (display) {
        drawAngle(
            canvasCtx,
            leftShoulderElbowWristAngle,
            results.poseLandmarks[13],
            true
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[13],
            left: true,
        };
    }
    return [leftShoulderElbowWristAngle, displayInfo];
}

function cptRightShoulderElbowWristAngle({
    results,
    canvasCtx,
    isLeft,
    display,
}) {
    let displayInfo;
    let rightShoulderElbowWristAngle;
    if (isLeft) {
        rightShoulderElbowWristAngle = findDependentAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[14],
            results.poseLandmarks[16]
        );
    } else {
        rightShoulderElbowWristAngle = findDependentAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[14],
            results.poseLandmarks[16],
            true
        );
    }
    rightShoulderElbowWristAngle = 360 - rightShoulderElbowWristAngle;
    if (display) {
        drawAngle(
            canvasCtx,
            rightShoulderElbowWristAngle,
            results.poseLandmarks[14],
            true
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[14],
            left: false,
        };
    }
    return [rightShoulderElbowWristAngle, displayInfo];
}

function cptLeftShoulderElbowHipAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let leftShoulderElbowHipAngle;
    if (isLeft) {
        leftShoulderElbowHipAngle = findDependentAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[13],
            results.poseLandmarks[23]
        );
    } else {
        leftShoulderElbowHipAngle = findDependentAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[13],
            results.poseLandmarks[23],
            true
        );
    }
    if (display) {
        drawAngle(
            canvasCtx,
            leftShoulderElbowHipAngle,
            results.poseLandmarks[13],
            true
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[13],
            left: true,
        };
    }
    return [leftShoulderElbowHipAngle, displayInfo];
}

function cptRightShoulderElbowHipAngle({
    results,
    canvasCtx,
    isLeft,
    display,
}) {
    let displayInfo;
    let rightShoulderElbowHipAngle;
    if (isLeft) {
        rightShoulderElbowHipAngle = findDependentAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[14],
            results.poseLandmarks[24]
        );
    } else {
        rightShoulderElbowHipAngle = findDependentAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[14],
            results.poseLandmarks[24],
            true
        );
    }
    if (display) {
        drawAngle(
            canvasCtx,
            rightShoulderElbowHipAngle,
            results.poseLandmarks[14],
            true
        );
    } else {
        displayInfo = {
            coord: results.poseLandmarks[14],
            left: false,
        };
    }
    return [rightShoulderElbowHipAngle, displayInfo];
}

function cptLeftHipKneeAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let leftHipKneeAngle;
    if (isLeft) {
        leftHipKneeAngle =
            360 -
            findAdjustedAngle(
                results.poseLandmarks[23],
                results.poseLandmarks[25],
                0
            );
    } else {
        leftHipKneeAngle = findAdjustedAngle(
            results.poseLandmarks[23],
            results.poseLandmarks[25],
            180
        );
    }
    if (display) {
        drawAngle(canvasCtx, leftHipKneeAngle, results.poseLandmarks[23], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[23],
            left: true,
        };
    }
    return [leftHipKneeAngle, displayInfo];
}

function cptRightHipKneeAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let rightHipKneeAngle;
    if (isLeft) {
        rightHipKneeAngle =
            360 -
            findAdjustedAngle(
                results.poseLandmarks[24],
                results.poseLandmarks[26],
                0
            );
    } else {
        rightHipKneeAngle = findAdjustedAngle(
            results.poseLandmarks[24],
            results.poseLandmarks[26],
            180
        );
    }
    if (display) {
        drawAngle(canvasCtx, rightHipKneeAngle, results.poseLandmarks[23], false);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[23],
            left: false,
        };
    }
    return [rightHipKneeAngle, displayInfo];
}

function cptHipKneeAnkleAngleLeft({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let leftKneeAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
    );
    if (!isLeft) {
        if (leftKneeAngle > 180) {
            leftKneeAngle = 360 - leftKneeAngle;
        }
    }
    if (display) {
        drawAngle(canvasCtx, leftKneeAngle, results.poseLandmarks[25], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[25],
            left: true,
        };
    }
    return [leftKneeAngle, displayInfo];
}

function cptHipKneeAnkleAngleRight({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let rightKneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
    );
    if (!isLeft) {
        if (rightKneeAngle > 180) {
            rightKneeAngle = 360 - rightKneeAngle;
        }
    }
    if (display) {
        drawAngle(canvasCtx, rightKneeAngle, results.poseLandmarks[26], false);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[26],
            left: false,
        };
    }
    return [rightKneeAngle, displayInfo];
}

function backAngleLeft({
    results,
    canvasCtx,
    display
}) {
    let displayInfo;
    const angle = findAdjustedAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
    );
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[11], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[11],
            left: true,
        };
    }
    return [angle, displayInfo];
}

function backAngleRight({
    results,
    canvasCtx,
    display
}) {
    let displayInfo;
    const angle =
        360 -
        findAdjustedAngle(results.poseLandmarks[12], results.poseLandmarks[24], 0);
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[12], false);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[12],
            left: false,
        };
    }
    return [angle, displayInfo];
}

function cptLeftKneeHipShoulderAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let angle;
    if (isLeft) {
        angle = findDependentAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[23],
            results.poseLandmarks[25]
        );
    } else {
        angle = findDependentAngle(
            results.poseLandmarks[11],
            results.poseLandmarks[23],
            results.poseLandmarks[25],
            true
        );
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[23], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[23],
            left: true,
        };
    }
    return [angle, displayInfo];
}

function cptRightKneeHipShoulderAngle({
    results,
    canvasCtx,
    isLeft,
    display
}) {
    let displayInfo;
    let angle;
    if (isLeft) {
        angle = findDependentAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[24],
            results.poseLandmarks[26],
            true
        );
    } else {
        angle = findDependentAngle(
            results.poseLandmarks[12],
            results.poseLandmarks[24],
            results.poseLandmarks[26]
        );
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[24], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[24],
            left: false,
        };
    }
    return [angle, displayInfo];
}

function cptLeftSpineTiltAngle({
    results,
    canvasCtx,
    display,
    isLeft
}) {
    let displayInfo;
    let angle;
    if (isLeft) {
        angle = findDependentAngle(
            results.backbones[0],
            results.backbones[2],
            results.backbones[4]
        );
    } else {
        angle = findDependentAngle(
            results.backbones[0],
            results.backbones[2],
            results.backbones[4],
            true
        );
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.backbones[2], true);
    } else {
        displayInfo = {
            coord: results.backbones[2],
            left: true,
        };
    }
    return [angle, displayInfo];
}

function cptRightSpineTiltAngle({
    results,
    canvasCtx,
    display,
    isLeft
}) {
    let displayInfo;
    let angle;
    if (!isLeft) {
        angle = findDependentAngle(
            results.backbones[0],
            results.backbones[2],
            results.backbones[4]
        );
    } else {
        angle = findDependentAngle(
            results.backbones[0],
            results.backbones[2],
            results.backbones[4],
            true
        );
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.backbones[2], true);
    } else {
        displayInfo = {
            coord: results.backbones[2],
            left: false,
        };
    }
    return [angle, displayInfo];
}

function cptLeftHipShoulderEarAngle({
    results,
    canvasCtx,
    display,
    isLeft
}) {
    let displayInfo;
    let angle;

    if (isLeft) {
        angle = findDependentAngle(
            results.poseLandmarks[23],
            results.poseLandmarks[11],
            results.poseLandmarks[7]
        );
    } else {
        angle = findDependentAngle(
            results.poseLandmarks[23],
            results.poseLandmarks[11],
            results.poseLandmarks[7],
            true
        );
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[7], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[7],
            left: true,
        };
    }
    return [angle, displayInfo];
}

function cptRightHipShoulderEarAngle({
    results,
    canvasCtx,
    display,
    isLeft
}) {
    let displayInfo;
    let angle;
    if (!isLeft) {
        angle = findDependentAngle(
            results.poseLandmarks[24],
            results.poseLandmarks[12],
            results.poseLandmarks[8]
        );
    } else {
        angle = findDependentAngle(
            results.poseLandmarks[24],
            results.poseLandmarks[12],
            results.poseLandmarks[8],
            true
        );
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[8], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[8],
            left: false,
        };
    }
    return [angle, displayInfo];
}

function cptAnkleKneeAngleLeft({
    results,
    canvasCtx,
    display,
    isLeft
}) {
    let displayInfo;
    let angle;
    if (isLeft) {
        angle = findAdjustedAngle(
            results.poseLandmarks[25],
            results.poseLandmarks[27]
        );
    } else {
        angle =
            360 -
            findAdjustedAngle(results.poseLandmarks[25], results.poseLandmarks[27]);
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[27], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[27],
            left: true,
        };
    }
    return [angle, displayInfo];
}

function cptAnkleKneeAngleRight({
    results,
    canvasCtx,
    display,
    isLeft
}) {
    let displayInfo;
    let angle;
    if (!isLeft) {
        angle = findAdjustedAngle(
            results.poseLandmarks[26],
            results.poseLandmarks[28]
        );
    } else {
        angle =
            360 -
            findAdjustedAngle(results.poseLandmarks[26], results.poseLandmarks[28]);
    }
    if (display) {
        drawAngle(canvasCtx, angle, results.poseLandmarks[28], true);
    } else {
        displayInfo = {
            coord: results.poseLandmarks[28],
            left: false,
        };
    }
    return [angle, displayInfo];
}