import Calendar from 'react-calendar';
import './calendar.css';
import React from 'react';
import {
    useTranslation
} from 'react-i18next';

export default function CalendarComponent() {
    const {
        t
    } = useTranslation();
    return ( <
        div className = "top-right article" >
        <
        Calendar className = {
            'calendr'
        }
        locale = {
            t('CalendarComponent.locale')
        }
        /> <
        /div>
    );
}