const initialState = {
  values: {
    correct: 0,
    incorrect: 0,
    time: 0,
    feedback: "",
  },
};

export const countsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_COUNT": {
      return {
        values: { ...state.values, ...action.payload },
      };
    }
    case "FEEDBACK": {
      return {
        values: { ...state.values, ...action.payload },
      };
    }
    case "RESET_COUNTS":
      return initialState;
    default: {
      return state;
    }
  }
};
