const initialState = {
  userRole: "public",
  values: {},
};
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN": {
      console.log("Email is::" + action);
      return { ...state, values: action.payload };
    }
    case "LOGOUT": {
      localStorage.clear();
      return null;
    }
    case "SIGNUP": {
      return { ...state, values: action.payload };
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole };
    }
    default: {
      return state;
    }
  }
};
