const initialState = {
  values: {
    bestValue: 0,
    time: 0,
    feedback: "",
  },
};

export const stretchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_STRETCH_OBSERVATION": {
      return {
        values: { ...state.values, ...action.payload },
      };
    }
    case "STRETCH_FEEDBACK": {
      return {
        values: { ...state.values, ...action.payload },
      };
    }
    case "RESET_STRETCH":
      return initialState;
    default: {
      return state;
    }
  }
};
