import React from "react";
import ic1 from "../../assets/ic1.png";
import "./profile.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { useTranslation } from "react-i18next";

const Goals = ({ board }) => {
  const { t } = useTranslation();
  return (
    <div className="goals-profile-content">
      <div className="profile-settings-title">
        <div className="left">
          <div className="profile-page-orange-rect"> </div>{" "}
          <h2> {t("DrawerComponent.goalsTitle")} </h2>{" "}
        </div>{" "}
        {/* <Select className="profile-goals-select" defaultValue={10}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>This month</MenuItem>
                    </Select> */}{" "}
      </div>{" "}
      <div className="goals-profile-content-inner">
        <h2> {t("DrawerComponent.goals")} </h2>{" "}
        <div className="progress-bar-group">
          <label> {t("PointsComponent.sweatPoints")} </label>{" "}
          <div className="profile-progress-bar-container">
            <ProgressBar
              className="progress-bar-profile"
              completed={board ? board.sweat_points : 0}
              customLabel={board ? board.sweat_points : 0}
              labelColor="#646970"
              bgColor="#cabdff"
              height="50px"
              borderRadius=".5rem"
              labelAlignment="left"
              baseBgColor="#f4f4f4"
              padding="0"
              maxCompleted={5000}
            />{" "}
            <div
              style={{
                paddingLeft: "2%",
              }}
            >
              {" "}
              12000{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="progress-bar-group">
          <label> {t("PointsComponent.sweatPoints")} </label>{" "}
          <div className="profile-progress-bar-container">
            <ProgressBar
              className="progress-bar-profile"
              completed={board ? board.workouts : 0}
              maxCompleted={365}
              customLabel={board ? board.workouts : 0}
              labelColor="#646970"
              height="50px"
              borderRadius=".5rem"
              labelAlignment="left"
              baseBgColor="#f4f4f4"
              padding="0"
            />
            <div
              style={{
                paddingLeft: "2%",
              }}
            >
              {" "}
              365{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <h3> {t("DrawerComponent.rewards")} </h3>{" "}
        <div className="rewards-container-goals">
          <div className="rewards-container-goals-content">
            <img src={ic1} alt="icon" />
            <div className="right">
              <span> {t("PointsComponent.xrCoins")} </span>{" "}
              <h1> {board ? board.coins : 0} </h1>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Goals;
