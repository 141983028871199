import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import { Card, TextField } from "@mui/material";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect } from "react";
import BasicSelect from "../BasicSelect";
import OptionsBadge from "../OptionsBadge";
import AssignedReferenceBox from "./AssignedReferenceBox";
import ConditionBox from "./ConditionBox";

function StateConfig({
  config,
  setConfig,
  refOptions,
  assignedRefOptions,
  type,
  copyConfig,
  useAngleConfigs,
  stateOptions,
}) {
  const name = React.useRef(config.name);
  const conditions = React.useRef(config.conditions || []);
  const assignedReferences = React.useRef(config.assignedReferences || []);
  const cptPixelHeight = React.useRef(config.cptPixelHeight);
  const pixelHeightAxis = React.useRef(config.pixelHeightAxis || "");

  useEffect(() => {
    conditions.current.forEach((condition) => {
      if (!condition.id) condition.id = _.uniqueId();
    });
    console.log(
      stateOptions.find((option) => option.id === config.sideLink),
      config.sideLink,
      stateOptions
    );
  }, [conditions.current]);

  return (
    <Badge
      badgeContent={
        <OptionsBadge setConfig={setConfig} copyConfig={copyConfig} />
      }
      sx={{ m: 0, width: "100%" }}
    >
      <Card
        sx={{
          overflow: "auto",
          height: "70vh",
          width: "100%",
          paddingLeft: 2,
          paddingTop: 4,
          backgroundColor: "rgba(9,196,9,0.41)",
        }}
      >
        <TextField
          name={config.name}
          label="name"
          type="text"
          variant="outlined"
          // className="inputRounded"
          InputLabelProps={{ disableAnimation: true }}
          shrink={"true"}
          sx={{ width: "70%", marginBottom: 1 }}
          value={name.current}
          onChange={(event) => {
            name.current = event.target.value;
            setConfig({ ...config, name: event.target.value });
          }}
        />
        {["RWUStretch", "stretchCount"].includes(type) && (
          <Box sx={{ width: "90%", marginBottom: 0 }}>
            <Typography variant="body1">Cpt Pixel Height</Typography>
            <Checkbox
              value={!cptPixelHeight.current}
              onClick={() => {
                cptPixelHeight.current = !cptPixelHeight.current;
                setConfig({
                  ...config,
                  cptPixelHeight: cptPixelHeight.current,
                });
              }}
            />
            <BasicSelect
              label="Pixel Height Axis"
              options={["x", "y"]}
              value={pixelHeightAxis.current}
              setValue={(value) => {
                pixelHeightAxis.current = value;
                setConfig({ ...config, pixelHeightAxis: value });
              }}
            />
          </Box>
        )}
        <Typography variant="h6" sx={{ margin: 3 }}>
          Conditions
        </Typography>
        {conditions.current.map((condition, index) => (
          <ConditionBox
            key={condition.id}
            useAngleConfigs={useAngleConfigs}
            refOptions={refOptions}
            assignedRefOptions={assignedRefOptions}
            config={condition}
            copyConfig={() => {
              const copiedCondition = _.cloneDeep(condition);
              conditions.current.push(copiedCondition);
              setConfig({
                ...config,
                conditions: conditions.current,
              });
            }}
            setConfig={(value) => {
              if (value) {
                conditions.current[index] = value;
              } else {
                conditions.current.splice(index, 1);
              }
              setConfig({ ...config, conditions: conditions.current });
            }}
          />
        ))}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="delete patient"
          onClick={(e) => {
            e.stopPropagation();
            config.conditions.push({
              ref: undefined,
              operator: undefined,
              value: 0,
            });
            setConfig({ ...config, conditions: config.conditions });
          }}
          sx={{ marginLeft: 3 }}
        >
          <AddIcon />
        </IconButton>
        <Divider />
        <Typography variant="h6" sx={{ margin: 3 }}>
          Assign References
        </Typography>
        {assignedReferences.current.map((reference, index) => (
          <AssignedReferenceBox
            refOptions={assignedRefOptions}
            key={"assigned-ref-" + index}
            config={reference}
            copyConfig={() => {
              const copiedReference = _.cloneDeep(reference);
              copiedReference.name += " (copy)";
              assignedReferences.current.push(copiedReference);
              setConfig({
                ...config,
                assignedReferences: assignedReferences.current,
              });
            }}
            setConfig={(value) => {
              if (value) {
                assignedReferences.current[index] = value;
              } else {
                assignedReferences.current.splice(index, 1);
              }
              setConfig({
                ...config,
                assignedReferences: assignedReferences.current,
              });
            }}
          />
        ))}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="delete patient"
          onClick={(e) => {
            e.stopPropagation();
            assignedReferences.current.push({
              ref: undefined,
            });
            setConfig({
              ...config,
              assignedReferences: assignedReferences.current,
            });
          }}
          sx={{ marginLeft: 3 }}
        >
          <AddIcon />
        </IconButton>
        <Divider sx={{ mb: 2 }} />
        <BasicSelect
          label={"side link"}
          options={stateOptions.concat(null)}
          value={stateOptions.find((option) => option.id === config.sideLink)}
          setValue={(value) => {
            setConfig({ ...config, sideLink: value });
          }}
        />
        <BasicSelect
          label={"side"}
          options={["left", "right", null]}
          value={config.side}
          setValue={(value) => {
            setConfig({ ...config, side: value });
          }}
        />
      </Card>
    </Badge>
  );
}

export default StateConfig;
