import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Angles from "../../../components/Demo/ExerciseManagement/Overview/Angles";
import AudioFeedbacks from "../../../components/Demo/ExerciseManagement/Overview/AudioFeedbacks";
import Exercises from "../../../components/Demo/ExerciseManagement/Overview/Exercises";
import DrawerMenu from "../../../components/Demo/ExerciseManagement/Overview/DrawerMenu";
import Roadmaps from "../../../components/Demo/ExerciseManagement/Overview/Roadmaps";
import Schedules from "../../../components/Demo/ExerciseManagement/Overview/Schedules";
import Tags from "../../../components/Demo/ExerciseManagement/Overview/Tags";
import Transitions from "../../../components/Demo/ExerciseManagement/Overview/Transitions";
import Videos from "../../../components/Demo/ExerciseManagement/Overview/Videos";
import OverviewAppBar from "../../../components/Demo/OverviewAppBar";

export default function ExerciseManagementOverview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [content, setContent] = React.useState(
    searchParams.get("content") || "Exercises"
  );
  const [searchValue, setSearchValue] = React.useState("");
  const [searchType, setSearchType] = React.useState("exercise"); // ['name', 'tag'
  const [orderByField, setOrderByField] = React.useState("name");
  const [orderByDirection, setOrderByDirection] = React.useState("asc");
  const [searchTermField, setSearchTermField] = React.useState("name");
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [searchTag, setSearchTag] = React.useState(null);

  useEffect(() => {
    setSearchValue("");
  }, [content]);

  function goToExerciseGenerator() {
    dispatch({
      type: "RESET-EXERCISE-CONFIG",
    });
    navigate("/exercise_generator");
  }

  useEffect(() => {
    navigate("/exercise_management?content=" + encodeURIComponent(content));
  }, [content, navigate]);

  const handleContentChange = (value) => {
    setContent(value);
    setDrawerOpen(false);
  };

  return (
    <div>
      <OverviewAppBar
        setSearchValue={setSearchValue}
        setInfoDialogOpen={goToExerciseGenerator}
        DrawerContent={<DrawerMenu setSelected={handleContentChange} />}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        setSearchTermField={setSearchTermField}
        setOrderByField={setOrderByField}
        setOrderByDirection={setOrderByDirection}
        setSearchTag={setSearchTag}
        searchTagOptions={tags}
        setSearchType={setSearchType}
        searchTypeOptions={
          content === "Tags"
            ? ["exercise", "schedule", "transition", "video", "audio"]
            : []
        }
      />
      {content === "Exercises" && (
        <Exercises
          searchValue={searchValue}
          orderByField={orderByField}
          searchTermField={searchTermField}
          orderByDirection={orderByDirection}
          searchTag={searchTag}
          setTags={setTags}
        />
      )}
      {content === "Feedback Audios" && (
        <AudioFeedbacks
          searchValue={searchValue}
          orderByField={orderByField}
          searchTermField={searchTermField}
          orderByDirection={orderByDirection}
          searchTag={searchTag}
          setTags={setTags}
        />
      )}
      {content === "Schedule" && (
        <Schedules
          searchValue={searchValue}
          orderByField={orderByField}
          searchTermField={searchTermField}
          orderByDirection={orderByDirection}
          searchTag={searchTag}
          setTags={setTags}
        />
      )}
      {content === "Transitions" && (
        <Transitions
          searchValue={searchValue}
          orderByField={orderByField}
          searchTermField={searchTermField}
          orderByDirection={orderByDirection}
          searchTag={searchTag}
          setTags={setTags}
        />
      )}
      {content === "Tags" && (
        <Tags searchValue={searchValue} type={searchType} />
      )}
      {content === "Angles" && (
        <Angles
          searchValue={searchValue}
          orderByField={orderByField}
          searchTermField={searchTermField}
          orderByDirection={orderByDirection}
          searchTag={searchTag}
          setTags={setTags}
        />
      )}
      {content === "Roadmaps" && (
        <Roadmaps
          searchValue={searchValue}
          orderByField={orderByField}
          searchTermField={searchTermField}
          orderByDirection={orderByDirection}
          searchTag={searchTag}
          setTags={setTags}
        />
      )}
      {content === "Videos" && (
        <Videos
          searchTerm={searchValue}
          orderByField={orderByField}
          searchTermField={searchTermField}
          orderByDirection={orderByDirection}
          searchTag={searchTag}
          setTags={setTags}
        />
      )}
    </div>
  );
}
