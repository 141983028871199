import AddIcon from "@mui/icons-material/Add";
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React from "react";
import {
  getTags,
  deleteTag,
} from "../../../../utilities/Demo/ExerciseManagement/firestore";
import TagConfigurator from "../Configurator/Tags/TagConfigurator";
import DeleteButton from "../DeleteButton";

export default function Tags({ searchValue, type = "exercise" }) {
  const [tags, setTags] = React.useState([]);
  const [config, setConfig] = React.useState(false);

  React.useEffect(() => {
    getTags({ searchTerm: searchValue, type: type }).then((tags) => {
      setTags(tags || []);
    });
  }, [searchValue, type]);

  const setConfigUpdate = (config) => {
    const index = tags.findIndex((tag) => tag.id === config.id);
    if (index !== -1) {
      setTags([...tags.slice(0, index), config, ...tags.slice(index + 1)]);
    } else {
      setTags([...tags, config]);
    }
  };

  const removeTag = (tagId) => {
    setTags(tags.filter((tag) => tag.id !== tagId));
  };

  if (config) {
    return (
      <TagConfigurator
        config={config}
        setConfig={setConfigUpdate}
        onClose={() => setConfig(null)}
      />
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <h1>Tags</h1>
      <Grid container spacing={2}>
        {tags.map((tag) => {
          return (
            <Grid item xs={12} sm={6} md={3} key={tag.id}>
              <Card
                sx={{ padding: 2, "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  setConfig(tag);
                }}
              >
                <h3>{tag.name}</h3>
                <p>{tag.description}</p>
                <DeleteButton
                  onDelete={() => {
                    deleteTag({
                      tagId: tag.id,
                    }).then(() => {
                      removeTag(tag.id);
                    });
                  }}
                  deletionTarget={"Tag" + tag.name}
                />
              </Card>
            </Grid>
          );
        })}
        <Grid item key={"createNew"} xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: 2,
              border: 1,
              borderRadius: 5,
              m: 2,
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setConfig({ name: "", description: "", id: "" });
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
