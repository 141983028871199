import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useCountsInTimeCoreFeedback } from "../../../../../hooks/Demo/feedbackTypes";
import { safeIterScore } from "../../../../../hooks/Demo/sharedLogic";
import { checkIfSide } from "../../../../../utilities/Demo/angles";
import { loadTurnAndLookBehindAudio } from "../../../../../utilities/Demo/audioFeedback";
import { checkIfFront } from "../../../../../utilities/Demo/gaitAnalysis";
import {
  findAdjustedAngle,
  findDependentAngle,
} from "../../../../../utilities/Demo/physio";
import PoseDetection from "../../../PoseDetection";
import {
  computeMetrics,
  leftUpperLegRomMetrics,
  rightUpperLegRomMetrics,
} from "../../../../../utilities/Demo/Physio/BergBalance/metrics";

export default function TurnAndLookBehind({ onComplete, showWebcam = false }) {
  const timeThresh = 60;
  const dispatch = useDispatch();
  const side = React.useRef(false);
  const lookedLeft = React.useRef(false);
  const lookedRight = React.useRef(false);
  const turnedStraight = React.useRef(false);
  const collecting = React.useRef(false);
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadTurnAndLookBehindAudio,
    maxScore: 1,
  });
  const metrics = useRef(
    leftUpperLegRomMetrics("Adduction", "Abduction").concat(
      rightUpperLegRomMetrics("Adduction", "Abduction")
    )
  );
  cptScore.current = () => {
    if (lookedLeft.current && lookedRight.current) return 1;
    if (lookedLeft.current || lookedRight.current) return 1 / 3;
    return 0;
  };

  const raiseScore = () => {
    const score = cptScore.current();
    if (!raiseLevel(score)) {
      playAudio(audioFeedback.current?.achievement, true);
    } else {
      safeIterScore(dispatch, score);
    }
  };

  function observation(
    hipKneeAngleLeft,
    hipKneeAngleRight,
    kneeAngleLeft,
    kneeAngleRight,
    results
  ) {
    if (
      results.poseLandmarks[24].visibility > 0.5 &&
      results.poseLandmarks[26].visibility > 0.5 &&
      results.poseLandmarks[28].visibility > 0.5 &&
      results.poseLandmarks[23].visibility > 0.5 &&
      results.poseLandmarks[25].visibility > 0.5 &&
      results.poseLandmarks[27].visibility > 0.5
    ) {
      side.current = checkIfSide(results, 10);
      const front = checkIfFront(results);
      if (front || side.current) {
        updateFeedback("Turn away from the the camera!");
        collecting.current = false;
        // lookedRight.current = false;
        // lookedLeft.current = false;
      } else {
        computeMetrics({
          metrics: metrics.current,
          angles: {
            current: {
              // kneeAngleLeft,
              // kneeAngleRight,
              hipKneeAngleLeft,
              hipKneeAngleRight,
            },
          },
          dispatch,
        });
        setStartingTime(seconds);
        updateFeedback("");
        const nodeDistLeftShoulder = Math.abs(
          results.poseLandmarks[0].y - results.poseLandmarks[11].y
        );
        const nodeDistRightShoulder = Math.abs(
          results.poseLandmarks[0].y - results.poseLandmarks[12].y
        );
        if (lookedLeft.current && lookedRight.current) {
          updateFeedback("Turn back straight!");
        } else {
          if (lookedLeft.current) {
            updateFeedback("Look over your right shoulder!");
          }
          if (lookedRight.current) {
            updateFeedback("Look over your left shoulder!");
          }
        }
        if (results.poseLandmarks[0].z < 0) {
          if (
            nodeDistLeftShoulder > nodeDistRightShoulder &&
            turnedStraight.current
          ) {
            lookedLeft.current = true;
            turnedStraight.current = false;
            raiseScore();
          } else {
            lookedRight.current = true;
            turnedStraight.current = false;
            raiseScore();
          }
        } else if (results.poseLandmarks[0].z > 0.1) {
          collecting.current = true;
          turnedStraight.current = true;
          if (lookedLeft.current && lookedRight.current) {
            collecting.current = false;
            lookedLeft.current = false;
            lookedRight.current = false;
            correctCount.current++;
            updateCounts(correctCount.current);
            raiseScore();
            onComplete();
          }
        }
      }
    } else {
      updateFeedback("Person out of frame!");
      collecting.current = false;
    }
  }

  function onResults(results) {
    if (!finishedIntro.current) return;

    const hipKneeAngleLeft =
      360 -
      findAdjustedAngle(results.poseLandmarks[23], results.poseLandmarks[25]);
    const hipKneeAngleRight = findAdjustedAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26]
    );
    const kneeAngleLeft = findDependentAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      results.poseLandmarks[27]
    );
    const kneeAngleRight = findDependentAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      results.poseLandmarks[28]
    );

    observation(
      hipKneeAngleLeft,
      hipKneeAngleRight,
      kneeAngleLeft,
      kneeAngleRight,
      results
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
