import React, { useEffect } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import DrawerComponent from "../../components/DrawerComponent";
import { useState } from "react";
import moment from "moment";
import CalendarComponent from "../../components/Dashboard/CalendarComponent";
import TopThreeClubComponent from "../../components/Dashboard/TopThreeClubComponent";
import SessionComponent from "../../components/Dashboard/SessionComponent";
import OverviewComponent from "../../components/Dashboard/OverviewComponent";
import BgComponent from "../../components/Background/BgComponent";
import ProgressComponent from "../../components/Dashboard/ProgressComponent";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  getDashboardData,
  getRankings,
  getScheduleData,
  getClubData,
  getAgeClubs,
} from "../../redux/actions/getDashboard";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const user = localStorage.getItem("user_data");
  const dispatch = useDispatch();
  const board = useSelector(({ board }) => board.values);
  const schedule = useSelector(({ schedule }) => schedule.values);
  const ranks = useSelector(({ ranks }) => ranks.values);
  const club = useSelector(({ club }) => club.values);
  const { user_details } = JSON.parse(localStorage.getItem("full_user"));
  const ageclub = useSelector(({ ageclub }) => ageclub.values);
  const { t } = useTranslation();
  // console.log("BORRRD SEL::", JSON.stringify(board));
  useEffect(() => {
    if (!board) getDashboardData(dispatch);
    getScheduleData(dispatch, moment().format("YYYY-MM-DD"), (some) => some);
    if (!ranks) {
      getRankings(dispatch, (a) => a);
    }

    if (!club) {
      getClubData(dispatch, (a) => a);
    }
    console.log("ageclubDash::", ageclub);
    if (!ageclub) {
      getAgeClubs(dispatch, (a) => a);
    }
  }, []);
  if (!user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="teacher-home">
      <BgComponent />
      <HeaderComponent />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="container">
        {user_details && user_details.type === "b2c" ? (
          <OverviewComponent data={board} topthree={club} />
        ) : (
          <OverviewComponent data={board} topthree={ranks} />
        )}
        {user_details && user_details.type === "b2c" ? (
          <TopThreeClubComponent
            topthree={ageclub}
            title={t("TopThreeComponent.title")}
          />
        ) : (
          <CalendarComponent />
        )}

        <ProgressComponent data={board} />
        <SessionComponent data={schedule} />
      </div>
    </div>
  );
};

export default Dashboard;
