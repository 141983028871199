import React, { useRef } from "react";
import "@tensorflow/tfjs-backend-webgl";
import { useCountsInTimeCoreFeedback } from "../../../hooks/Demo/feedbackTypes";
import PoseDetection from "./../PoseDetection";
import { checkIfSide } from "../../../utilities/Demo/angles";
import { safeIterScore } from "../../../hooks/Demo/sharedLogic";
import { loadSquatsAudio } from "../../../utilities/Demo/audioFeedback";
import { useDispatch } from "react-redux";

function FrontsideLungesDemo({ onComplete }) {
  const dispatch = useDispatch();
  const peaked = useRef(false);
  const timeThresh = 60;
  const lungeSide = useRef("left");
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadSquatsAudio,
  });
  cptScore.current = () => {
    return (correctCount.current / timeThresh) * 2;
  };

  function observeBalance(frontKnee, backKnee, frontHip) {
    if (!peaked.current) {
      if (
        frontKnee.z > 0 &&
        frontKnee.y >= frontHip.y / 2 &&
        backKnee.y < frontKnee.y
      ) {
        peaked.current = true;
        const score = cptScore.current();
        setStartingTime(seconds.current);
        correctCount.current++;
        updateCounts(correctCount.current);
        if (!raiseLevel(score)) {
          playAudio(audioFeedback.current?.achievement, true);
        } else {
          safeIterScore(dispatch, score);
        }
      } else {
        if (frontKnee.z > 0) {
          playAudio(audioFeedback.current?.lungeOtherSide);
          updateFeedback("Lunge on the other side!");
          return;
        }
        if (frontKnee.y >= frontHip.y / 2) {
          playAudio(audioFeedback.current?.goLower);
          updateFeedback("Go lower!");
          return;
        }
        if (backKnee.y >= frontKnee.y) {
          playAudio(audioFeedback.current?.keepBackStraight);
          updateFeedback("Get lower with your back knee!");
        }
      }
    } else {
      if (frontKnee.z < 0) {
        peaked.current = false;
        lungeSide.current = lungeSide.current === "left" ? "right" : "left";
      }
    }
  }

  function onResults(results) {
    if (!finishedIntro.current) return;

    if (checkIfSide(results)) {
      playAudio(audioFeedback.current?.faceCamera);
      updateFeedback("Face the camera!");
      return;
    }
    const leftKnee = results.poseLandmarks[23];
    const rightKnee = results.poseLandmarks[24];
    const leftHip = results.poseLandmarks[25];
    const rightHip = results.poseLandmarks[26];

    if (lungeSide.current === "left") {
      observeBalance(leftKnee, rightKnee, leftHip);
    } else {
      observeBalance(rightKnee, leftKnee, rightHip);
    }
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(FrontsideLungesDemo);
