import AddIcon from "@mui/icons-material/Add";
import { Card } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { getTag } from "../../../../utilities/Demo/ExerciseManagement/firestore";
import CloseBadge from "./CloseBadge";
import TagDialog from "./TagDialog";

export default function TagConfig({ tags, setTags, type }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  useEffect(() => {
    const tagObjects = [];
    const promises = tags.map(async (tag) => {
      if (typeof tag === "string") {
        const tagObject = await getTag({ tagId: tag });
        if (tagObject) {
          tagObjects.push(tagObject);
        }
      }
    });

    Promise.all(promises)
      .then(() => {
        // Now that all promises are resolved, you can set the tags
        // Assuming you have a "setTags" function to update the tags array
        setTags(tagObjects);
      })
      .catch((error) => {
        console.error("Error fetching tag objects:", error);
      });
  }, []);
  return (
    <Box sx={{ padding: 4 }}>
      <TagDialog
        setTag={setTags}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        type={type}
      />
      <h3>Tag Config</h3>
      <Grid container spacing={2}>
        {tags.map((tag, index) => {
          return (
            <Grid item key={tag.id} sx={{ mt: 2 }}>
              <Badge
                badgeContent={
                  <CloseBadge
                    sx={{ mt: 4, mr: 4 }}
                    setConfig={() => {
                      setTags(tags.filter((_, i) => i !== index));
                    }}
                  />
                }
              >
                <Card sx={{ padding: 2, paddingRight: 4 }}>
                  <Typography>{tag.name}</Typography>
                </Card>
              </Badge>
            </Grid>
          );
        })}
        <Grid item xs={2} sx={{ mt: 2.5 }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
