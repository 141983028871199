import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { useSelector } from "react-redux";
import AnglesChart from "./AnglesChart";

export default function ResultCharts() {
  const gaitResults = useSelector((state) => state.gaitResults);
  const {
    mainChartData,
    mainChartDataAll,
    kneeChartDataAll,
    kneeChartData,
    // hipChartDataAll,
    // hipChartData,
    // hipChartDataFront,
    // kneeChartDataFront,
    // hipRotationChartData,
    ankleChartDataAll,
    ankleChartData,
    // hipBalanceChartDataFront,
    // hipRotationChartDataFront,
  } = gaitResults.graphData || {};
  const chartHeight = "35%";
  const rangeChartColors = ["rgba(108,101,101,0.19)", "#76e671", "#008ffb"];

  return (
    <Box
      sx={{
        borderRadius: 2,
        borderColor: "black",
        padding: 2,
        mt: 2,
        mb: 2,
        height: "230vh",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2,
          height: "100%",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: 2,
            height: chartHeight,
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <AnglesChart
              chartKey={"knee-flex-extension"}
              data={kneeChartData}
              height={"100%"}
              slice={false}
              title={"Knee Flex/Extension"}
              normX={100}
              strokeWidth={2}
              chartType={"rangeArea"}
              colors={rangeChartColors}
            />{" "}
          </Grid>{" "}
          <Grid item xs={12} sm={12} md={6}>
            <AnglesChart
              chartKey={"knee-flex-extension"}
              data={kneeChartDataAll}
              height={"100%"}
              slice={false}
              title={"Knee Flex/Extension vs. Time"}
            />{" "}
          </Grid>{" "}
        </Grid>{" "}
        <Grid
          container
          spacing={2}
          sx={{
            padding: 2,
            height: chartHeight,
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <AnglesChart
              chartKey={"hip-flex-extension"}
              data={mainChartData}
              height={"100%"}
              slice={false}
              title={"Hip Flex/Extension"}
              normX={100}
              chartType={"rangeArea"}
              strokeWidth={2}
              colors={rangeChartColors}
              // colors={['#008FFB', 'transparent']}
            />{" "}
          </Grid>{" "}
          <Grid item xs={12} sm={12} md={6}>
            <AnglesChart
              chartKey={"hip-flex-extension"}
              data={mainChartDataAll}
              height={"100%"}
              slice={false}
              title={"Hip Flex/Extension vs. Time"}
            />{" "}
          </Grid>{" "}
        </Grid>{" "}
        {/*<Grid container spacing={2} sx={{ padding: 2, height: '25%' }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <AnglesChart
                          chartKey={'knee-pro-supination'}
                          data={kneeChartDataFront}
                          height={'100%'}
                          slice={false}
                          title={'Knee Pro/Supination'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AnglesChart
                          chartKey={'hip-abd-adduction'}
                          data={hipChartDataFront}
                          height={'100%'}
                          slice={false}
                          title={'Hip Abd/Adduction'}
                        />
                      </Grid>
                    </Grid>*/}{" "}
        <Grid
          container
          spacing={2}
          sx={{
            padding: 2,
            height: chartHeight,
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <AnglesChart
              chartKey={"ankle-dorsal-plantar-extension"}
              data={ankleChartData}
              height={"100%"}
              slice={false}
              title={"Ankle Dorsal/Plantar Flexion"}
              normX={100}
              chartType={"rangeArea"}
              strokeWidth={2}
              colors={rangeChartColors}
            />{" "}
          </Grid>{" "}
          <Grid item xs={12} sm={12} md={6}>
            <AnglesChart
              chartKey={"ankle-dorsal-plantar-extension"}
              data={ankleChartDataAll}
              height={"100%"}
              slice={false}
              title={"Ankle Dorsal/Plantar Flexion vs. Time"}
            />{" "}
          </Grid>{" "}
          {/*<Grid item xs={12} sm={12} md={6}>
                        <AnglesChart
                          chartKey={'hip-rotation'}
                          data={hipRotationChartData}
                          height={'100%'}
                          slice={false}
                          title={'Pelvic Tilt'}
                        />
                      </Grid>*/}{" "}
        </Grid>{" "}
        {/*<Grid container spacing={2} sx={{ padding: 2, height: '25%' }}>*/}{" "}
        {/*<Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          sx={{ height: ' 100%', backgroundColor: '#f1a60c' }}
                        >
                          <div style={{ padding: 200 }}>Ankle Varus/Valgus TBD</div>
                        </Grid>*/}{" "}
        {/*<Grid item xs={12} sm={12} md={6}>
                        <AnglesChart
                          chartKey={'hip-rotation-front'}
                          data={hipRotationChartDataFront}
                          height={'100%'}
                          slice={false}
                          title={'Pelvic Rotation'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AnglesChart
                          chartKey={'hip-balance-front'}
                          data={hipBalanceChartDataFront}
                          height={'100%'}
                          slice={false}
                          title={'Hip PoseHeld'}
                        />
                      </Grid>
                    </Grid>*/}{" "}
      </Grid>{" "}
    </Box>
  );
}
