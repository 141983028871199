import { CircularProgress, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GAIT_MIN_STEPS } from "../../../../../constants/Demo/gaitAnalysis";
import { useGaitResultsActions } from "../../../../../hooks/GaitAnalysis/actions";
import { GaitFeedbacks } from "../../../Feedbacks";
import GaitAnalysis from "./GaitAnalysis";
import AnglesChart from "../results/AnglesChart";
import GaitAnalysisFront from "./GaitAnalysisFront";
import LinearWithValueLabel from "./LinearProgress";
import { LoadingButton } from "@mui/lab";
import Stridalizer from "./Stridalizer";
import VideoSelector from "./VideoSelector";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const initialData = () => {
  return [
    {
      name: "Left Leg Angle",
      data: [],
    },
    {
      name: "Right Leg Angle",
      data: [],
    },
    {
      name: "Left Knee Angle",
      data: [],
    },
    {
      name: "Right knee Angle",
      data: [],
    },
    {
      name: "Left Ankle Angle",
      data: [],
    },
    {
      name: "Right Ankle Angle",
      data: [],
    },
    {
      name: "Left Hip Angle",
      data: [],
    },
    {
      name: "Right Hip Angle",
      data: [],
    },
    {
      name: "Hip Rotation Angle",
      data: [],
    },
    {
      name: "Hip Balance Angle",
      data: [],
    },
  ];
};

export default function RecordingSide({ front, setFront }) {
  const [videoUrl, setVideoUrl] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [legAngleData, setLegAngleData] = React.useState(initialData());
  const sensorData = React.useRef({
    left: [],
    right: [],
  });
  const [buttonEnabled, setButtonEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showWebcam, setShowWebcam] = React.useState(true);
  const [observationComponent, setObservationComponent] = React.useState(null);
  const [videoObservationComponent, setVideoObservationComponent] =
    React.useState(null);
  const [feedbackComponent, setFeedbackComponent] = React.useState(null);
  const [recordingEnabled, setRecordingEnabled] = React.useState(false);
  const { addResults, resetGaitResults } = useGaitResultsActions();
  const [hasInitResult, setHasInitResult] = useState(false);
  const relativeLandmarks = React.useRef([]);
  const [useSensorForCycle, setUseSensorForCycle] = useState(false);

  const gait = useSelector((state) =>
    front ? state.gaitFront.values : state.gait.values
  );

  useEffect(() => {
    if (front) {
      setLegAngleData(initialData());
    }
  }, [front]);

  useEffect(() => {
    resetGaitResults();
    addResults().then((res) => {
      if (res?.id) {
        setHasInitResult(true);
      } else {
        // reload the window
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (
      gait.stepsLeft >= GAIT_MIN_STEPS / 2 &&
      gait.stepsRight >= GAIT_MIN_STEPS / 2
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [gait.stepsLeft, gait.stepsRight]);

  useEffect(() => {
    if (front) {
      setObservationComponent(
        <GaitAnalysisFront
          setLegAngleData={setLegAngleData}
          showWebcam={showWebcam}
          recordingEnabled={recordingEnabled}
        />
      );
      setFeedbackComponent(<GaitFeedbacks front={true} />);
    } else {
      if (!videoUrl) {
        setObservationComponent(
          <GaitAnalysis
            key={"camGait"}
            setLegAngleData={setLegAngleData}
            showWebcam={showWebcam}
            videoUrl={videoUrl}
            sensorData={sensorData}
            recordingEnabled={recordingEnabled}
            relativeLandmarksRef={relativeLandmarks}
            useSensorForCycle={useSensorForCycle}
          />
        );
        // setFeedbackComponent(<GaitFeedbacks />);
      } else {
        setObservationComponent(<> </>);
        setFeedbackComponent(<> </>);
        setVideoObservationComponent(
          <GaitAnalysis
            key={videoUrl}
            setLegAngleData={setLegAngleData}
            showWebcam={showWebcam}
            videoUrl={videoUrl}
            sensorData={sensorData}
            recordingEnabled={recordingEnabled}
            relativeLandmarksRef={relativeLandmarks}
            useSensorForCycle={useSensorForCycle}
          />
        );
      }
    }
  }, [showWebcam, front, recordingEnabled, videoUrl]);

  if (!hasInitResult) {
    return <CircularProgress />;
  }

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "red",
          }}
        >
          {" "}
          {videoUrl && (
            <IconButton
              onClick={() => {
                setVideoUrl(null);
                URL.revokeObjectURL(videoUrl);
                setLegAngleData(initialData());
                resetGaitResults();
                setRecordingEnabled(false);
                setVideoObservationComponent(<> </>);
              }}
              style={{
                color: "black",
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}{" "}
        </Grid>{" "}
        <Grid
          item
          xs={6}
          sm={8}
          style={{
            position: "relative",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              backgroundColor: "black",
              height: "100%",
              borderRadius: 20,
            }}
          >
            {" "}
            {!videoUrl && observationComponent} {videoObservationComponent}{" "}
            {feedbackComponent}{" "}
          </div>{" "}
        </Grid>{" "}
        <Grid item xs={5.5} sm={3.5}>
          <Box
            sx={{
              position: "relative", // Add this line
              overflow: "hidden",
              backgroundColor: "white",
              height: "100%",
            }}
          >
            {" "}
            {legAngleData[0].data.length > 0 ? (
              <AnglesChart
                data={legAngleData}
                title={"Leg Flex/Extension"}
                chartKey={"leg-flex-extension"}
                height={"100%"}
                strokeWidth={5}
              />
            ) : (
              recordingEnabled && <CircularProgress />
            )}{" "}
            {!recordingEnabled && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setRecordingEnabled(true);
                  }}
                >
                  Start Recording{" "}
                </Button>{" "}
                <div
                  style={{
                    height: 20,
                  }}
                />{" "}
                <VideoSelector
                  setVideoUrl={(val) => {
                    setVideoUrl(val);
                    setRecordingEnabled(true);
                  }}
                  videoUrl={videoUrl}
                />{" "}
              </Box>
            )}{" "}
          </Box>{" "}
        </Grid>{" "}
        <Grid
          container
          spacing={1}
          sx={{
            margin: 1,
          }}
        >
          <Grid item xs={10} sm={10}>
            <Box
              sx={{
                borderColor: "black",
                borderRadius: 1,
                border: 1,
                paddingLeft: 2,
                height: "100%",
                width: "100%",
              }}
            >
              <LinearWithValueLabel front={front} />{" "}
            </Box>{" "}
          </Grid>{" "}
          <Grid
            item
            xs={2}
            sm={1.8}
            sx={{
              display: "flex",
              justifyContent: "right",
              height: "100%",
              width: "100%",
              marginRight: 0,
            }}
          >
            <LoadingButton
              fullWidth
              loading={loading}
              sx={{
                borderRadius: "80",
              }}
              variant={"outlined"}
              disabled={!buttonEnabled}
              onClick={() => {
                const appendix = front ? "-FRONT" : "";
                if (!front) {
                  setLoading(true);
                  dispatch({
                    type: `UPDATE-GAIT${appendix}-OBSERVATION`,
                    payload: {
                      graphData: legAngleData,
                      relativeLandmarks: relativeLandmarks.current,
                      sensorData: sensorData.current,
                    },
                  });
                  navigate("/gait_result", {
                    replace: false,
                  });
                } else {
                  dispatch({
                    type: `UPDATE-GAIT${appendix}-OBSERVATION`,
                    payload: {
                      graphData: legAngleData,
                      relativeLandmarks: relativeLandmarks.current,
                      sensorData: sensorData.current,
                    },
                  });
                  setFront(true);
                  navigate("/gait_analysis", {
                    replace: false,
                  });
                }
              }}
            >
              {" "}
              {!front ? "FINISH" : "OBSERVE FRONT"}{" "}
            </LoadingButton>{" "}
          </Grid>{" "}
        </Grid>{" "}
      </Grid>{" "}
      <Box
        sx={{
          marginTop: 2,
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            sm={6.8}
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            <Typography
              sx={{
                mt: 1,
              }}
            >
              {" "}
              {"Hide Webcam Video"}{" "}
            </Typography>{" "}
            <Switch
              onClick={() => {
                setShowWebcam(!showWebcam);
              }}
            />{" "}
          </Grid>{" "}
        </Grid>{" "}
        <Stridalizer />
        <div
          style={{
            marginTop: 2,
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Switch
            checked={useSensorForCycle}
            onClick={() => {
              setUseSensorForCycle(!useSensorForCycle);
            }}
          />{" "}
          <Typography> use sensors for both cycle and pressure </Typography>{" "}
        </div>{" "}
      </Box>{" "}
    </div>
  );
}
