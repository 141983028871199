import React, { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ResultPdf from "./ResultPdf";

export default function ResultPdfDownloadButton({
  cycleImage,
  pressureImage,
  pressureMapImage,
  doctorMetricsImage,
  patientInfo,
  metrics = {},
}) {
  const [loading, setLoading] = useState(false);

  const handleDocumentLoad = () => {
    setLoading(true);
  };

  return (
    <PDFDownloadLink
      document={
        <ResultPdf
          doctorMetricsImage={doctorMetricsImage}
          cycleImage={cycleImage}
          pressureImage={pressureImage}
          pressureMapImage={pressureMapImage}
          metrics={metrics}
          patientInfo={patientInfo}
        />
      }
      fileName="gait_report.pdf"
      onLoad={handleDocumentLoad}
    >
      {({ loading: pdfLoading }) => (
        <Button
          disabled={pdfLoading || loading}
          startIcon={
            loading ? <CircularProgress size={20} /> : <CloudDownloadIcon />
          }
        >
          {loading ? "Generating document..." : "Download now!"}
        </Button>
      )}
    </PDFDownloadLink>
  );
}
