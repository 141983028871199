import * as React from 'react';
import ic1 from '../../assets/ic1.png';
import ic2 from '../../assets/ic2.png';
import './PointsComponent.css';
import {
    useTranslation
} from 'react-i18next';

export default function PointsComponent({
    data
}) {
    const {
        t
    } = useTranslation();
    // console.log('data in points::', JSON.stringify(data));
    return ( <
        div className = "points-container" >
        <
        div className = "single-point-container" >
        <
        div className = "left" >
        <
        img src = {
            ic1
        }
        alt = "Bag Icon" / >
        <
        /div> <
        div className = "right" >
        <
        span > {
            t('PointsComponent.Workouts')
        } < /span> <
        h1 > {
            data ? data.workouts : 0
        } < /h1> <
        /div> <
        span className = "tooltiptext" > {
            t('PointsComponent.Workouts')
        } < /span> <
        /div> <
        div className = "single-point-container" >
        <
        div className = "left" >
        <
        img src = {
            ic2
        }
        alt = "Bar Graph Icon" / >
        <
        /div> <
        div className = "right" >
        <
        span > {
            t('PointsComponent.sweatPoints')
        } < /span> <
        h1 > {
            data ? Math.round(parseFloat(data.sweat_points)) : 0
        } < /h1> <
        /div> <
        span className = "tooltiptext" > {
            t('PointsComponent.sweatPoints')
        } < /span> <
        /div> <
        div className = "single-point-container" >
        <
        div className = "left" >
        <
        img src = {
            ic1
        }
        alt = "Bag Icon" / >
        <
        /div> <
        div className = "right" >
        <
        span > {
            t('PointsComponent.xrCoins')
        } < /span> <
        h1 > {
            data ? data.coins : 0
        } < /h1> <
        /div> <
        span className = "tooltiptext" > {
            t('PointsComponent.xrCoins')
        } < /span> <
        /div> <
        /div>
    );
}