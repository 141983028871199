const initialState = {};
export const allClassesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL-CLASSES": {
      return { ...state, values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const allSetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL-SETS": {
      return { ...state, values: action.payload };
    }
    default: {
      return state;
    }
  }
};
